import {
  HStack,
  Heading,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Stack,
  Tooltip,
  IconButton,
  Switch,
  useToast,
  Box,
  CircularProgress,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { MdOutlineCreate } from "react-icons/md";
import { getNotificaciones, editarNotificaciones } from "./helpers/api.helper";
import { useSelector } from "react-redux";

const AdministrarNotificaciones = () => {
  const [loading, setLoading] = useState(false);
  const [notificaciones, setNotificaciones] = useState([]);
  const navigate = useNavigate();
  const toast = useToast();
  const { user } = useSelector((state) => state.user);

  const initData = async () => {
    setLoading(true);
    let res;
    res = await getNotificaciones();
    if (res.status === 200) {
      setNotificaciones(res.data);
    } else {
      toast({
        status: "error",
        title: "Error",
        description: "Fallo la carga de datos",
        duration: 3000,
        isClosable: true,
      });
    }

    setLoading(false);
  };

  const handlePersonas = (event) => {
    let b = [];
    if (event.rolUsuarios) {
      for (let index = 0; index < event.rolUsuarios.length; index++) {
        const element = event.rolUsuarios[index];
        switch (element.rolUsuario) {
          case "admin":
            b.push("Admin");
            break;
          case "cliente":
            b.push("Cliente");
            break;
          case "usuarioBrent":
            b.push("Usuario BRENT");
            break;
          case "coorOp":
            b.push("Coor. de Operaciones");
            break;
          case "userOperaciones":
            b.push("Operaciones");
            break;
          case "userFacturacion":
            b.push("Facturación");
            break;
          case "userHS":
            b.push("Higiene y Seguridad");
            break;
          case "gerencia":
            b.push("Gerencia");
            break;
          case "userMantenimiento":
            b.push("Mantenimiento");
            break;
          case "usuarioIT":
            b.push("IT");
            break;

          default:
            b.push("Usuario BRENT");
            break;
        }
      }
    }

    let x = b.map((nombre) => nombre + "\n");

    return x.join("");
  };

  const loadingtable = () => {
    return (
      <HStack w="100%" placeContent="center">
        <CircularProgress isIndeterminate color="brand.naranja" />
      </HStack>
    );
  };

  const handleVia = (elemento) => {
    let x;
    if (elemento.rolUsuarios) {
      x = elemento.rolUsuarios.map(
        (elem) =>
          `${elem.telegram === 1 ? "Telegram " : ""}${
            elem.email && elem.telegram ? "/" : ""
          } ${elem.email === 1 ? "Email " : ""}${
            elem.sandTrucker && (elem.email || elem.telegram) ? "/" : ""
          } ${elem.sandTrucker === 1 ? "SandPro " : ""} \n`
      );

      return x.join("");
    }
  };

  const handleInactivarNotificacion = async (elemento) => {
    const { resultadoTipoE } = elemento;
    let state = resultadoTipoE;

    let res = await editarNotificaciones({
      id: elemento.idTipoE,
      resultadoTipoE: state ? false : true,
    });

    if (res.status === 200) {
      toast({
        status: "success",
        title: "Exito",
        description: `${state ? "Inhabilitado" : "Habilitado"} correctamente`,
        isClosable: true,
        duration: 2000,
      });
    } else {
      toast({
        status: "error",
        title: "Error",
        description: "Error al inhabilitar",
        isClosable: true,
        duration: 2000,
      });
    }

    initData();
  };

  useEffect(() => {
    initData();
  }, []);

  return (
    <Stack w="100%" h="lg">
      <HStack w="100%" justifyContent={"space-between"} px={5}>
        <Heading
          fontWeight="medium"
          size="xl"
          fontFamily="fonts.title"
          color="brand.gris_primario"
        >
          Administrar de notificaciones
        </Heading>
      </HStack>
      {loading && loadingtable()}
      {!loading && notificaciones.length > 0 && (
        <TableContainer w="100%" maxH={"100%"} overflowY="auto">
          <Table size={"sm"}>
            <Thead>
              <Tr>
                <Th>Evento</Th>
                <Th>Criticidad</Th>
                <Th>Personas a notificar</Th>
                <Th>Vía</Th>
                {(user.idRol === "admin" || user.idRol === "coorOp") && (
                  <Th>Inactiva / Activa</Th>
                )}
                <Th></Th>
              </Tr>
            </Thead>
            <Tbody>
              {notificaciones.map((notificacion) => (
                <Tr key={notificacion.idTipoE}>
                  <Td>
                    {notificacion.nombreTipoE}{" "}
                    {notificacion.claseTipoE !== "-" && notificacion.claseTipoE}
                  </Td>
                  <Td>{notificacion.criticidadTipoE || "-"}</Td>
                  <Td py={2} whiteSpace={"pre-wrap"}>
                    {handlePersonas(notificacion)}
                  </Td>
                  <Td whiteSpace={"pre-wrap"}>{handleVia(notificacion)}</Td>
                  <Td>
                    {(user.idRol === "admin" || user.idRol === "coorOp") && (
                      <Box display={"flex"} placeContent={"center"}>
                        <Switch
                          onChange={() =>
                            handleInactivarNotificacion(notificacion)
                          }
                          isChecked={notificacion.resultadoTipoE}
                          size={"lg"}
                        />
                      </Box>
                    )}
                  </Td>
                  <Td>
                    <Tooltip label="Editar Notificación">
                      <IconButton
                        variant={"link"}
                        icon={<MdOutlineCreate fontSize="20px" />}
                        onClick={() =>
                          navigate(
                            `/administrarNotificaciones/editar/${notificacion.idTipoE}`
                          )
                        }
                        _hover={{ color: "#FF8200" }}
                      />
                    </Tooltip>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      )}
      {!loading && notificaciones.length < 1 && (
        <Heading textAlign={"center"} size={"md"}>
          No hay configuraciones
        </Heading>
      )}
    </Stack>
  );
};

export default AdministrarNotificaciones;
