import React, { useEffect, useRef, useState } from "react";
import {
  Stack,
  HStack,
  Heading,
  Input,
  useToast,
  CircularProgress,
  Tooltip,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverArrow,
  PopoverHeader,
  List,
  ListItem,
  Progress,
  IconButton,
  Box,
  Text,
  Center,
} from "@chakra-ui/react";
import {
  MdOutlineGetApp,
  MdFilterList,
  MdRefresh,
  MdCheck,
  MdOutlineClose,
  MdArrowBack,
} from "react-icons/md";
import { descargarReportes } from "../operaciones/helpers/api.helper";
import { getReportesViajes, editarReportesViajes } from "./helpers/api.helper";
import { getTransportista } from "../inventario/helpers/api.helper";
import { getCamiones } from "../operaciones/helpers/api.helper";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import InfiniteScroll from "react-infinite-scroll-component";
import DisenoTabla from "./components/DisenoTabla";
import DisenoTablaFiltrada from "./components/DisenoTablaFiltrada";
import moment from "moment";

const ReporteViajes = () => {
  const [initialLoading, setInitialLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [loadingIII, setLoadingIII] = useState(false);
  const [formLoad, setFormLoad] = useState(false);
  const [tempDate, setTempDate] = useState({
    inicio: "",
    fin: "",
  });
  const [filterBy, setFilterBy] = useState({
    statusRV: [],
    inicio: "",
    fin: "",
    padDes: [],
    status2RV: [],
    transportista: [],
    dominio: [],
    interno: [],
  });
  const [reportesViajes, setReportesViajes] = useState([]);
  const [lengthReportes, setLengthReportes] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [nombresPad, setNombresPad] = useState([]);
  const [nombresTransportista, setNombresTransportista] = useState([]);
  const [internos, setInternos] = useState([]);
  const [dominios, setDominios] = useState([]);
  const [camiones, setCamiones] = useState([]);
  const [filtroCamiones, setFiltroCamiones] = useState("");
  const [filtroInterno, setFiltroInterno] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [scrollPositionX, setScrollPositionX] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [fechaFin, setFechaFin] = useState();
  const [camionFin, setCamionFin] = useState();
  const [filtroCamionesII, setFiltroCamionesII] = useState();
  const navigate = useNavigate();
  const toast = useToast();
  const { user } = useSelector((state) => state.user);
  const { padLocacion } = useParams();
  const padDestino = padLocacion;
  const scrollRef = useRef(null);

  const initData = async () => {
    setLoading(true);
    let res;
    let response;
    try {
      res = await getCamiones();
      if (res.status === 200) {
        setCamiones(res.data);
      }

      if (padLocacion) {
        res = await getReportesViajes({
          currentPage,
          padDestino,
        });
      } else {
        res = await getReportesViajes({
          currentPage,
        });
        response = await getTransportista();
      }

      if (res.status === 200) {
        setReportesViajes(res.data.reportes);
        setLengthReportes(res.data.totalData);
        setNombresPad(res.data.nombresPAD);
        setDominios(res.data.nombresCamiones);
        setInternos(res.data.internoCamiones);
        if (padLocacion) {
          setNombresTransportista(res.data.nombresTransportista);
        } else {
          let nombres = response.data.map((e) => e.nombreEmpresaT);
          setNombresTransportista(nombres);
        }
        if (res.data.totalData <= 50) {
          setHasMore(false);
        } else {
          setCurrentPage(currentPage + 1);
        }
      } else if (res.status === 404) {
        toast({
          status: "success",
          description: "Cargados correctamente",
          isClosable: true,
          duration: 2000,
        });
      }
    } catch (error) {
      console.error("Error al cargar los reportes:", error);
      toast({
        title: "Error",
        description: "Error al cargar los reportes",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
      setInitialLoading(false);
    }
  };

  const handleFilterSelection = (filterName, value) => {
    setFilterBy((prevState) => {
      const updatedState = {
        ...prevState,
        [filterName]: prevState[filterName].includes(value)
          ? prevState[filterName].filter((val) => val !== value)
          : [...prevState[filterName], value],
      };

      if (
        updatedState.statusRV.length === 0 &&
        updatedState.inicio === "" &&
        updatedState.fin === "" &&
        updatedState.padDes.length === 0 &&
        updatedState.status2RV.length === 0 &&
        updatedState.transportista.length === 0 &&
        updatedState.dominio.length === 0
      ) {
        initData();
      }
      return updatedState;
    });
  };

  const cleanFilter = () => {
    setFilterBy({
      statusRV: [],
      inicio: "",
      fin: "",
      padDes: [],
      status2RV: [],
      transportista: [],
      dominio: [],
      interno: [],
    });
    initData();
  };

  const handleDownload = async (datos) => {
    const {
      statusRV,
      inicio,
      fin,
      padDes,
      status2RV,
      transportista,
      dominio,
      interno,
    } = filterBy;
    let data;

    if (
      statusRV.length === 0 &&
      inicio === "" &&
      fin === "" &&
      padDes.length === 0 &&
      status2RV.length === 0 &&
      transportista.length === 0 &&
      dominio.length === 0 &&
      interno.length === 0
    ) {
      setLoadingIII(true);
      let res;
      if (padLocacion) {
        res = await getReportesViajes({ padDestino });
      } else {
        res = await getReportesViajes();
      }
      let reportesTotales = res.data.reportes.map((obj) => {
        return {
          ...obj,
          tsPozoOut: obj.tsPozoOut
            ? moment.utc(obj.tsPozoOut).format("YYYY/MM/DD HH:mm")
            : null,
          tsPlantaIn: obj.tsPlantaIn
            ? moment.utc(obj.tsPlantaIn).format("YYYY/MM/DD HH:mm")
            : null,
          tsPlantaOut: obj.tsPlantaOut
            ? moment.utc(obj.tsPlantaOut).format("YYYY/MM/DD HH:mm")
            : null,
          tsPozoIn: obj.tsPozoIn
            ? moment.utc(obj.tsPozoIn).format("YYYY/MM/DD HH:mm")
            : null,
          eta: obj.eta ? moment.utc(obj.eta).format("YYYY/MM/DD HH:mm") : null,
          fechaRV: obj.tsPozoIn
            ? moment.utc(obj.tsPozoIn).format("YYYY/MM/DD")
            : moment.utc(obj.fechaRV).format("YYYY/MM/DD"),
          cargaTotal: 27,
        };
      });

      data = reportesTotales;
    } else {
      let reportesTotales = datos.map((obj) => {
        obj.cargaTotal = 27;
        return obj;
      });
      data = reportesTotales;
    }

    let response = await descargarReportes({ reportes: data });
    if (response.status === 200) {
      setLoadingIII(false);

      const url = window.URL.createObjectURL(
        new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
        })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `Reporte-de-viajes.xlsx`);
      document.body.appendChild(link);
      link.click();
    } else if (response.status === 400) {
      return toast({
        title: "Error al descargar",
        description: "Error al descargar",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } else if (response.status === 500) {
      return toast({
        title: "Error",
        description: "Error del servidor ",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const loadingtable = (evento) => {
    if (evento === "inicial") {
      return (
        <HStack w="100%" placeContent="center">
          <CircularProgress isIndeterminate color="brand.naranja" />
        </HStack>
      );
    }
    if (evento === "tabla") {
      return (
        <HStack w="100%" placeContent="center">
          <Progress size="xs" isIndeterminate />
        </HStack>
      );
    }
  };

  const fetchMoreData = async () => {
    if (loading) return;
    setLoading(true);

    try {
      let res;
      if (padLocacion) {
        res = await getReportesViajes({
          currentPage,
          padDestino,
          ...filterBy,
        });
      } else {
        res = await getReportesViajes({
          currentPage,
          ...filterBy,
        });
      }

      if (res.status === 200) {
        if (res.data) {
          // Si hay datos en la respuesta, los agregamos al estado existente
          setReportesViajes((prevData) => [...prevData, ...res.data.reportes]);
          setLengthReportes(res.data.totalData);
        }

        if (res.data.totalData % 50 === 0 || res.data.reportes.length < 50) {
          setHasMore(false);
        } else {
          setCurrentPage(currentPage + 1);
        }
      } else {
        // Manejar errores de la solicitud
        toast({
          status: "error",
          description: "Error al cargar reportes",
          isClosable: true,
          duration: 2000,
        });
      }
    } catch (error) {
      // Manejar errores de la solicitud
      console.error("Error al cargar más datos:", error);
      toast({
        title: "Error",
        description: "Error al cargar más datos",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
      setInitialLoading(false);
    }

    setScrollPosition(scrollPosition + 1200);
    setScrollPositionX(scrollPositionX + 200);
  };

  /*   useEffect(() => {
    fetchMoreData();
  }, []); */

  useEffect(() => {
    if (!initialLoading && scrollRef.current) {
      scrollRef.current.scrollTop = 0; // Desplazar el scroll hacia la parte superior solo una vez
    }
  }, [initialLoading]);

  const handleCierreManual = async (editable, fechaFin, camionFin) => {
    setFormLoad(true);

    if (!fechaFin) {
      setLoading(false);
      return toast({
        title: "Error",
        description: "Fecha Fin requerida",
        status: "error",
        isClosable: true,
        duration: 3000,
      });
    }

    if (!camionFin) {
      setLoading(false);
      return toast({
        title: "Error",
        description: "Camion requerido",
        status: "error",
        isClosable: true,
        duration: 3000,
      });
    }

    let res;

    res = await editarReportesViajes({
      id: editable.idReporteViaje,
      camionFinal: camionFin.dominio,
      tsPozoIn: fechaFin,
    });

    if (res.status === 200) {
      toast({
        status: "success",
        isClosable: true,
        title: "Reporte finalizado correctamente",
        duration: 3000,
      });
      onClose();
      initData();
    } else {
      return toast({
        status: "error",
        isClosable: true,
        title: "Error al finalizar el reporte",
        description: res.data.error.message,
      });
    }

    setFormLoad(false);
  };

  const onClose = () => {
    setIsOpen(false);
    setFiltroCamionesII("");
    setCamionFin();
    setFechaFin();
  };

  useEffect(() => {
    initData();
  }, []);

  return (
    <Stack w="100%" h={"lg"}>
      {loadingIII && loadingtable("inicial")}
      {!loadingIII && (
        <>
          <HStack justifyContent="space-between" px={3}>
            <HStack fontWeight="medium">
              {padLocacion && (
                <Box>
                  <IconButton
                    icon={<MdArrowBack />}
                    variant="link"
                    onClick={() => navigate(-1)}
                    fontSize="24px"
                  />
                </Box>
              )}
              <Heading
                fontWeight="medium"
                size="xl"
                fontFamily="fonts.title"
                color="brand.gris_primario"
              >
                Reporte de viajes
              </Heading>
              {padLocacion && (
                <Text fontSize="16px" placeSelf="end" letterSpacing={1}>
                  en {padLocacion}
                </Text>
              )}
            </HStack>
            <HStack pr={2}>
              {(user.idRol === "admin" ||
                user.idRol === "coorOp" ||
                user.idRol === "userMantenimiento" ||
                user.idRol === "useFacturacion" ||
                user.idRol === "userHS" ||
                user.idRol === "gerencia") && (
                <Tooltip label="Descargar XLSX">
                  <IconButton
                    size="sm"
                    variant="outline"
                    border="1px solid #FF8200"
                    icon={<MdOutlineGetApp fontSize="20px" />}
                    color="brand.naranja"
                    _hover={{ bg: "brand.naranja", color: "white" }}
                    onClick={() => handleDownload(reportesViajes)}
                  />
                </Tooltip>
              )}
              <Popover isLazy>
                <PopoverTrigger>
                  <IconButton
                    icon={<MdFilterList fontSize="20px" />}
                    bg={"brand.fondos_secundarios"}
                    borderRadius="50%"
                    color={"black"}
                  />
                </PopoverTrigger>
                <PopoverContent>
                  <PopoverArrow />
                  <PopoverHeader>
                    <Tooltip label="Limpiar filtro">
                      <IconButton
                        icon={<MdRefresh />}
                        onClick={cleanFilter}
                        variant="link"
                        justifySelf={"end"}
                      />
                    </Tooltip>
                  </PopoverHeader>
                  <PopoverBody maxH={"sm"} overflowY={"auto"}>
                    <Accordion w={"100%"} allowToggle>
                      <AccordionItem>
                        <AccordionButton fontWeight={"bold"}>
                          Locacion
                        </AccordionButton>
                        <AccordionPanel>
                          <List cursor={"pointer"}>
                            <HStack w={"100%"}>
                              {filterBy.status2RV.includes("EN POZO") && (
                                <MdCheck />
                              )}
                              <ListItem
                                w={"100%"}
                                style={
                                  filterBy["status2RV"].includes("EN POZO")
                                    ? { backgroundColor: "lightgray" }
                                    : {}
                                }
                                onClick={() => {
                                  handleFilterSelection("status2RV", "EN POZO");
                                }}
                              >
                                En pozo
                              </ListItem>
                            </HStack>
                            <HStack w={"100%"}>
                              {filterBy.status2RV.includes(
                                "EN TRÁNSITO A POZO"
                              ) && <MdCheck />}
                              <ListItem
                                style={
                                  filterBy["status2RV"].includes(
                                    "EN TRÁNSITO A POZO"
                                  )
                                    ? { backgroundColor: "lightgray" }
                                    : {}
                                }
                                onClick={() => {
                                  handleFilterSelection(
                                    "status2RV",
                                    "EN TRÁNSITO A POZO"
                                  );
                                }}
                              >
                                En tránsito a pozo
                              </ListItem>
                            </HStack>
                            <HStack w={"100%"}>
                              {filterBy.status2RV.includes(
                                "EN TRÁNSITO A PLANTA"
                              ) && <MdCheck />}
                              <ListItem
                                style={
                                  filterBy["status2RV"].includes(
                                    "EN TRÁNSITO A PLANTA"
                                  )
                                    ? { backgroundColor: "lightgray" }
                                    : {}
                                }
                                onClick={() => {
                                  handleFilterSelection(
                                    "status2RV",
                                    "EN TRÁNSITO A PLANTA"
                                  );
                                }}
                              >
                                En tránsito a planta
                              </ListItem>
                            </HStack>
                          </List>
                        </AccordionPanel>
                      </AccordionItem>
                      <AccordionItem>
                        <AccordionButton fontWeight={"bold"}>
                          Estado
                        </AccordionButton>
                        <AccordionPanel>
                          <List>
                            <HStack w={"100%"}>
                              {filterBy.statusRV.includes("En curso") && (
                                <MdCheck />
                              )}
                              <ListItem
                                w={"100%"}
                                cursor={"pointer"}
                                style={
                                  filterBy["statusRV"].includes("En curso")
                                    ? { backgroundColor: "lightgray" }
                                    : {}
                                }
                                onClick={() => {
                                  handleFilterSelection("statusRV", "En curso");
                                }}
                              >
                                En curso
                              </ListItem>
                            </HStack>
                            <HStack w={"100%"}>
                              {filterBy.statusRV.includes("Finalizado") && (
                                <MdCheck />
                              )}
                              <ListItem
                                w={"100%"}
                                cursor={"pointer"}
                                style={
                                  filterBy["statusRV"].includes("Finalizado")
                                    ? { backgroundColor: "lightgray" }
                                    : {}
                                }
                                onClick={() => {
                                  handleFilterSelection(
                                    "statusRV",
                                    "Finalizado"
                                  );
                                }}
                              >
                                Finalizado
                              </ListItem>
                            </HStack>
                            <HStack w={"100%"}>
                              {filterBy.statusRV.includes("Caducado") && (
                                <MdCheck />
                              )}
                              <ListItem
                                w={"100%"}
                                cursor={"pointer"}
                                style={
                                  filterBy["statusRV"].includes("Caducado")
                                    ? { backgroundColor: "lightgray" }
                                    : {}
                                }
                                onClick={() => {
                                  handleFilterSelection("statusRV", "Caducado");
                                }}
                              >
                                Caducado
                              </ListItem>
                            </HStack>
                          </List>
                        </AccordionPanel>
                      </AccordionItem>
                      <AccordionItem>
                        <AccordionButton fontWeight={"bold"}>
                          PAD Destino
                        </AccordionButton>
                        <AccordionPanel>
                          <List cursor={"pointer"}>
                            {nombresPad.map((element) => (
                              <HStack w={"100%"} key={element}>
                                {filterBy.padDes.includes(element) && (
                                  <MdCheck />
                                )}
                                <ListItem
                                  w={"100%"}
                                  style={
                                    filterBy["padDes"].includes(element)
                                      ? { backgroundColor: "lightgray" }
                                      : {}
                                  }
                                  onClick={() => {
                                    handleFilterSelection("padDes", element);
                                  }}
                                >
                                  {element}
                                </ListItem>
                              </HStack>
                            ))}
                          </List>
                        </AccordionPanel>
                      </AccordionItem>
                      <AccordionItem>
                        <AccordionButton fontWeight={"bold"}>
                          Empresa Transportista
                        </AccordionButton>
                        <AccordionPanel>
                          <List cursor={"pointer"}>
                            {nombresTransportista.map((element) => (
                              <HStack w={"100%"} key={element}>
                                {filterBy.transportista.includes(element) && (
                                  <MdCheck />
                                )}
                                <ListItem
                                  w={"100%"}
                                  style={
                                    filterBy["transportista"].includes(element)
                                      ? { backgroundColor: "lightgray" }
                                      : {}
                                  }
                                  onClick={() => {
                                    handleFilterSelection(
                                      "transportista",
                                      element
                                    );
                                  }}
                                >
                                  {element}
                                </ListItem>
                              </HStack>
                            ))}
                          </List>
                        </AccordionPanel>
                      </AccordionItem>
                      <AccordionItem>
                        <AccordionButton>
                          <Input
                            w="xs"
                            variant="flushed"
                            type="text"
                            placeholder="Camion"
                            style={{ fontWeight: "bold", color: "black" }}
                            onChange={(e) => {
                              const value = e.target.value ?? "";
                              setFiltroCamiones(value.toLowerCase());
                            }}
                          />
                        </AccordionButton>
                        <AccordionPanel h="100px" overflowY="scroll">
                          {dominios
                            .filter((element) =>
                              element
                                .toLowerCase()
                                .includes(filtroCamiones ?? "")
                            )
                            .map((element) => (
                              <HStack w={"100%"} key={element}>
                                {filterBy.dominio.includes(element) && (
                                  <MdCheck />
                                )}
                                <option
                                  w={"100%"}
                                  style={
                                    filterBy["dominio"].includes(element)
                                      ? { backgroundColor: "lightgray" }
                                      : {}
                                  }
                                  onClick={() => {
                                    handleFilterSelection("dominio", element);
                                  }}
                                >
                                  {element}
                                </option>
                              </HStack>
                            ))}
                        </AccordionPanel>
                      </AccordionItem>
                      <AccordionItem>
                        <AccordionButton>
                          <Input
                            w="xs"
                            variant="flushed"
                            type="text"
                            placeholder="Interno"
                            style={{ fontWeight: "bold", color: "black" }}
                            onChange={(e) => {
                              const value = e.target.value ?? "";
                              setFiltroInterno(value.toLowerCase());
                            }}
                          />
                        </AccordionButton>
                        <AccordionPanel h="100px" overflowY="scroll">
                          {internos
                            .filter((element) =>
                              element
                                .toLowerCase()
                                .includes(filtroInterno ?? "")
                            )
                            .map((element) => (
                              <HStack w={"100%"} key={element}>
                                {filterBy.interno.includes(element) && (
                                  <MdCheck />
                                )}
                                <option
                                  w={"100%"}
                                  style={
                                    filterBy["interno"].includes(element)
                                      ? { backgroundColor: "lightgray" }
                                      : {}
                                  }
                                  onClick={() => {
                                    handleFilterSelection("interno", element);
                                  }}
                                >
                                  {element}
                                </option>
                              </HStack>
                            ))}
                        </AccordionPanel>
                      </AccordionItem>
                      <AccordionItem>
                        <HStack w={"100%"} justifyContent={"space-between"}>
                          <AccordionButton fontWeight={"bold"}>
                            Fecha
                          </AccordionButton>
                          <IconButton
                            size={"12px"}
                            variant={"link"}
                            cursor={"pointer"}
                            as={MdOutlineClose}
                            onClick={() => {
                              setFilterBy((prevState) => ({
                                ...prevState,
                                inicio: "", // Reinicia el valor de inicio a una cadena vacía
                                fin: "", // Reinicia el valor de fin a una cadena vacía
                              }));
                              initData(); // Llama a initData después de actualizar el filtro
                            }}
                          />
                        </HStack>
                        <AccordionPanel>
                          <DatePicker
                            selectsRange={true}
                            startDate={tempDate.inicio || filterBy.inicio}
                            endDate={tempDate.fin || filterBy.fin}
                            dateFormat="dd/MM/yyyy"
                            onChange={(update) => {
                              const [start, end] = update;
                              setTempDate({
                                inicio: start,
                                fin: end,
                              });
                              if (start && end) {
                                if (start === end) {
                                  setFilterBy((prevState) => ({
                                    ...prevState,
                                    inicio: start,
                                    fin: start,
                                  }));
                                } else {
                                  setFilterBy((prevState) => ({
                                    ...prevState,
                                    inicio: start,
                                    fin: end,
                                  }));
                                }
                              }
                            }}
                            inline
                          />
                        </AccordionPanel>
                      </AccordionItem>
                    </Accordion>
                  </PopoverBody>
                </PopoverContent>
              </Popover>
            </HStack>
          </HStack>
          <>
            {initialLoading && loadingtable("inicial")}
            {!initialLoading && (
              <>
                {reportesViajes.length > 0 &&
                filterBy.statusRV.length === 0 &&
                filterBy.inicio === "" &&
                filterBy.fin === "" &&
                filterBy.padDes.length === 0 &&
                filterBy.status2RV.length === 0 &&
                filterBy.transportista.length === 0 &&
                filterBy.dominio.length === 0 &&
                filterBy.interno.length === 0 ? (
                  <InfiniteScroll
                    dataLength={reportesViajes.length}
                    next={fetchMoreData}
                    hasMore={hasMore}
                    loader={loadingtable("tabla")}
                    scrollableTarget="TBody"
                    initialScrollY={0}
                  >
                    <DisenoTabla
                      reportesViajes={reportesViajes}
                      user={user}
                      initData={initData}
                      setReportesViajes={setReportesViajes}
                      handleCierreManual={handleCierreManual}
                      camiones={camiones}
                      setCamiones={setCamiones}
                      isOpen={isOpen}
                      setIsOpen={setIsOpen}
                      fechaFin={fechaFin}
                      setFechaFin={setFechaFin}
                      camionFin={camionFin}
                      setCamionFin={setCamionFin}
                      filtroCamiones={filtroCamiones}
                      setFiltroCamiones={setFiltroCamionesII}
                      onClose={onClose}
                    />
                  </InfiniteScroll>
                ) : (
                  <>{loading && loadingtable("tabla")}</>
                )}
              </>
            )}
          </>
          {!loading &&
            (filterBy.statusRV.length !== 0 ||
              filterBy.inicio !== "" ||
              filterBy.fin !== "" ||
              filterBy.padDes.length !== 0 ||
              filterBy.status2RV.length !== 0 ||
              filterBy.transportista.length !== 0 ||
              filterBy.dominio.length !== 0 ||
              filterBy.interno.length !== 0) && (
              <DisenoTablaFiltrada
                reportesViajes={reportesViajes}
                user={user}
                initData={initData}
                setReportesViajes={setReportesViajes}
                setLoading={setLoading}
                filterBy={filterBy}
                padDestino={padDestino}
                setLengthReportes={setLengthReportes}
                handleCierreManual={handleCierreManual}
                camiones={camiones}
                setCamiones={setCamiones}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                fechaFin={fechaFin}
                setFechaFin={setFechaFin}
                camionFin={camionFin}
                setCamionFin={setCamionFin}
                filtroCamiones={filtroCamiones}
                setFiltroCamiones={setFiltroCamiones}
                onClose={onClose}
              />
            )}
          {!loading && reportesViajes.length <= 0 && (
            <Box py={5}>
              <Heading textAlign={"center"} size="xl">
                No hay reportes de viaje
              </Heading>
            </Box>
          )}
        </>
      )}
    </Stack>
  );
};

export default ReporteViajes;
