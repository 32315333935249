import React, { useEffect, useState } from "react";
import {
  Stack,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Button,
  HStack,
  Heading,
  Text,
  useDisclosure,
  IconButton,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  Select,
  ModalBody,
  ModalCloseButton,
  Input,
} from "@chakra-ui/react";
import {
  MdNavigateNext,
  MdNavigateBefore,
  MdOutlineDownloadDone,
  MdRemoveCircleOutline,
  MdAdd,
  MdArrowRight,
  MdOutlineClose,
} from "react-icons/md";
import {
  editarEstadoSandcubes,
  getSandcubes,
} from "../../../helpers/api.helper";
import { editarSandcube3 } from "../../../../inventario/helpers/api.helper";

const TercerPasoEtapa = (props) => {
  const {
    setActiveStep,
    activeStep,
    sandcubesVaciar,
    setSancubesVaciar,
    vacios,
    setVacios,
    showButton,
    setShowButton,
    planificacion,
    values,
  } = props;
  const [todosSandcubes, setTodosSancubes] = useState([]);
  const [showDialog, setShowDialog] = useState(false);
  const [loading, setLoading] = useState(true);
  const [filterADD, setFilterADD] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  const {
    onOpen: openTotalScs,
    onClose: closeTotalScs,
    isOpen: keepTotalScs,
  } = useDisclosure();

  const handleVaciados = async () => {
    setLoading(true);
    let res = await getSandcubes();

    if (res.status === 200) {
      let filteredArray = res.data.filter(
        (item1) =>
          !sandcubesVaciar.some(
            (item2) => item2.idSandCube === item1.idSandCube
          )
      );
      setTodosSancubes(filteredArray);
    }
    setLoading(false);
  };

  const handleSeleccionados = (sandcube) => {
    setVacios(vacios.filter((item) => item.idSandCube !== sandcube.idSandCube));
    setSancubesVaciar([...sandcubesVaciar, sandcube]);
  };

  const handleVacios = (e) => {
    setSancubesVaciar(
      sandcubesVaciar.filter((item) => item.idSandCube !== e.idSandCube)
    );
    if (sandcubesVaciar.length === 1) {
      setShowDialog(true);
      setShowButton(true);
    }
    setVacios([...vacios, e]);
  };

  const handleFinalizar = async () => {
    setLoading(true);
    let data;
    if (values.etapaWeTSanD) {
      data = vacios.map((e) => {
        return { ...e, statusSandCube: "Vacio" };
      });
    } else {
      data = vacios.map((e) => {
        return { ...e, statusSandCube: "Vacio", wetsandSC: false };
      });
    }

    let scToPut = data.map((sc) => {
      return {
        idSandCube: sc.idSandCube,
        statusSandCube: sc.statusSandCube,
        wetsandSC: false,
      };
    });

    let res = await editarSandcube3({
      sandCubes: data,
    });

    res = await editarEstadoSandcubes({ sandCubes: scToPut });

    if (res.status === 200) {
      toast({
        status: "success",
        isClosable: true,
        title: "SandCubes vaciados correctamente",
        duration: 3000,
      });
    } else {
      return toast({
        status: "error",
        isClosable: true,
        title: "Error al vaciar SandCubes",
        duration: 3000,
      });
    }
    setSancubesVaciar(data);
    onClose();
    setActiveStep(activeStep + 1);
    setLoading(false);
  };

  const handlePlantaSandcube = (value, sandcube) => {
    let updatedFacturaciones;

    const editarIndex = sandcubesVaciar.findIndex(
      (item) => item.idSandCube === sandcube.idSandCube
    );

    if (editarIndex !== -1) {
      const editar = sandcubesVaciar[editarIndex];
      editar.idPlanta = parseInt(value);
      let a = planificacion.detallePlantas.find(
        (planta) => planta.idPlanta === parseInt(value)
      );

      editar.nombrePlanta = a.nombrePlanta;

      // Actualizar facturaciones
      sandcubesVaciar[editarIndex] = editar;
      // Si necesitas una nueva copia del array facturaciones actualizada, puedes hacerlo así:
      updatedFacturaciones = [...sandcubesVaciar];
      // Utiliza la variable updatedFacturaciones en lugar de facturaciones si necesitas la actualización fuera de esta función
    }
    setSancubesVaciar(updatedFacturaciones);
  };

  const showSandcubesADD = () => {
    if (!filterADD) {
      return todosSandcubes.sort();
    }
    let item = [];

    let filtrado = filterADD.toUpperCase();

    item = todosSandcubes.filter((element) => {
      return element.nombreSandCube.toString().includes(filtrado);
    });

    return item;
  };

  const removerSandCube = (sandcube) => {
    setSancubesVaciar(
      sandcubesVaciar.filter((item) => item.idSandCube !== sandcube.idSandCube)
    );
    setTodosSancubes([...todosSandcubes, sandcube]);
  };

  const handleAgregar = (e) => {
    setTodosSancubes(
      todosSandcubes.filter((item) => item.idSandCube !== e.idSandCube)
    );
    if (e.statusSandCube === "Vacio") {
      setVacios([...vacios, e]);
    } else {
      setSancubesVaciar([...sandcubesVaciar, e]);
    }
  };

  const handleMallaSandcube = (value, sandcube) => {
    let updatedFacturaciones;

    const editarIndex = sandcubesVaciar.findIndex(
      (item) => item.idSandCube === sandcube.idSandCube
    );

    if (editarIndex !== -1) {
      const editar = sandcubesVaciar[editarIndex];
      editar.malla = value;

      // A ctualizar facturaciones
      sandcubesVaciar[editarIndex] = editar;
      // Si necesitas una nueva copia del array facturaciones actualizada, puedes hacerlo así:
      updatedFacturaciones = [...sandcubesVaciar];
      // Utiliza la variable updatedFacturaciones en lugar de facturaciones si necesitas la actualización fuera de esta función
    }
    setSancubesVaciar(updatedFacturaciones);
  };

  if (sandcubesVaciar.length < 1) {
    setShowButton(true);
  }

  useEffect(() => {
    handleVaciados();
  }, []);

  return (
    <Stack w="100%" h="100%" justifyContent="space-between">
      <HStack w="100%" px={3}>
        <Stack w="50%">
          <Heading size="md">Llenos</Heading>
          <TableContainer h="xs" overflowY="scroll">
            <Table size="sm" variant="simple">
              <Thead>
                <Tr>
                  <Th></Th>
                  <Th>ID Sandcube</Th>
                  <Th>Malla</Th>
                  <Th>Planta</Th>
                  <Th></Th>
                </Tr>
              </Thead>
              <Tbody>
                {sandcubesVaciar
                  .filter(
                    (item1) =>
                      !vacios.some(
                        (item2) => item2.idSandCube === item1.idSandCube
                      )
                  )
                  .map((sandcube) => (
                    <Tr key={sandcube.idSandCube}>
                      <Td>
                        <IconButton
                          variant={"link"}
                          size={"sm"}
                          icon={<MdOutlineClose />}
                          onClick={() => removerSandCube(sandcube)}
                        />
                      </Td>
                      <Td>{sandcube.nombreSandCube}</Td>
                      <Td>
                        <Select
                          placeholder="- Malla -"
                          variant={"unstyled"}
                          value={sandcube.malla}
                          onChange={(e) =>
                            handleMallaSandcube(e.target.value, sandcube)
                          }
                        >
                          <option value={"100"}>100</option>
                          <option value={"30/70"}>30/70</option>
                          <option value={"70/140"}>70/140</option>
                          <option value={"50/70"}>50/70</option>
                          <option value={"40/80"}>40/80</option>
                          <option value={"40/70"}>40/70</option>
                          <option value={"30/50"}>30/50</option>
                          <option value={"20/40"}>20/40</option>
                        </Select>
                      </Td>
                      <Td>
                        <Select
                          placeholder="- Planta -"
                          variant={"unstyled"}
                          value={sandcube.idPlanta}
                          onChange={(e) =>
                            handlePlantaSandcube(e.target.value, sandcube)
                          }
                        >
                          {planificacion.detallePlantas &&
                            planificacion.detallePlantas.map(
                              (planta, index) => (
                                <option key={index} value={planta.idPlanta}>
                                  {planta.nombrePlanta}
                                </option>
                              )
                            )}
                        </Select>
                      </Td>
                      <Td>
                        <Button
                          variant="link"
                          _hover={{ color: "brand.naranja" }}
                          onClick={() => handleVacios(sandcube)}
                        >
                          Vaciar
                        </Button>
                      </Td>
                    </Tr>
                  ))}
              </Tbody>
            </Table>
          </TableContainer>
        </Stack>
        <Stack w="50%">
          <Heading size="md">Vacios</Heading>
          <TableContainer h="xs" overflowY="scroll">
            <Table size="sm" variant="simple">
              <Thead>
                <Tr>
                  <Th>ID Sandcube</Th>
                  <Th>Malla</Th>
                  <Th>Planta</Th>
                  <Th></Th>
                </Tr>
              </Thead>
              <Tbody>
                {vacios.map((sandcube) => (
                  <Tr key={sandcube.idSandCube}>
                    <Td>{sandcube.nombreSandCube}</Td>
                    <Td>{sandcube.malla}</Td>
                    <Td>{sandcube.nombrePlanta || "-"}</Td>
                    <Td>
                      <IconButton
                        variant="link"
                        icon={<MdRemoveCircleOutline />}
                        onClick={() => handleSeleccionados(sandcube)}
                        _hover={{ color: "brand.naranja" }}
                      />
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        </Stack>
      </HStack>
      <HStack spacing={28} px={5}>
        <Button
          size="sm"
          variant="link"
          color="black"
          leftIcon={<MdAdd color="#FF8200" />}
          onClick={openTotalScs}
        >
          Agregar Sandcube
        </Button>
        <Modal isOpen={keepTotalScs} onClose={closeTotalScs} size={"lg"}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Sandcubes</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Input
                w="xs"
                type="text"
                value={filterADD}
                placeholder={`Buscar`}
                onChange={(e) => {
                  setFilterADD(e.target.value);
                }}
                variant="flushed"
              />
              <TableContainer h="xs" overflowY="scroll" pt={2}>
                <Table size="sm" variant="simple">
                  <Thead>
                    <Tr>
                      <Th>ID Sandcube</Th>
                      <Th>Malla</Th>
                      <Th></Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {showSandcubesADD().map((sandcube) => (
                      <Tr key={sandcube.idSandCube}>
                        <Td>{sandcube.nombreSandCube}</Td>
                        <Td>{sandcube.malla}</Td>

                        <Td>
                          <IconButton
                            variant="link"
                            icon={<MdArrowRight />}
                            onClick={() => {
                              handleAgregar(sandcube);
                            }}
                            _hover={{ color: "brand.naranja" }}
                          />
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </TableContainer>
            </ModalBody>

            <ModalFooter>
              <Button mr={3} onClick={closeTotalScs}>
                Cerrar
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </HStack>
      <HStack w="100%" justifyContent="space-around">
        <Text fontSize="12px" color="brand.azul_text">
          {sandcubesVaciar.length} sandcubes para vaciar
        </Text>
        <HStack w="2XS"></HStack>
      </HStack>

      <HStack w="100%" justifyContent="space-between" p={3}>
        <Button
          fontSize={12}
          fontWeight="semibold"
          color="brand.naranja"
          border="1px solid #FF8200"
          leftIcon={<MdNavigateBefore fontSize="20px" />}
          variant="outline"
          onClick={() => setActiveStep(activeStep - 1)}
          isDisabled={loading}
          _hover={{ bg: "brand.naranja", color: "white" }}
        >
          Atras
        </Button>
        {showButton && (
          <Button
            fontSize={12}
            fontWeight="semibold"
            bg="brand.naranja"
            color="white"
            rightIcon={<MdNavigateNext fontSize="20px" />}
            variant="solid"
            form="FormularioEtapa"
            onClick={() => setShowDialog(true)}
            isDisabled={loading}
            _hover={{ bg: "white", color: "brand.naranja" }}
          >
            Finalizar
          </Button>
        )}
      </HStack>
      {showDialog && (
        <Modal isOpen={showDialog} onClose={() => setShowDialog(false)}>
          <ModalOverlay />
          <ModalContent borderRadius="10px" py={5}>
            <ModalHeader py={5}>
              <HStack justifyContent="center">
                <MdOutlineDownloadDone color="#FF8200" fontSize="1.7rem" />{" "}
                <Text fontSize="1.7rem" fontWeight="bold">
                  Listo! Sandcubes vacíos
                </Text>
              </HStack>
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody textAlign="center">
              Se han vaciado todos los sandcubes para esta etapa. Continuar para
              finalizar la carga de los datos restantes.
            </ModalBody>

            <ModalFooter justifyContent="center">
              <Button
                bg="brand.naranja"
                color="white"
                fontSize="14px"
                letterSpacing={1}
                p={5}
                isDisabled={loading}
                onClick={() => {
                  handleFinalizar();
                  setShowDialog(false);
                }}
                ml={3}
              >
                Finalizar etapa
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </Stack>
  );
};

export default TercerPasoEtapa;
