import React, { useEffect, useState } from "react";
import {
  HStack,
  IconButton,
  Box,
  Heading,
  Text,
  Step,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepStatus,
  StepTitle,
  Stepper,
  useSteps,
  Stack,
  useToast,
} from "@chakra-ui/react";
import { MdArrowBack } from "react-icons/md";
import { Formik, Form } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import {
  getEtapas,
  getPlanificaciones,
  getPlanificacionEtapas,
} from "../../../helpers/api.helper";
import PrimerPasoEtapa from "./PrimerPasoEtapa";
import SegundoPasoEtapa from "./SegundoPasoEtapa";
import TercerPasoEtapa from "./TercerPasoEtapa";
import CuartoPasoEtapa from "./CuartoPasoEtapa";
import { useSelector } from "react-redux";
import { crearEtapa, editarEtapa } from "../../../helpers/api.helper";
import moment from "moment";
import { getUsuarios } from "../../../../usuarios/helpers/api.helper";
import { act } from "react-dom/test-utils";

const CrearEditarEtapas = (props) => {
  const { type, seleccionado, onClose, initTablaEtapas } = props;
  const [planificacion, setPlanificacion] = useState([]);
  const [loading, setLoading] = useState(true);
  const [etapasDatos, setEtapasDatos] = useState([]);
  const [usuarios, setUsuarios] = useState([]);
  const [sandcubesVaciar, setSancubesVaciar] = useState([]);
  const [sandCubesPlanificacion, setSandCubesPlanificacion] = useState([]);
  const [seleccionados, setSeleccionados] = useState(
    type === "editar" && seleccionado.sandCubes ? seleccionado.sandCubes : []
  );
  const [vacios, setVacios] = useState([]);
  const [showButton, setShowButton] = useState(false);
  const [formValues, setFormValues] = useState({
    initialValues:
      type === "editar"
        ? {
            idPozoEtapa: seleccionado.pozo.nombrePozo,
            fechaInicioEtapaSC: moment(seleccionado.fechaInicioEtapaSC).format(
              "YYYY-MM-DD hh:mm"
            ),
            fechaFinalEtapaSC: seleccionado.fechaFinalEtapaSC,
            numPozoEtapaSC: seleccionado.numPozoEtapaSC,
            numTotalEtapaSC: seleccionado.numTotalEtapaSC,
            supervisor: seleccionado.supervisor,
            operador1: seleccionado.operador1,
            operador2: seleccionado.operador2,
            maquinista: seleccionado.maquinista,
            idSander: seleccionado.sander.idSander,
            idAutoE: seleccionado.autoelevador.idAutoE,
            etapaWeTSanD: seleccionado.etapaWeTSanD,
            sandcubesEtapa: seleccionado.sandCubes,
            limpieza: seleccionado.limpieza,
          }
        : {
            idPozoEtapa: "",
            fechaInicioEtapaSC: moment().format("YYYY-MM-DD HH:mm"),
            fechaFinalEtapaSC: moment().format("YYYY-MM-DD HH:mm"),
            numPozoEtapaSC: "",
            numTotalEtapaSC: "",
            supervisor: "",
            operador1: "",
            operador2: "",
            maquinista: "",
            idSander: "",
            idAutoE: "",
            etapaWeTSanD: false,
            limpieza: false,
          },
  });
  const [text, setText] = useState("");
  const navigate = useNavigate();
  const toast = useToast();
  const { user } = useSelector((state) => state.user);
  const { padLocacion } = useParams();
  const limpieza = false;
  const steps = [
    { title: "Detalles etapa" },
    { title: "Seleccion de sandcubes para fractura" },
    { title: "Vaciado de Sandcubes" },
    { title: "Cierre de etapa" },
  ];
  const { activeStep, setActiveStep } = useSteps({
    index: 0,
    count: steps.length,
  });

  const initData = async () => {
    let res;
    res = await getPlanificaciones({ padLocacion });

    if (res.status === 200) {
      setPlanificacion(res.data[0]);
    } else if (res.status === 400) {
      setLoading(false);
      return toast({
        title: "Error",
        description: " Error al cargar datos",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }

    if (type === "crear") {
      let a;
      res = await getEtapas({ ultimo: true, padLocacion });
      if (res.status === 200) {
        if (!res.data || res.data.length === 0) {
          return null;
        }

        let maxObj = res.data[0];
        res.data.forEach((obj) => {
          if (
            obj.detalle["numTotalEtapaSC"] > maxObj.detalle["numTotalEtapaSC"]
          ) {
            maxObj = obj;
          }
        });

        a = maxObj.detalle.numTotalEtapaSC + 1;

        setEtapasDatos(res.data);
      } else if (res.status === 204) {
        a = 1;
      }

      setFormValues((prev) => ({
        initialValues: {
          ...prev.initialValues,
          numTotalEtapaSC: a,
        },
      }));
    }

    res = await getUsuarios();

    if (res.status === 200) {
      setUsuarios(res.data);
    } else if (res.status === 400) {
      setLoading(false);
      return toast({
        title: "Error",
        description: " Error al cargar datos",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }

    setLoading(false);
  };

  useEffect(() => {
    initData();
  }, []);

  return (
    <Stack w="100%" h="100%">
      <HStack pl={5} w="100%" bg="brand.fondos_secundario">
        <Box>
          {type === "crear" && (
            <IconButton
              icon={<MdArrowBack />}
              variant="link"
              onClick={() => navigate(-1)}
              fontSize="24px"
            />
          )}
        </Box>
        <Heading
          fontWeight="medium"
          size="xl"
          fontFamily="fonts.title"
          color="brand.gris_primario"
        >
          Nueva etapa
        </Heading>
        <Text fontSize="16px" placeSelf="end" letterSpacing={1}>
          de {planificacion.idPadlocacion}
        </Text>
      </HStack>
      {planificacion && (
        <>
          <HStack w="100%" px={3} justifyContent="space-between">
            <Stepper index={activeStep} colorScheme="orange">
              {steps.map((step, index) => (
                <Step key={step}>
                  <StepIndicator>
                    <StepStatus
                      complete={<StepIcon />}
                      incomplete={<StepNumber />}
                      active={<StepNumber />}
                    />
                  </StepIndicator>

                  <Box flexShrink="1">
                    <StepTitle>{step.title}</StepTitle>
                  </Box>
                </Step>
              ))}
            </Stepper>
            <Text color="brand.azul_text" fontSize={14}>
              {activeStep + 1} de {steps.length} partes
            </Text>
          </HStack>
          {!loading && (
            <Formik
              initialValues={formValues.initialValues}
              enableReinitialize={true}
              onSubmit={async (values) => {
                setLoading(true);

                if (
                  moment(values.fechaInicioEtapaSC).isAfter(
                    values.fechaFinalEtapaSC
                  )
                ) {
                  setLoading(false);
                  return toast({
                    title: "Error",
                    description:
                      "La fecha final debe ser posterior a la inicial",
                    status: "error",
                    isClosable: true,
                    duration: 3000,
                  });
                }

                let z = planificacion.pozos.find(
                  (pozo) => pozo.nombrePozo === values.idPozoEtapa
                );

                let data =
                  type === "editar"
                    ? {
                        idPlanificacion: planificacion.idPlanificacion,
                        idPozo: z.idPozo,
                        id: seleccionado.idEtapaSC,
                        numPozoEtapaSC: values.numPozoEtapaSC,
                        numTotalEtapaSC: values.numTotalEtapaSC,
                        fechaInicioEtapaSC: values.fechaInicioEtapaSC,
                        fechaFinalEtapaSC: values.fechaFinalEtapaSC,
                        supervisor: values.supervisor,
                        maquinista: values.maquinista,
                        operador1: values.operador1,
                        operador2: values.operador2,
                        idSander: values.idSander,
                        idAutoE: values.idAutoE,
                        etapaWeTSanD: values.etapaWeTSanD,
                        sandcubes: sandcubesVaciar,
                        limpieza: values.limpieza,
                        observacionesESC: text,
                      }
                    : {
                        idPlanificacion: planificacion.idPlanificacion,
                        idPozo: z.idPozo,
                        numPozoEtapaSC: values.numPozoEtapaSC,
                        numTotalEtapaSC: values.numTotalEtapaSC,
                        fechaInicioEtapaSC: values.fechaInicioEtapaSC,
                        fechaFinalEtapaSC: values.fechaFinalEtapaSC,
                        supervisor: values.supervisor,
                        maquinista: values.maquinista,
                        operador1: values.operador1,
                        operador2: values.operador2,
                        idSander: values.idSander,
                        idAutoE: values.idAutoE,
                        etapaWeTSanD: values.etapaWeTSanD,
                        sandcubes: sandcubesVaciar,
                        limpieza: values.limpieza,
                        observacionesESC: text,
                      };

                let res;
                console.log(data);
                console.log(type);
                if (type === "editar") {
                  res = await editarEtapa(data);
                } else {
                  res = await crearEtapa(data);
                }
                if (res.status === 200) {
                  toast({
                    status: "success",
                    isClosable: true,
                    title: `Etapa ${
                      type === "editar" ? "editada" : "creada"
                    } correctamente`,
                    duration: 3000,
                  });
                } else if (res.status === 403) {
                  setLoading(false);
                  return toast({
                    status: "error",
                    isClosable: true,
                    title: `Error al ${
                      type === "editar" ? "editar" : "crear"
                    } etapa, el numero de etapa ya existe`,
                    duration: 3000,
                  });
                } else {
                  setLoading(false);
                  return toast({
                    status: "error",
                    isClosable: true,
                    title: `Error al ${
                      type === "editar" ? "editar" : "crear"
                    } etapa`,
                    duration: 3000,
                  });
                }

                if (type === "editar") {
                  onClose();
                  initTablaEtapas();
                } else {
                  navigate(-1);
                }
                setLoading(false);
              }}
            >
              {({
                values,
                handleChange,
                handleBlur,
                setFieldValue,
                handleSubmit,
              }) => (
                <Stack w="100%">
                  <Form id="FormularioEtapa">
                    {activeStep === 0 && (
                      <PrimerPasoEtapa
                        activeStep={activeStep}
                        setActiveStep={setActiveStep}
                        values={values}
                        planificacion={planificacion}
                        type={type}
                        user={user}
                        etapasDatos={etapasDatos}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        setFieldValue={setFieldValue}
                        setFormValues={setFormValues}
                        usuarios={usuarios}
                      />
                    )}
                    {activeStep === 1 && (
                      <SegundoPasoEtapa
                        activeStep={activeStep}
                        setActiveStep={setActiveStep}
                        type={type}
                        setSancubesVaciar={setSancubesVaciar}
                        values={values}
                        seleccionados={seleccionados}
                        setSeleccionados={setSeleccionados}
                        planificacion={planificacion}
                        sandCubesPlanificacion={sandCubesPlanificacion}
                        setSandCubesPlanificacion={setSandCubesPlanificacion}
                      />
                    )}
                    {activeStep === 2 && (
                      <TercerPasoEtapa
                        activeStep={activeStep}
                        setActiveStep={setActiveStep}
                        type={type}
                        sandcubesVaciar={sandcubesVaciar}
                        setSancubesVaciar={setSancubesVaciar}
                        setVacios={setVacios}
                        vacios={vacios}
                        showButton={showButton}
                        setShowButton={setShowButton}
                        planificacion={planificacion}
                        values={values}
                      />
                    )}
                    {activeStep === 3 && (
                      <CuartoPasoEtapa
                        activeStep={activeStep}
                        setActiveStep={setActiveStep}
                        values={values}
                        type={type}
                        onClose={onClose}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        setFieldValue={setFieldValue}
                        planificacion={planificacion}
                        text={text}
                        handleSubmit={handleSubmit}
                        setText={setText}
                      />
                    )}
                  </Form>
                </Stack>
              )}
            </Formik>
          )}
        </>
      )}
      {planificacion.length < 1 && <></>}
    </Stack>
  );
};

export default CrearEditarEtapas;
