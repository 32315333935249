/**
 * Formats a number with regional formatting options.
 *
 * @param {Object} options - The options for formatting the number.
 * @param {number} options.number - The number to be formatted.
 * @param {number} options.digits - The number of digits after the decimal point.
 * @return {string} The formatted number with regional formatting.
 */
export const regionalFormattingNumbers = ({ number, digits }) => {
  if (!number) {
    if (digits === 0) {
      return number.toFixed(0).toString();
    } else {
      const zeros = Array(digits).fill("0").join("");
      return `0,${zeros}`;
    }
  }
  return new Intl.NumberFormat("es-ES", {
    minimumFractionDigits: digits,
    maximumFractionDigits: digits,
    useGrouping: true,
  }).format(number);
};
