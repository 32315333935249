import {
  Stack,
  HStack,
  FormControl,
  FormLabel,
  Input,
  Heading,
  IconButton,
  Button,
  useToast,
  Switch,
  InputGroup,
  InputLeftAddon,
} from "@chakra-ui/react";
import { Formik, Form } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { MdArrowBack, MdFileDownloadDone } from "react-icons/md";
import {
  getSandvans,
  editarSandvans,
  crearSandvans,
} from "./../../helpers/api.helper";

const FormCrearEditarSandvan = (props) => {
  const { type } = props;
  const [loading, setLoading] = useState(false);
  const [sandvan, setSandVan] = useState([]);
  const navigate = useNavigate();
  const id = useParams();
  const toast = useToast();

  const initData = async () => {
    setLoading(true);
    let res = await getSandvans(id);

    if (res.status === 200) {
      setSandVan({
        codigoSandVan: res.data[0].codigoSandVan.substring(8),
        estadoSandVan: res.data[0].estadoSandVan,
        idSandVan: res.data[0].idSandVan,
      });
    } else {
      return toast({
        title: "Error",
        description: "Error al cargar los datos",
        isClosable: true,
        duration: 3000,
      });
    }

    setLoading(false);
  };

  let initialValues =
    type === "editar"
      ? {
          codigoSandVan: sandvan.codigoSandVan,
          estadoSandVan: sandvan.estadoSandVan === "inactivo" ? false : true,
        }
      : {
          codigoSandVan: "",
          estadoSandVan: "",
        };

  useEffect(() => {
    initData();
  }, []);

  return (
    <Stack bg="brand.fondo" w="100%" h="md" borderRadius={10} spacing={7}>
      <HStack w="100%" pl={5}>
        <IconButton
          icon={<MdArrowBack />}
          variant="link"
          onClick={() => navigate(-1)}
          fontSize="24px"
        />

        <Heading fontWeight="medium" py={3}>
          {type === "editar" ? "Editar" : "Nuevo"} SandVan
        </Heading>
      </HStack>
      {!loading && (
        <Formik
          initialValues={initialValues}
          onSubmit={async (values) => {
            setLoading(true);
            if (!values.codigoSandVan) {
              setLoading(false);
              return toast({
                title: "Error",
                description: "Codigo de sandvan requerido",
                status: "error",
                isClosable: true,
                duration: 3000,
              });
            } else if (!/^\d+$/.test(values.codigoSandVan)) {
              setLoading(false);
              return toast({
                title: "Error",
                description: "Ingrese solo números",
                status: "error",
                isClosable: true,
                duration: 3000,
              });
            }

            let res;

            if (type === "editar") {
              res = await editarSandvans({
                codigoSandVan: `SANDVAN ${values.codigoSandVan}`,
                estadoSandVan:
                  values.estadoSandVan === true ? "activo" : "inactivo",
                id: sandvan.idSandVan,
              });
            }
            if (type === "crear") {
              res = await crearSandvans({
                codigoSandVan: `SANDVAN${values.codigoSandVan}`,
                estadoSandVan:
                  values.estadoSandVan === true ? "activo" : "inactivo",
              });
            }

            if (res.status === 200) {
              toast({
                status: "success",
                isClosable: true,
                title: `SandVan ${
                  type === "crear" ? "creado" : "editado"
                } correctamente`,
                duration: 3000,
              });
              navigate(-1);
            } else {
              return toast({
                status: "error",
                isClosable: true,
                title: `Error al ${
                  type === "crear" ? "crear" : "editar"
                } sandvan`,
                description:
                  res.data.error.message || "Error al editar sandvan",
              });
            }

            setLoading(false);
          }}
        >
          {({ values, handleChange, handleBlur, handleSubmit }) => (
            <Form id="formSandvan">
              <Stack w="100%" spacing={10} px={7}>
                <HStack w="100%" spacing={10}>
                  <FormControl w={"xs"}>
                    <FormLabel fontWeight="bold">Nombre</FormLabel>
                    <InputGroup w={"xs"}>
                      <InputLeftAddon children="SANDVAN" />
                      <Input
                        name="codigoSandVan"
                        type="text"
                        maxLength={2}
                        placeholder="Codigo"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.codigoSandVan}
                        isRequired
                      />
                    </InputGroup>
                  </FormControl>
                  <FormControl w={"xs"}>
                    <FormLabel fontWeight="bold">Estado</FormLabel>
                    <Switch
                      colorScheme={"green"}
                      name="estadoSandVan"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isChecked={values.estadoSandVan}
                    />
                  </FormControl>
                </HStack>
                <Button
                  placeSelf="end"
                  rightIcon={<MdFileDownloadDone />}
                  bg="brand.naranja"
                  form="formSandvan"
                  color="white"
                  isLoading={loading}
                  size="md"
                  onClick={handleSubmit}
                >
                  Finalizar
                </Button>
              </Stack>
            </Form>
          )}
        </Formik>
      )}
    </Stack>
  );
};

export default FormCrearEditarSandvan;
