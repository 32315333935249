import { Text, VStack } from "@chakra-ui/react";
import React from "react";

function ItemDetalle(props) {
  const { title, data } = props;
  return (
    <VStack flexBasis={0} flexGrow={1} alignItems={"flex-start"}>
      <Text fontWeight="semibold">{title}</Text>
      <Text fontSize={"sm"} alignSelf={"flex-start"}>
        {data}
      </Text>
    </VStack>
  );
}

export default ItemDetalle;
