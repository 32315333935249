import React, { useEffect, useState } from "react";
import {
  Stack,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Button,
  HStack,
  Heading,
  useToast,
  IconButton,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Switch,
  Select,
} from "@chakra-ui/react";
import {
  MdNavigateNext,
  MdNavigateBefore,
  MdArrowLeft,
  MdArrowRight,
  MdAdd,
} from "react-icons/md";
import { getSandcubes } from "../../../helpers/api.helper";

const SegundoPasoEtapa = (props) => {
  const {
    setActiveStep,
    activeStep,
    setSancubesVaciar,
    values,
    seleccionados,
    setSeleccionados,
    planificacion,
    type,
    sandCubesPlanificacion,
    setSandCubesPlanificacion,
  } = props;
  const [sandcubs, setSandcubs] = useState([]);
  const [filter, setFilter] = useState("");
  const [filterADD, setFilterADD] = useState("");
  const [loading, setLoading] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  const initData = async () => {
    let res = await getSandcubes();
    if (res.status === 200) {
      let filteredArray = [
        ...sandCubesPlanificacion.filter(
          (item) => item.statusSandCube === "Vacio"
        ),

        ...res.data.filter(
          (item1) =>
            !sandCubesPlanificacion.some(
              (item2) => item2.idSandCube === item1.idSandCube
            )
        ),
      ];
      setSandcubs(filteredArray);
      toast({
        title: "Exito",
        description: "SandCubes cargados correctamente",
        status: "success",
        isClosable: true,
        duration: 3000,
      });
    } else {
      toast({
        title: "Error",
        description: "Error al cargar SandCubes",
        status: "error",
        isClosable: true,
        duration: 3000,
      });
    }
  };

  const handleSeleccionados = (sandcube) => {
    setSandCubesPlanificacion(
      sandCubesPlanificacion.filter(
        (item) => item.idSandCube !== sandcube.idSandCube
      )
    );
    setSeleccionados([...seleccionados, sandcube]);
  };

  const handleLista = (e) => {
    setSeleccionados(
      seleccionados.filter((items) => items.idSandCube !== e.idSandCube)
    );
    setSandCubesPlanificacion([...sandCubesPlanificacion, e]);
  };

  const handleAgregar = (e) => {
    setSandcubs(sandcubs.filter((item) => item.idSandCube !== e.idSandCube));

    setSeleccionados([...seleccionados, e]);
  };

  const showSandcubes = () => {
    if (!filter) {
      return sandCubesPlanificacion
        .filter(
          (item1) =>
            !seleccionados.some(
              (item2) => item2.idSandCube === item1.idSandCube
            )
        )
        .filter((e) => e.statusSandCube === "Lleno");
    }
    let item = [];

    let filtrado = filter.toUpperCase();

    item = sandCubesPlanificacion
      .filter((e) => e.statusSandCube === "Lleno")
      .filter((element) => {
        return element.nombreSandCube.toString().includes(filtrado);
      });

    return item;
  };

  const showSandcubesADD = () => {
    if (!filterADD) {
      return sandcubs;
    }
    let item = [];

    let filtrado = filterADD.toUpperCase();

    item = sandcubs.filter((element) => {
      return element.nombreSandCube.toString().includes(filtrado);
    });

    return item;
  };

  const handlePlantaSandcube = (value, sandcube) => {
    let updatedFacturaciones;

    const editarIndex = sandCubesPlanificacion.findIndex(
      (item) => item.idSandCube === sandcube.idSandCube
    );

    if (editarIndex !== -1) {
      const editar = sandCubesPlanificacion[editarIndex];
      editar.idPlanta = parseInt(value);
      let a = planificacion.detallePlantas.find(
        (planta) => planta.idPlanta === parseInt(value)
      );
      editar.nombrePlanta = a.nombrePlanta;
      // Actualizar facturaciones
      sandCubesPlanificacion[editarIndex] = editar;
      // Si necesitas una nueva copia del array facturaciones actualizada, puedes hacerlo así:
      updatedFacturaciones = [...sandCubesPlanificacion];
      // Utiliza la variable updatedFacturaciones en lugar de facturaciones si necesitas la actualización fuera de esta función
    }
    setSandCubesPlanificacion(updatedFacturaciones);
  };

  const handleMallaSandcube = (value, sandcube) => {
    let updatedFacturaciones;

    const editarIndex = sandCubesPlanificacion.findIndex(
      (item) => item.idSandCube === sandcube.idSandCube
    );

    if (editarIndex !== -1) {
      const editar = sandCubesPlanificacion[editarIndex];
      editar.malla = value;

      // A ctualizar facturaciones
      sandCubesPlanificacion[editarIndex] = editar;
      // Si necesitas una nueva copia del array facturaciones actualizada, puedes hacerlo así:
      updatedFacturaciones = [...sandCubesPlanificacion];
      // Utiliza la variable updatedFacturaciones en lugar de facturaciones si necesitas la actualización fuera de esta función
    }
    setSandCubesPlanificacion(updatedFacturaciones);
  };

  const handleWetsand = (sandcube, e) => {
    const nuevoValor = e;

    const nuevoArray = [...seleccionados];

    const indiceObjeto = nuevoArray.findIndex(
      (objeto) => objeto.idSandCube === sandcube.idSandCube
    );

    nuevoArray[indiceObjeto] = {
      ...nuevoArray[indiceObjeto],
      wetsandSC: nuevoValor,
    };

    setSeleccionados(nuevoArray);
  };

  const handleSandcubes = async () => {
    setLoading(true);
    setSancubesVaciar(seleccionados);
    setActiveStep(activeStep + 1);
    setLoading(false);
  };

  useEffect(() => {
    initData();
    setSandCubesPlanificacion(
      planificacion.scAsociados
        ? planificacion.scAsociados
        : type === "editar"
        ? values.sandcubesEtapa
        : []
    );
  }, []);

  return (
    <Stack w="100%" h="100%" justifyContent="space-between">
      <HStack w="100%" px={3}>
        <Stack w="50%">
          <HStack justifyContent="space-between" px={3}>
            <Heading size="md">Lista de Sandcubes</Heading>
            <Input
              w="xs"
              type="text"
              value={filter}
              placeholder={`Buscar`}
              onChange={(e) => {
                setFilter(e.target.value);
              }}
              variant="flushed"
            />
          </HStack>
          <TableContainer h="xs" overflowY="scroll">
            <Table size="sm" variant="simple">
              <Thead>
                <Tr>
                  <Th>ID Sandcube</Th>
                  <Th>Malla</Th>
                  <Th>Planta</Th>
                  <Th></Th>
                </Tr>
              </Thead>
              <Tbody>
                {showSandcubes().map((sandcube) => (
                  <Tr key={sandcube.idSandCube}>
                    <Td>{sandcube.nombreSandCube}</Td>
                    <Td>
                      <Select
                        placeholder="- Malla -"
                        variant={"unstyled"}
                        value={sandcube.malla}
                        onChange={(e) =>
                          handleMallaSandcube(e.target.value, sandcube)
                        }
                      >
                        <option value={"100"}>100</option>
                        <option value={"30/70"}>30/70</option>
                        <option value={"70/140"}>70/140</option>
                        <option value={"50/70"}>50/70</option>
                        <option value={"40/80"}>40/80</option>
                        <option value={"40/70"}>40/70</option>
                        <option value={"30/50"}>30/50</option>
                        <option value={"20/40"}>20/40</option>
                      </Select>
                    </Td>
                    <Td>
                      <Select
                        placeholder="- Planta -"
                        variant={"unstyled"}
                        value={sandcube.idPlanta}
                        onChange={(e) =>
                          handlePlantaSandcube(e.target.value, sandcube)
                        }
                      >
                        {planificacion.detallePlantas &&
                          planificacion.detallePlantas.map((planta) => (
                            <option value={planta.idPlanta}>
                              {planta.nombrePlanta}
                            </option>
                          ))}
                      </Select>
                    </Td>
                    <Td>
                      <IconButton
                        variant="link"
                        icon={<MdArrowRight />}
                        onClick={(e) => {
                          handleSeleccionados(sandcube);
                        }}
                        _hover={{ color: "brand.naranja" }}
                      />
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        </Stack>
        <Stack w="50%">
          <Heading size="md">Seleccionados</Heading>
          <TableContainer h="xs" overflowY="scroll">
            <Table size="sm" variant="simple">
              <Thead>
                <Tr>
                  <Th></Th>
                  <Th>ID Sandcube</Th>
                  <Th>Malla</Th>
                  <Th>Planta</Th>
                  {values.etapaWeTSanD && <Th>Wetsand</Th>}
                </Tr>
              </Thead>
              <Tbody>
                {seleccionados &&
                  seleccionados.map((sandcube) => (
                    <Tr key={sandcube.idSandCube}>
                      <Td>
                        <IconButton
                          variant="link"
                          icon={<MdArrowLeft />}
                          onClick={() => {
                            handleLista(sandcube);
                          }}
                          _hover={{ color: "brand.naranja" }}
                        />
                      </Td>
                      <Td>{sandcube.nombreSandCube}</Td>
                      <Td>{sandcube.malla}</Td>
                      <Td>{sandcube.nombrePlanta || "-"}</Td>
                      {values.etapaWeTSanD && (
                        <Td>
                          <Switch
                            name="wetsandSC"
                            isChecked={sandcube.wetsandSC}
                            onChange={(e) =>
                              handleWetsand(sandcube, e.target.checked)
                            }
                          />
                        </Td>
                      )}
                    </Tr>
                  ))}
              </Tbody>
            </Table>
          </TableContainer>
        </Stack>
      </HStack>
      <HStack spacing={28} px={5}>
        <Button
          size="sm"
          variant="link"
          color="black"
          leftIcon={<MdAdd color="#FF8200" />}
          onClick={onOpen}
        >
          Agregar Sandcube
        </Button>
        <Modal isOpen={isOpen} onClose={onClose} size={"lg"}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Sandcubes</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Input
                w="xs"
                type="text"
                value={filterADD}
                placeholder={`Buscar`}
                onChange={(e) => {
                  setFilterADD(e.target.value);
                }}
                variant="flushed"
              />
              <TableContainer h="xs" overflowY="scroll" pt={2}>
                <Table size="sm" variant="simple">
                  <Thead
                    style={{
                      position: "sticky",
                      top: 0,
                      zIndex: 1,
                      backgroundColor: "#F3F6F9",
                      opacity: 1,
                    }}
                  >
                    <Tr>
                      <Th>ID Sandcube</Th>
                      <Th>Malla</Th>
                      <Th>Planta</Th>
                      <Th></Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {showSandcubesADD().map((sandcube) => (
                      <Tr key={sandcube.idSandCube}>
                        <Td>{sandcube.nombreSandCube}</Td>
                        <Td>{sandcube.malla}</Td>
                        <Td>{sandcube.nombrePlanta}</Td>
                        <Td>
                          <IconButton
                            variant="link"
                            icon={<MdArrowRight />}
                            onClick={() => {
                              handleAgregar(sandcube);
                            }}
                            _hover={{ color: "brand.naranja" }}
                          />
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </TableContainer>
            </ModalBody>

            <ModalFooter>
              <Button mr={3} onClick={onClose}>
                Cerrar
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
        <Text fontSize="12px" color="brand.azul_text">
          {seleccionados.length} sandcubes seleccionados
        </Text>
      </HStack>
      <HStack w="100%" justifyContent="space-between" p={3}>
        <Button
          fontSize={12}
          fontWeight="semibold"
          color="brand.naranja"
          border="1px solid #FF8200"
          leftIcon={<MdNavigateBefore fontSize="20px" />}
          variant="outline"
          onClick={() => setActiveStep(activeStep - 1)}
          isDisabled={loading}
          _hover={{ bg: "brand.naranja", color: "white" }}
        >
          Atras
        </Button>
        <Button
          fontSize={12}
          fontWeight="semibold"
          bg="brand.naranja"
          color="white"
          rightIcon={<MdNavigateNext fontSize="20px" />}
          variant="solid"
          form="FormularioEtapa"
          onClick={() => handleSandcubes()}
          isDisabled={loading || seleccionados.length < 1}
          _hover={{ bg: "white", color: "brand.naranja" }}
        >
          Continuar
        </Button>
      </HStack>
    </Stack>
  );
};

export default SegundoPasoEtapa;
