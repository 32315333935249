import React from "react";
import { Heading, HStack, IconButton } from "@chakra-ui/react";
import { MdArrowBack } from "react-icons/md";
import { useNavigate } from "react-router-dom";

function HeaderReporteEtapas() {
  const navigate = useNavigate();

  return (
    <HStack alignItems={"center"}>
      <IconButton
        icon={<MdArrowBack />}
        variant="link"
        onClick={() => navigate(-1)}
        fontSize="24px"
      />
      <Heading
        fontWeight="medium"
        size="xl"
        fontFamily="fonts.title"
        color="brand.gris_primario"
      >
        Reporte de etapas
      </Heading>
    </HStack>
  );
}

export default HeaderReporteEtapas;
