import React, { useState } from "react";
import {
  IconButton,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useDisclosure,
  useToast,
  Button,
  Tooltip,
} from "@chakra-ui/react";
import { deleteUsuarios } from "../helpers/api.helper";
import { MdOutlineDelete } from "react-icons/md";

const EliminarUsuarios = (props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef();
  const [loading, setLoading] = useState(false);
  const { usuario, initUsuarios } = props;

  const toast = useToast();

  const handleBtnEliminarUsuario = async () => {
    setLoading(true);
    let res = await deleteUsuarios({ id: usuario.idUsuario });

    if (res.status === 200) {
      toast({
        status: "success",
        isClosable: true,
        title: "Usuario eliminado correctamente",
        duration: 3000,
      });
      initUsuarios();
      onClose();
    } else {
      toast({
        title: "Error al eliminar usuario",
        description: res.message,
        status: "error",
        isClosable: true,
        duration: 3000,
      });
    }
    setLoading(false);
  };

  return (
    <>
      <Tooltip label="Eliminar usuario">
        <IconButton
          variant="link"
          icon={<MdOutlineDelete />}
          fontSize="20px"
          onClick={onOpen}
        />
      </Tooltip>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Eliminar usuario
            </AlertDialogHeader>

            <AlertDialogBody>
              ¿Estás seguro que desea eliminar al usuario{" "}
              <b>{usuario.username}</b>?
              <br />
              Esta acción no se puede revertir.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} variant="link" onClick={onClose}>
                Cancelar
              </Button>
              <Button
                colorScheme="red"
                onClick={handleBtnEliminarUsuario}
                ml={3}
                isDisabled={loading}
              >
                Eliminar
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default EliminarUsuarios;
