import React, { useState } from "react";
import {
  HStack,
  Button,
  Heading,
  Box,
  Link,
  Img,
  Checkbox,
  Center,
  Text,
  Stack,
  Image,
  useMediaQuery,
} from "@chakra-ui/react";
import Fondo from "../../assets/fondoGrande.png";
import LogoBrent from "../../assets/Brent-Marca-con-bajada-CMYK.png";
import LogoMurchinson from "../../assets/Una Empresa.svg";
import LineaCurva from "../../assets/iconos/Linea-Curva.svg";
import { useMsal } from "@azure/msal-react";

const Login = () => {
  const [terminosYCondiciones, setTerminosYCondiciones] = useState(false);
  const { instance } = useMsal();
  const [Larger] = useMediaQuery("(min-width:1400px)", {
    ssr: true,
    fallback: false, // return false on the server, and re-evaluate on the client side
  });

  const handleLogin = () => {
    instance.loginRedirect();
  };

  return (
    <Stack
      w="100vw"
      h="100vh"
      bgImage={Fondo}
      backgroundSize="cover"
      backgroundRepeat="no-repeat"
      fontFamily="body"
      overflowY={"hidden"}
      justifyContent={"space-between"}
    >
      <Box>
        <HStack></HStack>
        <Box w="100%" pl={16} pt={10}>
          <Center
            h={["xs", "sm", "md", "md", "lg"]}
            w={"xl"}
            bgColor="white"
            borderRadius="30px"
            alignSelf="start"
            zIndex={100}
          >
            <Stack w="100%" h="100%">
              <Box
                placeSelf="start"
                pt={10}
                pl={10}
                alignSelf="start"
                boxSize={"45%"}
              >
                <Img src={LogoBrent} alt="" />
              </Box>
              <Stack
                h="100%"
                pt={7}
                textAlign="left"
                w="65%"
                pl={10}
                spacing={-3}
                placeSelf="start"
                alignSelf="start"
              >
                <Heading fontWeight="bold" textColor={"#FF8200"}>
                  SandPro Navigator
                </Heading>
                <br />
                <Text
                  fontWeight="normal"
                  textColor={"#9EAAB8"}
                  fontSize={"26px"}
                >
                  Portal de seguimiento de operación de última milla
                </Text>
              </Stack>
              <Stack w="100%" pl={10}>
                <Checkbox
                  borderColor="blackAlpha.500"
                  isChecked={terminosYCondiciones}
                  onChange={() =>
                    setTerminosYCondiciones(!terminosYCondiciones)
                  }
                  colorScheme={"orange"}
                  fontSize={"10px"}
                  size={"sm"}
                >
                  Acepto los{" "}
                  <Link href="#" textColor="brand.azul_text">
                    Términos & Condiciones
                  </Link>
                </Checkbox>
                <HStack justifyContent={"space-between"}>
                  <Button
                    bg="brand.azul_fondo"
                    color="white"
                    _hover={{ opacity: 0.7 }}
                    onClick={handleLogin}
                    isDisabled={!terminosYCondiciones}
                    fontSize={["14px", "14px", "16px", "18px", "20px"]}
                    w={"150px"}
                  >
                    Ingresar
                  </Button>
                  <Image src={LogoMurchinson} alt="" w="300px" />
                </HStack>
              </Stack>
            </Stack>
          </Center>
        </Box>
        {Larger && <Image src={LineaCurva} objectFit="cover" />}
      </Box>
      <HStack w="100%" justifyContent="end">
        <Text
          fontSize={["0.5rem", "0.4rem", "0.6rem", "0.7rem", "1.5rem"]}
          color={"rgba(255, 255, 255, .7)"}
          pb={7}
          pr={5}
        >
          BRENT © 2023 - Todos los derechos reservados.
        </Text>
      </HStack>
    </Stack>
  );
};

export default Login;
