import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Box,
  useToast,
} from "@chakra-ui/react";
import { MdOutlineCalendarMonth } from "react-icons/md";
import {
  descargarCronograma,
  descargarHistorialPlanificaciones,
} from "../../helpers/api.helper";
import moment from "moment";

const LineaTiempoPlanificaciones = (props) => {
  const { planificaciones, type } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  const handleDownload = async (e) => {
    let s1 = [];
    let s2 = [];
    let hist = [];
    let ano = new moment().format("YYYY");

    if (type === "historial") {
      e.forEach((elem) => {
        hist.push({
          toneladas: elem.totalTns,
          nombre: elem.idPadlocacion,
          etapas: elem.etapasTotalPad,
          set: elem.idSET,
          cliente: elem.cliente.nombreCliente,
          fechaInicio: elem.fechaInicio,
          fechaFin: elem.fechaFinal,
          npts: elem.npts,
          tnsNominales: elem.tnsNominales,
        });
      });
    } else {
      e.forEach((elem) => {
        if (elem.idSET === "set1") {
          s1.push({
            idPadlocacion: elem.idPadlocacion,
            inicial: elem.fechaInicio,
            final: elem.fechaFinal,
            estado: elem.estado,
            cliente: elem.cliente.nombreCliente,
          });
        } else {
          s2.push({
            idPadlocacion: elem.idPadlocacion,
            inicial: elem.fechaInicio,
            final: elem.fechaFinal,
            estado: elem.estado,
            cliente: elem.cliente.nombreCliente,
          });
        }
      });
    }
    const data = {
      set1: s1,
      set2: s2,
    };
    let response;

    if (type === "historial") {
      response = await descargarHistorialPlanificaciones({ historial: hist });
    } else {
      response = await descargarCronograma(data);
    }
    if (response.status === 200) {
      const url = window.URL.createObjectURL(
        new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
        })
      );
      const link = document.createElement("a");
      link.href = url;
      if (type === "historial") {
        link.setAttribute("download", `Historial-${ano}.xlsx`);
      } else {
        link.setAttribute("download", `Cronograma-${ano}.xlsx`);
      }
      document.body.appendChild(link);
      link.click();
    } else if (response.status === 400) {
      return toast({
        title: "Error al descargar",
        description: "Hay soperpocicion entre fechas ",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } else if (response.status === 500) {
      return toast({
        title: "Error",
        description: "Error del servidor ",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <>
      <Button
        onClick={onOpen}
        leftIcon={<MdOutlineCalendarMonth fontSize="20px" />}
        variant="link"
        bg="brand.fondo_secundario"
        color="brand.gris_primario"
        fontSize="14px"
        fontWeight="medium"
        size="md"
        _hover={{ color: "brand.naranja" }}
      >
        {type === "historial" ? "Historial" : "Cronograma"}
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {type === "historial"
              ? "Historial de planificaciones"
              : "Cronograma de planificaciones"}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <TableContainer>
              <Table>
                <Thead>
                  <Tr>
                    <Th>Nombre</Th>
                    <Th>Fecha Inicio</Th>
                    <Th>Fecha Final</Th>
                  </Tr>
                  {type === "planificaciones" && (
                    <Tr>
                      <Td colSpan={3} fontWeight="bold">
                        SET 1
                      </Td>
                    </Tr>
                  )}
                </Thead>
                <Tbody>
                  {planificaciones
                    .filter((planificacion) => planificacion.idSET === "set1")
                    .map((planificacion, index) => (
                      <Tr key={planificacion.idPlanificacion}>
                        <Td>{planificacion.idPadlocacion}</Td>
                        <Td>
                          {moment(planificacion.fechaInicio).format(
                            "DD/MM/YYYY"
                          )}
                        </Td>
                        <Td>
                          {moment(planificacion.fechaFinal).format(
                            "DD/MM/YYYY"
                          )}
                        </Td>
                      </Tr>
                    ))}
                </Tbody>
                {type === "planificaciones" && (
                  <Thead>
                    <Tr>
                      <Td colSpan={3} fontWeight="bold">
                        SET 2
                      </Td>
                    </Tr>
                  </Thead>
                )}
                <Tbody>
                  {planificaciones
                    .filter((planificacion) => planificacion.idSET === "set2")
                    .map((planificacion) => (
                      <Tr key={planificacion.idPlanificacion}>
                        <Td>{planificacion.idPadlocacion}</Td>
                        <Td>
                          {moment(planificacion.fechaInicio).format(
                            "DD/MM/YYYY"
                          )}
                        </Td>
                        <Td>
                          {moment(planificacion.fechaFinal).format(
                            "DD/MM/YYYY"
                          )}
                        </Td>
                      </Tr>
                    ))}
                </Tbody>
              </Table>
            </TableContainer>
          </ModalBody>

          <ModalFooter>
            <Button variant="link" onClick={onClose}>
              Cerrar
            </Button>
            <Box w="30px"></Box>
            <Button
              fontWeight="semibold"
              bg="brand.naranja"
              color="white"
              onClick={() =>
                handleDownload(
                  planificaciones.filter(
                    (planificacion) => planificacion.idSET !== "-"
                  )
                )
              }
              _hover={{ bg: "white", color: "brand.naranja" }}
            >
              Descargar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default LineaTiempoPlanificaciones;
