import React, { useState, useEffect } from "react";
import {
  HStack,
  Input,
  FormControl,
  Stack,
  FormLabel,
  Button,
  Select,
  useToast,
  Heading,
  Text,
  Switch,
  Card,
  CardBody,
  CardFooter,
  Textarea,
  IconButton,
  Box,
} from "@chakra-ui/react";
import { Form, Formik } from "formik";
import { MdNavigateNext, MdArrowDropDown, MdArrowBack } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import { getUsuarios } from "../../../../usuarios/helpers/api.helper";
import {
  getPlanificaciones,
  crearRevisionBaseSV,
  editarRevisionBaseSV,
} from "../../../helpers/api.helper";

const RevisionBaseSV = (props) => {
  const { type, tipo } = props;
  const [loading, setLoading] = useState(false);
  const [usuarios, setUsuarios] = useState([]);
  const [planificacion, setPlanificacion] = useState([]);
  const [responsable, setResponsable] = useState();
  const [fechaRevisionMP, setFechaRevisionMP] = useState();
  const [editable, setEditable] = useState([]);
  const navigate = useNavigate();
  const toast = useToast();
  const padLocacion = useParams();
  const [observaciones, setObservaciones] = useState("");
  const charCount = observaciones.length;

  const initData = async () => {
    setLoading(true);
    let res;

    res = await getUsuarios();

    if (res.status === 200) {
      setUsuarios(res.data);
    }

    res = await getPlanificaciones(padLocacion);

    if (res.status === 200) {
      setPlanificacion(res.data[0]);
    }

    setLoading(false);
  };

  useEffect(() => {
    initData();
  }, []);

  let initialValues =
    type === "editar"
      ? {
          idPlanificacion: editable.idPlanificacion,
          luminariaExtBSV: editable.luminariaExtBSV,
          tableroExtBSV: editable.tableroExtBSV,
          tomasExtBSV: editable.tomasExtBSV,
          indicadorVoltajeBSV: editable.indicadorVoltajeBSV,
          tableroIntP1BSV: editable.tableroIntP1BSV,
          tableroIntP2BSV: editable.tableroIntP2BSV,
          tableroIntP3BSV: editable.tableroIntP3BSV,
          tableroIntP4BSV: editable.tableroIntP4BSV,
          verificarAceiteBSV: editable.verificarAceiteBSV,
          verificarCombustibleBSV: editable.verificarCombustibleBSV,
          verificarCorreaBSV: editable.verificarCorreaBSV,
          funcionamientoAireaBSV: editable.funcionamientoAireaBSV,
          funcionamientoPasoaBSV: editable.funcionamientoPasoaBSV,
          observacionesBSV: editable.observacionesBSV,
          responsable: editable.responsableBSV,
          fechaRevision: editable.fechaRevision,
        }
      : {
          idPlanificacion: "",
          luminariaExtBSV: false,
          tableroExtBSV: false,
          tomasExtBSV: false,
          indicadorVoltajeBSV: false,
          tableroIntP1BSV: false,
          tableroIntP2BSV: false,
          tableroIntP3BSV: false,
          tableroIntP4BSV: false,
          verificarAceiteBSV: false,
          verificarCombustibleBSV: false,
          verificarCorreaBSV: false,
          funcionamientoAireaBSV: false,
          funcionamientoPasoaBSV: false,
          observacionesBSV: "",
          responsable: "",
          fechaRevision: "",
        };

  return (
    <Stack w="100%">
      <HStack>
        <IconButton
          icon={<MdArrowBack />}
          variant="link"
          onClick={() => navigate(-1)}
          fontSize="24px"
        />
        <Heading size={"sm"} px={2}>
          Revision Base SandVan
        </Heading>
      </HStack>
      <HStack px={7}>
        <FormControl isRequired>
          <FormLabel>Responsable</FormLabel>
          <Select
            w="2xs"
            size="sm"
            placeholder="Seleccione responsable"
            icon={<MdArrowDropDown />}
            onChange={(e) => setResponsable(e.target.value)}
          >
            {usuarios
              .sort((a, b) => {
                // Ordenar alfabéticamente por nombre y apellido
                const nameA = `${a.nombre}`.toUpperCase();
                const nameB = `${b.nombre}`.toUpperCase();
                if (nameA < nameB) return -1;
                if (nameA > nameB) return 1;
                return 0;
              })
              .map((usuario) => (
                <option key={usuario.idUsuario} value={usuario.idUsuario}>
                  {usuario.nombre}
                </option>
              ))}
          </Select>
        </FormControl>
      </HStack>
      {!loading && (
        <Formik
          initialValues={initialValues}
          onSubmit={async (values) => {
            setLoading(true);
            let data =
              tipo === "editar"
                ? {
                    id: editable.idRevisionBSV,
                    luminariaExtBSV: values.luminariaExtBSV,
                    tableroExtBSV: values.tableroExtBSV,
                    tomasExtBSV: values.tomasExtBSV,
                    indicadorVoltajeBSV: values.indicadorVoltajeBSV,
                    tableroIntP1BSV: values.tableroIntP1BSV,
                    tableroIntP2BSV: values.tableroIntP2BSV,
                    tableroIntP3BSV: values.tableroIntP3BSV,
                    tableroIntP4BSV: values.tableroIntP4BSV,
                    verificarAceiteBSV: values.verificarAceiteBSV,
                    verificarCombustibleBSV: values.verificarCombustibleBSV,
                    verificarCorreaBSV: values.verificarCorreaBSV,
                    funcionamientoAireaBSV: values.funcionamientoAireaBSV,
                    funcionamientoPasoaBSV: values.funcionamientoPasoaBSV,
                    observacionesBSV: observaciones,
                    responsable: responsable,

                    idPlanificacion: planificacion.idPlanificacion,
                    equipoRevision: "BaseSandvan",
                  }
                : {
                    luminariaExtBSV: values.luminariaExtBSV,
                    tableroExtBSV: values.tableroExtBSV,
                    tomasExtBSV: values.tomasExtBSV,
                    indicadorVoltajeBSV: values.indicadorVoltajeBSV,
                    tableroIntP1BSV: values.tableroIntP1BSV,
                    tableroIntP2BSV: values.tableroIntP2BSV,
                    tableroIntP3BSV: values.tableroIntP3BSV,
                    tableroIntP4BSV: values.tableroIntP4BSV,
                    verificarAceiteBSV: values.verificarAceiteBSV,
                    verificarCombustibleBSV: values.verificarCombustibleBSV,
                    verificarCorreaBSV: values.verificarCorreaBSV,
                    funcionamientoAireaBSV: values.funcionamientoAireaBSV,
                    funcionamientoPasoaBSV: values.funcionamientoPasoaBSV,
                    observacionesBSV: observaciones,
                    responsable: responsable,

                    idPlanificacion: planificacion.idPlanificacion,
                    equipoRevision: "BaseSandvan",
                  };

            let res;
            if (tipo === "editar") {
              res = await editarRevisionBaseSV(data);
            } else {
              res = await crearRevisionBaseSV(data);
            }
            if (res.status === 200) {
              toast({
                status: "success",
                isClosable: true,
                title: `Revision de base Sandvan ${
                  tipo === "editar" ? "editada" : "creada"
                } correctamente`,
                duration: 3000,
              });
            } else {
              setLoading(false);
              return toast({
                status: "error",
                isClosable: true,
                title: `Error al ${
                  tipo === "editar" ? "editar" : "crear"
                } revision de base Sandvan`,
                duration: 3000,
              });
            }

            navigate(-1);
            setLoading(false);
          }}
        >
          {({ values, handleSubmit, handleBlur, handleChange }) => (
            <Form id="miniPala">
              <HStack w="100%" spacing={20} p={5}>
                <Stack placeSelf="start">
                  <HStack>
                    <Switch
                      onChange={handleChange}
                      isChecked={values.luminariaExtBSV}
                      name="luminariaExtBSV"
                    />
                    <Text>Luminaria externa</Text>
                  </HStack>
                  <HStack>
                    <Switch
                      onChange={handleChange}
                      isChecked={values.tableroExtBSV}
                      name="tableroExtBSV"
                    />
                    <Text>Tablero externo</Text>
                  </HStack>
                  <HStack>
                    <Switch
                      onChange={handleChange}
                      isChecked={values.tomasExtBSV}
                      name="tomasExtBSV"
                    />
                    <Text>Tomas electricas externas</Text>
                  </HStack>
                  <HStack>
                    <Switch
                      onChange={handleChange}
                      isChecked={values.indicadorVoltajeBSV}
                      name="indicadorVoltajeBSV"
                    />
                    <Text>Indicadores de voltaje externo</Text>
                  </HStack>
                  <HStack>
                    <Switch
                      onChange={handleChange}
                      isChecked={values.tableroIntP1BSV}
                      name="tableroIntP1BSV"
                    />
                    <Text>Tablero interno P1</Text>
                  </HStack>
                  <HStack>
                    <Switch
                      onChange={handleChange}
                      isChecked={values.tableroIntP2BSV}
                      name="tableroIntP2BSV"
                    />
                    <Text>Tablero interno P2</Text>
                  </HStack>
                  <HStack>
                    <Switch
                      onChange={handleChange}
                      isChecked={values.tableroIntP3BSV}
                      name="tableroIntP3BSV"
                    />
                    <Text>Tablero interno P3</Text>
                  </HStack>
                  <HStack>
                    <Switch
                      onChange={handleChange}
                      isChecked={values.tableroIntP4BSV}
                      name="tableroIntP4BSV"
                    />
                    <Text>Tablero interno P4</Text>
                  </HStack>
                </Stack>
                <Stack placeSelf="start">
                  <HStack>
                    <Switch
                      onChange={handleChange}
                      isChecked={values.verificarAceiteBSV}
                      name="verificarAceiteBSV"
                    />
                    <Text>Verificar nivel de aceite en compresor de aire</Text>
                  </HStack>
                  <HStack>
                    <Switch
                      onChange={handleChange}
                      isChecked={values.verificarCombustibleBSV}
                      name="verificarCombustibleBSV"
                    />
                    <Text>
                      Verificar nivel de combustible en compresor de aire
                    </Text>
                  </HStack>
                  <HStack>
                    <Switch
                      onChange={handleChange}
                      isChecked={values.verificarCorreaBSV}
                      name="verificarCorreaBSV"
                    />
                    <Text>
                      Verificar estado de la corre en compresor de aire
                    </Text>
                  </HStack>
                  <HStack>
                    <Switch
                      onChange={handleChange}
                      isChecked={values.funcionamientoAireaBSV}
                      name="funcionamientoAireaBSV"
                    />
                    <Text>
                      Funcionamiento de sistema de aire en compresor de aire
                    </Text>
                  </HStack>
                  <HStack>
                    <Switch
                      onChange={handleChange}
                      isChecked={values.funcionamientoPasoaBSV}
                      name="funcionamientoPasoaBSV"
                    />
                    <Text>
                      Funcionamiento de llaves de paso en comrpesor de aire
                    </Text>
                  </HStack>
                </Stack>
              </HStack>
              <HStack w="100%" justifyContent="space-between" px={3}>
                <Box></Box>
                <FormControl w={"lg"}>
                  <FormLabel>Observaciones</FormLabel>
                  <Card w="lg" variant="unstyled" bg="brand.fondos_secundarios">
                    <CardBody>
                      <Textarea
                        w="lg"
                        placeholder="Descripcion"
                        name="observacionesBSV"
                        onChange={(e) => setObservaciones(e.target.value)}
                        onBlur={handleBlur}
                        maxLength="1500"
                        resize="none"
                      />
                    </CardBody>
                    <CardFooter placeSelf="end" color="brand.gris_medio">
                      {charCount}/1500
                    </CardFooter>
                  </Card>
                </FormControl>
                <Button
                  placeSelf={"end"}
                  fontSize={12}
                  fontWeight="semibold"
                  bg="brand.naranja"
                  color="white"
                  rightIcon={<MdNavigateNext fontSize="20px" />}
                  variant="solid"
                  form="miniPala"
                  onClick={handleSubmit}
                  isDisabled={loading}
                  _hover={{ bg: "white", color: "brand.naranja" }}
                >
                  Finalizar
                </Button>
              </HStack>
            </Form>
          )}
        </Formik>
      )}
    </Stack>
  );
};

export default RevisionBaseSV;
