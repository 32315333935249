import React, { useEffect, useState } from "react";
import {
  Stack,
  HStack,
  FormControl,
  FormLabel,
  Input,
  Text,
  Select,
  Button,
  Step,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepStatus,
  StepTitle,
  Stepper,
  useSteps,
  Box,
  Textarea,
  InputGroup,
  InputRightAddon,
  Card,
  CardBody,
  CardFooter,
  useToast,
  Heading,
  CheckboxGroup,
  InputRightElement,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  useDisclosure,
  CSSReset,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Switch,
  IconButton,
} from "@chakra-ui/react";
import {
  MdNavigateBefore,
  MdNavigateNext,
  MdArrowDropDown,
  MdFileDownloadDone,
  MdOutlineQrCode2,
  MdArrowBack,
} from "react-icons/md";
import { Form, Formik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import { getUsuarios } from "../../../../usuarios/helpers/api.helper";
import {
  crearRevisionSanders,
  editarRevisionSanders,
  getPlanificaciones,
  getRevisionSanders,
} from "../../../helpers/api.helper";
import HandleQrReader from "./QrReader";
import { AddIcon, MinusIcon } from "@chakra-ui/icons";

const RevisionSander = (props) => {
  const { tipo } = props;
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const toast = useToast();
  const padLocacion = useParams();
  const { id } = useParams();
  const [sanderBackUp, setSanderBackUp] = useState("SanderA");
  const [usuarios, setUsuarios] = useState([]);
  const [planificacion, setPlanificacion] = useState();
  const [responsable, setResponsable] = useState();
  const [editable, setEditable] = useState([]);
  const [scannedData, setScannedData] = useState();
  let steps = [
    { title: "Power Pack" },
    { title: "Sistema transporte" },
    { title: "Sistema hidráulico" },
    { title: "Sistema eléctrico" },
  ];
  const { activeStep, setActiveStep } = useSteps({
    index: 0,
    count: steps.length,
  });
  const [textObservacionesGral, setTextObservacionesGral] = useState("");
  const [textObservacionesSA, setTextObservacionesSA] = useState("");
  const [textObservacionesSB, setTextObservacionesSB] = useState("");
  const [presionRetonoBEA, setPresionRetonoBEA] = useState(0);
  const [presionRetonoBEB, setPresionRetonoBEB] = useState(0);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const hoy = new Date();
  const anio = hoy.getFullYear();
  const mes = String(hoy.getMonth() + 1).padStart(2, "0");
  const dia = hoy.getDate();
  const minutos = hoy.getMinutes();
  const horas = hoy.getHours();
  const hoyFormat = dia + "/" + mes + "/" + anio;
  const horaActual = horas + ":" + minutos;

  const initUsarios = async () => {
    let res;

    res = await getPlanificaciones(padLocacion);

    if (res.status === 200) {
      setPlanificacion(res.data[0]);
    }

    res = await getUsuarios();

    if (res.status === 200) {
      setUsuarios(res.data);
    }

    res = await getRevisionSanders();
    if (res.status === 200) {
      if (tipo === "crear") {
        setEditable(res.data);
      }
      if (tipo === "editar") {
        setEditable(() => {
          let a = res.data.filter((e) => e.idRevisionSander === parseInt(id));
          return a[0];
        });
      }

      setLoading(false);
    } else {
      setLoading(false);
      return hoyFormat + horaActual;
    }
  };

  const charCountSA = textObservacionesSA.length;
  const charCountSB = textObservacionesSB.length;
  const charCountGral = textObservacionesGral.length;

  let initialValues =
    tipo === "editar"
      ? {
          sanderA: editable.sanderA,
          responsable: editable.responsable,
          qrCodeA1: editable.qrCodeA1,
          powerPackA: editable.powerPackA,
          horasMotorA: editable.horasMotorA,
          nivelCombustibleA: editable.nivelCombustibleA,
          perdidasCombustibleA: editable.perdidasCombustibleA,
          presionAceiteA: editable.presionAceiteA,
          nivelAceiteA: editable.nivelAceiteA,
          tempRefrigeranteA: editable.tempRefrigeranteA,
          perdidasRefrigeranteA: editable.perdidasRefrigeranteA,
          nivelRefrigeranteA: editable.nivelRefrigeranteA,
          refrigerantePerdidaA: editable.refrigerantePerdidaA,
          estadoBorneasA: editable.estadoBorneasA,
          limpiezaFiltroAireA: editable.limpiezaFiltroAireA,
          limpizaA: editable.limpizaA,
          qrCodeA2A: editable.qrCodeA2A,
          bandaTransportadoraA: editable.bandaTransportadoraA,
          alineacionBandaA: editable.alineacionBandaA,
          rolosRetornoA: editable.rolosRetornoA,
          rolosVentiladosA: editable.rolosVentiladosA,
          rolosSdriveA: editable.rolosSdriveA,
          unionBandaA: editable.unionBandaA,
          ctcpA: editable.ctcpA,
          acoplamientoCFA: editable.acoplamientoCFA,
          anclajePlacaCeldasA: editable.anclajePlacaCeldasA,
          lubricacionRodamientosA: editable.lubricacionRodamientosA,
          lubricacionCadenaA: editable.lubricacionCadenaA,
          qrCodeA3A: editable.qrCodeA3A,
          hsCircuitoHidraA: editable.hsCircuitoHidraA,
          tempAceiteHidraA: editable.tempAceiteHidraA,
          nivelAceiteHidraA: editable.nivelAceiteHidraA,
          aceiteHidraPerdidaA: editable.aceiteHidraPerdidaA,
          movimientoCarrosA: editable.movimientoCarrosA,
          presionRetonoBEA: editable.presionRetonoBEA,
          presionSantByBPA: editable.presionSantByBPA,
          presionTrabajoBPA: editable.presionTrabajoBPA,
          tablerosCerradosA: editable.tablerosCerradosA,
          cableComandoA: editable.cableComandoA,
          calibracionBalanzaA: editable.calibracionBalanzaA,
          semaforoLucesA: editable.semaforoLucesA,
          observacionesSA: editable.observacionesSA,
          sanderB: editable.sanderB,
          qrCodeB1: editable.qrCodeB1,
          powerPackB: editable.powerPackB,
          horasMotorB: editable.horasMotorB,
          nivelCombustibleB: editable.nivelCombustibleB,
          perdidasCombustibleB: editable.perdidasCombustibleB,
          presionAceiteB: editable.presionAceiteB,
          nivelAceiteB: editable.nivelAceiteB,
          tempRefrigeranteB: editable.tempRefrigeranteB,
          perdidasRefrigeranteB: editable.perdidasRefrigeranteB,
          nivelRefrigeranteB: editable.nivelRefrigeranteB,
          refrigerantePerdidaB: editable.refrigerantePerdidaB,
          estadoBorneasB: editable.estadoBorneasB,
          limpiezaFiltroAireB: editable.limpiezaFiltroAireB,
          limpizaB: editable.limpizaB,
          qrCodeB2: editable.qrCodeB2,
          bandaTransportadoraB: editable.bandaTransportadoraB,
          alineacionBandaB: editable.alineacionBandaB,
          rolosRetornoB: editable.rolosRetornoB,
          rolosVentiladosB: editable.rolosVentiladosB,
          rolosSdriveB: editable.rolosSdriveB,
          unionBandaB: editable.unionBandaB,
          ctcpB: editable.ctcpB,
          acoplamientoCFB: editable.acoplamientoCFB,
          anclajePlacaCeldasB: editable.anclajePlacaCeldasB,
          lubricacionRodamientosB: editable.lubricacionRodamientosB,
          lubricacionCadenaB: editable.lubricacionCadenaB,
          qrCodeA3B: editable.qrCodeA3B,
          hsCircuitoHidraB: editable.hsCircuitoHidraB,
          tempAceiteHidraB: editable.tempAceiteHidraB,
          nivelAceiteHidraB: editable.nivelAceiteHidraB,
          aceiteHidraPerdidaB: editable.aceiteHidraPerdidaB,
          movimientoCarrosB: editable.movimientoCarrosB,
          presionRetonoBEB: editable.presionRetonoBEB,
          presionSantByBPB: editable.presionSantByBPB,
          presionTrabajoBPB: editable.presionTrabajoBPB,
          tablerosCerradosB: editable.tablerosCerradosB,
          cableComandoB: editable.cableComandoB,
          calibracionBalanzaB: editable.calibracionBalanzaB,
          semaforoLucesB: editable.semaforoLucesB,
          observacionesSB: editable.observacionesSB,
          observacionesGral: editable.observacionesGral,
          verResortesP1A: editable.verResortesP1A,
          verResortesP2A: editable.verResortesP2A,
          verResortesP3A: editable.verResortesP3A,
          verResortesP4A: editable.verResortesP4A,
          verContactoresP1A: editable.verContactoresP1A,
          verContactoresP2A: editable.verContactoresP2A,
          verContactoresP3A: editable.verContactoresP3A,
          verContactoresP4A: editable.verContactoresP4A,
          verCableadoP1A: editable.verCableadoP1A,
          verCableadoP2A: editable.verCableadoP2A,
          verCableadoP3A: editable.verCableadoP3A,
          verCableadoP4A: editable.verCableadoP4A,
          verConMangueraP1A: editable.verConMangueraP1A,
          verConMangueraP2A: editable.verConMangueraP2A,
          verConMangueraP3A: editable.verConMangueraP3A,
          verConMangueraP4A: editable.verConMangueraP4A,
          verResortesP1: editable.verResortesP1,
          verResortesP2: editable.verResortesP2,
          verResortesP3: editable.verResortesP3,
          verResortesP4: editable.verResortesP4,
          verContactoresP1: editable.verContactoresP1,
          verContactoresP2: editable.verContactoresP2,
          verContactoresP3: editable.verContactoresP3,
          verContactoresP4: editable.verContactoresP4,
          verCableadoP1: editable.verCableadoP1,
          verCableadoP2: editable.verCableadoP2,
          verCableadoP3: editable.verCableadoP3,
          verCableadoP4: editable.verCableadoP4,
          verConMangueraP1: editable.verConMangueraP1,
          verConMangueraP2: editable.verConMangueraP2,
          verConMangueraP3: editable.verConMangueraP3,
          verConMangueraP4: editable.verConMangueraP4,
        }
      : {
          sanderA: "",
          responsable: "",
          qrCodeA1: "",
          powerPackA: "",
          horasMotorA: "",
          nivelCombustibleA: "",
          perdidasCombustibleA: "",
          presionAceiteA: "",
          nivelAceiteA: "",
          tempRefrigeranteA: "",
          perdidasRefrigeranteA: "",
          nivelRefrigeranteA: "",
          refrigerantePerdidaA: "",
          estadoBorneasA: "",
          limpiezaFiltroAireA: "",
          limpizaA: "",
          qrCodeA2A: "",
          bandaTransportadoraA: "",
          alineacionBandaA: "",
          rolosRetornoA: "",
          rolosVentiladosA: "",
          rolosSdriveA: "",
          unionBandaA: "",
          ctcpA: "",
          acoplamientoCFA: "",
          anclajePlacaCeldasA: "",
          lubricacionRodamientosA: "",
          lubricacionCadenaA: "",
          qrCodeA3A: "",
          hsCircuitoHidraA: "",
          tempAceiteHidraA: "",
          nivelAceiteHidraA: "",
          aceiteHidraPerdidaA: "",
          movimientoCarrosA: "",
          presionRetonoBEA: "",
          presionSantByBPA: "",
          presionTrabajoBPA: "",
          tablerosCerradosA: "",
          cableComandoA: "",
          calibracionBalanzaA: "",
          semaforoLucesA: "",
          observacionesSA: "",
          sanderB: "",
          qrCodeB1: "",
          powerPackB: "",
          horasMotorB: "",
          nivelCombustibleB: "",
          perdidasCombustibleB: "",
          presionAceiteB: "",
          nivelAceiteB: "",
          tempRefrigeranteB: "",
          perdidasRefrigeranteB: "",
          nivelRefrigeranteB: "",
          refrigerantePerdidaB: "",
          estadoBorneasB: "",
          limpiezaFiltroAireB: "",
          limpizaB: "",
          qrCodeB2: "",
          bandaTransportadoraB: "",
          alineacionBandaB: "",
          rolosRetornoB: "",
          rolosVentiladosB: "",
          rolosSdriveB: "",
          unionBandaB: "",
          ctcpB: "",
          acoplamientoCFB: "",
          anclajePlacaCeldasB: "",
          lubricacionRodamientosB: "",
          lubricacionCadenaB: "",
          qrCodeA3B: "",
          hsCircuitoHidraB: "",
          tempAceiteHidraB: "",
          nivelAceiteHidraB: "",
          aceiteHidraPerdidaB: "",
          movimientoCarrosB: "",
          presionRetonoBEB: "",
          presionSantByBPB: "",
          presionTrabajoBPB: "",
          tablerosCerradosB: "",
          cableComandoB: "",
          calibracionBalanzaB: "",
          semaforoLucesB: "",
          observacionesSB: "",
          observacionesGral: "",
          verResortesP1A: "",
          verResortesP2A: "",
          verResortesP3A: "",
          verResortesP4A: "",
          verContactoresP1A: "",
          verContactoresP2A: "",
          verContactoresP3A: "",
          verContactoresP4A: "",
          verCableadoP1A: "",
          verCableadoP2A: "",
          verCableadoP3A: "",
          verCableadoP4A: "",
          verConMangueraP1A: "",
          verConMangueraP2A: "",
          verConMangueraP3A: "",
          verConMangueraP4A: "",
          verResortesP1: "",
          verResortesP2: "",
          verResortesP3: "",
          verResortesP4: "",
          verContactoresP1: "",
          verContactoresP2: "",
          verContactoresP3: "",
          verContactoresP4: "",
          verCableadoP1: "",
          verCableadoP2: "",
          verCableadoP3: "",
          verCableadoP4: "",
          verConMangueraP1: "",
          verConMangueraP2: "",
          verConMangueraP3: "",
          verConMangueraP4: "",
        };

  const handlecodigo = (e, setFieldValue) => {
    if (e === "sanderAQR1" && scannedData) {
      setFieldValue("qrCodeA1", scannedData.text);
      setScannedData();
      onClose();
    }
    if (e === "sanderAQR2" && scannedData) {
      setFieldValue("qrCodeA2A", scannedData.text);
      setScannedData();
      onClose();
    }
    if (e === "sanderAQR3" && scannedData) {
      setFieldValue("qrCodeA3A", scannedData.text);
      setScannedData();
      onClose();
    }
    if (e === "sanderBQR1" && scannedData) {
      setFieldValue("qrCodeB1", scannedData.text);
      setScannedData();
      onClose();
    }
    if (e === "sanderBQR2" && scannedData) {
      setFieldValue("qrCodeB2", scannedData.text);
      setScannedData();
      onClose();
    }
    if (e === "sanderBQR3" && scannedData) {
      setFieldValue("qrCodeA3B", scannedData.text);
      setScannedData();
      onClose();
    }
  };

  if (!loading && planificacion.dosificadora) {
    steps.push({ title: "Wetsand" });
  }

  useEffect(() => {
    initUsarios();
  }, []);

  return (
    <Stack w="100%" p={3}>
      <HStack>
        <IconButton
          icon={<MdArrowBack />}
          variant="link"
          onClick={() => navigate(-1)}
          fontSize="24px"
        />
        <Heading size={"sm"}>Revision Sanders</Heading>
      </HStack>
      <HStack w="100%" px={3} justifyContent="space-between">
        <Stepper index={activeStep} colorScheme="orange">
          {steps.map((step, index) => (
            <Step key={step}>
              <StepIndicator>
                {index !== 4 && (
                  <StepStatus
                    complete={<StepIcon />}
                    incomplete={<StepNumber />}
                    active={<StepNumber />}
                  />
                )}
                {index === 4 && (
                  <StepStatus
                    complete={<StepIcon />}
                    incomplete={"W"}
                    active={"W"}
                  />
                )}
              </StepIndicator>

              <Box flexShrink="1">
                <StepTitle>{step.title}</StepTitle>
              </Box>
            </Step>
          ))}
        </Stepper>
        <Box>
          <Text color="brand.azul_text" fontSize={14}>
            {sanderBackUp === "SanderA" ? "SANDER A" : "SANDER B"}
          </Text>
          <Text color="brand.azul_text" fontSize={14}>
            {activeStep + 1} de {steps.length} partes
          </Text>
        </Box>
      </HStack>

      {!loading && (
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          onSubmit={async (values) => {
            setLoading(true);

            let data =
              tipo === "editar"
                ? {
                    responsable: responsable,
                    idPlanificacion: editable.idPlanificacion,
                    id: editable.idRevisionSander,
                    sanderA: editable.sanderA,
                    qrCodeA1: values.qrCodeA1,
                    powerPackA: values.powerPackA,
                    horasMotorA: values.horasMotorA,
                    nivelCombustibleA: values.nivelCombustibleA,
                    perdidasCombustibleA: values.perdidasCombustibleA,
                    presionAceiteA: values.presionAceiteA,
                    nivelAceiteA: values.nivelAceiteA,
                    tempRefrigeranteA: values.tempRefrigeranteA,
                    perdidasRefrigeranteA: values.perdidasRefrigeranteA,
                    nivelRefrigeranteA: values.nivelRefrigeranteA,
                    refrigerantePerdidaA: values.refrigerantePerdidaA,
                    estadoBorneasA: values.estadoBorneasA,
                    limpiezaFiltroAireA: values.limpiezaFiltroAireA,
                    limpizaA: values.limpizaA,
                    qrCodeA2A: values.qrCodeA2A,
                    bandaTransportadoraA: values.bandaTransportadoraA,
                    alineacionBandaA: values.alineacionBandaA,
                    rolosRetornoA: values.rolosRetornoA,
                    rolosVentiladosA: values.rolosVentiladosA,
                    rolosSdriveA: values.rolosSdriveA,
                    unionBandaA: values.unionBandaA,
                    ctcpA: values.ctcpA,
                    acoplamientoCFA: values.acoplamientoCFA,
                    anclajePlacaCeldasA: values.anclajePlacaCeldasA,
                    lubricacionRodamientosA: values.lubricacionRodamientosA,
                    lubricacionCadenaA: values.lubricacionCadenaA,
                    qrCodeA3A: values.qrCodeA3A,
                    hsCircuitoHidraA: values.hsCircuitoHidraA,
                    tempAceiteHidraA: values.tempAceiteHidraA,
                    nivelAceiteHidraA: values.nivelAceiteHidraA,
                    aceiteHidraPerdidaA: values.aceiteHidraPerdidaA,
                    movimientoCarrosA: values.movimientoCarrosA,
                    presionRetonoBEA: presionRetonoBEA,
                    presionSantByBPA: values.presionSantByBPA,
                    presionTrabajoBPA: values.presionTrabajoBPA,
                    tablerosCerradosA: values.tablerosCerradosA,
                    cableComandoA: values.cableComandoA,
                    calibracionBalanzaA: values.calibracionBalanzaA,
                    semaforoLucesA: values.semaforoLucesA,
                    observacionesSA: textObservacionesSA,
                    sanderB: editable.sanderB,
                    qrCodeB1: values.qrCodeB1,
                    powerPackB: values.powerPackB,
                    horasMotorB: values.horasMotorB,
                    nivelCombustibleB: values.nivelCombustibleB,
                    perdidasCombustibleB: values.perdidasCombustibleB,
                    presionAceiteB: values.presionAceiteB,
                    nivelAceiteB: values.nivelAceiteB,
                    tempRefrigeranteB: values.tempRefrigeranteB,
                    perdidasRefrigeranteB: values.perdidasRefrigeranteB,
                    nivelRefrigeranteB: values.nivelRefrigeranteB,
                    refrigerantePerdidaB: values.refrigerantePerdidaB,
                    estadoBorneasB: values.estadoBorneasB,
                    limpiezaFiltroAireB: values.limpiezaFiltroAireB,
                    limpizaB: values.limpizaB,
                    qrCodeB2: values.qrCodeB2,
                    bandaTransportadoraB: values.bandaTransportadoraB,
                    alineacionBandaB: values.alineacionBandaB,
                    rolosRetornoB: values.rolosRetornoB,
                    rolosVentiladosB: values.rolosVentiladosB,
                    rolosSdriveB: values.rolosSdriveB,
                    unionBandaB: values.unionBandaB,
                    ctcpB: values.ctcpB,
                    acoplamientoCFB: values.acoplamientoCFB,
                    anclajePlacaCeldasB: values.anclajePlacaCeldasB,
                    lubricacionRodamientosB: values.lubricacionRodamientosB,
                    lubricacionCadenaB: values.lubricacionCadenaB,
                    qrCodeA3B: values.qrCodeA3B,
                    hsCircuitoHidraB: values.hsCircuitoHidraB,
                    tempAceiteHidraB: values.tempAceiteHidraB,
                    nivelAceiteHidraB: values.nivelAceiteHidraB,
                    aceiteHidraPerdidaB: values.aceiteHidraPerdidaB,
                    movimientoCarrosB: values.movimientoCarrosB,
                    presionRetonoBEB: values.presionRetonoBEB,
                    presionSantByBPB: values.presionSantByBPB,
                    presionTrabajoBPB: values.presionTrabajoBPB,
                    tablerosCerradosB: values.tablerosCerradosB,
                    cableComandoB: values.cableComandoB,
                    calibracionBalanzaB: values.calibracionBalanzaB,
                    semaforoLucesB: values.semaforoLucesB,
                    observacionesSB: textObservacionesSB,
                    observacionesGral: textObservacionesGral,
                    verResortesP1A: values.verResortesP1A,
                    verResortesP2A: values.verResortesP2A,
                    verResortesP3A: values.verResortesP3A,
                    verResortesP4A: values.verResortesP4A,
                    verContactoresP1A: values.verContactoresP1A,
                    verContactoresP2A: values.verContactoresP2A,
                    verContactoresP3A: values.verContactoresP3A,
                    verContactoresP4A: values.verContactoresP4A,
                    verCableadoP1A: values.verCableadoP1A,
                    verCableadoP2A: values.verCableadoP2A,
                    verCableadoP3A: values.verCableadoP3A,
                    verCableadoP4A: values.verCableadoP4A,
                    verConMangueraP1A: values.verConMangueraP1A,
                    verConMangueraP2A: values.verConMangueraP2A,
                    verConMangueraP3A: values.verConMangueraP3A,
                    verConMangueraP4A: values.verConMangueraP4A,
                    verResortesP1: values.verResortesP1,
                    verResortesP2: values.verResortesP2,
                    verResortesP3: values.verResortesP3,
                    verResortesP4: values.verResortesP4,
                    verContactoresP1: values.verContactoresP1,
                    verContactoresP2: values.verContactoresP2,
                    verContactoresP3: values.verContactoresP3,
                    verContactoresP4: values.verContactoresP4,
                    verCableadoP1: values.verCableadoP1,
                    verCableadoP2: values.verCableadoP2,
                    verCableadoP3: values.verCableadoP3,
                    verCableadoP4: values.verCableadoP4,
                    verConMangueraP1: values.verConMangueraP1,
                    verConMangueraP2: values.verConMangueraP2,
                    verConMangueraP3: values.verConMangueraP3,
                    verConMangueraP4: values.verConMangueraP4,
                    equipoRevision: "Sander",
                  }
                : {
                    responsable: responsable,
                    idPlanificacion: planificacion.idPlanificacion,
                    sanderA: values.sanderA,
                    qrCodeA1: values.qrCodeA1,
                    powerPackA: values.powerPackA,
                    horasMotorA: values.horasMotorA,
                    nivelCombustibleA: values.nivelCombustibleA,
                    perdidasCombustibleA: values.perdidasCombustibleA,
                    presionAceiteA: values.presionAceiteA,
                    nivelAceiteA: values.nivelAceiteA,
                    tempRefrigeranteA: values.tempRefrigeranteA,
                    perdidasRefrigeranteA: values.perdidasRefrigeranteA,
                    nivelRefrigeranteA: values.nivelRefrigeranteA,
                    refrigerantePerdidaA: values.refrigerantePerdidaA,
                    estadoBorneasA: values.estadoBorneasA,
                    limpiezaFiltroAireA: values.limpiezaFiltroAireA,
                    limpizaA: values.limpizaA,
                    qrCodeA2A: values.qrCodeA2A,
                    bandaTransportadoraA: values.bandaTransportadoraA,
                    alineacionBandaA: values.alineacionBandaA,
                    rolosRetornoA: values.rolosRetornoA,
                    rolosVentiladosA: values.rolosVentiladosA,
                    rolosSdriveA: values.rolosSdriveA,
                    unionBandaA: values.unionBandaA,
                    ctcpA: values.ctcpA,
                    acoplamientoCFA: values.acoplamientoCFA,
                    anclajePlacaCeldasA: values.anclajePlacaCeldasA,
                    lubricacionRodamientosA: values.lubricacionRodamientosA,
                    lubricacionCadenaA: values.lubricacionCadenaA,
                    qrCodeA3A: values.qrCodeA3A,
                    hsCircuitoHidraA: values.hsCircuitoHidraA,
                    tempAceiteHidraA: values.tempAceiteHidraA,
                    nivelAceiteHidraA: values.nivelAceiteHidraA,
                    aceiteHidraPerdidaA: values.aceiteHidraPerdidaA,
                    movimientoCarrosA: values.movimientoCarrosA,
                    presionRetonoBEA: presionRetonoBEA,
                    presionSantByBPA: values.presionSantByBPA,
                    presionTrabajoBPA: values.presionTrabajoBPA,
                    tablerosCerradosA: values.tablerosCerradosA,
                    cableComandoA: values.cableComandoA,
                    calibracionBalanzaA: values.calibracionBalanzaA,
                    semaforoLucesA: values.semaforoLucesA,
                    observacionesSA: textObservacionesSA,
                    sanderB: values.sanderB,
                    qrCodeB1: values.qrCodeB1,
                    powerPackB: values.powerPackB,
                    horasMotorB: values.horasMotorB,
                    nivelCombustibleB: values.nivelCombustibleB,
                    perdidasCombustibleB: values.perdidasCombustibleB,
                    presionAceiteB: values.presionAceiteB,
                    nivelAceiteB: values.nivelAceiteB,
                    tempRefrigeranteB: values.tempRefrigeranteB,
                    perdidasRefrigeranteB: values.perdidasRefrigeranteB,
                    nivelRefrigeranteB: values.nivelRefrigeranteB,
                    refrigerantePerdidaB: values.refrigerantePerdidaB,
                    estadoBorneasB: values.estadoBorneasB,
                    limpiezaFiltroAireB: values.limpiezaFiltroAireB,
                    limpizaB: values.limpizaB,
                    qrCodeB2: values.qrCodeB2,
                    bandaTransportadoraB: values.bandaTransportadoraB,
                    alineacionBandaB: values.alineacionBandaB,
                    rolosRetornoB: values.rolosRetornoB,
                    rolosVentiladosB: values.rolosVentiladosB,
                    rolosSdriveB: values.rolosSdriveB,
                    unionBandaB: values.unionBandaB,
                    ctcpB: values.ctcpB,
                    acoplamientoCFB: values.acoplamientoCFB,
                    anclajePlacaCeldasB: values.anclajePlacaCeldasB,
                    lubricacionRodamientosB: values.lubricacionRodamientosB,
                    lubricacionCadenaB: values.lubricacionCadenaB,
                    qrCodeA3B: values.qrCodeA3B,
                    hsCircuitoHidraB: values.hsCircuitoHidraB,
                    tempAceiteHidraB: values.tempAceiteHidraB,
                    nivelAceiteHidraB: values.nivelAceiteHidraB,
                    aceiteHidraPerdidaB: values.aceiteHidraPerdidaB,
                    movimientoCarrosB: values.movimientoCarrosB,
                    presionRetonoBEB: values.presionRetonoBEB,
                    presionSantByBPB: values.presionSantByBPB,
                    presionTrabajoBPB: values.presionTrabajoBPB,
                    tablerosCerradosB: values.tablerosCerradosB,
                    cableComandoB: values.cableComandoB,
                    calibracionBalanzaB: values.calibracionBalanzaB,
                    semaforoLucesB: values.semaforoLucesB,
                    observacionesSB: textObservacionesSB,
                    observacionesGral: textObservacionesGral,
                    verResortesP1A: values.verResortesP1A,
                    verResortesP2A: values.verResortesP2A,
                    verResortesP3A: values.verResortesP3A,
                    verResortesP4A: values.verResortesP4A,
                    verContactoresP1A: values.verContactoresP1A,
                    verContactoresP2A: values.verContactoresP2A,
                    verContactoresP3A: values.verContactoresP3A,
                    verContactoresP4A: values.verContactoresP4A,
                    verCableadoP1A: values.verCableadoP1A,
                    verCableadoP2A: values.verCableadoP2A,
                    verCableadoP3A: values.verCableadoP3A,
                    verCableadoP4A: values.verCableadoP4A,
                    verConMangueraP1A: values.verConMangueraP1A,
                    verConMangueraP2A: values.verConMangueraP2A,
                    verConMangueraP3A: values.verConMangueraP3A,
                    verConMangueraP4A: values.verConMangueraP4A,
                    verResortesP1: values.verResortesP1,
                    verResortesP2: values.verResortesP2,
                    verResortesP3: values.verResortesP3,
                    verResortesP4: values.verResortesP4,
                    verContactoresP1: values.verContactoresP1,
                    verContactoresP2: values.verContactoresP2,
                    verContactoresP3: values.verContactoresP3,
                    verContactoresP4: values.verContactoresP4,
                    verCableadoP1: values.verCableadoP1,
                    verCableadoP2: values.verCableadoP2,
                    verCableadoP3: values.verCableadoP3,
                    verCableadoP4: values.verCableadoP4,
                    verConMangueraP1: values.verConMangueraP1,
                    verConMangueraP2: values.verConMangueraP2,
                    verConMangueraP3: values.verConMangueraP3,
                    verConMangueraP4: values.verConMangueraP4,
                    equipoRevision: "Sander",
                  };

            let res;
            if (tipo === "editar") {
              res = await editarRevisionSanders(data);
            } else {
              res = await crearRevisionSanders(data);
            }
            if (res.status === 200) {
              toast({
                status: "success",
                isClosable: true,
                title: `Revision de Sanders ${
                  tipo === "editar" ? "editada" : "creada"
                } correctamente`,
                duration: 3000,
              });
            } else {
              setLoading(false);
              return toast({
                status: "error",
                isClosable: true,
                title: `Error al ${
                  tipo === "editar" ? "editar" : "crear"
                } revision de sanders`,
                duration: 3000,
              });
            }

            navigate(-1);
            setLoading(false);
          }}
        >
          {({
            values,
            handleBlur,
            handleSubmit,
            handleChange,
            setFieldValue,
          }) => (
            <Form id="FormSander">
              {sanderBackUp === "SanderA" && (
                <>
                  {activeStep === 0 && (
                    <Stack>
                      <HStack justifyContent="space-between">
                        <FormControl w="2xs" size="sm" isRequired>
                          <FormLabel>Responsable</FormLabel>
                          <Select
                            w="2xs"
                            size="sm"
                            placeholder="Seleccione responsable"
                            icon={<MdArrowDropDown />}
                            onChange={(e) => setResponsable(e.target.value)}
                          >
                            {usuarios
                              .sort((a, b) => {
                                // Ordenar alfabéticamente por nombre y apellido
                                const nameA = `${a.nombre}`.toUpperCase();
                                const nameB = `${b.nombre}`.toUpperCase();
                                if (nameA < nameB) return -1;
                                if (nameA > nameB) return 1;
                                return 0;
                              })
                              .map((usuario) => (
                                <option
                                  key={usuario.idUsuario}
                                  value={usuario.idUsuario}
                                >
                                  {usuario.nombre}
                                </option>
                              ))}
                          </Select>
                        </FormControl>
                        {tipo === "crear" && (
                          <FormControl w="2xs" size="sm" isRequired>
                            <FormLabel>Sander A</FormLabel>
                            <Select
                              w="2xs"
                              size="sm"
                              icon={<MdArrowDropDown />}
                              name="sanderA"
                              placeholder="Seleccionar Sander"
                              value={values.sanderA}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            >
                              {planificacion.sanderPrincipal && (
                                <option
                                  value={planificacion.sanderPrincipal.idSander}
                                >
                                  {planificacion.sanderPrincipal.codigoSander}
                                </option>
                              )}
                              {planificacion.sanderBackup && (
                                <option
                                  value={planificacion.sanderBackup.idSander}
                                >
                                  {planificacion.sanderBackup.codigoSander}
                                </option>
                              )}
                            </Select>
                          </FormControl>
                        )}
                        {tipo === "editar" && (
                          <FormControl w="2xs" size="sm" isRequired>
                            <FormLabel>Sander A</FormLabel>
                            <Input
                              value={values.sanderA}
                              w="2xs"
                              size="sm"
                              bg="brand.gris_medio"
                              readOnly
                            />
                          </FormControl>
                        )}
                        <FormControl></FormControl>
                      </HStack>
                      <Text fontWeight="bold" color="brand.gris_medio">
                        Power Pack
                      </Text>
                      <HStack>
                        <FormControl w="2xs">
                          <FormLabel>QR Code A.1</FormLabel>
                          <InputGroup size="md">
                            <Input
                              placeholder="QR Code A.1"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              name="qrCodeA1"
                              value={values.qrCodeA1}
                            />
                            <InputRightElement width="4.5rem">
                              <Button h="1.75rem" size="sm" onClick={onOpen}>
                                <MdOutlineQrCode2 />
                              </Button>
                            </InputRightElement>
                          </InputGroup>

                          <Modal isOpen={isOpen} onClose={onClose}>
                            <ModalOverlay />
                            <ModalContent>
                              <ModalBody>
                                <CSSReset />
                                <HandleQrReader
                                  scannedData={scannedData}
                                  setScannedData={setScannedData}
                                  handlecodigo={handlecodigo}
                                  type="sanderAQR1"
                                  setFieldValue={setFieldValue}
                                />
                              </ModalBody>
                            </ModalContent>
                          </Modal>
                        </FormControl>
                        <FormControl isRequired w="2xs">
                          <FormLabel>Power Pack</FormLabel>
                          <Input
                            placeholder="Power Pack"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="powerPackA"
                            value={values.powerPackA}
                          />
                        </FormControl>
                        <FormControl w="2xs">
                          <FormLabel>Horas del Motor</FormLabel>
                          <Input
                            placeholder="Horas del Motor"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="horasMotorA"
                            value={values.horasMotorA}
                          />
                        </FormControl>
                      </HStack>
                      <HStack>
                        <FormControl isRequired w="2xs">
                          <FormLabel>Nivel de combustible</FormLabel>
                          <Input
                            placeholder="% nivel de combustible"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="nivelCombustibleA"
                            value={values.nivelCombustibleA}
                          />
                        </FormControl>
                        <FormControl isRequired w="sm" placeSelf="end">
                          <HStack>
                            <Switch
                              onChange={handleChange}
                              isChecked={values.perdidasCombustibleA}
                              name="perdidasCombustibleA"
                            />
                            <Text>Sin pérdidas?</Text>
                          </HStack>
                          <Text fontSize="10px" placeSelf="end">
                            Marcar si NO se registran pérdidas de combustible
                          </Text>
                        </FormControl>
                        <FormControl w="2xs"></FormControl>
                      </HStack>
                      <HStack>
                        <FormControl isRequired w="2xs">
                          <FormLabel>Presión del aceite</FormLabel>
                          <Input
                            placeholder="Presión del aceite"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="presionAceiteA"
                            value={values.presionAceiteA}
                          />
                        </FormControl>
                        <FormControl isRequired w="sm" placeSelf="end">
                          <HStack>
                            <Switch
                              onChange={handleChange}
                              isChecked={values.nivelAceiteA}
                              name="nivelAceiteA"
                            />
                            <Text>Nivel de aceite normal?</Text>
                          </HStack>

                          <Text fontSize="10px" placeSelf="end">
                            Marcar si el nivel de acite ES NORMAL
                          </Text>
                        </FormControl>
                        <FormControl w="2xs"></FormControl>
                      </HStack>
                      <HStack>
                        <FormControl isRequired w="2xs">
                          <FormLabel>Temperatura refrigerante</FormLabel>
                          <Input
                            placeholder="Temperatura refrigerante"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="tempRefrigeranteA"
                            value={values.tempRefrigeranteA}
                          />
                        </FormControl>
                        <HStack placeContent={"end"}>
                          <FormControl isRequired w="sm">
                            <HStack>
                              <Switch
                                onChange={handleChange}
                                isChecked={values.perdidasRefrigeranteA}
                                name="perdidasRefrigeranteA"
                              />
                              <Text>Sin pérdidas?</Text>
                            </HStack>

                            <Text fontSize="10px" placeSelf="end">
                              Marcar si NO se registran pérdidas de refrigerante
                            </Text>
                          </FormControl>
                          <FormControl w="sm">
                            <HStack>
                              <Switch
                                onChange={handleChange}
                                isChecked={values.nivelRefrigeranteA}
                                name="nivelRefrigeranteA"
                              />
                              <Text>Nivel de refrigerante normal?</Text>
                            </HStack>
                            <Text fontSize="10px" placeSelf="end">
                              Marcar si el nivel de refrigerante ES NORMAL
                            </Text>
                          </FormControl>
                        </HStack>
                      </HStack>
                      <Stack>
                        <FormControl>
                          <HStack>
                            <Switch
                              onChange={handleChange}
                              isChecked={values.refrigerantePerdidaA}
                              name="refrigerantePerdidaA"
                            />
                            <Text>Refrigerante: Sin pérdidas?</Text>

                            <Text fontSize="10px" placeSelf="end">
                              Marcar si no registran pérdidas de líquido
                              refrigerante en el sistema
                            </Text>
                          </HStack>
                        </FormControl>
                        <FormControl>
                          <HStack>
                            <Switch
                              onChange={handleChange}
                              isChecked={values.estadoBorneasA}
                              name="estadoBorneasA"
                            />
                            <Text>Eléctrico: Estado borneras</Text>
                            <Text fontSize="10px" placeSelf="end">
                              Marcar si el estado de borneras es normal
                            </Text>
                          </HStack>
                        </FormControl>
                        <FormControl>
                          <HStack>
                            <Switch
                              onChange={handleChange}
                              isChecked={values.limpiezaFiltroAireA}
                              name="limpiezaFiltroAireA"
                            />
                            <Text>Limpieza de filtro primario de aire</Text>

                            <Text fontSize="10px" placeSelf="end">
                              Realizar limpieza con golpes suaves en el cuerpo
                              de filtro. Marcar, una vez realizada la limpieza,
                              si el indicador de saturación de admisión no
                              indica reemplazo
                            </Text>
                          </HStack>
                        </FormControl>
                        <FormControl>
                          <HStack>
                            <Switch
                              onChange={handleChange}
                              isChecked={values.limpizaA}
                              name="limpizaA"
                            />
                            <Text>Limpieza</Text>

                            <Text fontSize="10px" placeSelf="end">
                              Marcar si no hay depósitos de arena
                            </Text>
                          </HStack>
                        </FormControl>
                      </Stack>
                      <HStack w="100%" justifyContent="end" p={3}>
                        <Button
                          fontSize={12}
                          fontWeight="semibold"
                          bg="brand.naranja"
                          color="white"
                          rightIcon={<MdNavigateNext fontSize="20px" />}
                          variant="solid"
                          form="FormularioEtapa"
                          isDisabled={loading}
                          onClick={() => {
                            if (!responsable) {
                              setLoading(false);
                              return toast({
                                title: "Error",
                                description: "Responsable requerido",
                                status: "error",
                                isClosable: true,
                                duration: 3000,
                              });
                            } else if (!values.sanderA) {
                              setLoading(false);
                              return toast({
                                title: "Error",
                                description: "Sander requerido",
                                status: "error",
                                isClosable: true,
                                duration: 3000,
                              });
                            } else if (!values.powerPackA) {
                              setLoading(false);
                              return toast({
                                title: "Error",
                                description: "Power Pack requerido",
                                status: "error",
                                isClosable: true,
                                duration: 3000,
                              });
                            } else if (!values.nivelCombustibleA) {
                              setLoading(false);
                              return toast({
                                title: "Error",
                                description: "Nivel de combustible requerido",
                                status: "error",
                                isClosable: true,
                                duration: 3000,
                              });
                            } else if (!values.presionAceiteA) {
                              setLoading(false);
                              return toast({
                                title: "Error",
                                description: "Presión de aceite requerido",
                                status: "error",
                                isClosable: true,
                                duration: 3000,
                              });
                            } else if (!values.tempRefrigeranteA) {
                              setLoading(false);
                              return toast({
                                title: "Error",
                                description:
                                  "Temperatura de refigerante requerida",
                                status: "error",
                                isClosable: true,
                                duration: 3000,
                              });
                            } else {
                              setActiveStep(activeStep + 1);
                            }
                          }}
                          _hover={{ bg: "white", color: "brand.naranja" }}
                        >
                          Continuar
                        </Button>
                      </HStack>
                    </Stack>
                  )}
                  {activeStep === 1 && (
                    <Stack>
                      <HStack>
                        <FormControl w="2xs">
                          <FormLabel>QR Code A.2</FormLabel>
                          <InputGroup size="md">
                            <Input
                              w="2xs"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              name="qrCodeA2A"
                              value={values.qrCodeA2A}
                            />
                            <InputRightElement width="4.5rem">
                              <Button h="1.75rem" size="sm" onClick={onOpen}>
                                <MdOutlineQrCode2 />
                              </Button>
                            </InputRightElement>
                          </InputGroup>
                          <Modal isOpen={isOpen} onClose={onClose}>
                            <ModalOverlay />
                            <ModalContent>
                              <ModalBody>
                                <CSSReset />
                                <HandleQrReader
                                  scannedData={scannedData}
                                  setScannedData={setScannedData}
                                  handlecodigo={handlecodigo}
                                  type="sanderAQR2"
                                  setFieldValue={setFieldValue}
                                />
                              </ModalBody>
                            </ModalContent>
                          </Modal>
                          <Text fontSize="10px" placeSelf="end">
                            Capture QR con cámara
                          </Text>
                        </FormControl>
                      </HStack>
                      <HStack>
                        <Stack>
                          <FormControl>
                            <HStack>
                              <Switch
                                onChange={handleChange}
                                isChecked={values.bandaTransportadoraA}
                                name="bandaTransportadoraA"
                              />
                              <Text>Banda Transportadora</Text>
                            </HStack>
                            <Text fontSize="10px">
                              Marcar si Estado OK. Verificar ausencia de
                              cortaduras, roce, daños.
                            </Text>
                          </FormControl>
                          <FormControl>
                            <HStack>
                              <Switch
                                onChange={handleChange}
                                isChecked={values.alineacionBandaA}
                                name="alineacionBandaA"
                              />
                              <Text>Alineación de Banda</Text>
                            </HStack>
                            <Text fontSize="10px">
                              Marcar si la banda se encuentra centrada sobre
                              rodillos y cuna. Alinear si es necesario
                            </Text>
                          </FormControl>
                          <FormControl>
                            <HStack>
                              <Switch
                                onChange={handleChange}
                                isChecked={values.rolosRetornoA}
                                name="rolosRetornoA"
                              />
                              <Text>Rolos de Retorno</Text>
                            </HStack>
                            <Text fontSize="10px">
                              Marchar la banda a baja velocidad. Marcar si los
                              rodillos giran libremente sin ruido o vibraciones
                            </Text>
                          </FormControl>
                          <FormControl>
                            <HStack>
                              <Switch
                                onChange={handleChange}
                                isChecked={values.rolosVentiladosA}
                                name="rolosVentiladosA"
                              />
                              <Text>Rolos ventilados</Text>
                            </HStack>
                            <Text fontSize="10px">
                              Marcar si no hay fisuras, deformación, falta de
                              bulones, juego en conos, desgaste apreciable
                            </Text>
                          </FormControl>

                          <FormControl>
                            <HStack>
                              <Switch
                                onChange={handleChange}
                                isChecked={values.rolosSdriveA}
                                name="rolosSdriveA"
                              />
                              <Text>Rolos Sdrive</Text>
                            </HStack>

                            <Text fontSize="10px">
                              Marcar si no hay fisuras, deformación,
                              oscilaciones, desalineación, desgaste apreciable
                            </Text>
                          </FormControl>
                          <FormControl>
                            <HStack>
                              <Switch
                                onChange={handleChange}
                                isChecked={values.unionBandaA}
                                name="unionBandaA"
                              />
                              <Text>Unión de Banda</Text>
                            </HStack>
                            <Text fontSize="10px">
                              Marcar si no hay fisuras, desgaste, roturas,
                              pérdida de tornillos, apertura de fajas, etc.
                            </Text>
                          </FormControl>
                        </Stack>
                        <Stack placeSelf="start">
                          <FormControl>
                            <HStack>
                              <Switch
                                onChange={handleChange}
                                isChecked={values.ctcpA}
                                name="ctcpA"
                              />
                              <Text>
                                Cadena de Transmisión de cinta principal
                              </Text>
                            </HStack>
                            <Text fontSize="10px">
                              Marchar la cinta a baja velocidad. Marcar si la
                              cadena gira sin vibraciones o roses contra el
                              protector de cadena. Tensar si es necesario
                            </Text>
                          </FormControl>
                          <FormControl>
                            <HStack>
                              <Switch
                                onChange={handleChange}
                                isChecked={values.acoplamientoCFA}
                                name="acoplamientoCFA"
                              />
                              <Text>Acoplamiento de Cinta Frontal</Text>
                            </HStack>
                            <Text fontSize="10px">
                              Marchar la cinta a baja velocidad. Marcar si el
                              elemento elástico está en buen estado, no hay
                              desalineación, juego, vibraciones o deslizamiento
                              sobre ejes.
                            </Text>
                          </FormControl>
                          <FormControl>
                            <HStack>
                              <Switch
                                onChange={handleChange}
                                isChecked={values.anclajePlacaCeldasA}
                                name="anclajePlacaCeldasA"
                              />
                              <Text>Anclaje placas de celdas</Text>
                            </HStack>
                            <Text fontSize="10px">
                              Marcar si las placas de apoyo están fijas y no hay
                              arena entre placas y celdas
                            </Text>
                          </FormControl>
                          <FormControl>
                            <HStack>
                              <Switch
                                onChange={handleChange}
                                isChecked={values.lubricacionRodamientosA}
                                name="lubricacionRodamientosA"
                              />
                              <Text>Lubricación de rodamientos</Text>
                            </HStack>
                            <Text fontSize="10px">
                              Marcar ok luego de haberlos lubricado. “Lubricar
                              cajas de rodamiento correspondientes a rolos
                              tractores (x4), rolos ventilados (x4) y rolo
                              S-Drive (x2)”
                            </Text>
                          </FormControl>
                          <FormControl>
                            <HStack>
                              <Switch
                                onChange={handleChange}
                                isChecked={values.lubricacionCadenaA}
                                name="lubricacionCadenaA"
                              />
                              <Text>Lubricación de cadena</Text>
                            </HStack>
                            <Text fontSize="10px">
                              Marcar ok luego de haber lubricado
                            </Text>
                          </FormControl>
                        </Stack>
                      </HStack>
                      <HStack justifyContent="space-between" p={3}>
                        <Button
                          fontSize={12}
                          letterSpacing={1}
                          border="1px solid orange"
                          fontWeight="semibold"
                          variant="outline"
                          color="brand.naranja"
                          leftIcon={<MdNavigateBefore fontSize="20px" />}
                          onClick={() => {
                            setActiveStep(activeStep - 1);
                          }}
                          alignSelf="end"
                          _hover={{ bg: "brand.naranja", color: "white" }}
                        >
                          Atras
                        </Button>
                        <Button
                          fontSize={12}
                          fontWeight="semibold"
                          bg="brand.naranja"
                          color="white"
                          rightIcon={<MdNavigateNext fontSize="20px" />}
                          variant="solid"
                          form="FormularioEtapa"
                          isDisabled={loading}
                          onClick={() => {
                            setActiveStep(activeStep + 1);
                          }}
                          _hover={{ bg: "white", color: "brand.naranja" }}
                        >
                          Continuar
                        </Button>
                      </HStack>
                    </Stack>
                  )}
                  {activeStep === 2 && (
                    <Stack>
                      <HStack justifyContent="space-between">
                        <FormControl w="xs">
                          <FormLabel>QR Code A.3</FormLabel>
                          <InputGroup size="md">
                            <Input
                              w="2xs"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              name="qrCodeA3A"
                              value={values.qrCodeA3A}
                            />
                            <InputRightElement width="4.5rem">
                              <Button h="1.75rem" size="sm" onClick={onOpen}>
                                <MdOutlineQrCode2 />
                              </Button>
                            </InputRightElement>
                          </InputGroup>
                          <Modal isOpen={isOpen} onClose={onClose}>
                            <ModalOverlay />
                            <ModalContent>
                              <ModalBody>
                                <CSSReset />
                                <HandleQrReader
                                  scannedData={scannedData}
                                  setScannedData={setScannedData}
                                  handlecodigo={handlecodigo}
                                  type="sanderAQR3"
                                  setFieldValue={setFieldValue}
                                />
                              </ModalBody>
                            </ModalContent>
                          </Modal>
                          <Text fontSize="10px" placeSelf="end">
                            Capture QR con cámara
                          </Text>
                        </FormControl>
                        <FormControl w="xs" isRequired alignSelf="start">
                          <FormLabel w="xs">
                            Horas Circuito Hidráulico (Q.Variable)
                          </FormLabel>
                          <Input
                            type="number"
                            w="2xs"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="hsCircuitoHidraA"
                            value={values.hsCircuitoHidraA}
                          />
                        </FormControl>
                        <FormControl w="xs" isRequired>
                          <FormLabel>Aceite Hidráulico: Temperatura</FormLabel>
                          <Input
                            w="2xs"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="tempAceiteHidraA"
                            value={values.tempAceiteHidraA}
                          />
                          <Text w="xs" fontSize="10px" placeSelf="end">
                            Registrar valor de temperatura en °C. Rango normal:
                            40-80°
                          </Text>
                        </FormControl>
                      </HStack>
                      <Stack>
                        <FormControl>
                          <HStack>
                            <Switch
                              onChange={handleChange}
                              isChecked={values.nivelAceiteHidraA}
                              name="nivelAceiteHidraA"
                            />
                            <Text>Aceite Hidráulico: Nivel</Text>

                            <Text fontSize="10px">
                              Marcar si el Nivel del Aceite Hidráulico es Normal
                              (entre marcas de máximo y mínimo). Si el nivel de
                              hidráulico es inferior al punto mínimo, completar
                            </Text>
                          </HStack>
                        </FormControl>
                        <FormControl>
                          <HStack>
                            <Switch
                              onChange={handleChange}
                              isChecked={values.aceiteHidraPerdidaA}
                              name="aceiteHidraPerdidaA"
                            />
                            <Text>Aceite Hidráulico: Sin Pérdidas?</Text>

                            <Text fontSize="10px">
                              Marcar si no se registran pérdidas de aceite
                              hidráulico
                            </Text>
                          </HStack>
                        </FormControl>
                        <FormControl>
                          <HStack>
                            <Switch
                              onChange={handleChange}
                              isChecked={values.movimientoCarrosA}
                              name="movimientoCarrosA"
                            />
                            <Text>Movimiento de Carros</Text>

                            <Text fontSize="10px">
                              Marcar si el movimiento de los carros es Normal
                            </Text>
                          </HStack>
                        </FormControl>
                      </Stack>
                      <HStack>
                        <FormControl w="xs" isRequired>
                          <FormLabel>
                            Presión de retorno de bomba de engranajes{" "}
                          </FormLabel>
                          <NumberInput
                            defaultValue={values.presionRetonoBEA}
                            min={0}
                            max={8}
                            step={0.1}
                            maxW={32}
                            onChange={(e) => setPresionRetonoBEA(e)}
                            onBlur={handleBlur}
                            name="presionRetonoBEA"
                            allowMouseWheel
                          >
                            <NumberInputField value={presionRetonoBEA} />
                            <NumberInputStepper>
                              <NumberIncrementStepper
                                children={<AddIcon />}
                                bg="green.500"
                                color={"white"}
                              />
                              <NumberDecrementStepper
                                children={<MinusIcon />}
                                bg="red.500"
                                color={"white"}
                              />
                            </NumberInputStepper>
                          </NumberInput>

                          <Text fontSize="10px" placeSelf="end">
                            Registrar presión en bar. Max. 8 bar
                          </Text>
                        </FormControl>
                        <FormControl w="xs" isRequired>
                          <FormLabel>
                            Presión de stand by de bomba de pistones{" "}
                          </FormLabel>
                          <InputGroup w="2xs">
                            <Input
                              w="2xs"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              name="presionSantByBPA"
                              value={values.presionSantByBPA}
                            />
                            <InputRightAddon children="BAR" />
                          </InputGroup>
                          <Text fontSize="10px" placeSelf="end">
                            Motor a 1700 RPM y cintas detenidas.
                          </Text>
                        </FormControl>
                        <FormControl w="xs" isRequired>
                          <FormLabel>
                            Presión de trabajo de bomba de pistones
                          </FormLabel>
                          <InputGroup w="2xs">
                            <Input
                              w="2xs"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              name="presionTrabajoBPA"
                              value={values.presionTrabajoBPA}
                            />
                            <InputRightAddon children="BAR" />
                          </InputGroup>
                          <Text fontSize="10px" placeSelf="end">
                            Checkear presión durante máxima concentración de
                            etapa anterior.
                          </Text>
                        </FormControl>
                      </HStack>
                      <HStack w="100%" justifyContent="space-between" p={3}>
                        <Button
                          fontSize={12}
                          letterSpacing={1}
                          border="1px solid orange"
                          fontWeight="semibold"
                          variant="outline"
                          color="brand.naranja"
                          leftIcon={<MdNavigateBefore fontSize="20px" />}
                          onClick={() => setActiveStep(activeStep - 1)}
                          alignSelf="end"
                          _hover={{ bg: "brand.naranja", color: "white" }}
                        >
                          Atras
                        </Button>
                        <Button
                          fontSize={12}
                          fontWeight="semibold"
                          bg="brand.naranja"
                          color="white"
                          rightIcon={<MdNavigateNext fontSize="20px" />}
                          variant="solid"
                          form="FormularioEtapa"
                          isDisabled={loading}
                          onClick={() => {
                            if (!values.hsCircuitoHidraA) {
                              setLoading(false);
                              return toast({
                                title: "Error",
                                description:
                                  "Horas Circuito Hidráulico requeridos",
                                status: "error",
                                isClosable: true,
                                duration: 3000,
                              });
                            } else if (!values.tempAceiteHidraA) {
                              setLoading(false);
                              return toast({
                                title: "Error",
                                description:
                                  "Aceite Hidráulico: Temperatura requerida",
                                status: "error",
                                isClosable: true,
                                duration: 3000,
                              });
                            } else if (!presionRetonoBEA) {
                              setLoading(false);
                              return toast({
                                title: "Error",
                                description:
                                  "Presión de retorno de bomba de engranajes requerida",
                                status: "error",
                                isClosable: true,
                                duration: 3000,
                              });
                            } else if (!values.presionSantByBPA) {
                              setLoading(false);
                              return toast({
                                title: "Error",
                                description:
                                  "Presión de stand by de bomba de pistones requerida",
                                status: "error",
                                isClosable: true,
                                duration: 3000,
                              });
                            } else if (!values.presionTrabajoBPA) {
                              setLoading(false);
                              return toast({
                                title: "Error",
                                description:
                                  "Presión de trabajo de bomba de pistones requerida",
                                status: "error",
                                isClosable: true,
                                duration: 3000,
                              });
                            } else {
                              setActiveStep(activeStep + 1);
                            }
                          }}
                          _hover={{ bg: "white", color: "brand.naranja" }}
                        >
                          Continuar
                        </Button>
                      </HStack>
                    </Stack>
                  )}
                  {activeStep === 3 && (
                    <Stack>
                      <HStack spacing={20}>
                        <Stack>
                          <FormControl>
                            <HStack>
                              <Switch
                                onChange={handleChange}
                                isChecked={values.tablerosCerradosA}
                                name="tablerosCerradosA"
                              />
                              <Text>Tableros cerrados</Text>
                            </HStack>
                            <Text fontSize="10px">
                              Marcar ok si están bien cerrados
                            </Text>
                          </FormControl>
                          <FormControl>
                            <HStack>
                              <Switch
                                onChange={handleChange}
                                isChecked={values.cableComandoA}
                                name="cableComandoA"
                              />
                              <Text>Cable de comando</Text>
                            </HStack>
                            <Text fontSize="10px">
                              Marcar ok si está en buenas condiciones
                            </Text>
                          </FormControl>
                          <FormControl>
                            <HStack>
                              <Switch
                                onChange={handleChange}
                                isChecked={values.calibracionBalanzaA}
                                name="calibracionBalanzaA"
                              />
                              <Text>Calibración de balanzas</Text>
                            </HStack>
                            <Text fontSize="10px">
                              Marcar ok si están calibradas. 10% máxima
                              imprecision
                            </Text>
                          </FormControl>
                          <FormControl>
                            <HStack>
                              <Switch
                                onChange={handleChange}
                                isChecked={values.semaforoLucesA}
                                name="semaforoLucesA"
                              />
                              <Text>Funcionamiento Semáforos y Luces</Text>
                            </HStack>
                            <Text fontSize="10px">
                              Marcar si las luces y semáforos funcionan
                              correctamente
                            </Text>
                          </FormControl>
                        </Stack>
                        <FormControl placeSelf="center" w="sm">
                          <FormLabel>Observaciones y Comentarios</FormLabel>
                          <Card
                            w="lg"
                            variant="unstyled"
                            bg="brand.fondos_secundarios"
                          >
                            <CardBody>
                              <Textarea
                                w="lg"
                                placeholder="Descripcion"
                                name="observacionesSA"
                                onChange={(e) =>
                                  setTextObservacionesSA(e.target.value)
                                }
                                onBlur={handleBlur}
                                maxLength="1500"
                                resize="none"
                              />
                            </CardBody>
                            <CardFooter
                              placeSelf="end"
                              color="brand.gris_medio"
                            >
                              {charCountSA}/1500
                            </CardFooter>
                          </Card>
                          <Text fontSize="10px">
                            Agregue aquí comentarios y observaciones acerca de
                            la revisión general de equipos
                          </Text>
                        </FormControl>
                      </HStack>
                      <HStack justifyContent="space-between" p={3}>
                        <Button
                          fontSize={12}
                          letterSpacing={1}
                          border="1px solid orange"
                          fontWeight="semibold"
                          variant="outline"
                          color="brand.naranja"
                          leftIcon={<MdNavigateBefore fontSize="20px" />}
                          onClick={() => setActiveStep(activeStep - 1)}
                          alignSelf="end"
                          _hover={{ bg: "brand.naranja", color: "white" }}
                        >
                          Atras
                        </Button>
                        {!planificacion.dosificadora && (
                          <Button
                            fontSize={12}
                            fontWeight="semibold"
                            bg="brand.naranja"
                            color="white"
                            rightIcon={<MdNavigateNext fontSize="20px" />}
                            variant="solid"
                            form="FormularioEtapa"
                            isDisabled={loading}
                            onClick={() => {
                              setSanderBackUp("SanderB");
                              setActiveStep(activeStep - 3);
                            }}
                            _hover={{ bg: "white", color: "brand.naranja" }}
                          >
                            Pasar a SANDER B
                          </Button>
                        )}
                        {planificacion.dosificadora && (
                          <Button
                            fontSize={12}
                            fontWeight="semibold"
                            bg="brand.naranja"
                            color="white"
                            rightIcon={<MdNavigateNext fontSize="20px" />}
                            variant="solid"
                            form="FormSander"
                            isDisabled={loading}
                            onClick={() => setActiveStep(activeStep + 1)}
                            _hover={{ bg: "white", color: "brand.naranja" }}
                          >
                            Siguiente
                          </Button>
                        )}
                      </HStack>
                    </Stack>
                  )}
                  {activeStep === 4 && (
                    <Stack>
                      <HStack>
                        <Stack>
                          <FormControl isRequired w="sm">
                            <HStack>
                              <Switch
                                onChange={handleChange}
                                isChecked={values.verResortesP1A}
                                name="verResortesP1"
                              />
                              <Text>Verificar estado de resortes P1</Text>
                            </HStack>
                          </FormControl>
                          <FormControl isRequired w="sm">
                            <HStack>
                              <Switch
                                onChange={handleChange}
                                isChecked={values.verResortesP2A}
                                name="verResortesP2"
                              />
                              <Text>Verificar estado de resortes P2</Text>
                            </HStack>
                          </FormControl>
                          <FormControl isRequired w="sm">
                            <HStack>
                              <Switch
                                onChange={handleChange}
                                isChecked={values.verResortesP3A}
                                name="verResortesP3"
                              />
                              <Text>Verificar estado de resortes P3</Text>
                            </HStack>
                          </FormControl>
                          <FormControl isRequired w="sm">
                            <HStack>
                              <Switch
                                onChange={handleChange}
                                isChecked={values.verResortesP4A}
                                name="verResortesP4"
                              />
                              <Text>Verificar estado de resortes P4</Text>
                            </HStack>
                          </FormControl>

                          <FormControl>
                            <HStack>
                              <Switch
                                onChange={handleChange}
                                isChecked={values.verContactoresP1A}
                                name="verContactoresP1"
                              />
                              <Text>Verificar estado de contactores P1</Text>
                            </HStack>
                          </FormControl>
                          <FormControl>
                            <HStack>
                              <Switch
                                onChange={handleChange}
                                isChecked={values.verContactoresP2A}
                                name="verContactoresP2"
                              />
                              <Text>Verificar estado de contactores P2</Text>
                            </HStack>
                          </FormControl>
                          <FormControl>
                            <HStack>
                              <Switch
                                onChange={handleChange}
                                isChecked={values.verContactoresP3A}
                                name="verContactoresP3"
                              />
                              <Text>Verificar estado de contactores P3</Text>
                            </HStack>
                          </FormControl>
                          <FormControl>
                            <HStack>
                              <Switch
                                onChange={handleChange}
                                isChecked={values.verContactoresP4A}
                                name="verContactoresP4"
                              />
                              <Text>Verificar estado de contactores P4</Text>
                            </HStack>
                          </FormControl>
                        </Stack>
                        <Stack>
                          <FormControl isRequired w="sm">
                            <HStack>
                              <Switch
                                onChange={handleChange}
                                isChecked={values.verCableadoP1A}
                                name="verCableadoP1"
                              />
                              <Text>Verificar cableado de contactor P1</Text>
                            </HStack>
                          </FormControl>
                          <FormControl isRequired w="sm">
                            <HStack>
                              <Switch
                                onChange={handleChange}
                                isChecked={values.verCableadoP2A}
                                name="verCableadoP2"
                              />
                              <Text>Verificar cableado de contactor P2</Text>
                            </HStack>
                          </FormControl>
                          <FormControl isRequired w="sm">
                            <HStack>
                              <Switch
                                onChange={handleChange}
                                isChecked={values.verCableadoP3A}
                                name="verCableadoP3"
                              />
                              <Text>Verificar cableado de contactor P3</Text>
                            </HStack>
                          </FormControl>
                          <FormControl isRequired w="sm">
                            <HStack>
                              <Switch
                                onChange={handleChange}
                                isChecked={values.verCableadoP4A}
                                name="verCableadoP4"
                              />
                              <Text>Verificar cableado de contactor P4</Text>
                            </HStack>
                          </FormControl>

                          <FormControl>
                            <HStack>
                              <Switch
                                onChange={handleChange}
                                isChecked={values.verConMangueraP1A}
                                name="verConMangueraP1"
                              />
                              <Text>
                                verificar conectores y mangueras de aire -
                                contactor P1
                              </Text>
                            </HStack>
                          </FormControl>
                          <FormControl>
                            <HStack>
                              <Switch
                                onChange={handleChange}
                                isChecked={values.verConMangueraP2A}
                                name="verConMangueraP2"
                              />
                              <Text>
                                verificar conectores y mangueras de aire -
                                contactor P2
                              </Text>
                            </HStack>
                          </FormControl>
                          <FormControl>
                            <HStack>
                              <Switch
                                onChange={handleChange}
                                isChecked={values.verConMangueraP3A}
                                name="verConMangueraP3"
                              />
                              <Text>
                                verificar conectores y mangueras de aire -
                                contactor P3
                              </Text>
                            </HStack>
                          </FormControl>
                          <FormControl>
                            <HStack>
                              <Switch
                                onChange={handleChange}
                                isChecked={values.verConMangueraP4A}
                                name="verConMangueraP4"
                              />
                              <Text>
                                verificar conectores y mangueras de aire -
                                contactor P4
                              </Text>
                            </HStack>
                          </FormControl>
                        </Stack>
                      </HStack>
                      <HStack w="100%" justifyContent="end" p={3}>
                        <Button
                          fontSize={12}
                          fontWeight="semibold"
                          bg="brand.naranja"
                          color="white"
                          rightIcon={<MdNavigateNext fontSize="20px" />}
                          variant="solid"
                          form="FormularioEtapa"
                          isDisabled={loading}
                          onClick={() => {
                            setSanderBackUp("SanderB");
                            setActiveStep(
                              planificacion.dosificadora
                                ? activeStep - 4
                                : activeStep - 3
                            );
                          }}
                          _hover={{ bg: "white", color: "brand.naranja" }}
                        >
                          Pasar a SANDER B
                        </Button>
                      </HStack>
                    </Stack>
                  )}
                </>
              )}
              {sanderBackUp === "SanderB" && (
                <>
                  {activeStep === 0 && (
                    <Stack>
                      <HStack>
                        {tipo === "crear" && (
                          <FormControl isRequired>
                            <FormLabel>Sander B</FormLabel>
                            <Select
                              w="2xs"
                              size="sm"
                              icon={<MdArrowDropDown />}
                              name="sanderB"
                              value={values.sanderB}
                              placeholder="Seleccionar Sander"
                              onChange={handleChange}
                              onBlur={handleBlur}
                            >
                              {planificacion.sanderPrincipal && (
                                <option
                                  value={planificacion.sanderPrincipal.idSander}
                                >
                                  {planificacion.sanderPrincipal.codigoSander}
                                </option>
                              )}
                              {planificacion.sanderBackup && (
                                <option
                                  value={planificacion.sanderBackup.idSander}
                                >
                                  {planificacion.sanderBackup.codigoSander}
                                </option>
                              )}
                            </Select>
                          </FormControl>
                        )}
                        {tipo === "editar" && (
                          <FormControl w="2xs" size="sm" isRequired>
                            <FormLabel>Sander B</FormLabel>
                            <Input
                              value={values.sanderB}
                              w="2xs"
                              size="sm"
                              bg="brand.gris_medio"
                              readOnly
                            />
                          </FormControl>
                        )}
                        <FormControl></FormControl>
                        <FormControl></FormControl>
                      </HStack>
                      <Text fontWeight="bold" color="brand.gris_medio">
                        Power Pack
                      </Text>
                      <HStack>
                        <FormControl w="2xs">
                          <FormLabel>QR Code B.1</FormLabel>
                          <InputGroup size="md">
                            <Input
                              placeholder="QR Code B.1"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              name="qrCodeB1"
                              value={values.qrCodeB1}
                            />
                            <InputRightElement width="4.5rem">
                              <Button h="1.75rem" size="sm" onClick={onOpen}>
                                <MdOutlineQrCode2 />
                              </Button>
                            </InputRightElement>
                          </InputGroup>
                          <Modal isOpen={isOpen} onClose={onClose}>
                            <ModalOverlay />
                            <ModalContent>
                              <ModalBody>
                                <CSSReset />
                                <HandleQrReader
                                  scannedData={scannedData}
                                  setScannedData={setScannedData}
                                  handlecodigo={handlecodigo}
                                  type="sanderBQR1"
                                  setFieldValue={setFieldValue}
                                />
                              </ModalBody>
                            </ModalContent>
                          </Modal>
                        </FormControl>
                        <FormControl isRequired w="2xs">
                          <FormLabel>Power Pack</FormLabel>
                          <Input
                            placeholder="Power Pack"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="powerPackB"
                            value={values.powerPackB}
                          />
                        </FormControl>
                        <FormControl w="2xs">
                          <FormLabel>Horas del Motor</FormLabel>
                          <Input
                            placeholder="Horas del Motor"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="horasMotorB"
                            value={values.horasMotorB}
                          />
                        </FormControl>
                      </HStack>
                      <HStack>
                        <FormControl isRequired w="2xs">
                          <FormLabel>Nivel de combustible</FormLabel>
                          <Input
                            placeholder="% nivel de combustible"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="nivelCombustibleB"
                            value={values.nivelCombustibleB}
                          />
                        </FormControl>
                        <FormControl isRequired w="sm" placeSelf="end">
                          <HStack>
                            <Switch
                              onChange={handleChange}
                              isChecked={values.perdidasCombustibleB}
                              name="perdidasCombustibleB"
                            />
                            <Text>Sin pérdidas?</Text>
                          </HStack>
                          <Text fontSize="10px">
                            Marcar si NO se registran pérdidas de combustible
                          </Text>
                        </FormControl>

                        <FormControl w="2xs"></FormControl>
                      </HStack>
                      <HStack>
                        <FormControl isRequired w="2xs">
                          <FormLabel>Presión del aceite</FormLabel>
                          <Input
                            placeholder="Presión del aceite"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="presionAceiteB"
                            value={values.presionAceiteB}
                          />
                        </FormControl>
                        <FormControl isRequired w="sm" placeSelf="end">
                          <HStack>
                            <Switch
                              onChange={handleChange}
                              isChecked={values.nivelAceiteB}
                              name="nivelAceiteB"
                            />
                            <Text>Nivel de aceite normal?</Text>
                          </HStack>

                          <Text fontSize="10px">
                            Marcar si el nivel de acite ES NORMAL
                          </Text>
                        </FormControl>
                        <FormControl w="2xs"></FormControl>
                      </HStack>
                      <HStack>
                        <FormControl isRequired w="2xs">
                          <FormLabel>Temperatura refrigerante</FormLabel>
                          <Input
                            placeholder="Temperatura refrigerante"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="tempRefrigeranteB"
                            value={values.tempRefrigeranteB}
                          />
                        </FormControl>
                        <FormControl isRequired w="sm" placeSelf="end">
                          <HStack>
                            <Switch
                              onChange={handleChange}
                              isChecked={values.perdidasRefrigeranteB}
                              name="perdidasRefrigeranteB"
                            />
                            <Text>Sin pérdidas?</Text>
                          </HStack>
                          <Text fontSize="10px">
                            Marcar si NO se registran pérdidas de refrigerante
                          </Text>
                        </FormControl>
                        <FormControl isRequired w="sm" placeSelf="end">
                          <HStack>
                            <Switch
                              onChange={handleChange}
                              isChecked={values.nivelRefrigeranteB}
                              name="nivelRefrigeranteB"
                            />
                            <Text>Nivel de refrigerante normal?</Text>
                          </HStack>

                          <Text fontSize="10px">
                            Marcar si el nivel de refrigerante ES NORMAL
                          </Text>
                        </FormControl>
                      </HStack>
                      <Stack>
                        <FormControl>
                          <HStack>
                            <Switch
                              onChange={handleChange}
                              isChecked={values.refrigerantePerdidaB}
                              name="refrigerantePerdidaB"
                            />
                            <Text>Refrigerante: Sin pérdidas?</Text>

                            <Text fontSize="10px">
                              Marcar si no registran pérdidas de líquido
                              refrigerante en el sistema
                            </Text>
                          </HStack>
                        </FormControl>
                        <FormControl>
                          <HStack>
                            <Switch
                              onChange={handleChange}
                              isChecked={values.estadoBorneasB}
                              name="estadoBorneasB"
                            />
                            <Text>Eléctrico: Estado borneras</Text>

                            <Text fontSize="10px">
                              Marcar si el estado de borneras es normal
                            </Text>
                          </HStack>
                        </FormControl>
                        <FormControl>
                          <HStack>
                            <Switch
                              onChange={handleChange}
                              isChecked={values.limpiezaFiltroAireB}
                              name="limpiezaFiltroAireB"
                            />
                            <Text>Limpieza de filtro primario de aire</Text>
                            <Text fontSize="10px">
                              Realizar limpieza con golpes suaves en el cuerpo
                              de filtro. Marcar, una vez realizada la limpieza,
                              si el indicador de saturación de admisión no
                              indica reemplazo
                            </Text>
                          </HStack>
                        </FormControl>
                        <FormControl>
                          <HStack>
                            <Switch
                              onChange={handleChange}
                              isChecked={values.limpizaB}
                              name="limpizaB"
                            />
                            <Text>Limpieza</Text>
                            <Text fontSize="10px">
                              Marcar si no hay depósitos de arena
                            </Text>
                          </HStack>
                        </FormControl>
                      </Stack>
                      <HStack w="100%" justifyContent="space-between" p={3}>
                        <Button
                          fontSize={12}
                          letterSpacing={1}
                          border="1px solid orange"
                          fontWeight="semibold"
                          variant="outline"
                          color="brand.naranja"
                          leftIcon={<MdNavigateBefore fontSize="20px" />}
                          onClick={() => {
                            setSanderBackUp("SanderA");
                            setActiveStep(activeStep + 3);
                          }}
                          alignSelf="end"
                          _hover={{ bg: "brand.naranja", color: "white" }}
                        >
                          Atras
                        </Button>
                        <Button
                          fontSize={12}
                          fontWeight="semibold"
                          bg="brand.naranja"
                          color="white"
                          rightIcon={<MdNavigateNext fontSize="20px" />}
                          variant="solid"
                          form="FormularioEtapa"
                          isDisabled={loading}
                          onClick={() => {
                            if (!values.sanderB) {
                              setLoading(false);
                              return toast({
                                title: "Error",
                                description: "Sander requerido",
                                status: "error",
                                isClosable: true,
                                duration: 3000,
                              });
                            } else if (!values.powerPackB) {
                              setLoading(false);
                              return toast({
                                title: "Error",
                                description: "Power Pack requerido",
                                status: "error",
                                isClosable: true,
                                duration: 3000,
                              });
                            } else if (!values.nivelCombustibleB) {
                              setLoading(false);
                              return toast({
                                title: "Error",
                                description: "Nivel de combustible requerido",
                                status: "error",
                                isClosable: true,
                                duration: 3000,
                              });
                            } else if (!values.presionAceiteB) {
                              setLoading(false);
                              return toast({
                                title: "Error",
                                description: "Presión de aceite requerido",
                                status: "error",
                                isClosable: true,
                                duration: 3000,
                              });
                            } else if (!values.tempRefrigeranteB) {
                              setLoading(false);
                              return toast({
                                title: "Error",
                                description:
                                  "Temperatura de refigerante requerida",
                                status: "error",
                                isClosable: true,
                                duration: 3000,
                              });
                            } else {
                              setActiveStep(activeStep + 1);
                            }
                          }}
                          _hover={{ bg: "white", color: "brand.naranja" }}
                        >
                          Continuar
                        </Button>
                      </HStack>
                    </Stack>
                  )}
                  {activeStep === 1 && (
                    <Stack>
                      <HStack>
                        <FormControl w="2xs">
                          <FormLabel>QR Code B.2</FormLabel>
                          <InputGroup size="md">
                            <Input
                              w="2xs"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              name="qrCodeB2"
                              value={values.qrCodeB2}
                            />
                            <InputRightElement width="4.5rem">
                              <Button h="1.75rem" size="sm" onClick={onOpen}>
                                <MdOutlineQrCode2 />
                              </Button>
                            </InputRightElement>
                          </InputGroup>
                          <Modal isOpen={isOpen} onClose={onClose}>
                            <ModalOverlay />
                            <ModalContent>
                              <ModalBody>
                                <CSSReset />
                                <HandleQrReader
                                  scannedData={scannedData}
                                  setScannedData={setScannedData}
                                  handlecodigo={handlecodigo}
                                  type="sanderBQR2"
                                  setFieldValue={setFieldValue}
                                />
                              </ModalBody>
                            </ModalContent>
                          </Modal>
                          <Text fontSize="10px" placeSelf="end">
                            Capture QR con cámara
                          </Text>
                        </FormControl>
                      </HStack>
                      <HStack>
                        <Stack>
                          <CheckboxGroup>
                            <FormControl>
                              <HStack>
                                <Switch
                                  onChange={handleChange}
                                  isChecked={values.bandaTransportadoraB}
                                  name="bandaTransportadoraB"
                                />
                                <Text>Banda Transportadora</Text>
                              </HStack>

                              <Text fontSize="10px">
                                Marcar si Estado OK. Verificar ausencia de
                                cortaduras, roce, daños.
                              </Text>
                            </FormControl>
                            <FormControl>
                              <HStack>
                                <Switch
                                  onChange={handleChange}
                                  isChecked={values.alineacionBandaB}
                                  name="alineacionBandaB"
                                />
                                <Text>Alineación de Banda</Text>
                              </HStack>
                              <Text fontSize="10px">
                                Marcar si la banda se encuentra centrada sobre
                                rodillos y cuna. Alinear si es necesario
                              </Text>
                            </FormControl>
                            <FormControl>
                              <HStack>
                                <Switch
                                  onChange={handleChange}
                                  isChecked={values.rolosRetornoB}
                                  name="rolosRetornoB"
                                />
                                <Text>Rolos de Retorno</Text>
                              </HStack>

                              <Text fontSize="10px">
                                Marchar la banda a baja velocidad. Marcar si los
                                rodillos giran libremente sin ruido o
                                vibraciones
                              </Text>
                            </FormControl>
                            <FormControl>
                              <HStack>
                                <Switch
                                  onChange={handleChange}
                                  isChecked={values.rolosVentiladosB}
                                  name="rolosVentiladosB"
                                />
                                <Text>Rolos ventilados</Text>
                              </HStack>
                              <Text fontSize="10px">
                                Marcar si no hay fisuras, deformación, falta de
                                bulones, juego en conos, desgaste apreciable
                              </Text>
                            </FormControl>

                            <FormControl>
                              <HStack>
                                <Switch
                                  onChange={handleChange}
                                  isChecked={values.rolosSdriveB}
                                  name="rolosSdriveB"
                                />
                                <Text>Rolos Sdrive</Text>
                              </HStack>
                              <Text fontSize="10px">
                                Marcar si no hay fisuras, deformación,
                                oscilaciones, desalineación, desgaste apreciable
                              </Text>
                            </FormControl>
                            <FormControl>
                              <HStack>
                                <Switch
                                  onChange={handleChange}
                                  isChecked={values.unionBandaB}
                                  name="unionBandaB"
                                />
                                <Text>Unión de Banda</Text>
                              </HStack>
                              <Text fontSize="10px">
                                Marcar si no hay fisuras, desgaste, roturas,
                                pérdida de tornillos, apertura de fajas, etc.
                              </Text>
                            </FormControl>
                          </CheckboxGroup>
                        </Stack>
                        <Stack placeSelf="start">
                          <FormControl>
                            <HStack>
                              <Switch
                                onChange={handleChange}
                                isChecked={values.ctcpB}
                                name="ctcpB"
                              />
                              <Text>
                                Cadena de Transmisión de cinta principal
                              </Text>
                            </HStack>
                            <Text fontSize="10px">
                              Marchar la cinta a baja velocidad. Marcar si la
                              cadena gira sin vibraciones o roses contra el
                              protector de cadena. Tensar si es necesario
                            </Text>
                          </FormControl>
                          <FormControl>
                            <HStack>
                              <Switch
                                onChange={handleChange}
                                isChecked={values.acoplamientoCFB}
                                name="acoplamientoCFB"
                              />
                              <Text>Acoplamiento de Cinta Frontal</Text>
                            </HStack>
                            <Text fontSize="10px">
                              Marchar la cinta a baja velocidad. Marcar si el
                              elemento elástico está en buen estado, no hay
                              desalineación, juego, vibraciones o deslizamiento
                              sobre ejes.
                            </Text>
                          </FormControl>
                          <FormControl>
                            <HStack>
                              <Switch
                                onChange={handleChange}
                                isChecked={values.anclajePlacaCeldasB}
                                name="anclajePlacaCeldasB"
                              />
                              <Text>Anclaje placas de celdas</Text>
                            </HStack>
                            <Text fontSize="10px">
                              Marcar si las placas de apoyo están fijas y no hay
                              arena entre placas y celdas
                            </Text>
                          </FormControl>
                          <FormControl>
                            <HStack>
                              <Switch
                                onChange={handleChange}
                                isChecked={values.lubricacionRodamientosB}
                                name="lubricacionRodamientosB"
                              />
                              <Text>Lubricación de rodamientos</Text>
                            </HStack>

                            <Text fontSize="10px">
                              Marcar ok luego de haberlos lubricado. “Lubricar
                              cajas de rodamiento correspondientes a rolos
                              tractores (x4), rolos ventilados (x4) y rolo
                              S-Drive (x2)”
                            </Text>
                          </FormControl>
                          <FormControl>
                            <HStack>
                              <Switch
                                onChange={handleChange}
                                isChecked={values.lubricacionCadenaB}
                                name="lubricacionCadenaB"
                              />
                              <Text>Lubricación de cadena</Text>

                              <Text fontSize="10px">
                                Marcar ok luego de haber lubricado
                              </Text>
                            </HStack>
                          </FormControl>
                        </Stack>
                      </HStack>
                      <HStack w="100%" justifyContent="space-between" p={3}>
                        <Button
                          fontSize={12}
                          letterSpacing={1}
                          border="1px solid orange"
                          fontWeight="semibold"
                          variant="outline"
                          color="brand.naranja"
                          leftIcon={<MdNavigateBefore fontSize="20px" />}
                          onClick={() => {
                            setActiveStep(activeStep - 1);
                          }}
                          alignSelf="end"
                          _hover={{ bg: "brand.naranja", color: "white" }}
                        >
                          Atras
                        </Button>
                        <Button
                          fontSize={12}
                          fontWeight="semibold"
                          bg="brand.naranja"
                          color="white"
                          rightIcon={<MdNavigateNext fontSize="20px" />}
                          variant="solid"
                          form="FormularioEtapa"
                          isDisabled={loading}
                          onClick={() => setActiveStep(activeStep + 1)}
                          _hover={{ bg: "white", color: "brand.naranja" }}
                        >
                          Continuar
                        </Button>
                      </HStack>
                    </Stack>
                  )}
                  {activeStep === 2 && (
                    <Stack w="100%" spacing={7}>
                      <HStack justifyContent="space-between">
                        <FormControl w="xs">
                          <FormLabel>QR Code B.3</FormLabel>

                          <InputGroup size="md">
                            <Input
                              w="2xs"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              name="qrCodeA3B"
                              value={values.qrCodeA3B}
                            />
                            <InputRightElement width="4.5rem">
                              <Button h="1.75rem" size="sm" onClick={onOpen}>
                                <MdOutlineQrCode2 />
                              </Button>
                            </InputRightElement>
                          </InputGroup>
                          <Modal isOpen={isOpen} onClose={onClose}>
                            <ModalOverlay />
                            <ModalContent>
                              <ModalBody>
                                <CSSReset />
                                <HandleQrReader
                                  scannedData={scannedData}
                                  setScannedData={setScannedData}
                                  handlecodigo={handlecodigo}
                                  type="sanderBQR3"
                                  setFieldValue={setFieldValue}
                                />
                              </ModalBody>
                            </ModalContent>
                          </Modal>
                          <Text fontSize="10px" placeSelf="end">
                            Capture QR con cámara
                          </Text>
                        </FormControl>
                        <FormControl w="xs" isRequired alignSelf="start">
                          <FormLabel>
                            Horas Circuito Hidráulico (Q.Variable)
                          </FormLabel>
                          <Input
                            type="number"
                            w="2xs"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="hsCircuitoHidraB"
                            value={values.hsCircuitoHidraB}
                          />
                        </FormControl>
                        <FormControl w="xs" isRequired>
                          <FormLabel>Aceite Hidráulico: Temperatura</FormLabel>
                          <Input
                            w="2xs"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="tempAceiteHidraB"
                            value={values.tempAceiteHidraB}
                          />
                          <Text w="xs" fontSize="10px" placeSelf="end">
                            Registrar valor de temperatura en °C. Rango normal:
                            40-80°
                          </Text>
                        </FormControl>
                      </HStack>
                      <Stack>
                        <FormControl>
                          <HStack>
                            <Switch
                              onChange={handleChange}
                              isChecked={values.nivelAceiteHidraB}
                              name="nivelAceiteHidraB"
                            />
                            <Text>Aceite Hidráulico: Nivel</Text>

                            <Text fontSize="10px" placeSelf="end">
                              Marcar si el Nivel del Aceite Hidráulico es Normal
                              (entre marcas de máximo y mínimo). Si el nivel de
                              hidráulico es inferior al punto mínimo, completar
                            </Text>
                          </HStack>
                        </FormControl>
                        <FormControl>
                          <HStack>
                            <Switch
                              onChange={handleChange}
                              isChecked={values.aceiteHidraPerdidaB}
                              name="aceiteHidraPerdidaB"
                            />
                            <Text>Aceite Hidráulico: Sin Pérdidas?</Text>

                            <Text fontSize="10px" placeSelf="end">
                              Marcar si no se registran pérdidas de aceite
                              hidráulico
                            </Text>
                          </HStack>
                        </FormControl>
                        <FormControl>
                          <HStack>
                            <Switch
                              onChange={handleChange}
                              isChecked={values.movimientoCarrosB}
                              name="movimientoCarrosB"
                            />
                            <Text>Movimiento de Carros</Text>

                            <Text fontSize="10px" placeSelf="end">
                              Marcar si el movimiento de los carros es Normal
                            </Text>
                          </HStack>
                        </FormControl>
                      </Stack>
                      <HStack justifyContent="space-between">
                        <FormControl w="xs" isRequired>
                          <FormLabel>
                            Presión de retorno de bomba de engranajes{" "}
                          </FormLabel>
                          <NumberInput
                            defaultValue={values.presionRetonoBEB}
                            min={0}
                            max={8}
                            step={0.1}
                            maxW={32}
                            onChange={(e) => setPresionRetonoBEB(e)}
                            onBlur={handleBlur}
                            name="presionRetonoBEB"
                            allowMouseWheel
                          >
                            <NumberInputField value={presionRetonoBEB} />
                            <NumberInputStepper>
                              <NumberIncrementStepper
                                children={<AddIcon />}
                                bg="green.500"
                                color={"white"}
                              />
                              <NumberDecrementStepper
                                children={<MinusIcon />}
                                bg="red.500"
                                color={"white"}
                              />
                            </NumberInputStepper>
                          </NumberInput>

                          <Text fontSize="10px" placeSelf="end">
                            Registrar presión en bar. Max. 8 bar
                          </Text>
                        </FormControl>
                        <FormControl w="xs" isRequired>
                          <FormLabel>
                            Presión de stand by de bomba de pistones{" "}
                          </FormLabel>
                          <InputGroup w="2xs">
                            <Input
                              w="2xs"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              name="presionSantByBPB"
                              value={values.presionSantByBPB}
                            />
                            <InputRightAddon children="BAR" />
                          </InputGroup>
                          <Text fontSize="10px" placeSelf="end">
                            Motor a 1700 RPM y cintas detenidas.
                          </Text>
                        </FormControl>
                        <FormControl w="xs" isRequired>
                          <FormLabel>
                            Presión de trabajo de bomba de pistones
                          </FormLabel>
                          <InputGroup w="2xs">
                            <Input
                              w="2xs"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              name="presionTrabajoBPB"
                              value={values.presionTrabajoBPB}
                            />
                            <InputRightAddon children="BAR" />
                          </InputGroup>
                          <Text w="xs" fontSize="10px" placeSelf="end">
                            Checkear presión durante máxima concentración de
                            etapa anterior.
                          </Text>
                        </FormControl>
                      </HStack>
                      <HStack w="100%" justifyContent="space-between" p={3}>
                        <Button
                          fontSize={12}
                          letterSpacing={1}
                          border="1px solid orange"
                          fontWeight="semibold"
                          variant="outline"
                          color="brand.naranja"
                          leftIcon={<MdNavigateBefore fontSize="20px" />}
                          onClick={() => {
                            setActiveStep(activeStep - 1);
                          }}
                          alignSelf="end"
                          _hover={{ bg: "brand.naranja", color: "white" }}
                        >
                          Atras
                        </Button>
                        <Button
                          fontSize={12}
                          fontWeight="semibold"
                          bg="brand.naranja"
                          color="white"
                          rightIcon={<MdNavigateNext fontSize="20px" />}
                          variant="solid"
                          form="FormularioEtapa"
                          isDisabled={loading}
                          onClick={() => {
                            if (!values.hsCircuitoHidraB) {
                              setLoading(false);
                              return toast({
                                title: "Error",
                                description:
                                  "Horas Circuito Hidráulico requeridos",
                                status: "error",
                                isClosable: true,
                                duration: 3000,
                              });
                            } else if (!values.tempAceiteHidraB) {
                              setLoading(false);
                              return toast({
                                title: "Error",
                                description:
                                  "Aceite Hidráulico: Temperatura requerida",
                                status: "error",
                                isClosable: true,
                                duration: 3000,
                              });
                            } else if (!presionRetonoBEB) {
                              setLoading(false);
                              return toast({
                                title: "Error",
                                description:
                                  "Presión de retorno de bomba de engranajes requerida",
                                status: "error",
                                isClosable: true,
                                duration: 3000,
                              });
                            } else if (!values.presionSantByBPB) {
                              setLoading(false);
                              return toast({
                                title: "Error",
                                description:
                                  "Presión de stand by de bomba de pistones requerida",
                                status: "error",
                                isClosable: true,
                                duration: 3000,
                              });
                            } else if (!values.presionTrabajoBPB) {
                              setLoading(false);
                              return toast({
                                title: "Error",
                                description:
                                  "Presión de trabajo de bomba de pistones requerida",
                                status: "error",
                                isClosable: true,
                                duration: 3000,
                              });
                            } else {
                              setActiveStep(activeStep + 1);
                            }
                          }}
                          _hover={{ bg: "white", color: "brand.naranja" }}
                        >
                          Continuar
                        </Button>
                      </HStack>
                    </Stack>
                  )}
                  {activeStep === 3 && (
                    <Stack>
                      <HStack justifyContent="space-between" px={5}>
                        <Stack>
                          <FormControl>
                            <HStack>
                              <Switch
                                onChange={handleChange}
                                isChecked={values.tablerosCerradosB}
                                name="tablerosCerradosB"
                              />
                              <Text>Tableros cerrados</Text>

                              <Text fontSize="10px">
                                Marcar ok si están bien cerrados
                              </Text>
                            </HStack>
                          </FormControl>
                          <FormControl>
                            <HStack>
                              <Switch
                                onChange={handleChange}
                                isChecked={values.cableComandoB}
                                name="cableComandoB"
                              />
                              <Text>Cable de comando</Text>

                              <Text fontSize="10px">
                                Marcar ok si está en buenas condiciones
                              </Text>
                            </HStack>
                          </FormControl>
                          <FormControl>
                            <HStack>
                              <Switch
                                onChange={handleChange}
                                isChecked={values.calibracionBalanzaB}
                                name="calibracionBalanzaB"
                              />
                              <Text>Calibración de balanzas</Text>

                              <Text fontSize="10px">
                                Marcar ok si están calibradas. 10% máxima
                                imprecision
                              </Text>
                            </HStack>
                          </FormControl>
                          <FormControl>
                            <HStack>
                              <Switch
                                onChange={handleChange}
                                isChecked={values.semaforoLucesB}
                                name="semaforoLucesB"
                              />
                              <Text>Funcionamiento Semáforos y Luces</Text>

                              <Text fontSize="10px">
                                Marcar si las luces y semáforos funcionan
                                correctamente
                              </Text>
                            </HStack>
                          </FormControl>
                        </Stack>
                        <FormControl placeSelf="start" w="lg">
                          <FormLabel>Observaciones y Comentarios</FormLabel>
                          <Card
                            w="lg"
                            variant="unstyled"
                            bg="brand.fondos_secundarios"
                          >
                            <CardBody>
                              <Textarea
                                w="lg"
                                placeholder="Descripcion"
                                name="observacionesSB"
                                onChange={(e) =>
                                  setTextObservacionesSB(e.target.value)
                                }
                                onBlur={handleBlur}
                                maxLength="1500"
                                resize="none"
                              />
                            </CardBody>
                            <CardFooter
                              placeSelf="end"
                              color="brand.gris_medio"
                            >
                              {charCountSB}/1500
                            </CardFooter>
                          </Card>
                          <Text fontSize="10px">
                            Agregue aquí comentarios y observaciones acerca de
                            la revisión general de equipos
                          </Text>
                        </FormControl>
                      </HStack>
                      <Stack>
                        <FormControl placeSelf="start" px={5}>
                          <FormLabel fontSize="20px">Informe final</FormLabel>
                          <Card
                            w="lg"
                            variant="unstyled"
                            bg="brand.fondos_secundarios"
                          >
                            <CardBody>
                              <Textarea
                                w="lg"
                                placeholder="Descripcion"
                                name="observacionesGral"
                                onChange={(e) =>
                                  setTextObservacionesGral(e.target.value)
                                }
                                onBlur={handleBlur}
                                maxLength="1500"
                                resize="none"
                              />
                            </CardBody>
                            <CardFooter
                              placeSelf="end"
                              color="brand.gris_medio"
                            >
                              {charCountGral}/1500
                            </CardFooter>
                          </Card>
                        </FormControl>
                      </Stack>
                      <HStack w="100%" justifyContent="space-between" p={3}>
                        <Button
                          fontSize={12}
                          letterSpacing={1}
                          border="1px solid orange"
                          fontWeight="semibold"
                          variant="outline"
                          color="brand.naranja"
                          leftIcon={<MdNavigateBefore fontSize="20px" />}
                          onClick={() => {
                            setActiveStep(activeStep - 1);
                          }}
                          alignSelf="end"
                          _hover={{ bg: "brand.naranja", color: "white" }}
                        >
                          Atras
                        </Button>
                        {!planificacion.dosificadora && (
                          <Button
                            fontSize={12}
                            fontWeight="semibold"
                            bg="brand.naranja"
                            color="white"
                            rightIcon={<MdFileDownloadDone fontSize="20px" />}
                            variant="solid"
                            form="FormSander"
                            isDisabled={loading}
                            onClick={handleSubmit}
                            _hover={{ bg: "white", color: "brand.naranja" }}
                          >
                            Finalizar
                          </Button>
                        )}
                        {planificacion.dosificadora && (
                          <Button
                            fontSize={12}
                            fontWeight="semibold"
                            bg="brand.naranja"
                            color="white"
                            rightIcon={<MdNavigateNext fontSize="20px" />}
                            variant="solid"
                            form="FormSander"
                            isDisabled={loading}
                            onClick={() => setActiveStep(activeStep + 1)}
                            _hover={{ bg: "white", color: "brand.naranja" }}
                          >
                            Siguiente
                          </Button>
                        )}
                      </HStack>
                    </Stack>
                  )}
                  {activeStep === 4 && (
                    <Stack>
                      <HStack>
                        <Stack>
                          <FormControl isRequired w="sm">
                            <HStack>
                              <Switch
                                onChange={handleChange}
                                isChecked={values.verResortesP1}
                                name="verResortesP1"
                              />
                              <Text>Verificar estado de resortes P1</Text>
                            </HStack>
                          </FormControl>
                          <FormControl isRequired w="sm">
                            <HStack>
                              <Switch
                                onChange={handleChange}
                                isChecked={values.verResortesP2}
                                name="verResortesP2"
                              />
                              <Text>Verificar estado de resortes P2</Text>
                            </HStack>
                          </FormControl>
                          <FormControl isRequired w="sm">
                            <HStack>
                              <Switch
                                onChange={handleChange}
                                isChecked={values.verResortesP3}
                                name="verResortesP3"
                              />
                              <Text>Verificar estado de resortes P3</Text>
                            </HStack>
                          </FormControl>
                          <FormControl isRequired w="sm">
                            <HStack>
                              <Switch
                                onChange={handleChange}
                                isChecked={values.verResortesP4}
                                name="verResortesP4"
                              />
                              <Text>Verificar estado de resortes P4</Text>
                            </HStack>
                          </FormControl>

                          <FormControl>
                            <HStack>
                              <Switch
                                onChange={handleChange}
                                isChecked={values.verContactoresP1}
                                name="verContactoresP1"
                              />
                              <Text>Verificar estado de contactores P1</Text>
                            </HStack>
                          </FormControl>
                          <FormControl>
                            <HStack>
                              <Switch
                                onChange={handleChange}
                                isChecked={values.verContactoresP2}
                                name="verContactoresP2"
                              />
                              <Text>Verificar estado de contactores P2</Text>
                            </HStack>
                          </FormControl>
                          <FormControl>
                            <HStack>
                              <Switch
                                onChange={handleChange}
                                isChecked={values.verContactoresP3}
                                name="verContactoresP3"
                              />
                              <Text>Verificar estado de contactores P3</Text>
                            </HStack>
                          </FormControl>
                          <FormControl>
                            <HStack>
                              <Switch
                                onChange={handleChange}
                                isChecked={values.verContactoresP4}
                                name="verContactoresP4"
                              />
                              <Text>Verificar estado de contactores P4</Text>
                            </HStack>
                          </FormControl>
                        </Stack>
                        <Stack>
                          <FormControl isRequired w="sm">
                            <HStack>
                              <Switch
                                onChange={handleChange}
                                isChecked={values.verCableadoP1}
                                name="verCableadoP1"
                              />
                              <Text>Verificar cableado de contactor P1</Text>
                            </HStack>
                          </FormControl>
                          <FormControl isRequired w="sm">
                            <HStack>
                              <Switch
                                onChange={handleChange}
                                isChecked={values.verCableadoP2}
                                name="verCableadoP2"
                              />
                              <Text>Verificar cableado de contactor P2</Text>
                            </HStack>
                          </FormControl>
                          <FormControl isRequired w="sm">
                            <HStack>
                              <Switch
                                onChange={handleChange}
                                isChecked={values.verCableadoP3}
                                name="verCableadoP3"
                              />
                              <Text>Verificar cableado de contactor P3</Text>
                            </HStack>
                          </FormControl>
                          <FormControl isRequired w="sm">
                            <HStack>
                              <Switch
                                onChange={handleChange}
                                isChecked={values.verCableadoP4}
                                name="verCableadoP4"
                              />
                              <Text>Verificar cableado de contactor P4</Text>
                            </HStack>
                          </FormControl>

                          <FormControl>
                            <HStack>
                              <Switch
                                onChange={handleChange}
                                isChecked={values.verConMangueraP1}
                                name="verConMangueraP1"
                              />
                              <Text>
                                verificar conectores y mangueras de aire -
                                contactor P1
                              </Text>
                            </HStack>
                          </FormControl>
                          <FormControl>
                            <HStack>
                              <Switch
                                onChange={handleChange}
                                isChecked={values.verConMangueraP2}
                                name="verConMangueraP2"
                              />
                              <Text>
                                verificar conectores y mangueras de aire -
                                contactor P2
                              </Text>
                            </HStack>
                          </FormControl>
                          <FormControl>
                            <HStack>
                              <Switch
                                onChange={handleChange}
                                isChecked={values.verConMangueraP3}
                                name="verConMangueraP3"
                              />
                              <Text>
                                verificar conectores y mangueras de aire -
                                contactor P3
                              </Text>
                            </HStack>
                          </FormControl>
                          <FormControl>
                            <HStack>
                              <Switch
                                onChange={handleChange}
                                isChecked={values.verConMangueraP4}
                                name="verConMangueraP4"
                              />
                              <Text>
                                verificar conectores y mangueras de aire -
                                contactor P4
                              </Text>
                            </HStack>
                          </FormControl>
                        </Stack>
                      </HStack>
                      <HStack w="100%" justifyContent="end" p={3}>
                        <Button
                          fontSize={12}
                          fontWeight="semibold"
                          bg="brand.naranja"
                          color="white"
                          rightIcon={<MdFileDownloadDone fontSize="20px" />}
                          variant="solid"
                          form="FormSander"
                          isDisabled={loading}
                          onClick={handleSubmit}
                          _hover={{ bg: "white", color: "brand.naranja" }}
                        >
                          Finalizar
                        </Button>
                      </HStack>
                    </Stack>
                  )}
                </>
              )}
            </Form>
          )}
        </Formik>
      )}
    </Stack>
  );
};

export default RevisionSander;
