import React, { useEffect, useState } from "react";
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  IconButton,
  Text,
  Box,
  HStack,
  Stack,
  Heading,
  Card,
  CardHeader,
  CardBody,
} from "@chakra-ui/react";
import { MdOutlineMarkunread, MdOutlineDrafts } from "react-icons/md";
import { putNotificacionesII } from "../../config/helpers/api.helper";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Bell } from "../../../assets/iconos/notifications_FILL0.svg";
import moment from "moment";
import { useSelector } from "react-redux";
import { getUsuarios, putUsuarios } from "../../usuarios/helpers/api.helper";

const Notificationes = (props) => {
  const { notificaciones, setNotificaciones } = props;
  const navigate = useNavigate();
  let today = moment().format("YYYY-MM-DD");
  const { user } = useSelector((state) => state.user);
  const [usuario, setUsuario] = useState();
  const [loading, setLoading] = useState(true);

  const initUsuario = async () => {
    let res = await getUsuarios({ id: user.idUsuario });

    if (res.status === 200) {
      setUsuario(res.data[0]);
    }
    setLoading(false);
  };

  const handleLeido = async (element) => {
    let data = {
      id: element.idnotificacionesBS,
      leidoNBS: !element.leidoNBS,
    };
    let res = await putNotificacionesII(data);

    if (res.status === 200) {
      const editar = notificaciones.map((item) => {
        if (item.idnotificacionesBS === element.idnotificacionesBS) {
          return {
            ...item,
            leidoNBS: !element.leidoNBS,
          };
        }
        return item;
      });

      setNotificaciones(editar);
    }
  };

  const handleGlobalActivas = async () => {
    if (usuario.notificacionGlobalActivas === true) {
      let res = await putUsuarios({
        notificacionGlobalActivas: false,
        idUsuario: user.idUsuario,
      });
      console.log(res);
      if (res.status === 200) {
        setUsuario({ ...usuario, notificacionGlobalActivas: false });
      }
    }
  };

  const openModal = async (item) => {
    if (item.nombreTipoE === "Registro Evento") {
      await putNotificacionesII({
        id: item.idnotificacionesBS,
        leidoNBS: true,
      });
      return navigate(`/operaciones/curso/eventos/${item.padLocacion}`);
    } else if (item.eventoNBS === "Creación Etapa") {
      await putNotificacionesII({
        id: item.idnotificacionesBS,
        leidoNBS: true,
      });
      return navigate(`/operaciones/curso/etapas/${item.padLocacion}`);
    } else {
      let data = {
        id: item.idnotificacionesBS,
        leidoNBS: !item.leidoNBS,
      };
      let res = await putNotificacionesII(data);

      if (res.status === 200) {
        const editar = notificaciones.map((e) => {
          if (e.idnotificacionesBS === item.idnotificacionesBS) {
            return {
              ...e,
              leidoNBS: !item.leidoNBS,
            };
          }
          return e;
        });

        setNotificaciones(editar);
      }
    }
  };

  const handleDif = () => {
    let notificacionesVisibles = [];

    for (let index = 0; index < notificaciones.length; index++) {
      const element = notificaciones[index];
      let a = moment.utc(element.fechaEnvioNBS).format("YYYY-MM-DD");
      const diffHoras = moment(today).diff(a, "days");
      if (diffHoras < 2) {
        notificacionesVisibles.push(element);
      }
    }
    let b = notificaciones.filter((e) => e.leidoNBS === false);
    notificacionesVisibles = [...notificacionesVisibles, ...b];
    return notificacionesVisibles;
  };

  useEffect(() => {
    initUsuario();
  }, []);

  return (
    !loading && (
      <Popover placement="bottom-end">
        <PopoverTrigger>
          <Box position={"relative"} display={"inline-block"}>
            <IconButton
              bg="gray.300"
              borderRadius="50%"
              name="notificaciones"
              onClick={() => handleGlobalActivas()}
            >
              <Box>
                <Bell />
              </Box>
            </IconButton>
            {notificaciones.length > 0 &&
              usuario.notificacionGlobalActivas === true && (
                <Box
                  position="absolute"
                  top="1px"
                  right="-7px"
                  bg="#B21515"
                  color="white"
                  borderRadius="50%"
                  placeContent={"center"}
                  width="20px"
                  height="20px"
                  display="flex"
                  fontWeight={"light"}
                  fontSize="12px"
                  zIndex={10}
                >
                  <Text>
                    {notificaciones.filter((e) => e.leidoNBS === false).length}
                  </Text>
                </Box>
              )}
          </Box>
        </PopoverTrigger>
        <PopoverContent>
          <PopoverArrow />
          <PopoverHeader letterSpacing={1}>
            Notificaciones {`(${notificaciones.length})`}
          </PopoverHeader>
          <PopoverBody>
            <Stack overflow={"auto"} maxH={"xs"}>
              {handleDif().map((elem) => (
                <Card
                  key={elem.idnotificacionesBS}
                  size={"sm"}
                  onClick={() => openModal(elem)}
                  variant="unstyled"
                  bg={
                    elem.leidoNBS === false
                      ? "brand.fondos_secundarios"
                      : "white"
                  }
                  p={2}
                  cursor={"pointer"}
                >
                  <CardHeader key={elem.idnotificacionesBS}>
                    <HStack justifyContent={"space-between"} borderRadius={5}>
                      <Heading
                        size={"md"}
                        fontWeight={"semibold"}
                        color={"gray.600"}
                        textAlign={"left"}
                      >
                        {elem.tituloNBS}
                      </Heading>
                      <IconButton
                        variant={"link"}
                        icon={
                          elem.leidoNBS === false ? (
                            <MdOutlineMarkunread
                              onClick={() => handleLeido(elem)}
                            />
                          ) : (
                            <MdOutlineDrafts />
                          )
                        }
                      />
                    </HStack>
                  </CardHeader>
                  <CardBody>
                    <Heading size={"sm"} color={"gray"} fontWeight={"normal"}>
                      {elem.descripciónNBS}
                    </Heading>
                  </CardBody>
                </Card>
              ))}
            </Stack>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    )
  );
};

export default Notificationes;
