import React from "react";
import {
  HStack,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Stack,
  Card,
  CardHeader,
  Text,
  Divider,
  CardBody,
  Box,
} from "@chakra-ui/react";
import ItemDetalle from "./ItemDetalle";
import moment from "moment";

function DetalleEtapa(props) {
  const { seleccionado, handleTnsNominales } = props;
  return (
    <Box
      // flex={1}
      w={seleccionado ? "25%" : "20%"}
      maxHeight="100%"
      overflow="hidden"
    >
      <Card
        // pr={2}
        display="flex"
        flexDirection="column"
        maxHeight={"calc(100vh - 155px)"}
        overflowY={"auto"}
        bg="brand.layout"
        border="none"
        boxShadow="none"
      >
        <CardHeader pb="unset" color="brand.azul_text">
          <HStack>
            <Text fontWeight="semibold" fontSize="24px">
              Detalle Etapa
            </Text>{" "}
          </HStack>
        </CardHeader>
        <Divider pt={1} color="#EBEAEA" />
        <CardBody flex={1} overflow="auto">
          <HStack py={1}>
            <ItemDetalle title="PAD" data={seleccionado.padLocacion} />
            <ItemDetalle title="ID Pozo" data={seleccionado.pozo.nombrePozo} />
          </HStack>
          <Divider />
          <HStack py={1}>
            <ItemDetalle
              title="Nro. Etapa (pozo)"
              data={seleccionado.numPozoEtapaSC}
            />
            <ItemDetalle
              title="Nro. Etapa Total"
              data={seleccionado.numTotalEtapaSC}
            />
          </HStack>
          <Divider />
          <HStack py={1}>
            <ItemDetalle
              title="Fecha y Hora Inicio"
              data={moment
                .utc(seleccionado.fechaInicioEtapaSC)
                .format("DD-MM-YYYY HH:mm")}
            />
            <ItemDetalle
              title={"Fecha y Hora Final"}
              data={moment
                .utc(seleccionado.fechaFinalEtapaSC)
                .format("DD-MM-YYYY HH:mm")}
            />
          </HStack>
          <Divider />
          <HStack py={1}>
            <ItemDetalle title={"Supervisor"} data={seleccionado.supervisor} />
            <ItemDetalle title={"Maquinista"} data={seleccionado.maquinista} />
          </HStack>
          <Divider />
          <HStack py={1}>
            <ItemDetalle title={"Operador 1"} data={seleccionado.operador1} />
            <ItemDetalle title={"Operador 2"} data={seleccionado.operador2} />
          </HStack>
          <Divider />
          <HStack py={1}>
            <ItemDetalle
              title={"Sander"}
              data={seleccionado.sander.codigoSander}
            />
            <ItemDetalle
              title={"Autoelevador"}
              data={seleccionado.autoelevador.nombreAutoE}
            />
          </HStack>
          <Divider />
          <HStack py={1}>
            <ItemDetalle
              title={"Cantidad de SCs"}
              data={seleccionado.sandCubes ? seleccionado.sandCubes.length : 0}
            />
            <ItemDetalle
              title={"Toneladas (nominales)"}
              data={handleTnsNominales(seleccionado)}
            />
          </HStack>
          <Divider />
          <Stack py={1}>
            <TableContainer overflowY={"auto"} maxHeight={"258px"}>
              <Table variant="simple" size="sm">
                <Thead position={"sticky"} top={0} backgroundColor={"white"}>
                  <Th>ID Sandcube</Th>
                  <Th>Malla</Th>
                  <Th>Planta</Th>
                </Thead>
                <Tbody>
                  {seleccionado.sandCubes &&
                    seleccionado.sandCubes.map((sandcube) => (
                      <Tr key={sandcube.idSandCube}>
                        <Td>
                          {sandcube.idSandCube}
                          {sandcube.wetsandSC === true && " - W"}
                        </Td>
                        <Td>{sandcube.malla || "-"}</Td>
                        <Td>{sandcube.planta || "-"}</Td>
                      </Tr>
                    ))}
                </Tbody>
              </Table>
            </TableContainer>
          </Stack>
        </CardBody>
      </Card>
    </Box>
  );
}

export default DetalleEtapa;
