import React, { useEffect, useState } from "react";
import {
  Heading,
  Stack,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  useToast,
  HStack,
  CircularProgress,
  Box,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  useDisclosure,
  Icon,
} from "@chakra-ui/react";
import OperacionEnCurso from "./components/curso/OperacionEnCurso";
import { getPlanificaciones } from "./helpers/api.helper";
import {
  MdOutlineAirportShuttle,
  MdKeyboardArrowRight,
  MdOutlineAlarm,
  MdWarningAmber,
  MdOutlineSearch,
  MdOutlineNavigateNext,
  MdAddCircle,
  MdBuild,
  MdLeaderboard,
} from "react-icons/md";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { getReportesViajes } from "./helpers/api.helper";
import BotonOperacion from "./components/curso/BotonOperacion";

const CustomButton = ({ onClick, children }) => {
  return (
    <Box
      p={2}
      bg="white"
      color={"brand.naranja"}
      w={"90px"}
      h={"70px"}
      ml={-5}
      variant={"link"}
      as={"button"}
      fontSize="14px"
      fontWeight="bold"
      letterSpacing={1}
      _hover={{ color: "white", bg: "brand.naranja" }}
      onClick={onClick}
      borderTopRightRadius={"5px"}
      borderBottomRightRadius={"5px"}
    >
      {children}
    </Box>
  );
};

const EnCurso = (props) => {
  const {
    mobile,
    setTabSeleccionado,
    tabSeleccionado,
    operacionSeleccionada,
    setOperacionSeleccionada,
  } = props;
  const [loading, setLoading] = useState(true);
  const [ambas, setAmbas] = useState([]);
  const [operacion1, setOperacion1] = useState([]);
  const [operacion2, setOperacion2] = useState([]);
  const [x, setX] = useState();
  const [y, setY] = useState();
  const [repo, setRepo] = useState([]);
  const toast = useToast();
  const { user } = useSelector((state) => state.user);
  const navigate = useNavigate();

  const initData = async () => {
    let res = await getPlanificaciones({ estado: "activo" });

    if (res.status === 200) {
      let resolve = res.data.sort((a, b) => {
        const nameA = a.idSET.toUpperCase(); // ignore upper and lowercase
        const nameB = b.idSET.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      });

      setAmbas(resolve);
      setOperacion1(resolve[0]);
      setOperacion2(resolve[1]);
      setOperacionSeleccionada(tabSeleccionado === 0 ? resolve[0] : resolve[1]);
      handleStandByPozo(resolve[0]);
    } else if (res.status === 204) {
      toast({
        status: "success",
        description: "Cargadas correctamente, no hay operaciones activas",
        isClosable: true,
        duration: 2000,
      });
    } else {
      return toast({
        description: "Error al cargar planificaciones",
        title: "Error",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
    setLoading(false);
  };

  const {
    onOpen: openAsociacion,
    onClose: closeAsociacion,
    isOpen: keepAsocicacion,
  } = useDisclosure();
  const {
    onOpen: openReportes,
    onClose: closeReportes,
    isOpen: keepReportes,
  } = useDisclosure();
  const {
    onOpen: openEtapas,
    onClose: closeEtapas,
    isOpen: keepAEtapas,
  } = useDisclosure();
  const {
    onOpen: openTurnos,
    onClose: closeTurnos,
    isOpen: keepTurnos,
  } = useDisclosure();
  const {
    onOpen: openEventos,
    onClose: closeEventos,
    isOpen: keepEventos,
  } = useDisclosure();

  const loadingtable = () => {
    return (
      <HStack w="100%" placeContent="center">
        <CircularProgress isIndeterminate color="brand.naranja" />
      </HStack>
    );
  };

  const handleStandByPozo = async (op) => {
    setLoading(true);

    try {
      const padDestino = op.idPadlocacion;

      const res = await getReportesViajes({ padDestino });

      if (res.status === 200) {
        setRepo(res.data.reportes);

        const sumaStandByPozo = res.data.reportes.reduce(
          (acumulador, elemento) => acumulador + elemento.standByPozo,
          0
        );

        const promedioStandByPozo =
          res.data.reportes.length > 0
            ? (sumaStandByPozo / res.data.reportes.length).toFixed(1)
            : 0;

        setX(!isNaN(promedioStandByPozo) ? promedioStandByPozo : 0);

        if (op.camiones) {
          const preY = op.camiones.filter(
            (element) => element.camionActivo === "true"
          );

          setY(preY.length);
        }
      } else if (res.status === 204) {
        setX(0);
        setY(0);
      } else {
        toast({
          title: "Error",
          description: "Error al cargar los reportes",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "Error",
        description: "Ocurrió un error inesperado",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    initData();
  }, []);

  return (
    <Stack w="100%" size="xl" overflowY={"auto"} px={7} spacing={5}>
      {loading && loadingtable()}
      {!loading && ambas.length >= 1 && (
        <HStack spacing={-1}>
          <Tabs
            variant="enclosed"
            size="sm"
            defaultIndex={tabSeleccionado}
            zIndex={10}
          >
            <TabList>
              <Tab
                _selected={{ bg: "white", color: "black", fontWeight: "bold" }}
                isDisabled={!operacion1}
                onClick={() => {
                  setTabSeleccionado(0);
                  setOperacionSeleccionada(operacion1);
                  handleStandByPozo(operacion1);
                }}
              >
                {`${tabSeleccionado === 0 ? "OPERACION ACTIVA:" : ""} ${
                  operacion1.idSET === "set1" ? "SET 1" : "SET 2"
                }`}
              </Tab>
              {operacion2 && (
                <Tab
                  _selected={{
                    bg: "white",
                    color: "black",
                    fontWeight: "bold",
                  }}
                  isDisabled={!operacion2}
                  onClick={() => {
                    setTabSeleccionado(1);
                    setOperacionSeleccionada(operacion2);
                    handleStandByPozo(operacion2);
                  }}
                >
                  {`${tabSeleccionado === 1 ? "OPERACION ACTIVA:" : ""} ${
                    operacion2.idSET === "set1" ? "SET 1" : "SET 2"
                  }`}
                </Tab>
              )}
            </TabList>

            <TabPanels>
              {operacion1 && (
                <TabPanel bg={"white"} borderRadius={"10px"}>
                  <HStack spacing={-1}>
                    <Stack w={"50%"} bg={"white"} p={5} borderRadius={"10px"}>
                      <OperacionEnCurso
                        nombre={"Operacion 1"}
                        operacion={operacion1}
                        mobile={mobile}
                        x={x}
                        y={y}
                        repo={repo}
                      />
                    </Stack>
                  </HStack>
                </TabPanel>
              )}
              {operacion2 && (
                <TabPanel bg={"white"} borderRadius={"10px"}>
                  <HStack spacing={-1}>
                    <Stack w={"50%"} bg={"white"} p={5} borderRadius={"10px"}>
                      <OperacionEnCurso
                        nombre={"Operacion 2"}
                        operacion={operacion2}
                        mobile={mobile}
                        x={x}
                        y={y}
                        repo={repo}
                      />
                    </Stack>
                  </HStack>
                </TabPanel>
              )}
            </TabPanels>
          </Tabs>
          <Stack spacing={5} pt={10}>
            <Popover
              placement={!mobile ? "bottom" : "right"}
              isOpen={keepReportes}
              onOpen={openReportes}
              onClose={closeReportes}
              returnFocusOnClose={false}
              trigger="hover"
            >
              <PopoverTrigger w={"auto"}>
                <BotonOperacion
                  text="Reporte de viajes"
                  leftIcon={<MdOutlineAlarm size="24px" />}
                  rightIcon={
                    <MdOutlineNavigateNext color="orange" fontSize="30px" />
                  }
                  onMouseEnter={openReportes}
                  onMouseLeave={closeReportes}
                  user={user}
                  roles={[
                    "admin",
                    "coorOp",
                    "userOperaciones",
                    "useFacturacion",
                    "userHS",
                    "gerencia",
                    "userMantenimiento",
                  ]}
                />
              </PopoverTrigger>
              {(user.idRol === "admin" ||
                user.idRol === "coorOp" ||
                user.idRol === "userOperaciones" ||
                user.idRol === "useFacturacion" ||
                user.idRol === "userHS" ||
                user.idRol === "gerencia" ||
                user.idRol === "userMantenimiento") && (
                <PopoverContent
                  backgroundColor="transparent"
                  boxShadow="none"
                  border="none"
                  w="60px"
                >
                  <PopoverBody
                    onMouseEnter={openReportes}
                    onMouseLeave={closeReportes}
                    marginTop={!mobile ? -4 : 0}
                  >
                    <CustomButton
                      onClick={() => {
                        navigate(
                          `/operaciones/curso/reporte/${operacionSeleccionada.idPadlocacion}`
                        );
                      }}
                    >
                      <Icon as={MdOutlineSearch} fontSize={"20px"} />
                      Consultar
                    </CustomButton>
                  </PopoverBody>
                </PopoverContent>
              )}
            </Popover>

            <Popover
              placement={!mobile ? "bottom" : "right"}
              isOpen={keepAsocicacion}
              onOpen={openAsociacion}
              onClose={closeAsociacion}
              returnFocusOnClose={false}
              trigger="hover"
            >
              <PopoverTrigger>
                <BotonOperacion
                  leftIcon={<MdOutlineAirportShuttle size="24px" />}
                  text="Asociación Camión/SCs"
                  rightIcon={
                    <MdKeyboardArrowRight color="orange" fontSize="30px" />
                  }
                  onMouseEnter={openAsociacion}
                  onMouseLeave={closeAsociacion}
                  user={user}
                  roles={[
                    "admin",
                    "coorOp",
                    "userOperaciones",
                    "useFacturacion",
                    "userHS",

                    "userMantenimiento",
                    "usuarioBrent",
                  ]}
                />
              </PopoverTrigger>
              {(user.idRol === "admin" ||
                user.idRol === "coorOp" ||
                user.idRol === "userHS" ||
                user.idRol === "usuarioBrent" ||
                user.idRol === "useFacturacion" ||
                user.idRol === "userOperaciones" ||
                user.idRol === "userMantenimiento") && (
                <PopoverContent
                  backgroundColor="transparent"
                  boxShadow="none"
                  border="none"
                  w="60px"
                >
                  <PopoverBody
                    onMouseEnter={openAsociacion}
                    onMouseLeave={closeAsociacion}
                    marginTop={!mobile ? -4 : 0}
                    w={"200px"}
                  >
                    <HStack bg="#F3F6F9" spacing={-1}>
                      <CustomButton
                        onClick={() => {
                          navigate(
                            `/operaciones/curso/asociacion/${operacionSeleccionada.idPadlocacion}`
                          );
                        }}
                      >
                        {" "}
                        <Icon as={MdOutlineSearch} fontSize={"20px"} />
                        Consultar
                      </CustomButton>
                      {(user.idRol === "admin" ||
                        user.idRol === "coorOp" ||
                        user.idRol === "userHS" ||
                        user.idRol === "userMantenimiento" ||
                        user.idRol === "useFacturacion" ||
                        user.idRol === "userOperaciones") && (
                        <CustomButton
                          onClick={() => {
                            navigate(
                              `/operaciones/curso/asociacion/nueva/${operacionSeleccionada.idPadlocacion}`
                            );
                          }}
                        >
                          {" "}
                          <Icon as={MdAddCircle} fontSize={"20px"} />
                          Agregar
                        </CustomButton>
                      )}
                    </HStack>
                  </PopoverBody>
                </PopoverContent>
              )}
            </Popover>

            <Popover
              placement={!mobile ? "bottom" : "right"}
              isOpen={keepAEtapas}
              onOpen={openEtapas}
              onClose={closeEtapas}
              returnFocusOnClose={false}
              trigger="hover"
            >
              <PopoverTrigger>
                <BotonOperacion
                  leftIcon={<MdLeaderboard size="24px" />}
                  text="Cédula de fractura"
                  rightIcon={
                    <MdKeyboardArrowRight color="orange" fontSize="30px" />
                  }
                  onMouseEnter={openEtapas}
                  onMouseLeave={closeEtapas}
                  user={user}
                  roles={[
                    "admin",
                    "coorOp",
                    "userOperaciones",
                    "useFacturacion",
                    "userHS",
                    "gerencia",
                    "userMantenimiento",
                  ]}
                />
              </PopoverTrigger>
              <PopoverContent
                backgroundColor="transparent"
                boxShadow="none"
                border="none"
                w="60px"
              >
                <PopoverBody
                  onMouseEnter={openEtapas}
                  onMouseLeave={closeEtapas}
                  marginTop={!mobile ? -4 : 0}
                >
                  <HStack bg="#F3F6F9" spacing={-1}>
                    {(user.idRol === "admin" ||
                      user.idRol === "coorOp" ||
                      user.idRol === "userOperaciones" ||
                      user.idRol === "useFacturacion" ||
                      user.idRol === "gerencia" ||
                      user.idRol === "userHS" ||
                      user.idRol === "userMantenimiento") && (
                      <CustomButton
                        onClick={() => {
                          navigate(
                            `/operaciones/curso/etapas/${operacionSeleccionada.idPadlocacion}`
                          );
                        }}
                      >
                        {" "}
                        <Icon as={MdOutlineSearch} fontSize={"20px"} />
                        Consultar
                      </CustomButton>
                    )}
                    {(user.idRol === "admin" ||
                      user.idRol === "userOperaciones" ||
                      user.idRol === "useFacturacion" ||
                      user.idRol === "userHS" ||
                      user.idRol === "userMantenimiento" ||
                      user.idRol === "coorOp") && (
                      <CustomButton
                        onClick={() => {
                          navigate(
                            `/operaciones/curso/etapas/nueva/${operacionSeleccionada.idPadlocacion}`
                          );
                        }}
                      >
                        <Icon as={MdAddCircle} fontSize={"20px"} />
                        Agregar
                      </CustomButton>
                    )}
                  </HStack>
                </PopoverBody>
              </PopoverContent>
            </Popover>

            <Popover
              placement={mobile ? "right" : "bottom"}
              isOpen={keepTurnos}
              onOpen={openTurnos}
              onClose={closeTurnos}
              returnFocusOnClose={false}
              trigger="hover"
            >
              <PopoverTrigger>
                <BotonOperacion
                  leftIcon={<MdBuild size="24px" />}
                  text="Revisión entre turnos"
                  rightIcon={
                    <MdKeyboardArrowRight color="orange" fontSize="30px" />
                  }
                  onMouseEnter={openTurnos}
                  onMouseLeave={closeTurnos}
                  user={user}
                  roles={[
                    "admin",
                    "coorOp",
                    "userOperaciones",
                    "useFacturacion",
                    "userHS",
                    "userMantenimiento",
                  ]}
                />
              </PopoverTrigger>
              <PopoverContent
                backgroundColor="transparent"
                boxShadow="none"
                border="none"
                w="60px"
              >
                <PopoverBody
                  onMouseEnter={openTurnos}
                  onMouseLeave={closeTurnos}
                  marginTop={!mobile ? -4 : 0}
                >
                  <HStack bg="#F3F6F9" spacing={-1}>
                    {(user.idRol === "admin" ||
                      user.idRol === "useFacturacion" ||
                      user.idRol === "userHS" ||
                      user.idRol === "coorOp" ||
                      user.idRol === "userOperaciones" ||
                      user.idRol === "userMantenimiento") && (
                      <CustomButton
                        onClick={() => {
                          navigate(
                            `/operaciones/curso/revision/${operacionSeleccionada.idPlanificacion}`
                          );
                        }}
                      >
                        <Icon as={MdOutlineSearch} fontSize={"20px"} />
                        Consultar
                      </CustomButton>
                    )}
                    {(user.idRol === "admin" ||
                      user.idRol === "coorOp" ||
                      user.idRol === "userHS" ||
                      user.idRol === "userMantenimiento" ||
                      user.idRol === "useFacturacion" ||
                      user.idRol === "userOperaciones") && (
                      <CustomButton
                        onClick={() => {
                          navigate(
                            `/operaciones/curso/revision/nuevo/${operacionSeleccionada.idPadlocacion}`
                          );
                        }}
                      >
                        <Icon as={MdAddCircle} fontSize={"20px"} />
                        Agregar
                      </CustomButton>
                    )}
                  </HStack>
                </PopoverBody>
              </PopoverContent>
            </Popover>

            <Popover
              placement={mobile ? "right" : "bottom"}
              isOpen={keepEventos}
              onOpen={openEventos}
              onClose={closeEventos}
              returnFocusOnClose={false}
              trigger="hover"
            >
              <PopoverTrigger>
                <BotonOperacion
                  leftIcon={<MdWarningAmber size="24px" />}
                  text="Registro de eventos"
                  rightIcon={
                    <MdKeyboardArrowRight color="orange" fontSize="30px" />
                  }
                  onMouseEnter={openEventos}
                  onMouseLeave={closeEventos}
                  user={user}
                  roles={[
                    "admin",
                    "coorOp",
                    "userOperaciones",
                    "useFacturacion",
                    "userHS",
                    "gerencia",
                    "userMantenimiento",
                  ]}
                />
              </PopoverTrigger>
              <PopoverContent
                backgroundColor="transparent"
                boxShadow="none"
                border="none"
                w="60px"
              >
                <PopoverBody
                  onMouseEnter={openEventos}
                  onMouseLeave={closeEventos}
                  marginTop={!mobile ? -4 : 0}
                >
                  <HStack bg="#F3F6F9" spacing={-1}>
                    {(user.idRol === "admin" ||
                      user.idRol === "coorOp" ||
                      user.idRol === "userOperaciones" ||
                      user.idRol === "userMantenimiento" ||
                      user.idRol === "useFacturacion" ||
                      user.idRol === "userHS" ||
                      user.idRol === "gerencia") && (
                      <CustomButton
                        onClick={() => {
                          navigate(
                            `/operaciones/curso/eventos/${operacionSeleccionada.idPadlocacion}`
                          );
                        }}
                      >
                        <Icon as={MdOutlineSearch} fontSize={"20px"} />
                        Consultar
                      </CustomButton>
                    )}
                    {(user.idRol === "admin" ||
                      user.idRol === "userOperaciones" ||
                      user.idRol === "useFacturacion" ||
                      user.idRol === "userHS" ||
                      user.idRol === "userMantenimiento" ||
                      user.idRol === "coorOp") && (
                      <CustomButton
                        onClick={() => {
                          navigate(
                            `/operaciones/curso/eventos/nuevo/${operacionSeleccionada.idPadlocacion}`
                          );
                        }}
                      >
                        <Icon as={MdAddCircle} fontSize={"20px"} />
                        Agregar
                      </CustomButton>
                    )}
                  </HStack>
                </PopoverBody>
              </PopoverContent>
            </Popover>
          </Stack>
        </HStack>
      )}
      {!loading && ambas.length < 1 && (
        <Box py={5}>
          <Heading textAlign={"center"} size="xl">
            No hay operaciones activas
          </Heading>
        </Box>
      )}
    </Stack>
  );
};

export default EnCurso;
