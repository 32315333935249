import React, { useEffect, useState } from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Stack,
  HStack,
  IconButton,
  Heading,
  useToast,
  Button,
  Tooltip,
  CircularProgress,
} from "@chakra-ui/react";
import { MdAddCircleOutline, MdOutlineCreate } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import EliminarAutoelevador from "./components/autoelevadores/EliminarAutoelevador";
import { getAutoelevadores } from "../operaciones/helpers/api.helper";
import { useSelector } from "react-redux";

const Autoelevadores = () => {
  const [autoelevadores, setAutoelevadores] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const toast = useToast();
  const { user } = useSelector((state) => state.user);

  const initData = async () => {
    setLoading(true);
    let res = await getAutoelevadores();
    if (res.status === 200) {
      setAutoelevadores(res.data);
    } else {
      toast({
        title: "Error",
        description: "Error al cargar los autoelevadores",
        duration: 3000,
        isClosable: true,
        status: "error",
      });
    }
    setLoading(false);
  };

  const loadingtable = () => {
    return (
      <HStack w="100%" placeContent="center">
        <CircularProgress isIndeterminate color="brand.naranja" />
      </HStack>
    );
  };

  useEffect(() => {
    initData();
  }, []);

  return (
    <Stack w="100%" h="xl">
      <HStack w="100%" justifyContent="space-between" px={5}>
        <Heading
          fontWeight="medium"
          size="xl"
          fontFamily="fonts.title"
          color="brand.gris_primario"
        >
          Autoelevadores
        </Heading>
        {(user.idRol === "admin" || user.idRol === "coorOp") && (
          <Button
            size="sm"
            variant="outline"
            border="1px solid #FF8200"
            leftIcon={<MdAddCircleOutline fontSize="20px" />}
            color="brand.naranja"
            onClick={() => navigate("/inventario/autoelevadores/nuevo")}
            _hover={{ bg: "brand.naranja", color: "white" }}
          >
            Nuevo autoelevador
          </Button>
        )}
      </HStack>
      {loading && loadingtable()}
      {!loading && autoelevadores.length > 0 && (
        <TableContainer>
          <Table size="sm" h="max-content" variant="simple">
            <Thead>
              <Tr borderBottom="2px solid black">
                <Th>ID Autoelevador</Th>
                <Th>SET</Th>
                <Th>Activo / Inactivo</Th>
                {(user.idRol === "admin" || user.idRol === "coorOp") && (
                  <Th textAlign={"center"}>Acciones</Th>
                )}
              </Tr>
            </Thead>
            <Tbody>
              {autoelevadores.map((autoelevador) => (
                <Tr key={autoelevador.idAutoE}>
                  <Td>{autoelevador.nombreAutoE}</Td>
                  <Td>{autoelevador.idSET === "set1" ? "SET 1" : "SET 2"}</Td>
                  <Td
                    color={
                      autoelevador.estadoAutoE === "activo"
                        ? "green.500"
                        : "red.500"
                    }
                  >
                    {autoelevador.estadoAutoE === "activo"
                      ? "Activo"
                      : "Inactivo"}
                  </Td>
                  {(user.idRol === "admin" || user.idRol === "coorOp") && (
                    <Td textAlign={"center"}>
                      <Tooltip label="Editar Autoelevador">
                        <IconButton
                          variant="link"
                          icon={<MdOutlineCreate fontSize="20px" />}
                          onClick={() =>
                            navigate(
                              `/inventario/autoelevadores/editar/${autoelevador.idAutoE}`
                            )
                          }
                          _hover={{ color: "brand.naranja" }}
                        />
                      </Tooltip>
                      <EliminarAutoelevador
                        autoelevador={autoelevador}
                        initData={initData}
                      />
                    </Td>
                  )}
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      )}
      {!loading && autoelevadores.length < 1 && (
        <Heading textAlign={"center"} size={"md"}>
          No hay datos para mostrar
        </Heading>
      )}
    </Stack>
  );
};

export default Autoelevadores;
