import React from "react";
import { useToast, Button } from "@chakra-ui/react";
import { MdOutlineGetApp } from "react-icons/md";
import { descargarEventoPDF } from "../../../helpers/api.helper";

const DescargarEventoPDF = (props) => {
  const { planificacion, seleccionado, type } = props;
  const toast = useToast();

  const handleDownload = async (evento) => {
    let data;

    delete evento.idRegistroEventos;
    if (evento.fotoRegistroEventos) {
      data = {
        accionesRegistroEventos: evento.accionesRegistroEventos,
        actoresQuienRegistroEventos: evento.actoresQuienRegistroEventos,
        actoresRegistroEventos: evento.actoresRegistroEventos,
        descripcionRegistroEventos: evento.descripcionRegistroEventos,
        detectoRegistroEventos: evento.detectoRegistroEventos.nombre,
        equipoRegistroEventos: evento.equipoRegistroEventos,
        fechaAccionRegistroEventos: evento.fechaAccionRegistroEventos,
        fechaRegistroEventos: evento.fechaRegistroEventos,
        foto: evento.fotoRegistroEventos,
        informoRegistroEventos: evento.informoRegistroEventos,
        masFotosRegistroEventos: evento.masFotosRegistroEventos,
        otroDondeRegistroEventos: evento.otroDondeRegistroEventos,
        otroRegistroEventos: evento.otroRegistroEventos,
        responsableAccionRegistroEventos:
          evento.responsableAccionRegistroEventos,
        responsableRegistroEventos: evento.responsableRegistroEventos.nombre,
        tipoRegistroEventos: evento.tipoRegistroEventos,
        valoracionRegistroEventos: evento.valoracionRegistroEventos,
        id: planificacion.idPlanificacion,
      };
    } else if (planificacion.idPlanificacion) {
      data = {
        accionesRegistroEventos: evento.accionesRegistroEventos,
        actoresQuienRegistroEventos: evento.actoresQuienRegistroEventos,
        actoresRegistroEventos: evento.actoresRegistroEventos,
        descripcionRegistroEventos: evento.descripcionRegistroEventos,
        detectoRegistroEventos: evento.detectoRegistroEventos.nombre,
        equipoRegistroEventos: evento.equipoRegistroEventos,
        fechaAccionRegistroEventos: evento.fechaAccionRegistroEventos,
        fechaRegistroEventos: evento.fechaRegistroEventos,
        informoRegistroEventos: evento.informoRegistroEventos,
        masFotosRegistroEventos: evento.masFotosRegistroEventos,
        otroDondeRegistroEventos: evento.otroDondeRegistroEventos,
        otroRegistroEventos: evento.otroRegistroEventos,
        responsableAccionRegistroEventos:
          evento.responsableAccionRegistroEventos,
        responsableRegistroEventos: evento.responsableRegistroEventos.nombre,
        tipoRegistroEventos: evento.tipoRegistroEventos,
        valoracionRegistroEventos: evento.valoracionRegistroEventos,
        id: planificacion.idPlanificacion,
      };
    } else {
      data = {
        accionesRegistroEventos: evento.accionesRegistroEventos,
        actoresQuienRegistroEventos: evento.actoresQuienRegistroEventos,
        actoresRegistroEventos: evento.actoresRegistroEventos,
        descripcionRegistroEventos: evento.descripcionRegistroEventos,
        detectoRegistroEventos: evento.detectoRegistroEventos.nombre,
        equipoRegistroEventos: evento.equipoRegistroEventos,
        fechaAccionRegistroEventos: evento.fechaAccionRegistroEventos,
        fechaRegistroEventos: evento.fechaRegistroEventos,
        informoRegistroEventos: evento.informoRegistroEventos,
        masFotosRegistroEventos: evento.masFotosRegistroEventos,
        otroDondeRegistroEventos: evento.otroDondeRegistroEventos,
        otroRegistroEventos: evento.otroRegistroEventos,
        responsableAccionRegistroEventos:
          evento.responsableAccionRegistroEventos,
        responsableRegistroEventos: evento.responsableRegistroEventos.nombre,
        tipoRegistroEventos: evento.tipoRegistroEventos,
        valoracionRegistroEventos: evento.valoracionRegistroEventos,
      };
    }

    let response = await descargarEventoPDF(data);

    if (response.status === 200) {
      const url = window.URL.createObjectURL(
        new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
        })
      );
      const link = document.createElement("a");
      link.href = url;

      link.setAttribute(
        "download",
        `Registro-evento-${planificacion.idPadlocacion}-${evento.fechaRegistroEventos}.pdf`
      );
      document.body.appendChild(link);
      link.click();
    } else {
      return toast({
        title: "Error",
        description: "Error del servidor ",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Button
      variant="outline"
      colorScheme="orange"
      leftIcon={<MdOutlineGetApp fontSize="20px" />}
      size="sm"
      fontWeight="semibold"
      fontSize={12}
      letterSpacing={1}
      onClick={() => handleDownload(seleccionado)}
      _hover={{ bg: "brand.naranja", color: "white" }}
    >
      Descargar
    </Button>
  );
};

export default DescargarEventoPDF;
