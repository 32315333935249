import React, { useEffect, useState } from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Stack,
  HStack,
  IconButton,
  Heading,
  useToast,
  Tooltip,
  Button,
  Input,
  CircularProgress,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  List,
  ListItem,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { getPlantas } from "../operaciones/helpers/api.helper";
import EliminarPlanta from "./components/plantas/EliminarPlanta";
import {
  MdAddCircleOutline,
  MdOutlineCreate,
  MdFilterList,
  MdRefresh,
  MdCheck,
} from "react-icons/md";
import { useSelector } from "react-redux";

const Planta = () => {
  const [plantas, setPlantas] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [filterBy, setFilterBy] = useState({
    nombrePlanta: "",
    tipoArena: "",
    wetsand: "",
  });
  const navigate = useNavigate();
  const toast = useToast();
  const { user } = useSelector((state) => state.user);

  const initData = async () => {
    setLoading(true);
    let res = await getPlantas();
    if (res.status === 200) {
      setPlantas(res.data);
    } else {
      toast({
        title: "Error",
        description: "Error al cargar las plantas",
        duration: 3000,
        isClosable: true,
        status: "error",
      });
    }

    setLoading(false);
  };

  const loadingtable = () => {
    return (
      <HStack w="100%" placeContent="center">
        <CircularProgress isIndeterminate color="brand.naranja" />
      </HStack>
    );
  };

  const filterStyle = (filterName) => ({
    // Aplicar un estilo diferente al filtro seleccionado
    backgroundColor:
      filterName === selectedFilter ? "lightblue" : "transparent",
  });

  const showFiltrados = () => {
    if (Object.values(filterBy).every((value) => !value)) {
      return plantas;
    }
    return plantas.filter((planta) => {
      const { nombrePlanta, tipoArena, wetsand } = filterBy;

      return (
        (!nombrePlanta ||
          planta.nombrePlanta
            .toLowerCase()
            .includes(nombrePlanta.toLowerCase())) &&
        (!tipoArena ||
          planta.tipoArena1 === tipoArena ||
          planta.tipoArena2 === tipoArena ||
          planta.tipoArena3 === tipoArena ||
          planta.tipoArena4 === tipoArena) &&
        (!wetsand || planta.wetsand === wetsand)
      );
    });
  };
  const cleanFilter = () => {
    setFilterBy({
      nombrePlanta: "",
      tipoArena: "",
      wetsand: "",
    });
  };

  useEffect(() => {
    initData();
  }, []);

  return (
    <Stack w="100%" h="lg">
      <HStack w="100%" justifyContent="space-between" px={5}>
        <Heading
          fontWeight="medium"
          size="xl"
          fontFamily="fonts.title"
          color="brand.gris_primario"
        >
          Plantas
        </Heading>
        <HStack>
          {(user.idRol === "admin" || user.idRol === "coorOp") && (
            <Button
              size="sm"
              variant="outline"
              border="1px solid #FF8200"
              leftIcon={<MdAddCircleOutline fontSize="20px" />}
              color="brand.naranja"
              onClick={() => navigate("/inventario/planta/nueva")}
              _hover={{ bg: "brand.naranja", color: "white" }}
            >
              Nueva planta
            </Button>
          )}
          <Popover isLazy>
            <PopoverTrigger>
              <IconButton
                icon={<MdFilterList fontSize="20px" />}
                bg={"brand.fondos_secundarios"}
                borderRadius="50%"
                color={"black"}
              />
            </PopoverTrigger>
            <PopoverContent>
              <PopoverArrow />
              <PopoverHeader>
                <Tooltip label="Limpiar filtro">
                  <IconButton
                    icon={<MdRefresh />}
                    onClick={cleanFilter}
                    variant="link"
                    justifySelf={"end"}
                  />
                </Tooltip>
              </PopoverHeader>
              <PopoverBody maxH={"sm"} overflowY={"auto"}>
                <Accordion allowToggle>
                  <AccordionItem py={1}>
                    <Input
                      variant={"filled"}
                      placeholder="Por nombre"
                      size="sm"
                      w="2xs"
                      onChange={(e) => {
                        setFilterBy((prevstate) => ({
                          ...prevstate,
                          nombrePlanta: e.target.value,
                        }));
                        showFiltrados();
                      }}
                      value={filterBy.nombrePlanta}
                    />
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItem>
                      <AccordionButton fontWeight={"bold"}>
                        Tipo de malla
                      </AccordionButton>
                      <AccordionPanel>
                        <List spacing={2} cursor={"pointer"}>
                          <HStack>
                            {selectedFilter === "100" && <MdCheck />}

                            <ListItem
                              style={filterStyle("100")}
                              onClick={() => {
                                setFilterBy((prevstate) => ({
                                  ...prevstate,
                                  tipoArena: "100",
                                }));
                                setSelectedFilter("100");
                                showFiltrados();
                              }}
                            >
                              100
                            </ListItem>
                          </HStack>
                          <HStack>
                            {selectedFilter === "3070" && <MdCheck />}

                            <ListItem
                              style={filterStyle("3070")}
                              onClick={() => {
                                setFilterBy((prevstate) => ({
                                  ...prevstate,
                                  tipoArena: "30/70",
                                }));
                                setSelectedFilter("3070");
                                showFiltrados();
                              }}
                            >
                              30/70
                            </ListItem>
                          </HStack>
                          <HStack>
                            {selectedFilter === "7040" && <MdCheck />}

                            <ListItem
                              style={filterStyle("7040")}
                              onClick={() => {
                                setFilterBy((prevstate) => ({
                                  ...prevstate,
                                  tipoArena: "70/140",
                                }));
                                setSelectedFilter("7040");
                                showFiltrados();
                              }}
                            >
                              70/140
                            </ListItem>
                          </HStack>
                          <HStack>
                            {selectedFilter === "5070" && <MdCheck />}

                            <ListItem
                              style={filterStyle("5070")}
                              onClick={() => {
                                setFilterBy((prevstate) => ({
                                  ...prevstate,
                                  tipoArena: "50/70",
                                }));
                                setSelectedFilter("5070");
                                showFiltrados();
                              }}
                            >
                              50/70
                            </ListItem>
                          </HStack>
                          <HStack>
                            {selectedFilter === "4080" && <MdCheck />}

                            <ListItem
                              style={filterStyle("4080")}
                              onClick={() => {
                                setFilterBy((prevstate) => ({
                                  ...prevstate,
                                  tipoArena: "40/80",
                                }));
                                setSelectedFilter("4080");
                                showFiltrados();
                              }}
                            >
                              40/80
                            </ListItem>
                          </HStack>
                          <HStack>
                            {selectedFilter === "4070" && <MdCheck />}

                            <ListItem
                              style={filterStyle("4070")}
                              onClick={() => {
                                setFilterBy((prevstate) => ({
                                  ...prevstate,
                                  tipoArena: "40/70",
                                }));
                                setSelectedFilter("4070");
                                showFiltrados();
                              }}
                            >
                              40/70
                            </ListItem>
                          </HStack>
                          <HStack>
                            {selectedFilter === "3050" && <MdCheck />}

                            <ListItem
                              style={filterStyle("3050")}
                              onClick={() => {
                                setFilterBy((prevstate) => ({
                                  ...prevstate,
                                  tipoArena: "30/50",
                                }));
                                setSelectedFilter("3050");
                                showFiltrados();
                              }}
                            >
                              30/50
                            </ListItem>
                          </HStack>
                          <HStack>
                            {selectedFilter === "2040" && <MdCheck />}

                            <ListItem
                              style={filterStyle("2040")}
                              onClick={() => {
                                setFilterBy((prevstate) => ({
                                  ...prevstate,
                                  tipoArena: "20/40",
                                }));
                                setSelectedFilter("2040");
                                showFiltrados();
                              }}
                            >
                              20/40
                            </ListItem>
                          </HStack>
                        </List>
                      </AccordionPanel>
                    </AccordionItem>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItem>
                      <AccordionButton fontWeight={"bold"}>
                        Wetsand
                      </AccordionButton>
                      <AccordionPanel>
                        <List spacing={2} cursor={"pointer"}>
                          <HStack>
                            {selectedFilter === "westandSi" && <MdCheck />}

                            <ListItem
                              style={filterStyle("westandSi")}
                              onClick={() => {
                                setFilterBy((prevstate) => ({
                                  ...prevstate,
                                  wetsand: true,
                                }));
                                setSelectedFilter("westandSi");
                                showFiltrados();
                              }}
                            >
                              Si
                            </ListItem>
                          </HStack>
                          <HStack>
                            {selectedFilter === "wetsandNo" && <MdCheck />}

                            <ListItem
                              style={filterStyle("wetsandNo")}
                              onClick={() => {
                                setFilterBy((prevstate) => ({
                                  ...prevstate,
                                  wetsand: false,
                                }));
                                setSelectedFilter("wetsandNo");
                                showFiltrados();
                              }}
                            >
                              No
                            </ListItem>
                          </HStack>
                        </List>
                      </AccordionPanel>
                    </AccordionItem>
                  </AccordionItem>
                </Accordion>
              </PopoverBody>
            </PopoverContent>
          </Popover>
        </HStack>
      </HStack>
      {loading && loadingtable()}
      {!loading && plantas.length > 0 && (
        <TableContainer overflowY={"auto"}>
          <Table size="sm" h="100%" variant="simple">
            <Thead>
              <Tr borderBottom="2px solid black">
                <Th>Planta</Th>
                <Th>Tipos de arena</Th>
                <Th>Wetsand</Th>
                <Th>Ubicacion</Th>
                <Th>Geocerca</Th>
                {(user.idRol !== "operaciones" ||
                  user.idRol !== "mantenimiento") && (
                  <Th textAlign={"center"}>Acciones</Th>
                )}
              </Tr>
            </Thead>
            <Tbody>
              {showFiltrados().map((planta) => (
                <Tr key={planta.idPlanta}>
                  <Td>{planta.nombrePlanta}</Td>
                  <Td>
                    {planta.tipoArena1}
                    {planta.tipoArena2 && ` - ${planta.tipoArena2}`}
                    {planta.tipoArena3 && ` - ${planta.tipoArena3}`}
                    {planta.tipoArena4 && ` - ${planta.tipoArena4}`}
                  </Td>
                  <Td>{planta.wetsand === true ? "Si" : "No"}</Td>
                  <Td>
                    {planta.geocerca
                      ? `${planta.geocerca[0]?.latitudCentro}, ${planta.geocerca[0]?.longitudCentro}`
                      : "-"}
                  </Td>
                  <Td>{planta.geocerca && planta.geocerca[0].nombreGeo}</Td>
                  {(user.idRol === "admin" || user.idRol === "coorOp") && (
                    <Td textAlign={"center"}>
                      <Tooltip label="Editar Planta">
                        <IconButton
                          variant="link"
                          icon={<MdOutlineCreate fontSize="20px" />}
                          onClick={() =>
                            navigate(
                              `/inventario/planta/editar/${planta.idPlanta}`
                            )
                          }
                          _hover={{ color: "brand.naranja" }}
                        />
                      </Tooltip>
                      <EliminarPlanta planta={planta} initData={initData} />
                    </Td>
                  )}
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      )}
      {!loading && plantas.length < 1 && (
        <Heading textAlign={"center"} size={"md"}>
          No hay datos para mostrar
        </Heading>
      )}
    </Stack>
  );
};

export default Planta;
