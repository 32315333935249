import React from "react";
import { MdOutlineArrowCircleRight } from "react-icons/md";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
} from "@chakra-ui/react";
import FormCrearEditarPlanificacion from "./FormCrearEditarPlanificacion";

const ContinuarPlanificacion = (props) => {
  const { seleccionado, initData } = props;
  const { onOpen, isOpen, onClose } = useDisclosure();
  return (
    <>
      <Button
        colorScheme="orange"
        rightIcon={<MdOutlineArrowCircleRight fontSize="20px" />}
        flexWrap="semibold"
        size="sm"
        fontSize={12}
        letterSpacing={1}
        onClick={onOpen}
        _hover={{ bg: "brand.naranja", color: "white" }}
      >
        Continuar
      </Button>
      <Modal isOpen={isOpen} onClose={onClose} size="full">
        <ModalOverlay />
        <ModalContent>
          <ModalBody bg="brand.fondo">
            <FormCrearEditarPlanificacion
              type="continuar"
              seleccionado={seleccionado}
              initPlanificacion={initData}
              onClose={onClose}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ContinuarPlanificacion;
