import ajax from "../../../utils/axios";

export const getNotificaciones = async (params) => {
  let response;
  try {
    response = await ajax.get("/endpoint/tiponotificacion", { params });
    return response;
  } catch (error) {
    console.error("Error en: api-helper.js - getNotificaciones() - Error", {
      error,
    });
    return error.response;
  }
};

export const editarNotificaciones = async (params) => {
  try {
    let res = await ajax.put("/endpoint/tiponotificacion", params);

    return res;
  } catch (error) {
    console.error(
      "Error en: api.helper.js - editarNotificaciones() - Error",
      error
    );
    return error.response.data;
  }
};

export const editarUsuariosNotificaciones = async (params) => {
  try {
    let res = await ajax.put("/endpoint/filtronotificacion", params);

    return res;
  } catch (error) {
    console.error(
      "Error en: api.helper.js - editarUsuariosNotificaciones() - Error",
      error
    );
    return error.response.data;
  }
};

export const removerUsuariosNotificaciones = async (params) => {
  try {
    let res = await ajax.delete("/endpoint/filtronotificacion", params);

    return res;
  } catch (error) {
    console.error(
      "Error en: api.helper.js - removerUsuariosNotificaciones() - Error",
      error
    );
    return error.response.data;
  }
};

export const getHabilitados = async (params) => {
  let response;
  try {
    response = await ajax.get("/endpoint/filtronotificacion", { params });
    return response;
  } catch (error) {
    console.error("Error en: api-helper.js - getHabilitados() - Error", {
      error,
    });
    return error.response;
  }
};

export const getFiltroRol = async (params) => {
  let response;
  try {
    response = await ajax.get("/endpoint/filtrorolnotificacion", { params });
    return response;
  } catch (error) {
    if (error.response === undefined) {
      console.error("Error en: api-helper.js - getFiltroRol() - Error", {
        error,
      });
    }
    return error.response;
  }
};

export const putFiltroRol = async (params) => {
  let response;
  try {
    response = await ajax.put("/endpoint/filtrorolnotificacion", params);
    return response;
  } catch (error) {
    console.error("Error en: api-helper.js - putFiltroRol() - Error", {
      error,
    });
    return error.response;
  }
};
export const postFiltroRol = async (params) => {
  let response;
  try {
    response = await ajax.post("/endpoint/filtrorolnotificacion", params);
    return response;
  } catch (error) {
    console.error("Error en: api-helper.js - postFiltroRol() - Error", {
      error,
    });
    return error.response;
  }
};

export const deleteFiltroRol = async (params) => {
  try {
    const response = await ajax.put(
      "/endpoint/filtrorolnotificacion/delete",
      params
    );
    return response;
  } catch (error) {
    console.error("Error en: api-helper.js - deleteFiltroRol() - Error", {
      error,
    });
    return error.response;
  }
};

export const getNotificacionesII = async (params) => {
  let response;
  try {
    response = await ajax.get("/endpoint/notificaciones", { params });
    return response;
  } catch (error) {
    console.error("Error en: api-helper.js - getNotificacionesII() - Error", {
      error,
    });
    return error.response;
  }
};
export const putNotificacionesII = async (params) => {
  let response;
  try {
    response = await ajax.put("/endpoint/notificaciones", params);
    return response;
  } catch (error) {
    console.error("Error en: api-helper.js - putNotificacionesII() - Error", {
      error,
    });
    return error.response;
  }
};
