import {
  Input,
  useDisclosure,
  Stack,
  HStack,
  VStack,
  IconButton,
  SlideFade,
  List,
  ListItem,
  Heading,
  Box,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Buscador } from "../assets/iconos/search_FILL0.svg";
import "@fontsource/open-sans";

/**
 * @component
 * @param {String} name Nombre de la lista de datos que se desea filtrar
 * @param {String} filter Estado al cual va a tener el valor
 * @param {Function} setFilter Función que va a setear el estado de filtro
 *
 */

const FilterData = (props) => {
  const { biblioteca } = props;
  const { isOpen, onToggle, onClose } = useDisclosure();
  const [filtro, setFiltro] = useState();
  const [buttonSearch, setButtonSearch] = useState(false);
  const navigate = useNavigate();

  const handleFiltro = () => {
    let busqueda = filtro;

    let resultados = biblioteca.filter((elemet) =>
      elemet.tag.includes(busqueda.toLowerCase())
    );

    if (filtro) {
      return (
        <List
          bg={"white"}
          borderRadius={"5px"}
          position={"absolute"}
          zIndex={10}
          cursor={"pointer"}
          w={["md", "lg", "xl", "2xl"]}
          border={"1px solid gray"}
          overflowY={"scroll"}
          maxH={"xl"}
        >
          {resultados.map((elemento) => (
            <ListItem
              key={elemento.tag}
              bg={"white"}
              color={"gray.600"}
              fontFamily={`'Open-sans'`}
              _hover={{ bg: "gray.200" }}
              px={7}
              py={3}
              onClick={() => {
                onClose();
                navigate(elemento.link);
                setFiltro("");
              }}
              borderBottom={"1px solid gray"}
            >
              <Heading size={"md"} fontWeight={"semibold"}>
                {elemento.nombre}
              </Heading>
              <Heading size={"sm"} fontWeight={"semibold"}>
                {elemento.link
                  .replace("/", "")
                  .replace(/\//g, " > ")
                  .replace(/\b\w/g, (l) => l.toUpperCase())
                  .replace(/([A-Z])/g, " $1")}
              </Heading>
            </ListItem>
          ))}
        </List>
      );
    }
  };

  return (
    <VStack>
      <HStack w={"100%"} spacing={-10}>
        <SlideFade in={isOpen} offsetX={100} unmountOnExit={true}>
          <Input
            type="search"
            placeholder="Buscar"
            fontStyle={"italic"}
            color={"gray"}
            borderRadius={"50px"}
            onChange={(e) => setFiltro(e.target.value)}
            variant={"filled"}
            w={["md", "lg", "xl", "2xl"]}
          />
        </SlideFade>

        <IconButton
          name="buscador"
          placeholder={"Buscar"}
          bg={buttonSearch ? "gray.700" : "gray.300"}
          borderRadius="50%"
          cursor={"pointer"}
          onClick={() => {
            onToggle();
            setButtonSearch(!buttonSearch);
          }}
        >
          <Box>
            <Buscador style={{ fill: buttonSearch ? "white" : "black" }} />
          </Box>
        </IconButton>
      </HStack>
      {filtro && <Stack w={"100%"}>{handleFiltro()}</Stack>}
    </VStack>
  );
};

export default FilterData;
