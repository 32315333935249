import {
  HStack,
  FormControl,
  Stack,
  Box,
  Button,
  Select,
  Card,
  CardBody,
  CardFooter,
  Textarea,
  FormLabel,
  useToast,
  Input,
  Heading,
  IconButton,
  Switch,
  Text,
} from "@chakra-ui/react";
import { Formik, Form } from "formik";
import React, { useEffect, useState } from "react";
import {
  MdNavigateNext,
  MdNavigateBefore,
  MdArrowDropDown,
  MdArrowBack,
} from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import { getUsuarios } from "../../../../usuarios/helpers/api.helper";
import {
  crearRevisionDosificadoras,
  editarRevisionDosificadoras,
  getPlanificaciones,
  getRevisionDosificadoras,
} from "../../../helpers/api.helper";

const RevisionDosificadora = (props) => {
  const { tipo } = props;
  const [loading, setLoading] = useState(true);
  const [descripcionD, setDescripcionD] = useState("");
  const [usuarios, setUsuarios] = useState([]);
  const [planificacion, setPlanificacion] = useState();
  const [responsable, setResponsable] = useState();
  const [fechaRevisionDosi, setFechaRevisionDosi] = useState();
  const [dosi, setDosi] = useState();
  const [editable, setEditable] = useState([]);
  const toast = useToast();
  const padLocacion = useParams();
  const { id } = useParams();
  const navigate = useNavigate();
  const charCount = descripcionD.length;

  const initUsarios = async () => {
    let res;

    res = await getUsuarios();

    if (res.status === 200) {
      setUsuarios(res.data);
    }

    res = await getPlanificaciones(padLocacion);

    if (res.status === 200) {
      setPlanificacion(res.data[0]);
    }

    res = await getRevisionDosificadoras();
    if (res.status === 200) {
      if (tipo === "crear") {
        setEditable(res.data);
      }

      if (tipo === "editar") {
        setEditable(() => {
          let a = res.data.filter(
            (e) => e.idRevisionDosificadora === parseInt(id)
          );

          return a[0];
        });
      }

      setLoading(false);
    }
  };
  const handleCaracteres = (event) => {
    const { value } = event.target;
    setDescripcionD(value);
  };

  let initialValues =
    tipo === "editar"
      ? {
          responsable: editable.responsableDosi,
          transmisionDosi: editable.transmisionDosi,
          alineacionBandaDosi: editable.alineacionBandaDosi,
          tensionBandaDosi: editable.tensionBandaDosi,
          unionBandaDosi: editable.unionBandaDosi,
          estadoBandaDosi: editable.estadoBandaDosi,
          estadoFaldonHuleDosi: editable.estadoFaldonHuleDosi,
          rodillosDosi: editable.rodillosDosi,
          desgasteRodilloDosi: editable.desgasteRodilloDosi,
          materialEnRodilloDosi: editable.materialEnRodilloDosi,
          separacionProtectorRodilloDosi:
            editable.separacionProtectorRodilloDosi,
          desgasteRoloTractorDosi: editable.desgasteRoloTractorDosi,
          nvlAceiteHidraDosi: editable.nvlAceiteHidraDosi,
          observacionesDosi: editable.observacionesDosi,
          fechaRevision: editable.fechaRevision,
        }
      : {
          responsable: false,
          transmisionDosi: false,
          alineacionBandaDosi: false,
          tensionBandaDosi: false,
          unionBandaDosi: false,
          estadoBandaDosi: false,
          estadoFaldonHuleDosi: false,
          rodillosDosi: false,
          desgasteRodilloDosi: false,
          materialEnRodilloDosi: false,
          separacionProtectorRodilloDosi: false,
          desgasteRoloTractorDosi: false,
          nvlAceiteHidraDosi: false,
          observacionesDosi: false,
          fechaRevision: "",
        };

  useEffect(() => {
    initUsarios();
  }, []);

  return (
    <Stack w="100%">
      <HStack>
        <IconButton
          icon={<MdArrowBack />}
          variant="link"
          onClick={() => navigate(-1)}
          fontSize="24px"
        />
        <Heading size={"sm"}>Revision Dosificadora</Heading>
      </HStack>
      {!loading && (
        <Formik
          initialValues={initialValues}
          onSubmit={async (values) => {
            setLoading(true);
            let data =
              tipo === "editar"
                ? {
                    id: editable.idRevisionDosificadora,
                    responsable: responsable,
                    idPlanificacion: editable.idPlanificacion,
                    transmisionDosi: values.transmisionDosi,
                    alineacionBandaDosi: values.alineacionBandaDosi,
                    tensionBandaDosi: values.tensionBandaDosi,
                    unionBandaDosi: values.unionBandaDosi,
                    estadoBandaDosi: values.estadoBandaDosi,
                    estadoFaldonHuleDosi: values.estadoFaldonHuleDosi,
                    rodillosDosi: values.rodillosDosi,
                    desgasteRodilloDosi: values.desgasteRodilloDosi,
                    materialEnRodilloDosi: values.materialEnRodilloDosi,
                    separacionProtectorRodilloDosi:
                      values.separacionProtectorRodilloDosi,
                    desgasteRoloTractorDosi: values.desgasteRoloTractorDosi,
                    nvlAceiteHidraDosi: values.nvlAceiteHidraDosi,
                    observacionesDosi: descripcionD,
                    equipoRevision: "Dosificadora",
                    idDosificadora: dosi,
                  }
                : {
                    responsable: responsable,
                    idPlanificacion: planificacion.idPlanificacion,
                    transmisionDosi: values.transmisionDosi,
                    alineacionBandaDosi: values.alineacionBandaDosi,
                    tensionBandaDosi: values.tensionBandaDosi,
                    unionBandaDosi: values.unionBandaDosi,
                    estadoBandaDosi: values.estadoBandaDosi,
                    estadoFaldonHuleDosi: values.estadoFaldonHuleDosi,
                    rodillosDosi: values.rodillosDosi,
                    desgasteRodilloDosi: values.desgasteRodilloDosi,
                    materialEnRodilloDosi: values.materialEnRodilloDosi,
                    separacionProtectorRodilloDosi:
                      values.separacionProtectorRodilloDosi,
                    desgasteRoloTractorDosi: values.desgasteRoloTractorDosi,
                    nvlAceiteHidraDosi: values.nvlAceiteHidraDosi,
                    observacionesDosi: descripcionD,
                    equipoRevision: "Dosificadora",
                    idDosificadora: dosi,
                  };

            let res;
            if (tipo === "editar") {
              res = await editarRevisionDosificadoras(data);
            } else {
              res = await crearRevisionDosificadoras(data);
            }
            if (res.status === 200) {
              toast({
                status: "success",
                isClosable: true,
                title: `Revision de dosificadora ${
                  tipo === "editar" ? "editada" : "creada"
                } correctamente`,
                duration: 3000,
              });
            } else {
              setLoading(false);
              return toast({
                status: "error",
                isClosable: true,
                title: `Error al ${
                  tipo === "editar" ? "editar" : "crear"
                } revision de dosificadora`,
                duration: 3000,
              });
            }

            navigate(-1);
            setLoading(false);
          }}
        >
          {({ values, handleChange, handleBlur, handleSubmit }) => (
            <Form id="dosificadora">
              <HStack px={5}>
                <FormControl isRequired>
                  <FormLabel>Responsable</FormLabel>
                  <Select
                    w="2xs"
                    size="sm"
                    placeholder="Seleccione responsable"
                    icon={<MdArrowDropDown />}
                    onChange={(e) => setResponsable(e.target.value)}
                  >
                    {usuarios
                      .sort((a, b) => {
                        // Ordenar alfabéticamente por nombre y apellido
                        const nameA = `${a.nombre}`.toUpperCase();
                        const nameB = `${b.nombre}`.toUpperCase();
                        if (nameA < nameB) return -1;
                        if (nameA > nameB) return 1;
                        return 0;
                      })
                      .map((usuario) => (
                        <option
                          key={usuario.idUsuario}
                          value={usuario.idUsuario}
                        >
                          {usuario.nombre}
                        </option>
                      ))}
                  </Select>
                </FormControl>
                {planificacion.dosificadora && (
                  <FormControl>
                    <FormLabel>Dosificadora</FormLabel>
                    {tipo === "crear" && (
                      <Select
                        w="2xs"
                        size="sm"
                        placeholder="Seleccione dosificadora"
                        icon={<MdArrowDropDown />}
                        onChange={(e) => setDosi(e.target.value)}
                      >
                        <option
                          value={planificacion.dosificadora.idDosificadora}
                        >
                          {planificacion.dosificadora.nombreDosificadora}
                        </option>
                      </Select>
                    )}
                  </FormControl>
                )}
                <FormControl isRequired>
                  <FormLabel>Fecha y Hora</FormLabel>
                  <Input
                    w="2xs"
                    size="sm"
                    type="datetime-local"
                    onChange={(e) => setFechaRevisionDosi(e.target.value)}
                  />
                </FormControl>
              </HStack>
              <HStack w="100%" justifyContent="space-around" p={3} spacing={10}>
                <Stack w="50%" placeSelf="start">
                  <HStack>
                    <Switch
                      onChange={handleChange}
                      isChecked={values.transmisionDosi}
                      name="transmisionDosi"
                    />
                    <Text>
                      Inspección del reductor del Conjunto
                      <br /> de transmision para radiado (zona de cubierta)
                    </Text>
                  </HStack>
                  <HStack>
                    <Switch
                      onChange={handleChange}
                      isChecked={values.alineacionBandaDosi}
                      name="alineacionBandaDosi"
                    />
                    <Text>Verifique la alineacion de la banda</Text>
                  </HStack>
                  <HStack>
                    <Switch
                      onChange={handleChange}
                      isChecked={values.tensionBandaDosi}
                      name="tensionBandaDosi"
                    />
                    <Text>Verifique la tension de la banda</Text>
                  </HStack>
                  <HStack>
                    <Switch
                      onChange={handleChange}
                      isChecked={values.unionBandaDosi}
                      name="unionBandaDosi"
                    />
                    <Text>Verifique la unión de banda</Text>
                  </HStack>
                  <HStack>
                    <Switch
                      onChange={handleChange}
                      isChecked={values.estadoFaldonHuleDosi}
                      name="estadoFaldonHuleDosi"
                    />
                    <Text>
                      Verifique el estado del faldon de hule <br />y ajustar de
                      ser necesario
                    </Text>
                  </HStack>
                  <HStack>
                    <Switch
                      onChange={handleChange}
                      isChecked={values.rodillosDosi}
                      name="rodillosDosi"
                    />
                    <Text>
                      Verificar que los rodillos de
                      <br /> las estaciones giren libremente
                    </Text>
                  </HStack>
                </Stack>
                <Stack w="50%">
                  <HStack>
                    <Switch
                      onChange={handleChange}
                      isChecked={values.estadoBandaDosi}
                      name="estadoBandaDosi"
                    />
                    <Text>Verifique el estado de la banda</Text>
                  </HStack>
                  <HStack>
                    <Switch
                      onChange={handleChange}
                      isChecked={values.desgasteRodilloDosi}
                      name="desgasteRodilloDosi"
                    />
                    <Text>
                      Verificar si algun rodillo de
                      <br /> las estaciones presenta desgaste
                    </Text>
                  </HStack>

                  <HStack>
                    <Switch
                      onChange={handleChange}
                      isChecked={values.materialEnRodilloDosi}
                      name="materialEnRodilloDosi"
                    />
                    <Text>
                      Limpieza de rodillos de las estaciones, si
                      <br /> presenta acumulacion de material
                    </Text>
                  </HStack>
                  <HStack>
                    <Switch
                      onChange={handleChange}
                      isChecked={values.separacionProtectorRodilloDosi}
                      name="separacionProtectorRodilloDosi"
                    />
                    <Text>
                      Verificar que haya separacion entre el protector de
                      <br /> retorno y el rodillo de retorno
                    </Text>
                  </HStack>
                  <HStack>
                    <Switch
                      onChange={handleChange}
                      isChecked={values.desgasteRoloTractorDosi}
                      name="desgasteRoloTractorDosi"
                    />
                    <Text>
                      Verifique el desgaste de la cuchilla en el rolo tractor
                    </Text>
                  </HStack>
                  <HStack>
                    <Switch
                      onChange={handleChange}
                      isChecked={values.nvlAceiteHidraDosi}
                      name="nvlAceiteHidraDosi"
                    />
                    <Text>Verifique el nivel de aceite hidraulico</Text>
                  </HStack>
                </Stack>
              </HStack>

              <HStack w="100%" justifyContent="space-between" p={3}>
                <Box></Box>
                <FormControl w="lg">
                  <FormLabel>Observaciones</FormLabel>
                  <Card w="lg" variant="unstyled" bg="brand.fondos_secundarios">
                    <CardBody>
                      <Textarea
                        w="lg"
                        placeholder="Descripcion"
                        name="descripcionRegistroEventos"
                        onChange={(e) => setDescripcionD(e.target.value)}
                        onBlur={handleBlur}
                        maxLength="1500"
                        resize="none"
                      />
                    </CardBody>
                    <CardFooter placeSelf="end" color="brand.gris_medio">
                      {charCount}/1500
                    </CardFooter>
                  </Card>
                </FormControl>
                <Button
                  fontSize={12}
                  fontWeight="semibold"
                  bg="brand.naranja"
                  color="white"
                  rightIcon={<MdNavigateNext fontSize="20px" />}
                  variant="solid"
                  form="dosificadora"
                  onClick={handleSubmit}
                  isDisabled={loading}
                  _hover={{ bg: "white", color: "brand.naranja" }}
                >
                  Finalizar
                </Button>
              </HStack>
            </Form>
          )}
        </Formik>
      )}
    </Stack>
  );
};

export default RevisionDosificadora;
