import React, { useState, useEffect } from "react";
import {
  Box,
  HStack,
  SimpleGrid,
  Text,
  Heading,
  Card,
  Button,
  CardHeader,
  CardBody,
  Stack,
  IconButton,
  Tooltip,
  useToast,
  Input,
  CircularProgress,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  Accordion,
  AccordionItem,
  Switch,
} from "@chakra-ui/react";
import { MdNavigateNext } from "react-icons/md";
import Eliminartransportista from "./components/transportista/EliminarTransportista";
import { useNavigate } from "react-router-dom";
import {
  MdAddCircleOutline,
  MdOutlineCreate,
  MdFilterList,
  MdRefresh,
} from "react-icons/md";
import { getTransportista, editarTransportista } from "./helpers/api.helper";
import { useSelector } from "react-redux";

const EmpresaTransportista = (props) => {
  const { mobile } = props;
  const [transportistas, setTransportistas] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filterBy, setFilterBy] = useState({
    nombreEmpresaT: "",
    razonSocialCliente: "",
    tipoCliente: "",
  });
  const navigate = useNavigate();
  const toast = useToast();
  const { user } = useSelector((state) => state.user);

  const initData = async () => {
    setLoading(true);
    let res = await getTransportista();
    if (res.status === 200) {
      setTransportistas(res.data);
    } else if (res.status === 204) {
      toast({
        title: "Info",
        description: "No existen transportistas",
        duration: 3000,
        isClosable: true,
        status: "info",
      });
    } else {
      toast({
        title: "Error",
        description: "Error al cargar los transportistas",
        duration: 3000,
        isClosable: true,
        status: "error",
      });
    }

    setLoading(false);
  };

  const showFiltrados = () => {
    if (Object.values(filterBy).every((value) => !value)) {
      return transportistas;
    }
    return transportistas.filter((transportista) => {
      const { nombreEmpresaT, razonSocialEmpresaT, tipoCliente } = filterBy;

      return (
        (!nombreEmpresaT ||
          transportista.nombreEmpresaT
            .toLowerCase()
            .includes(nombreEmpresaT.toLowerCase())) &&
        (!razonSocialEmpresaT ||
          transportista.razonSocialEmpresaT
            .toLowerCase()
            .includes(razonSocialEmpresaT.toLowerCase())) &&
        (!tipoCliente || transportista.tipoCliente === tipoCliente)
      );
    });
  };

  const handleHabilitar = async (transportista) => {
    let data = {
      ...transportista,
      habilitado: !transportista.habilitado,
    };
    data = {
      ...data,
      habilitado: data.habilitado === true ? 1 : 0,
    };

    let res = await editarTransportista(data);

    if (res.status === 200) {
      toast({
        title: "Exito",
        description:
          data.habilitado === true
            ? "Habilitado correctamente"
            : "Inhabilitado correctamente",
        duration: 3000,
        isClosable: true,
        status: "success",
      });
      initData();
    } else {
      toast({
        title: "Error",
        description: "Error al editar la empresa",
        duration: 3000,
        isClosable: true,
        status: "error",
      });
    }
  };
  const loadingtable = () => {
    return (
      <HStack w="100%" placeContent="center">
        <CircularProgress isIndeterminate color="brand.naranja" />
      </HStack>
    );
  };
  const cleanFilter = () => {
    setFilterBy({
      nombreCliente: "",
      razonSocialCliente: "",
      tipoCliente: "",
    });
  };

  useEffect(() => {
    initData();
  }, []);

  return (
    <Stack w={"100%"} h={"xl"} px={5}>
      <HStack w="100%" justifyContent="space-between">
        <Heading
          fontWeight="medium"
          size="xl"
          fontFamily="fonts.title"
          color="brand.gris_primario"
        >
          Empresas transportistas
        </Heading>

        <HStack>
          {(user.idRol === "admin" || user.idRol === "coorOp") && (
            <Button
              size="sm"
              variant="outline"
              border="1px solid #FF8200"
              leftIcon={<MdAddCircleOutline fontSize="20px" />}
              color="brand.naranja"
              onClick={() => navigate("/inventario/transportista/nuevo")}
              _hover={{ bg: "brand.naranja", color: "white" }}
            >
              Nuevo transportista
            </Button>
          )}
          <Popover isLazy>
            <PopoverTrigger>
              <IconButton
                icon={<MdFilterList fontSize="20px" />}
                bg={"brand.fondos_secundarios"}
                borderRadius="50%"
                color={"black"}
              />
            </PopoverTrigger>
            <PopoverContent>
              <PopoverArrow />
              <PopoverHeader>
                <Tooltip label="Limpiar filtro">
                  <IconButton
                    icon={<MdRefresh />}
                    onClick={cleanFilter}
                    variant="link"
                    justifySelf={"end"}
                  />
                </Tooltip>
              </PopoverHeader>
              <PopoverBody maxH={"sm"} overflowY={"auto"}>
                <Accordion allowToggle>
                  <AccordionItem py={1}>
                    <Input
                      placeholder="Por nombre"
                      variant={"filled"}
                      size="sm"
                      w="2xs"
                      onChange={(e) => {
                        setFilterBy((prevstate) => ({
                          ...prevstate,
                          nombreEmpresaT: e.target.value,
                        }));
                        showFiltrados();
                      }}
                      value={filterBy.nombreCliente}
                    />
                  </AccordionItem>
                  <AccordionItem py={1}>
                    <Input
                      placeholder="Por razón social"
                      variant={"filled"}
                      size="sm"
                      w="2xs"
                      onChange={(e) => {
                        setFilterBy((prevstate) => ({
                          ...prevstate,
                          razonSocialEmpresaT: e.target.value,
                        }));
                        showFiltrados();
                      }}
                      value={filterBy.razonSocialCliente}
                    />
                  </AccordionItem>
                </Accordion>
              </PopoverBody>
            </PopoverContent>
          </Popover>
        </HStack>
      </HStack>
      {loading && loadingtable()}
      {!loading && transportistas.length > 0 && (
        <SimpleGrid w="100%" columns={3} spacing={5} px={3}>
          {showFiltrados().map((transportista) => (
            <Card
              key={transportista.idEmpresaT}
              p={3}
              borderRadius={5}
              bg={"#f5f5f5"}
              size={"sm"}
            >
              <CardHeader>
                <HStack w="100%" justifyContent="space-between">
                  <Heading size={"xs"}>{transportista.nombreEmpresaT}</Heading>
                  {(user.idRol === "admin" || user.idRol === "coorOp") && (
                    <Box>
                      <Switch
                        onChange={() => handleHabilitar(transportista)}
                        isChecked={transportista.habilitado}
                        pb={3}
                        pr={2}
                      />
                      <Tooltip label="Editar Cliente">
                        <IconButton
                          variant={"link"}
                          icon={<MdOutlineCreate fontSize="20px" />}
                          color="black"
                          onClick={() =>
                            navigate(
                              `/inventario/transportista/editar/${transportista.idEmpresaT}`
                            )
                          }
                          _hover={{ color: "#FF8200" }}
                        />
                      </Tooltip>
                      <Eliminartransportista
                        transportista={transportista}
                        initData={initData}
                      />
                    </Box>
                  )}
                </HStack>
              </CardHeader>
              <CardBody>
                <Stack direction={mobile ? "row" : "column"}>
                  <Text fontWeight="semibold">CUIT: </Text>
                  <Text>{transportista.cuitCuilEmpresaT}</Text>
                </Stack>
                <Stack direction={mobile ? "row" : "column"}>
                  <Text fontWeight="semibold">Razon social: </Text>
                  <Text>{transportista.razonSocialEmpresaT}</Text>
                </Stack>
                <Stack direction={mobile ? "row" : "column"}>
                  <Text fontWeight="semibold">Contacto: </Text>
                  <Text>{transportista.telefonoEmpresaT}</Text>
                </Stack>
                <Stack direction={mobile ? "row" : "column"}>
                  <Text fontWeight="semibold">Correo: </Text>
                  <Text>{transportista.emaillEmpresaT}</Text>
                </Stack>
                <HStack>
                  <Text>
                    {transportista.direccionEmpresaT} -{" "}
                    {transportista.ciudadEmpresaT} -{" "}
                    {transportista.provinciaEmpresaT}
                  </Text>
                </HStack>
                <Button
                  variant={"link"}
                  color={"brand.naranja"}
                  rightIcon={<MdNavigateNext />}
                  placeSelf="start"
                  onClick={() =>
                    navigate(
                      `/inventario/transportista/tablaCamiones/${transportista.nombreEmpresaT}`
                    )
                  }
                >
                  Ver camiones
                </Button>
              </CardBody>
            </Card>
          ))}
        </SimpleGrid>
      )}
      {!loading && transportistas.length < 1 && (
        <Heading textAlign={"center"} size={"md"}>
          No hay datos para mostrar
        </Heading>
      )}
    </Stack>
  );
};

export default EmpresaTransportista;
