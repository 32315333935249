import React from "react";
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  HStack,
  IconButton,
  List,
  ListItem,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import DatePicker from "react-datepicker";
import { MdCheck, MdFilterList, MdRefresh } from "react-icons/md";

function FiltrosReporteEtapas(props) {
  const {
    etapas,
    filterBy,
    handleFilterSelection,
    cleanFilter,
    handleDateChange,
  } = props;
  return (
    <Popover isLazy>
      <PopoverTrigger>
        <IconButton
          icon={<MdFilterList fontSize="20px" />}
          bg={"brand.fondos_secundarios"}
          borderRadius="50%"
          color={"black"}
        />
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverHeader>
          <Tooltip label="Limpiar filtro">
            <IconButton
              icon={<MdRefresh />}
              onClick={cleanFilter}
              variant="link"
              justifySelf={"end"}
            />
          </Tooltip>
        </PopoverHeader>
        <PopoverBody maxH={"sm"} overflowY={"auto"}>
          <Accordion w={"100%"} allowToggle>
            <AccordionItem>
              <AccordionButton fontWeight={"bold"}>PAD</AccordionButton>
              <AccordionPanel>
                <List cursor={"pointer"}>
                  {etapas
                    .map((etapa) => etapa.padLocacion)
                    .filter(
                      (value, index, self) => self.indexOf(value) === index
                    )
                    .map((locacion) => (
                      <HStack w={"100%"} key={locacion}>
                        {filterBy.padDes.includes(locacion) && <MdCheck />}
                        <ListItem
                          w={"100%"}
                          style={
                            filterBy.padDes.includes(locacion)
                              ? { backgroundColor: "lightgray" }
                              : {}
                          }
                          onClick={() =>
                            handleFilterSelection("padDes", locacion)
                          }
                        >
                          {locacion}
                        </ListItem>
                      </HStack>
                    ))}
                </List>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionButton fontWeight={"bold"}>ID Pozo</AccordionButton>
              <AccordionPanel>
                <List cursor={"pointer"}>
                  {etapas
                    .map((etapa) => etapa.pozo?.nombrePozo)
                    .filter(
                      (value, index, self) =>
                        self.indexOf(value) === index && value
                    ) // Filtra valores únicos y no undefined
                    .map((nombrePozo) => (
                      <HStack w={"100%"} key={nombrePozo}>
                        {filterBy.idPozo.includes(nombrePozo) && <MdCheck />}
                        <ListItem
                          w={"100%"}
                          style={
                            filterBy.idPozo.includes(nombrePozo)
                              ? { backgroundColor: "lightgray" }
                              : {}
                          }
                          onClick={() =>
                            handleFilterSelection("idPozo", nombrePozo)
                          }
                        >
                          {nombrePozo}
                        </ListItem>
                      </HStack>
                    ))}
                </List>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionButton fontWeight={"bold"}>Wetsand</AccordionButton>
              <AccordionPanel>
                <List cursor={"pointer"}>
                  <HStack w={"100%"}>
                    <ListItem
                      w={"100%"}
                      style={
                        filterBy.etapaWeTSanD.includes("true")
                          ? { backgroundColor: "lightgray" }
                          : {}
                      }
                      onClick={() =>
                        handleFilterSelection("etapaWeTSanD", "true")
                      }
                    >
                      SI
                    </ListItem>
                    <ListItem
                      w={"100%"}
                      style={
                        filterBy.etapaWeTSanD.includes("false")
                          ? { backgroundColor: "lightgray" }
                          : {}
                      }
                      onClick={() =>
                        handleFilterSelection("etapaWeTSanD", "false")
                      }
                    >
                      NO
                    </ListItem>
                  </HStack>
                </List>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionButton fontWeight={"bold"}>
                <HStack>
                  <Text fontWeight={"bold"}>Fecha</Text>
                </HStack>
              </AccordionButton>
              <AccordionPanel>
                <DatePicker
                  selected={filterBy.startDate}
                  onChange={handleDateChange}
                  startDate={filterBy.startDate}
                  endDate={filterBy.endDate}
                  selectsRange
                  inline
                />
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
}

export default FiltrosReporteEtapas;
