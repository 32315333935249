import React from "react";
import { MdOutlineCreate } from "react-icons/md";
import {
  IconButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Tooltip,
} from "@chakra-ui/react";

import CrearEditarAsociacion from "./CrearEditarAsociacion";

const EditarAsociacion = (props) => {
  const { asociacion, initAsociaciones } = props;
  const { onOpen, isOpen, onClose } = useDisclosure();
  return (
    <>
      <Tooltip label="Editar Asociación">
        <IconButton
          onClick={onOpen}
          icon={<MdOutlineCreate fontSize="20px" />}
          variant="link"
          _hover={{ color: "brand.naranja" }}
        />
      </Tooltip>
      <Modal isOpen={isOpen} onClose={onClose} size="full">
        <ModalOverlay />
        <ModalContent>
          <ModalBody bg="brand.fondo">
            <CrearEditarAsociacion
              type="editar"
              asociacion={asociacion}
              initAsociaciones={initAsociaciones}
              onClose={onClose}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default EditarAsociacion;
