import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  Input,
  useToast,
} from "@chakra-ui/react";
import { MdOutlineAdd } from "react-icons/md";
import { editarReportesViajes } from "../../../helpers/api.helper";

const AgregarToneladas = (props) => {
  const { reporte, reportesViajes, setReportesViajes } = props;
  const [toneladas, setToneladas] = useState();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  const handleAgregar = async () => {
    let res;

    res = await editarReportesViajes({
      id: reporte.idReporteViaje,
      ToneladasRV: toneladas,
    });

    if (res.status === 200) {
      let editar = reportesViajes.find(
        (item) => item.idReporteViaje === reporte.idReporteViaje
      );
      const indexFechaDetencion = reportesViajes.findIndex(
        (detalle) => detalle.idReporteViaje === reporte.idReporteViaje
      );
      if (editar) {
        editar = { ...reporte, ToneladasRV: toneladas }; // Corrección aquí
      }
      if (indexFechaDetencion !== -1) {
        // Añadida verificación para evitar errores si el índice no se encuentra
        reportesViajes[indexFechaDetencion] = editar;
      }
      setReportesViajes([...reportesViajes]);

      onClose();
      toast({
        title: "Exito",
        description: "Teneladas agregado correctamente",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } else {
      toast({
        title: "Error",
        description: "Error al cargar las toneladas",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <>
      <Button
        variant="link"
        leftIcon={<MdOutlineAdd color="#FF8200" />}
        onClick={onOpen}
        size="sm"
      >
        Agregar
      </Button>
      <Modal isOpen={isOpen} onClose={onClose} isCentered size="sm">
        <ModalOverlay />
        <ModalContent borderRadius="20px">
          <ModalHeader placeSelf="center" fontWeight="bold">
            Toneladas
          </ModalHeader>
          <ModalCloseButton size="sm" />
          <ModalBody>
            <Input
              placeholder="Toneladas"
              borderRadius="20px"
              onChange={(e) => setToneladas(e.target.value)}
              type="number"
            />
          </ModalBody>

          <ModalFooter placeContent="center">
            <Button
              bg="brand.naranja"
              fontSize="14px"
              color="white"
              onClick={() => handleAgregar()}
            >
              Cargar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AgregarToneladas;
