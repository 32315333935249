import React from "react";
import {
  Stack,
  HStack,
  FormControl,
  FormLabel,
  Input,
  RadioGroup,
  Radio,
  Textarea,
  Card,
  CardBody,
  CardFooter,
  Checkbox,
  Text,
} from "@chakra-ui/react";

const PrimerPasoRegistro = (props) => {
  const {
    handleChange,
    handleBlur,
    values,
    text,
    setText,
    setFieldValue,
    setImgUpload,
  } = props;

  const handleCaracteres = (event, setFieldValue) => {
    const text = event.target.value;
    setText(text);
    setFieldValue("descripcionRegistroEventos", text);
  };
  const charCount = text.length;

  return (
    <Stack w="100%" pb={3}>
      <HStack justifyContent="space-around" pb={3}>
        <FormControl w="2xs" isRequired>
          <FormLabel fontWeight="semibold">Evento</FormLabel>
          <RadioGroup
            display={"block"}
            name="tipoRegistroEventos"
            value={values.tipoRegistroEventos}
          >
            <HStack>
              <Radio
                display={"block"}
                onChange={handleChange}
                onBlur={handleBlur}
                value="Calidad"
              >
                Calidad
              </Radio>
              <Radio
                display={"block"}
                onChange={handleChange}
                onBlur={handleBlur}
                value="Mantenimiento"
              >
                Mantenimiento
              </Radio>
            </HStack>
          </RadioGroup>
        </FormControl>
        <FormControl w="2xs" isRequired>
          <FormLabel fontWeight="semibold">Valoracion de Evento</FormLabel>
          <RadioGroup
            display={"block"}
            name="valoracionRegistroEventos"
            value={values.valoracionRegistroEventos}
          >
            <HStack>
              <Radio
                display={"block"}
                onChange={handleChange}
                onBlur={handleBlur}
                value="Leve"
              >
                Leve
              </Radio>
              <Radio
                display={"block"}
                onChange={handleChange}
                onBlur={handleBlur}
                value="Grave"
              >
                Grave
              </Radio>
              <Radio
                display={"block"}
                onChange={handleChange}
                onBlur={handleBlur}
                value="Muy Grave"
              >
                Muy grave
              </Radio>
            </HStack>
          </RadioGroup>
        </FormControl>
        <FormControl w="2xs" isRequired>
          <FormLabel fontWeight="semibold">Fecha/Hora del evento</FormLabel>
          <Input
            w="2xs"
            type="datetime-local"
            name="fechaRegistroEventos"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.fechaRegistroEventos}
          />
        </FormControl>
      </HStack>
      <HStack justifyContent="space-around" pb={3}>
        <FormControl w="2xs">
          <FormLabel fontWeight="semibold">Equipo involucrado</FormLabel>
          <RadioGroup
            display={"block"}
            name="equipoRegistroEventos"
            value={values.equipoRegistroEventos}
          >
            <HStack justifyContent="start">
              <Stack justifyContent="left">
                <Radio
                  display={"inline"}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value="sander"
                >
                  Sander
                </Radio>
                <Radio
                  display={"block"}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value="sandcubes"
                >
                  Sandcubes
                </Radio>
                <Radio
                  display={"block"}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value="sandVan"
                >
                  SandVan
                </Radio>
                <Radio
                  display={"block"}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value="dosificadora"
                >
                  Dosificadora
                </Radio>
              </Stack>
              <Stack placeSelf="start">
                <Radio
                  display={"block"}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value="generadores"
                >
                  Generadores
                </Radio>
                <Radio
                  display={"block"}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value="autoelevador"
                >
                  Autoelevador
                </Radio>
                <Radio
                  display={"block"}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value="minipala"
                >
                  Mini Pala
                </Radio>
                <Radio
                  display={"block"}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value="otro"
                >
                  Ninguno
                </Radio>
              </Stack>
            </HStack>
          </RadioGroup>
        </FormControl>
        <FormControl w="2xs" isRequired>
          <HStack w="lg" spacing={-1}>
            <FormLabel fontWeight="semibold" placeSelf="start">
              Descripcion de Evento
            </FormLabel>
            <Text fontSize={12} color={"gray"}>
              ¿Qué pasó?¿Cómo pasó?¿Quiénes estuvieron involucrados?
            </Text>
          </HStack>
          <Card w="lg" variant="unstyled" bg="brand.fondos_secundarios">
            <CardBody>
              <Textarea
                w="lg"
                placeholder="Descripcion"
                name="descripcionRegistroEventos"
                onChange={(e) => handleCaracteres(e, setFieldValue)}
                onBlur={handleBlur}
                value={values.descripcionRegistroEventos}
                maxLength="1500"
                resize="none"
              />
            </CardBody>
            <CardFooter placeSelf="end" color="brand.gris_medio">
              {charCount}/1500
            </CardFooter>
          </Card>
        </FormControl>
        <FormControl w="2xs"></FormControl>
      </HStack>
      <HStack px={7} pb={3}>
        <FormControl w="xs" placeSelf="start">
          <FormLabel fontWeight="semibold">Foto</FormLabel>
          <Input
            variant="filled"
            type="file"
            onChange={(e) => setImgUpload(e.target.files[0])}
            accept=".jpeg, .jpg, .png"
            multiple={false}
            _hover="none"
            bg="brand.fondo_secundario"
          />
        </FormControl>
      </HStack>
      <HStack>
        <Stack placeSelf={"end"} px={5}>
          <Checkbox
            name="masFotosRegistroEventos"
            onChange={handleChange}
            isChecked={values.masFotosRegistroEventos}
          >
            Se disponen más fotos?
          </Checkbox>
          <HStack w={"sm"}>
            <Checkbox
              w={"xs"}
              name="otroRegistroEventos"
              onChange={handleChange}
              isChecked={values.otroRegistroEventos}
            >
              Se registró evento en otro registro?
            </Checkbox>
            <Input
              type="text"
              placeholder="¿Donde?"
              size="sm"
              name="otroDondeRegistroEventos"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.otroDondeRegistroEventos}
            />
          </HStack>
        </Stack>
        <Stack placeSelf={"end"}>
          <HStack w={"sm"}>
            <Checkbox
              w={"sm"}
              name="actoresRegistroEventos"
              onChange={handleChange}
              isChecked={values.actoresRegistroEventos}
            >
              Involucró otros actores/empresas?
            </Checkbox>
            <Input
              type="text"
              placeholder="¿Quien?"
              size="sm"
              name="actoresQuienRegistroEventos"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.actoresQuienRegistroEventos}
            />
          </HStack>
          <HStack w={"sm"}>
            <Checkbox
              w={"sm"}
              name="informoRegistroEventos"
              onChange={handleChange}
              isChecked={values.informoRegistroEventos}
            >
              Se informó al cliente?
            </Checkbox>
            <Input
              type="text"
              placeholder="Respuesta obtenida"
              size="sm"
              name="rtaObtenidaCl"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.rtaObtenidaCl}
            />
          </HStack>
        </Stack>
        <FormControl w={"xs"} px={5}>
          <FormLabel>A quién se comunicó?</FormLabel>
          <Stack spacing={3}>
            <Input
              type="text"
              placeholder=""
              size="sm"
              name="aQuienComunico"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.aQuienComunico}
              w={"2xs"}
            />
            <Input
              type="text"
              placeholder="Respuesta obtenida"
              size="sm"
              name="rtaObtenida"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.rtaObtenida}
              w={"2xs"}
            />
          </Stack>
        </FormControl>
      </HStack>
    </Stack>
  );
};

export default PrimerPasoRegistro;
