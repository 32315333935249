import React from "react";
import {
  useToast,
  IconButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
} from "@chakra-ui/react";
import { MdOutlineGetApp } from "react-icons/md";
import { descargarEventos } from "../../../helpers/api.helper";
import moment from "moment";

const DescargarEventos = (props) => {
  const { planificacion, eventos, type } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const handleDownload = async () => {
    let data = eventos.map((evento) => {
      return {
        fechaRegistroEventos: evento.fechaRegistroEventos,
        equipoRegistroEventos: evento.equipoRegistroEventos,
        tipoRegistroEventos: evento.tipoRegistroEventos,
        valoracionRegistroEventos: evento.valoracionRegistroEventos,
        detectoRegistroEventos: evento.detectoRegistroEventos.nombre,
        descripcionRegistroEventos: evento.descripcionRegistroEventos,
        fotoRegistroEventos: evento.fotoRegistroEventos,
        masFotosRegistroEventos: evento.masFotosRegistroEventos,
        otroRegistroEventos: evento.otroRegistroEventos,
        otroDondeRegistroEventos: evento.otroDondeRegistroEventos,
        actoresRegistroEventos: evento.actoresRegistroEventos,
        actoresQuienRegistroEventos: evento.actoresQuienRegistroEventos,
        informoRegistroEventos: evento.informoRegistroEventos,
        accionesRegistroEventos: evento.accionesRegistroEventos,
        responsableAccionRegistroEventos:
          evento.responsableAccionRegistroEventos,
        fechaAccionRegistroEventos: evento.fechaAccionRegistroEventos,
        responsableRegistroEventos: evento.responsableRegistroEventos.nombre,
        idPlanificacion: evento.idPlanificacion,
        idRegistro: evento.idRegistroEventos,
        locacionRegistroEvento: evento.locacionRegistroEvento,
      };
    });

    let response = await descargarEventos({
      registros: [...data],
    });
    if (response.status === 200) {
      const url = window.URL.createObjectURL(
        new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
        })
      );
      const link = document.createElement("a");
      link.href = url;
      if (type === "historial") {
        link.setAttribute("download", `Historial-registro-eventos.xlsx`);
      } else {
        link.setAttribute(
          "download",
          `Registro-Eventos-${planificacion.idPadlocacion}.xlsx`
        );
      }
      document.body.appendChild(link);
      link.click();
    } else if (response.status === 500) {
      return toast({
        title: "Error",
        description: "Error del servidor ",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <>
      <IconButton
        size="sm"
        variant="outline"
        border="1px solid #FF8200"
        icon={<MdOutlineGetApp fontSize="20px" />}
        color="brand.naranja"
        _hover={{ bg: "brand.naranja", color: "white" }}
        onClick={onOpen}
      />
      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Pre visualizacion - Descarga</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <TableContainer w={"xl"} h={"sm"} overflowY="auto">
              <Table variant="simple" size="sm">
                <Thead>
                  <Tr>
                    <Th>Fecha y Hora</Th>
                    <Th>Valoración</Th>
                    <Th>Responsable</Th>
                  </Tr>
                </Thead>
                {eventos.map((registro) => (
                  <Tbody key={eventos.idRegistroEventos} textAlign="center">
                    <Tr>
                      <Td>
                        {moment
                          .utc(registro.fechaRegistroEventos)
                          .format("DD-MM-YYYY HH:mm")}
                      </Td>
                      <Td textTransform="uppercase">
                        {registro.valoracionRegistroEventos}
                      </Td>
                      <Td>{registro.responsableRegistroEventos.nombre}</Td>
                    </Tr>
                  </Tbody>
                ))}
              </Table>
            </TableContainer>
          </ModalBody>

          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={onClose}>
              Cerrar
            </Button>
            <Button
              bg="brand.naranja"
              color="white"
              onClick={() => handleDownload()}
            >
              Descargar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default DescargarEventos;
