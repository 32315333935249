import React, { useEffect, useState } from "react";
import { Button, Text, Stack } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { MdCheckCircle, MdOutlineCircle, MdReport } from "react-icons/md";
import moment from "moment";

const ButtonRevision = (props) => {
  const { equipo, today, padLocacion, objetoEquipo } = props;
  const navigate = useNavigate();
  const [equipoRevision, setEquipoRevision] = useState();

  const handleColor = (e) => {
    let a = moment(today);
    let days;

    if (e) {
      let duration = moment.duration(a.diff(e));
      days = duration.asDays();
    }

    if (days > 1) {
      return true;
    } else if (!days) {
      return true;
    } else {
      return false;
    }
  };

  const handleNombre = (equipo) => {
    switch (equipo) {
      case "revisionAutoelevador":
        return "Autoelevador";
      case "revisionGenerador":
        return "Generador";
      case "revisionBaseSandVan":
        return "Base SandVan";
      case "revisionDosificadora":
        return "Dosificadora";
      case "revisionLuminarias":
        return "Luminarias";
      case "revisionMiniPala":
        return "Mini Pala";
      case "revisionSander":
        return "Sander";
      case "revisionSandVan":
        return "SandVan";
      default:
        break;
    }
  };

  const handleEquipo = () => {
    let equipoFiltrado = objetoEquipo.filter(
      (elem) => elem.tipoRevision === equipo
    );

    setEquipoRevision(equipoFiltrado[0]);
  };

  useEffect(() => {
    handleEquipo();
  }, []);

  return (
    <Button
      size="lg"
      height="100px"
      variant="outline"
      borderRadius="10px"
      w="sm"
      leftIcon={
        handleColor(equipoRevision?.fecha) && <MdReport color="red.500" />
      }
      rightIcon={
        equipoRevision?.fecha &&
        moment(equipoRevision?.fecha).format("DD/MM/YYYY") ===
          moment(today).format("DD/MM/YYYY") ? (
          <MdCheckCircle />
        ) : (
          <MdOutlineCircle />
        )
      }
      color={
        handleColor(equipoRevision?.fecha) === true
          ? "red.500"
          : "brand.naranja"
      }
      colorScheme={
        handleColor(equipoRevision?.fecha) === true ? "red" : "orange"
      }
      _hover={{
        bg:
          handleColor(equipoRevision?.fecha) === true
            ? "red.500"
            : "brand.naranja",
        color: "white",
      }}
      onClick={() =>
        navigate(
          `/operaciones/curso/revision/nuevo/${padLocacion.padLocacion}/${equipo}`
        )
      }
      isDisabled={equipoRevision?.fecha === today}
    >
      <Stack>
        <Text>{handleNombre(equipo)}</Text>
        <Text fontSize="10px">
          {equipoRevision
            ? moment.utc(equipoRevision?.fecha).format("DD-MM-YY HH:mm")
            : "--/--/--"}
        </Text>
      </Stack>
    </Button>
  );
};

export default ButtonRevision;
