import axios from "axios";
import ajax from "../../../utils/axios";

export const getReportesViajes = async (params) => {
  let response;
  try {
    response = await ajax.get("/endpoint/reporteviaje", { params });
    return response;
  } catch (error) {
    console.error("Error en: api-helper.js - getReportesViajes() - Error", {
      error,
    });
    return error.response;
  }
};

export const getReportesViajesFiltrados = async (params) => {
  let response;
  try {
    response = await ajax.post("/endpoint/filterreporte", params);
    return response;
  } catch (error) {
    console.error("Error en: api-helper.js - getReportesViajes() - Error", {
      error,
    });
    return error.response;
  }
};

export const editarReportesViajes = async (params) => {
  try {
    let res = await ajax.put("/endpoint/reporteviaje", params);
    return res;
  } catch (error) {
    console.error(
      "Error en: api.helper.js - editarReportesViajes() - Error",
      error
    );
    return error.response.data;
  }
};

export const descargarReportesViajes = async (params) => {
  try {
    let res = await ajax.post("/endpoint/exportreporteviaje", params, {
      responseType: "blob",
    });
    return res;
  } catch (error) {
    console.error(
      "Error en: api.helper.js - descargarReportesViajes() - Error",
      error
    );
    return error.response;
  }
};

export const getRSV = async () => {
  let response;
  try {
    response = await axios.get(
      "https://www.rsv.com.ar/api/FIU3cRVZL847MthE/current_pos.json",
      {
        withCredentials: false,
        headers: {
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "Content-Type": "application/json",
        },
      }
    );
    return response;
  } catch (error) {
    console.error("Error en: api-helper.js - getRSV() - Error", {
      error,
    });
    return error.response;
  }
};

export const getGeocamiones = async (params) => {
  let response;
  try {
    response = await ajax.get("/endpoint/geocamiones", { params });
    return response;
  } catch (error) {
    console.error("Error en: api-helper.js - getGeocamiones() - Error", {
      error,
    });
    return error.response;
  }
};

export const getFacturacion = async (params) => {
  let response;

  try {
    response = await ajax.get("/endpoint/facturacion", { params });
    return response;
  } catch (error) {
    console.error("Error en: api-helper.js - getFacturacion() - Error", {
      error,
    });
    return error.response;
  }
};

export const editarFacturacion = async (params) => {
  try {
    let res = await ajax.put("/endpoint/facturacion", params);
    return res;
  } catch (error) {
    console.error(
      "Error en: api.helper.js - editarFacturacion() - Error",
      error
    );
    return error.response.data;
  }
};

export const descargarFacturacion = async (params) => {
  try {
    let res = await ajax.post("/endpoint/exportfacturacion", params, {
      responseType: "blob",
    });

    return res;
  } catch (error) {
    console.error(
      "Error en: api.helper.js - descargarPlanificacion() - Error",
      error
    );
    return error.response.data;
  }
};

export const getDetenciones = async (params) => {
  let response;
  try {
    response = await ajax.get("/endpoint/detencion", { params });
    return response;
  } catch (error) {
    console.error("Error en: api-helper.js - getDetenciones() - Error", {
      error,
    });
    return error.response;
  }
};

export const editarDetenciones = async (params) => {
  try {
    let res = await ajax.put("/endpoint/detencion", params);
    return res;
  } catch (error) {
    console.error(
      "Error en: api.helper.js - editarDetenciones() - Error",
      error
    );
    return error.response.data;
  }
};
