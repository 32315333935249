import React from "react";
import { Box, Button, useToast } from "@chakra-ui/react";
import { TbCreativeCommonsSa } from "react-icons/tb";
import { editarPlanificacion } from "../../helpers/api.helper";
import { useNavigate } from "react-router-dom";

const BtnReActivar = (props) => {
  const toast = useToast();
  const navigate = useNavigate();
  const { seleccionado, initData } = props;

  const handleReactivar = async () => {
    navigate(
      `/operaciones/planificacion/reActivar/${seleccionado.idPlanificacion}`
    );
    /*  let res = await editarPlanificacion({
        id: seleccionado.idPlanificacion,
        estado: "activo",
      });
    if (res.status === 200) {
      initData();
      toast({
        description: "Re-Activado correctamente",
        title: "Exito",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } else {
      return toast({
        description: "Error al Re-Activar",
        title: "Error",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } */
  };
  return (
    <Box>
      <Button
        color="green"
        variant="link"
        size="sm"
        leftIcon={<TbCreativeCommonsSa />}
        onClick={() => handleReactivar()}
      >
        Re-Activar
      </Button>
    </Box>
  );
};

export default BtnReActivar;
