import React, { useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useMediaQuery } from "@chakra-ui/react";
import { useMsal } from "@azure/msal-react";
import Login from "../auth/Login";
import Layout from "../layout/Layout";
import Dashboard from "../dashboard/Dashboard";
import DetalleOperacion from "../dashboard/components/DetalleOperacion";
import AdministrarNotificaciones from "../config/AdministrarNotificaciones";
//----------------------------
import Usuarios from "../usuarios/Usuarios";
import FormCrearEditarUsuarios from "../usuarios/components/FormCrearEditarUsuarios";
//----------------------------
import Historial from "../operaciones/Historial";
import Planificacion from "../operaciones/Planificacion";
import FormCrearEditarPlanificacion from "../operaciones/components/planificacion/FormCrearEditarPlanificacion";
import EnCurso from "../operaciones/EnCurso";
import TablaAsociacion from "../operaciones/components/curso/TablaAsociacion";
import CrearEditarAsociacion from "../operaciones/components/curso/CrearEditarAsociacion";
import TablaEtapas from "../operaciones/components/curso/etapas/TablaEtapas";
import CrearEditarEtapas from "../operaciones/components/curso/etapas/CrearEditarEtapas";
//----------------------------
import Autoelevadores from "../inventario/Autoelevadores";
import Cliente from "../inventario/Cliente";
import EmpresaTransportista from "../inventario/EmpresaTransportista";
import Locacion from "../inventario/Locacion";
import Planta from "../inventario/Planta";
import Sandcubes from "../inventario/Sandcubes";
import Sander from "../inventario/Sander";
import Sandvan from "../inventario/Sandvan";
//---------------------------
// import CertificacionCamiones from "../reportes/CertificacionCamiones";
import ReporteViajes from "../reportes/ReporteViajes";
import FormCrearEditarNotificacion from "../config/components/administrarNotificaciones/FormCrearEditarNotificacion";
import CrearEditarRegistro from "../operaciones/components/curso/eventos/CrearEditarRegistro";
import TablaRegistros from "../operaciones/components/curso/eventos/TablaRegistros";
import CrearEditarRegistroFijo from "../reportes/components/CrearEditarRegistroFijo";
//---------------------------
import CrearEditarRevision from "../operaciones/components/curso/revision/CrearEditarRevision";
import TablaRevision from "../operaciones/components/curso/revision/TablaRevision";
import RevisionDosificadora from "../operaciones/components/curso/revision/RevisionDosificadora";
import RevisionAutoelevador from "../operaciones/components/curso/revision/RevisionAutoelevador";
import RevisionGeneradores from "../operaciones/components/curso/revision/RevisionGeneradores";
import RevisionLuminaria from "../operaciones/components/curso/revision/RevisionLuminaria";
import RevisionMiniPala from "../operaciones/components/curso/revision/RevisionMiniPala";
import RevisionSandVan from "../operaciones/components/curso/revision/RevisionSandVan";
import RevisionBaseSV from "../operaciones/components/curso/revision/RevisionBaseSV";
import RevisionSander from "../operaciones/components/curso/revision/RevisionSander";
import TablaCamiones from "../inventario/components/transportista/TablaCamiones";
import FormCrearEditarPlanta from "../inventario/components/plantas/FormCrearEditarPlanta";
import FormCrearEditarTransportista from "../inventario/components/transportista/FormCrearEditarTransportista";
import FormCrearEditarCliente from "../inventario/components/clientes/FormCrearEditarCliente";
import FormCrearEditarAutoelevador from "../inventario/components/autoelevadores/FormCrearEditarAutoelevador";
import FormCrearEditarSander from "../inventario/components/sanders/FormCrearEditarSander";
import FormCrearEditarSandvan from "../inventario/components/sandvans/FormCrearEditarSandvan";
import FormCrearEditarSandcubes from "../inventario/components/sandcubes/FormCrearEditarSandcubes";
import FormCrearCamiones from "../inventario/components/transportista/FormCrearCamiones";
import FormEditarCamion from "../inventario/components/transportista/FormEditarCamion";
import Camiones from "../inventario/Camiones";
//--------------------------
import Geocercas from "../geocercas/Geocercas";
import MapaCamiones from "../geocercas/MapaCamiones";
import RevisionTurnos from "../reportes/RevisionTurnos";
import ReporteEtapas from "../reportes/ReporteEtapas";

const Routing = () => {
  const { user } = useSelector((state) => state.user);
  const { accounts } = useMsal();
  const [notificaciones, setNotificaciones] = useState([]);
  const [filter, setFilter] = useState();
  const [expanded, setExpanded] = useState(true);
  const [tabSeleccionado, setTabSeleccionado] = useState(0);
  const [operacionSeleccionada, setOperacionSeleccionada] = useState([]);
  const [mobile] = useMediaQuery("(min-width: 1200px)", {
    ssr: true,
    fallback: false,
  });
  const [filterBy, setFilterBy] = useState({
    malla: [],
    statusSandCube: [],
    condicion: [],
    wetsand: [],
    set: [],
    ubicacion: [],
  });

  return (
    <Routes>
      {accounts.length === 0 || !user ? (
        <Route path="*" element={<Login />} />
      ) : (
        <Route
          path="/"
          element={
            <Layout
              user={user}
              expanded={expanded}
              setExpanded={setExpanded}
              mobile={mobile}
              filter={filter}
              setFilter={setFilter}
              notificaciones={notificaciones}
              setNotificaciones={setNotificaciones}
            />
          }
        >
          <Route path="">
            <Route
              path="/dashboard"
              element={
                <Dashboard
                  mobile={mobile}
                  filter={filter}
                  setNotificaciones={setNotificaciones}
                />
              }
            />
            <Route
              path=""
              element={<Navigate to="/dashboard" replace={true} />}
            />
            <Route
              path="*"
              element={<Navigate to="/dashboard" replace={true} />}
            />
          </Route>
          <Route
            path="/detalleOperacion/:padLocacion"
            element={<DetalleOperacion mobile={mobile} filter={filter} />}
          />
          <Route path="/administrarNotificaciones">
            <Route
              path=""
              element={
                <AdministrarNotificaciones mobile={mobile} filter={filter} />
              }
            />
            <Route
              path="editar/:id"
              element={
                <FormCrearEditarNotificacion mobile={mobile} type={"editar"} />
              }
            />
          </Route>
          {(user.idRol === "admin" ||
            user.idRol === "usuarioIT" ||
            user.idRol === "coorOp") && (
            <Route path="/usuarios">
              <Route
                path=""
                element={<Usuarios mobile={mobile} filter={filter} />}
              />
              <Route
                path="nuevo"
                element={
                  <FormCrearEditarUsuarios type="crear" mobile={mobile} />
                }
              />
              <Route
                path="editar/:id"
                element={
                  <FormCrearEditarUsuarios type="editar" mobile={mobile} />
                }
              />
            </Route>
          )}
          <Route path="/operaciones">
            <Route path="planificacion">
              <Route
                path=""
                element={
                  <Planificacion
                    expanded={expanded}
                    mobile={mobile}
                    filter={filter}
                  />
                }
              />
              <Route
                path="nueva"
                element={
                  <FormCrearEditarPlanificacion
                    type="crear"
                    mobile={mobile}
                    expanded={expanded}
                  />
                }
              />
              <Route
                path="nueva/:id"
                element={
                  <FormCrearEditarPlanificacion
                    type="continuar"
                    mobile={mobile}
                    expanded={expanded}
                  />
                }
              />
              <Route path="editar">
                <Route
                  path=":id"
                  element={
                    <FormCrearEditarPlanificacion
                      type="editar"
                      mobile={mobile}
                      expanded={expanded}
                    />
                  }
                />
              </Route>
              <Route path="reActivar">
                <Route
                  path=":id"
                  element={
                    <FormCrearEditarPlanificacion
                      type="reActivar"
                      mobile={mobile}
                      expanded={expanded}
                    />
                  }
                />
              </Route>
            </Route>
            <Route path="curso">
              <Route
                path=""
                element={
                  <EnCurso
                    mobile={mobile}
                    filter={filter}
                    tabSeleccionado={tabSeleccionado}
                    setTabSeleccionado={setTabSeleccionado}
                    operacionSeleccionada={operacionSeleccionada}
                    setOperacionSeleccionada={setOperacionSeleccionada}
                  />
                }
              />
              <Route path="asociacion">
                <Route
                  path="nueva/:padLocacion"
                  element={
                    <CrearEditarAsociacion type="nueva" mobile={mobile} />
                  }
                />
                <Route path="">
                  <Route
                    path=":padLocacionACSC"
                    element={
                      <TablaAsociacion mobile={mobile} filter={filter} />
                    }
                  />
                  <Route
                    path="editar/:idACSC"
                    element={
                      <CrearEditarAsociacion type="editar" mobile={mobile} />
                    }
                  />
                </Route>
              </Route>
              <Route path="etapas">
                <Route
                  path="nueva/:padLocacion"
                  element={<CrearEditarEtapas type="crear" mobile={mobile} />}
                />
                <Route
                  path=":padLocacion"
                  element={
                    <TablaEtapas
                      expanded={expanded}
                      mobile={mobile}
                      filter={filter}
                    />
                  }
                />
                <Route
                  path="editar/:idEtapa"
                  element={<CrearEditarEtapas type="editar" mobile={mobile} />}
                />
              </Route>
              <Route path="reporte">
                <Route
                  path=":padLocacion"
                  element={
                    <ReporteViajes
                      mobile={mobile}
                      filter={filter}
                      expanded={expanded}
                    />
                  }
                />
              </Route>
              <Route path="eventos">
                <Route
                  path="nuevo/:padLocacion"
                  element={<CrearEditarRegistro type="crear" mobile={mobile} />}
                />
                <Route
                  path=":padLocacion"
                  element={
                    <TablaRegistros
                      expanded={expanded}
                      mobile={mobile}
                      filter={filter}
                    />
                  }
                />
                <Route
                  path="editar/:padLocacion/:id"
                  element={
                    <CrearEditarRegistro
                      type="editar"
                      mobile={mobile}
                      notificaciones={notificaciones}
                    />
                  }
                />
              </Route>
              <Route path="revision">
                <Route path="nuevo/:padLocacion">
                  <Route
                    path=""
                    element={
                      <CrearEditarRevision type="crear" mobile={mobile} />
                    }
                  />
                  <Route
                    path="revisionGenerador"
                    element={
                      <RevisionGeneradores
                        expanded={expanded}
                        tipo="crear"
                        mobile={mobile}
                        notificaciones={notificaciones}
                      />
                    }
                  />
                  <Route
                    path="revisionSander"
                    element={
                      <RevisionSander
                        expanded={expanded}
                        tipo="crear"
                        mobile={mobile}
                        notificaciones={notificaciones}
                      />
                    }
                  />
                  <Route
                    path="revisionAutoelevador"
                    element={
                      <RevisionAutoelevador
                        expanded={expanded}
                        tipo="crear"
                        mobile={mobile}
                        notificaciones={notificaciones}
                      />
                    }
                  />
                  <Route
                    path="revisionMiniPala"
                    element={
                      <RevisionMiniPala
                        expanded={expanded}
                        tipo="crear"
                        mobile={mobile}
                        notificaciones={notificaciones}
                      />
                    }
                  />
                  <Route
                    path="revisionLuminarias"
                    element={
                      <RevisionLuminaria
                        expanded={expanded}
                        tipo="crear"
                        mobile={mobile}
                        notificaciones={notificaciones}
                      />
                    }
                  />
                  <Route
                    path="revisionSandVan"
                    element={
                      <RevisionSandVan
                        expanded={expanded}
                        tipo="crear"
                        mobile={mobile}
                        notificaciones={notificaciones}
                      />
                    }
                  />
                  <Route
                    path="revisionBaseSandVan"
                    element={
                      <RevisionBaseSV
                        expanded={expanded}
                        tipo="crear"
                        mobile={mobile}
                        notificaciones={notificaciones}
                      />
                    }
                  />
                  <Route
                    path="revisionDosificadora"
                    element={
                      <RevisionDosificadora
                        expanded={expanded}
                        tipo="crear"
                        mobile={mobile}
                        notificaciones={notificaciones}
                      />
                    }
                  />
                </Route>
                <Route path="editar/:padLocacion">
                  <Route
                    path="Generador/:id"
                    element={
                      <RevisionGeneradores
                        expanded={expanded}
                        tipo="editar"
                        mobile={mobile}
                        notificaciones={notificaciones}
                      />
                    }
                  />
                  <Route
                    path="Sander/:id"
                    element={
                      <RevisionSander
                        expanded={expanded}
                        tipo="editar"
                        mobile={mobile}
                        notificaciones={notificaciones}
                      />
                    }
                  />
                  <Route
                    path="Autoelevador/:id"
                    element={
                      <RevisionAutoelevador
                        expanded={expanded}
                        tipo="editar"
                        mobile={mobile}
                        notificaciones={notificaciones}
                      />
                    }
                  />
                  <Route
                    path="Mini Pala/:id"
                    element={
                      <RevisionMiniPala
                        expanded={expanded}
                        tipo="editar"
                        mobile={mobile}
                        notificaciones={notificaciones}
                      />
                    }
                  />
                  <Route
                    path="Luminaria/:id"
                    element={
                      <RevisionLuminaria
                        expanded={expanded}
                        tipo="editar"
                        mobile={mobile}
                        notificaciones={notificaciones}
                      />
                    }
                  />
                  <Route
                    path="SandVan/:id"
                    element={
                      <RevisionSandVan
                        expanded={expanded}
                        tipo="editar"
                        mobile={mobile}
                        notificaciones={notificaciones}
                      />
                    }
                  />
                  <Route
                    path="BaseSandvan/:id"
                    element={
                      <RevisionBaseSV
                        expanded={expanded}
                        tipo="editar"
                        mobile={mobile}
                        notificaciones={notificaciones}
                      />
                    }
                  />
                  <Route
                    path="Dosificadora/:id"
                    element={
                      <RevisionDosificadora
                        expanded={expanded}
                        tipo="editar"
                        mobile={mobile}
                        notificaciones={notificaciones}
                      />
                    }
                  />
                </Route>
                <Route
                  path=":padLocacion"
                  element={
                    <TablaRevision
                      expanded={expanded}
                      tipo="editar"
                      mobile={mobile}
                      notificaciones={notificaciones}
                    />
                  }
                />
                <Route
                  path="editar/:idReporte"
                  element={
                    <CrearEditarRevision type="editar" mobile={mobile} />
                  }
                />
              </Route>
            </Route>
            <Route
              path="historial"
              element={
                <Historial
                  expanded={expanded}
                  mobile={mobile}
                  filter={filter}
                />
              }
            />
          </Route>
          <Route path="/inventario">
            <Route path="autoelevadores">
              <Route path="" element={<Autoelevadores filter={filter} />} />
              <Route
                path="nuevo"
                element={<FormCrearEditarAutoelevador type={"crear"} />}
              />
              <Route
                path="editar/:id"
                element={<FormCrearEditarAutoelevador type={"editar"} />}
              />
            </Route>
            <Route path="clientes">
              <Route path="" element={<Cliente />} />
              <Route
                path="nuevo"
                element={<FormCrearEditarCliente type={"crear"} />}
              />
              <Route
                path="editar/:id"
                element={<FormCrearEditarCliente type={"editar"} />}
              />
            </Route>
            <Route path="transportista">
              <Route
                path=""
                element={
                  <EmpresaTransportista mobile={mobile} filter={filter} />
                }
              />
              <Route path="tablaCamiones/:nombreTransportista">
                <Route
                  path=""
                  element={
                    <TablaCamiones filter={filter} expanded={expanded} />
                  }
                />
                <Route
                  path="agregarCamiones"
                  element={<FormCrearCamiones filter={filter} />}
                />
                <Route path="editarCamion/:id" element={<FormEditarCamion />} />
              </Route>
              <Route
                path="nuevo"
                element={<FormCrearEditarTransportista type={"crear"} />}
              />
              <Route
                path="editar/:id"
                element={<FormCrearEditarTransportista type={"editar"} />}
              />
            </Route>
            <Route path="camiones" element={<Camiones filter={filter} />} />
            <Route path="locacion" element={<Locacion filter={filter} />} />
            <Route path="planta">
              <Route path="" element={<Planta filter={filter} />} />
              <Route
                path="nueva"
                element={<FormCrearEditarPlanta type={"crear"} />}
              />
              <Route
                path="editar/:id"
                element={<FormCrearEditarPlanta type={"editar"} />}
              />
            </Route>
            <Route path="sandcubes">
              <Route
                path=""
                element={
                  <Sandcubes
                    filter={filter}
                    filterBy={filterBy}
                    setFilterBy={setFilterBy}
                  />
                }
              />
              <Route
                path="nuevo"
                element={<FormCrearEditarSandcubes type={"crear"} />}
              />
              <Route
                path="editar/:id"
                element={<FormCrearEditarSandcubes type={"editar"} />}
              />
            </Route>
            <Route path="sander">
              <Route path="" element={<Sander filter={filter} />} />
              <Route
                path="nuevo"
                element={<FormCrearEditarSander type={"crear"} />}
              />
              <Route
                path="editar/:id"
                element={<FormCrearEditarSander type={"editar"} />}
              />
            </Route>
            <Route path="sandvan">
              <Route path="" element={<Sandvan filter={filter} />} />
              <Route
                path="nuevo"
                element={<FormCrearEditarSandvan type={"crear"} />}
              />
              <Route
                path="editar/:id"
                element={<FormCrearEditarSandvan type={"editar"} />}
              />
            </Route>
          </Route>
          {(user.idRol === "admin" || user.idRol === "coorOp") && (
            <Route path="/geocercas">
              <Route path="" element={<Geocercas />} />
            </Route>
          )}
          {user.idRol !== "usuarioIT" && (
            <Route path="/mapaCamiones" element={<MapaCamiones />} />
          )}
          {user.idRol !== "cliente" && (
            <Route path="/reportes">
              {/*  <Route
                path="certificacion"
                element={
                  <CertificacionCamiones mobile={mobile} filter={filter} />
                }
              /> */}
              <Route path="registro">
                <Route
                  path=""
                  element={
                    <TablaRegistros
                      expanded={expanded}
                      mobile={mobile}
                      filter={filter}
                    />
                  }
                />
                <Route
                  path="nuevo"
                  element={<CrearEditarRegistroFijo type="crear" />}
                />
              </Route>
              <Route
                path="reportetapas"
                element={<ReporteEtapas mobile={mobile} filter={filter} />}
              />
              <Route
                path="reporte"
                element={<ReporteViajes mobile={mobile} filter={filter} />}
              />
              <Route
                path="revision"
                element={
                  <RevisionTurnos
                    expanded={expanded}
                    mobile={mobile}
                    notificaciones={notificaciones}
                  />
                }
              />
            </Route>
          )}
        </Route>
      )}
    </Routes>
  );
};

export default Routing;
