import React from "react";
import {
  useToast,
  IconButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
} from "@chakra-ui/react";
import { MdOutlineGetApp } from "react-icons/md";
import { descargarRevisiones } from "../../../helpers/api.helper";
import moment from "moment";

const DescargaRevision = (props) => {
  const { planificacion, revisiones, type } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  const filtrarRevisionesPorEquipo = (revisiones, equipo) => {
    return revisiones.filter((revision) => revision.equipoRevision === equipo);
  };

  const handleDownload = async () => {
    try {
      let revisionesAutoelevador = filtrarRevisionesPorEquipo(
        revisiones,
        "Autoelevador"
      );
      let revisionesDosificadora = filtrarRevisionesPorEquipo(
        revisiones,
        "Dosificadora"
      );
      let revisionesGenerador = filtrarRevisionesPorEquipo(
        revisiones,
        "Generador"
      );
      let revisionesMiniPala = filtrarRevisionesPorEquipo(
        revisiones,
        "Mini Pala"
      );
      let revisionesSander = filtrarRevisionesPorEquipo(revisiones, "Sander");
      let revisionesSandVan = filtrarRevisionesPorEquipo(revisiones, "SandVan");
      let revisionesLuminaria = filtrarRevisionesPorEquipo(
        revisiones,
        "Luminaria"
      );
      let revisionesBaseSandvan = filtrarRevisionesPorEquipo(
        revisiones,
        "BaseSandvan"
      );

      let response;
      if (type === "historial") {
        response = await descargarRevisiones({
          revisionesSander,
          revisionesAutoelevador,
          revisionesDosificadora,
          revisionesGenerador,
          revisionesMiniPala,
          revisionesSandVan,
          revisionesLuminaria,
          revisionesBaseSandvan,
        });
      } else {
        response = await descargarRevisiones({
          revisionesSander,
          revisionesAutoelevador,
          revisionesDosificadora,
          revisionesGenerador,
          revisionesMiniPala,
          revisionesSandVan,
          revisionesLuminaria,
          revisionesBaseSandvan,
          id: planificacion.idPlanificacion,
        });
      }
      if (response.status === 200) {
        const url = window.URL.createObjectURL(
          new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
          })
        );
        const link = document.createElement("a");
        link.href = url;
        if (planificacion.idPadlocacion) {
          link.setAttribute(
            "download",
            `Revision-entre-turno-${
              planificacion.idPadlocacion
            }-${moment().format("DD/MM/YYYY")}.xlsx`
          );
        } else {
          link.setAttribute("download", `Revision-entre-turno-general.xlsx`);
        }

        document.body.appendChild(link);
        link.click();
      } else {
        throw new Error("Error al descargar");
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        toast({
          title: "Error al descargar",
          description: "Hay superposición entre fechas",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      } else {
        toast({
          title: "Error",
          description: "Error del servidor",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    }
  };

  const handleResponsable = (event) => {
    let item = event;

    return item.nombre;
  };

  return (
    <>
      <IconButton
        size="sm"
        variant="outline"
        border="1px solid #FF8200"
        icon={<MdOutlineGetApp fontSize="20px" />}
        color="brand.naranja"
        _hover={{ bg: "brand.naranja", color: "white" }}
        onClick={onOpen}
      />
      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Pre visualizacion - Descarga</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <TableContainer w={"xl"} h="sm" overflowY="auto">
              <Table variant="simple" size="sm">
                <Thead>
                  <Tr>
                    <Th>Fecha y Hora</Th>
                    <Th>Valoración</Th>
                    <Th>Responsable</Th>
                  </Tr>
                </Thead>
                <Tbody textAlign="center">
                  {revisiones.map((revision, index) => (
                    <Tr key={index}>
                      <Td>
                        {moment
                          .utc(revision.fechaRevision)
                          .format("DD-MM-YYYY HH:mm")}
                      </Td>
                      <Td textTransform="uppercase">
                        {revision.equipoRevision}
                      </Td>
                      <Td>{handleResponsable(revision)}</Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          </ModalBody>

          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={onClose}>
              Cerrar
            </Button>
            <Button
              bg="brand.naranja"
              color="white"
              onClick={() => handleDownload()}
            >
              Descargar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default DescargaRevision;
