import React, { useState, useEffect } from "react";
import {
  Box,
  VStack,
  HStack,
  Text,
  Heading,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  FormControl,
  FormLabel,
  Divider,
  IconButton,
  CircularProgress,
  Stack,
  useToast,
  Input,
  Radio,
  RadioGroup,
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  List,
  ListItem,
  Tooltip,
} from "@chakra-ui/react";
import { ArrowUpIcon, ArrowDownIcon } from "@chakra-ui/icons";
import { sortData } from "../../../../../utils/sortData";
import {
  MdArrowBack,
  MdFilterList,
  MdRefresh,
  MdOutlineImportExport,
  MdAddCircle,
} from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import { getEtapas, getPlanificaciones } from "../../../helpers/api.helper";
import EliminarEtapa from "./EliminarEtapa";
import EditarEtapa from "./EditarEtapa";
import DescargaEtapas from "./DescargaEtapas";
import { useSelector } from "react-redux";
import moment from "moment";
import { regionalFormattingNumbers } from "../../../../../utils/regionalFormattingNumbers";

const TablaEtapas = (props) => {
  const navigate = useNavigate();
  const [etapas, setEtapas] = useState([]);
  const [planificacion, setPlanificacion] = useState([]);
  const [loading, setLoading] = useState(false);
  const [seleccionado, setSeleccionado] = useState();
  const [sortField, setSortField] = useState();
  const [sortDirection, setSortDirection] = useState();
  const [filterBy, setFilterBy] = useState({
    pozo: "",
    inicio: "",
    fin: "",
  });
  const { padLocacion } = useParams();
  const toast = useToast();
  const { user } = useSelector((state) => state.user);
  const limpieza = false;

  const initData = async () => {
    setLoading(true);
    let res;
    res = await getEtapas({ padLocacion });

    if (res.status === 200) {
      setEtapas(res.data);
      toast({
        status: "success",
        isClosable: true,
        title: "Etapas cargadas correctamente",
        duration: 3000,
      });
    } else if (res.status === 204) {
      toast({
        status: "info",
        isClosable: true,
        title: "No hay etapas cargadas",
        duration: 3000,
      });
    } else {
      toast({
        status: "error",
        isClosable: true,
        title: "Error al cargar etapas",
        duration: 3000,
      });
    }

    res = await getPlanificaciones({ padLocacion });

    if (res.status === 200) {
      setPlanificacion(res.data[0]);
    } else {
      toast({
        status: "error",
        isClosable: true,
        title: "Error al cargar planificacion",
        duration: 3000,
      });
    }
    setLoading(false);
  };

  const loadingtable = () => {
    return (
      <HStack w="100%" placeContent="center">
        <CircularProgress isIndeterminate color="brand.naranja" />
      </HStack>
    );
  };

  const showEtapas = () => {
    if (Object.values(filterBy).every((value) => !value)) {
      return etapas;
    }
    return etapas.filter((etapa) => {
      const { pozo, inicio, fin } = filterBy;
      return (
        (!pozo || etapa.pozo.nombrePozo === pozo) &&
        (!inicio || etapa.fechaInicio >= inicio) &&
        (!fin || etapa.fechaFinal <= fin)
      );
    });
  };

  const nombresPozoUnicos = etapas.reduce((nombresUnicos, etapa) => {
    if (!nombresUnicos.includes(etapa.pozo.nombrePozo)) {
      nombresUnicos.push(etapa.pozo.nombrePozo);
    }
    return nombresUnicos;
  }, []);

  const cleanFilter = () => {
    setFilterBy({
      pozo: "",
      inicio: "",
      fin: "",
    });
  };

  const handleTnsNominales = (seleccionado) => {
    let sc = seleccionado.sandCubes ? seleccionado.sandCubes.length : 0;
    let cantidad = planificacion.mallas[0].tnsArenaSc;

    return (sc * cantidad).toFixed(2);
  };

  useEffect(() => {
    initData();
  }, []);

  return (
    <Stack w="100%" h={"lg"}>
      <HStack
        w="100%"
        bg="brand.fondos_secundario"
        justifyContent="space-between"
      >
        <HStack pl={5} fontWeight="medium">
          <Box>
            <IconButton
              icon={<MdArrowBack />}
              variant="link"
              onClick={() => navigate(-1)}
              fontSize="24px"
            />
          </Box>
          <Heading
            fontWeight="medium"
            size="xl"
            fontFamily="fonts.title"
            color="brand.gris_primario"
          >
            Consulta de etapas
          </Heading>
          <Text fontSize="16px" placeSelf="end" letterSpacing={1}>
            en {padLocacion}
          </Text>
        </HStack>
        <HStack>
          {(user.idRol === "admin" ||
            user.idRol === "userOperaciones" ||
            user.idRol === "coorOp") && (
            <Button
              color={"brand.naranja"}
              variant={"outline"}
              size={"sm"}
              border={"1px solid brand.naranja"}
              _hover={{ color: "white", bg: "brand.naranja" }}
              onClick={() => {
                navigate(`/operaciones/curso/etapas/nueva/${padLocacion}`);
              }}
              leftIcon={<MdAddCircle />}
            >
              Agregar cédula
            </Button>
          )}
          <Box px={3}>
            {(user.idRol !== "usuarioBrent" ||
              user.idRol !== "operaciones") && (
              <DescargaEtapas etapas={etapas} planificacion={planificacion} />
            )}
            <Popover isLazy>
              <PopoverTrigger>
                <IconButton
                  icon={<MdFilterList fontSize="20px" />}
                  bg={"brand.fondos_secundarios"}
                  borderRadius="50%"
                  color={"black"}
                />
              </PopoverTrigger>
              <PopoverContent>
                <PopoverArrow />
                <PopoverHeader>
                  <Tooltip label="Limpiar filtro">
                    <IconButton
                      icon={<MdRefresh />}
                      onClick={cleanFilter}
                      variant="link"
                      justifySelf={"end"}
                    />
                  </Tooltip>
                </PopoverHeader>
                <PopoverBody maxH={"sm"} overflowY={"auto"}>
                  <Accordion allowToggle>
                    <AccordionItem>
                      <AccordionButton fontWeight={"bold"}>
                        Por Set
                      </AccordionButton>
                      <AccordionPanel>
                        <List spacing={2} cursor={"pointer"}>
                          {nombresPozoUnicos.map((e) => (
                            <ListItem
                              key={e}
                              onClick={() => {
                                setFilterBy((prevstate) => ({
                                  ...prevstate,
                                  pozo: e,
                                }));
                                showEtapas();
                              }}
                            >
                              {e}
                            </ListItem>
                          ))}
                        </List>
                      </AccordionPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionButton fontWeight={"bold"}>
                        <HStack>
                          <Text fontWeight={"bold"}>Fecha inicio</Text>
                          <Input
                            type="date"
                            size="sm"
                            w={150}
                            onChange={(e) => {
                              setFilterBy((prevstate) => ({
                                ...prevstate,
                                inicio: e.target.value,
                              }));
                              showEtapas();
                            }}
                            value={filterBy.inicio}
                          />
                        </HStack>
                      </AccordionButton>
                    </AccordionItem>
                  </Accordion>
                </PopoverBody>
              </PopoverContent>
            </Popover>
          </Box>
        </HStack>
      </HStack>

      <HStack
        w="100%"
        h={["lg", "xl", "2xl"]}
        px={3}
        bg="brand.fondos_secundario"
        borderRadius={5}
        justifyContent={"space-between"}
      >
        <Stack placeSelf="self-start" w={"100%"} h="100%">
          {loading && loadingtable()}
          {!loading && etapas.length >= 1 && (
            <TableContainer overflowY="auto">
              <Table variant="simple" size="sm">
                <Thead
                  style={{
                    position: "sticky",
                    top: 0,
                    zIndex: 1,
                    backgroundColor: "#F3F6F9",
                    opacity: 1,
                  }}
                >
                  <Tr>
                    <Th></Th>
                    <Th
                      _hover={{ cursor: "pointer" }}
                      onMouseEnter={() =>
                        (document.querySelector(".hover-icon").style.display =
                          "inline-block")
                      }
                      onMouseLeave={() =>
                        (document.querySelector(".hover-icon").style.display =
                          "none")
                      }
                    >
                      <span
                        className="sortable"
                        onClick={() => {
                          const { sortedData, direction } = sortData(
                            "etapasTotalPad",
                            etapas
                          );
                          setSortField("etapas");
                          setSortDirection(direction);
                          setEtapas(sortedData);
                        }}
                      >
                        Nro Etapa <br /> (total)
                        {sortDirection === "asc" && sortField === "etapas" && (
                          <ArrowUpIcon />
                        )}
                        {sortDirection === "des" && sortField === "etapas" && (
                          <ArrowDownIcon />
                        )}
                      </span>
                      <span className="hover-icon" style={{ display: "none" }}>
                        <MdOutlineImportExport />
                      </span>
                    </Th>
                    <Th
                      _hover={{ cursor: "pointer" }}
                      onMouseEnter={() =>
                        (document.querySelector(".hover-icon1").style.display =
                          "inline-block")
                      }
                      onMouseLeave={() =>
                        (document.querySelector(".hover-icon1").style.display =
                          "none")
                      }
                    >
                      <span
                        className="sortable"
                        onClick={() => {
                          const { sortedData, direction } = sortData(
                            "idPadlocacion",
                            etapas
                          );
                          setSortField("idPadlocacion");
                          setSortDirection(direction);
                          setEtapas(sortedData);
                        }}
                      >
                        Nro Etapa <br /> (pozo)
                        {sortDirection === "asc" &&
                          sortField === "idPadlocacion" && <ArrowUpIcon />}
                        {sortDirection === "des" &&
                          sortField === "idPadlocacion" && <ArrowDownIcon />}
                      </span>
                      <span className="hover-icon1" style={{ display: "none" }}>
                        <MdOutlineImportExport />
                      </span>
                    </Th>
                    <Th>
                      <span className="sortable">
                        ID <br /> Pozo
                      </span>
                    </Th>
                    <Th>Wetsand</Th>
                    <Th>Fecha</Th>
                    <Th
                      _hover={{ cursor: "pointer" }}
                      onMouseEnter={() =>
                        (document.querySelector(".hover-icon2").style.display =
                          "inline-block")
                      }
                      onMouseLeave={() =>
                        (document.querySelector(".hover-icon2").style.display =
                          "none")
                      }
                    >
                      <span
                        className="sortable"
                        onClick={() => {
                          const { sortedData, direction } = sortData(
                            "idSET",
                            etapas
                          );
                          setSortField("etapas");
                          setSortDirection(direction);
                          setEtapas(sortedData);
                        }}
                      >
                        Cant. <br /> SCs.
                        {sortDirection === "asc" && sortField === "idSET" && (
                          <ArrowUpIcon />
                        )}
                        {sortDirection === "des" && sortField === "idSET" && (
                          <ArrowDownIcon />
                        )}
                      </span>
                      <span className="hover-icon2" style={{ display: "none" }}>
                        <MdOutlineImportExport />
                      </span>
                    </Th>

                    <Th
                      style={{ textAlign: "right" }}
                      _hover={{ cursor: "pointer" }}
                      onMouseEnter={() =>
                        (document.querySelector(".hover-icon3").style.display =
                          "inline-block")
                      }
                      onMouseLeave={() =>
                        (document.querySelector(".hover-icon3").style.display =
                          "none")
                      }
                    >
                      <span
                        className="sortable"
                        onClick={() => {
                          const { sortedData, direction } = sortData(
                            "estado",
                            etapas
                          );
                          setSortField("estado");
                          setSortDirection(direction);
                          setEtapas(sortedData);
                        }}
                      >
                        Tns.
                        {sortDirection === "asc" && sortField === "estado" && (
                          <ArrowUpIcon />
                        )}
                        {sortDirection === "des" && sortField === "estado" && (
                          <ArrowDownIcon />
                        )}
                      </span>
                      <span className="hover-icon3" style={{ display: "none" }}>
                        <MdOutlineImportExport />
                      </span>
                    </Th>
                    {(user.idRol === "admin" ||
                      user.idRol === "coorOp" ||
                      user?.puesto === "supervisor") && <Th>Acciones</Th>}
                  </Tr>
                </Thead>

                <Tbody textAlign="center">
                  {showEtapas().map((etapa) => (
                    <Tr
                      key={etapa.idEtapaSC}
                      onClick={() => setSeleccionado(etapa)}
                    >
                      <Td>
                        {" "}
                        <RadioGroup value={seleccionado?.idEtapaSC}>
                          <Radio
                            value={etapa.idEtapaSC}
                            border="1px solid black"
                          />
                        </RadioGroup>
                      </Td>
                      <Td>{etapa.numTotalEtapaSC}</Td>
                      <Td>{etapa.numPozoEtapaSC}</Td>
                      <Td>{etapa.pozo.nombrePozo}</Td>
                      <Td>{etapa.etapaWeTSanD === true ? "SI" : "NO"}</Td>
                      <Td>
                        {moment(etapa.fechaFinalEtapaSC).format("DD-MM-YYYY")}
                      </Td>
                      <Td>{etapa.sandCubes ? etapa.sandCubes.length : 0}</Td>
                      <Td style={{ textAlign: "right" }}>
                        {etapa.sandCubes
                          ? regionalFormattingNumbers({
                              number: etapa.sandCubes.length * 13.5,
                              digits: 2,
                            })
                          : 0}
                      </Td>
                      <Td>
                        {(user.idRol === "admin" ||
                          user.idRol === "coorOp" ||
                          user?.puesto === "supervisor") && (
                          <>
                            <EditarEtapa
                              seleccionado={etapa}
                              initData={initData}
                            />
                            <EliminarEtapa
                              seleccionado={etapa}
                              initData={initData}
                            />
                          </>
                        )}
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          )}
          {!loading && etapas.length < 1 && (
            <Heading textAlign={"center"} size={"md"}>
              No hay datos para mostrar
            </Heading>
          )}
        </Stack>
        {seleccionado && (
          <Card
            bg="brand.layout"
            border="none"
            w={"40%"}
            h="100%"
            boxShadow="none"
            pr={2}
          >
            <CardHeader pb="unset" color="brand.azul_text">
              <HStack>
                <Text fontWeight="semibold" fontSize="24px">
                  Detalle Etapa {seleccionado.numTotalEtapaSC}
                </Text>{" "}
                <Text placeSelf="end">({padLocacion})</Text>
              </HStack>
            </CardHeader>
            <Divider pt={1} color="#EBEAEA" />
            <CardBody overflow="auto">
              <HStack py={1}>
                <FormControl alignSelf="start">
                  <FormLabel fontWeight="semibold" placeSelf="start">
                    ID Pozo
                  </FormLabel>
                  <Text fontSize="sm">{seleccionado.pozo.nombrePozo}</Text>
                </FormControl>
              </HStack>
              <Divider />
              <HStack py={1}>
                <FormControl>
                  <FormLabel fontWeight="semibold">Nro. Etapa (pozo)</FormLabel>
                  <Text fontSize="sm">{seleccionado.numPozoEtapaSC}</Text>
                </FormControl>
                <FormControl alignSelf="start">
                  <FormLabel fontWeight="semibold" placeSelf="start">
                    Nro. Etapa Total
                  </FormLabel>
                  <Text fontSize="sm">{seleccionado.numTotalEtapaSC}</Text>
                </FormControl>
              </HStack>
              <Divider />
              <HStack py={1}>
                <FormControl>
                  <FormLabel fontWeight="semibold">
                    Fecha y Hora Inicio
                  </FormLabel>
                  <Text fontSize="sm">
                    {moment
                      .utc(seleccionado.fechaInicioEtapaSC)
                      .format("DD-MM-YYYY HH:mm")}
                  </Text>
                </FormControl>
                <FormControl alignSelf="start">
                  <FormLabel fontWeight="semibold" placeSelf="start">
                    Fecha y Hora Final
                  </FormLabel>
                  <Text fontSize="sm">
                    {moment
                      .utc(seleccionado.fechaFinalEtapaSC)
                      .format("DD-MM-YYYY HH:mm")}
                  </Text>
                </FormControl>
              </HStack>
              <Divider />
              <HStack py={1}>
                <FormControl>
                  <FormLabel fontWeight="semibold">Supervisor</FormLabel>
                  <Text fontSize="sm">{seleccionado.supervisor}</Text>
                </FormControl>
                <FormControl alignSelf="start">
                  <FormLabel fontWeight="semibold" placeSelf="start">
                    Maquinista
                  </FormLabel>
                  <Text fontSize="sm">{seleccionado.maquinista}</Text>
                </FormControl>
              </HStack>
              <Divider />
              <HStack py={1}>
                <FormControl>
                  <FormLabel fontWeight="semibold">Operador 1</FormLabel>
                  <Text fontSize="sm">{seleccionado.operador1}</Text>
                </FormControl>
                <FormControl alignSelf="start">
                  <FormLabel fontWeight="semibold" placeSelf="start">
                    Operador 2
                  </FormLabel>
                  <Text fontSize="sm">{seleccionado.operador2}</Text>
                </FormControl>
              </HStack>
              <Divider />
              <HStack py={1}>
                <FormControl>
                  <FormLabel fontWeight="semibold">Sander</FormLabel>
                  <Text fontSize="sm">{seleccionado.sander.codigoSander}</Text>
                </FormControl>
                <FormControl alignSelf="start">
                  <FormLabel fontWeight="semibold" placeSelf="start">
                    Autoelevador
                  </FormLabel>
                  <Text fontSize="sm">
                    {seleccionado.autoelevador.nombreAutoE}
                  </Text>
                </FormControl>
              </HStack>
              <Divider />
              <HStack py={1}>
                <FormControl>
                  <FormLabel fontWeight="semibold">Cantidad de SCs</FormLabel>
                  <Text fontSize="sm">
                    {seleccionado.sandCubes ? seleccionado.sandCubes.length : 0}
                  </Text>
                </FormControl>
                <FormControl alignSelf="start">
                  <FormLabel fontWeight="semibold" placeSelf="start">
                    Toneladas (nominales)
                  </FormLabel>
                  <Text fontSize="sm">{handleTnsNominales(seleccionado)}</Text>
                </FormControl>
              </HStack>
              <Divider />
              <HStack py={1}>
                <FormControl alignSelf="start">
                  <FormLabel fontWeight="semibold" placeSelf="start">
                    ID Sandcube
                  </FormLabel>
                  {seleccionado.sandCubes &&
                    seleccionado.sandCubes.map((sandcube) => (
                      <Text key={sandcube.idSandCube} fontSize="sm">
                        {sandcube.nombreSandCube}{" "}
                        {sandcube.wetsandSC === true && "- W"}
                      </Text>
                    ))}
                </FormControl>
                <FormControl alignSelf="start">
                  <FormLabel fontWeight="semibold" placeSelf="start">
                    Malla
                  </FormLabel>
                  {seleccionado.sandCubes &&
                    seleccionado.sandCubes.map((sandcube) => (
                      <Text key={sandcube.idSandCube} fontSize="sm">
                        {sandcube.malla || "-"}
                      </Text>
                    ))}
                </FormControl>
                <FormControl alignSelf="start">
                  <FormLabel fontWeight="semibold" placeSelf="start">
                    Planta
                  </FormLabel>
                  {seleccionado.sandCubes &&
                    seleccionado.sandCubes.map((sandcube) => (
                      <Text key={sandcube.idSandCube} fontSize="sm">
                        {sandcube.nombrePlanta !== "Sin planta"
                          ? sandcube.nombrePlanta
                          : "-"}
                      </Text>
                    ))}
                </FormControl>
              </HStack>
            </CardBody>
          </Card>
        )}
      </HStack>
    </Stack>
  );
};

export default TablaEtapas;
