import {
  Stack,
  HStack,
  FormControl,
  FormLabel,
  Input,
  Heading,
  IconButton,
  Button,
  useToast,
  Switch,
  InputGroup,
  InputLeftAddon,
} from "@chakra-ui/react";
import { Formik, Form } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { MdArrowBack, MdFileDownloadDone } from "react-icons/md";
import {
  getAutoelevadores,
  editarAutoelevadores,
  crearAutoelevadores,
} from "./../../helpers/api.helper";

const FormCrearEditarAutoelevador = (props) => {
  const { type } = props;
  const [loading, setLoading] = useState(false);
  const [autoelevador, setAutoelevador] = useState([]);
  const navigate = useNavigate();
  const id = useParams();
  const toast = useToast();

  const initData = async () => {
    setLoading(true);
    let res = await getAutoelevadores(id);

    if (res.status === 200) {
      setAutoelevador({
        nombreAutoE: res.data[0].nombreAutoE.substring(19),
        estadoAutoE: res.data[0].estadoAutoE,
        idAutoE: res.data[0].idAutoE,
      });
    } else {
      toast({
        title: "Error",
        description: "Error al cargar los datos",
        isClosable: true,
        duration: 3000,
      });
    }

    setLoading(false);
  };

  let initialValues =
    type === "editar"
      ? {
          nombreAutoE: autoelevador.nombreAutoE,
          estadoAutoE: autoelevador.estadoAutoE === "activo" ? true : false,
        }
      : {
          nombreAutoE: "",
          estadoAutoE: false,
        };

  useEffect(() => {
    initData();
  }, []);

  return (
    <Stack bg="brand.fondo" w="100%" h="md" borderRadius={10} spacing={7}>
      <HStack w="100%" pl={5}>
        <IconButton
          icon={<MdArrowBack />}
          variant="link"
          onClick={() => navigate(-1)}
          fontSize="24px"
        />

        <Heading fontWeight="medium" py={3}>
          {type === "editar" ? "Editar" : "Nuevo"} Autoelevador
        </Heading>
      </HStack>
      {!loading && (
        <Formik
          initialValues={initialValues}
          onSubmit={async (values) => {
            setLoading(true);
            if (!values.nombreAutoE) {
              setLoading(false);
              return toast({
                title: "Error",
                description: "Nombre del cliente es requerido",
                status: "error",
                isClosable: true,
                duration: 3000,
              });
            } else if (!/^\d+$/.test(values.nombreAutoE)) {
              setLoading(false);
              return toast({
                title: "Error",
                description: "Ingrese solo números",
                status: "error",
                isClosable: true,
                duration: 3000,
              });
            }

            let res;

            if (type === "editar") {
              res = await editarAutoelevadores({
                nombreAutoE: `AUTOELEVADOR KALMAR${values.nombreAutoE}`,
                estadoAutoE:
                  values.estadoAutoE === true ? "activo" : "inactivo",
                id: autoelevador.idAutoE,
              });
            }
            if (type === "crear") {
              res = await crearAutoelevadores({
                nombreAutoE: `AUTOELEVADOR KALMAR${values.nombreAutoE}`,
                estadoAutoE:
                  values.estadoAutoE === true ? "activo" : "inactivo",
              });
            }

            if (res.status === 200) {
              toast({
                status: "success",
                isClosable: true,
                title: `Autoelevador ${
                  type === "crear" ? "creado" : "editado"
                } correctamente`,
                duration: 3000,
              });
              navigate(-1);
            } else {
              return toast({
                status: "error",
                isClosable: true,
                title: `Error al ${
                  type === "crear" ? "crear" : "editar"
                } autoelevador`,
                description:
                  res.data.error.message || "Error al editar autoelevador",
              });
            }

            setLoading(false);
          }}
        >
          {({ values, handleChange, handleBlur, handleSubmit }) => (
            <Form id="formAutoelevador">
              <Stack w="100%" spacing={10} px={7}>
                <HStack w="100%" spacing={10}>
                  <FormControl w={"xs"}>
                    <FormLabel fontWeight="bold">Nombre</FormLabel>
                    <InputGroup w={"xs"}>
                      <InputLeftAddon children="AUTOELEVADOR KALMAR" />
                      <Input
                        name="nombreAutoE"
                        type="text"
                        maxLength={3}
                        placeholder="Codigo"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.nombreAutoE}
                        isRequired
                      />
                    </InputGroup>
                  </FormControl>
                  <FormControl w={"xs"}>
                    <FormLabel fontWeight="bold">Estado</FormLabel>
                    <Switch
                      colorScheme="green"
                      name="estadoAutoE"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isChecked={values.estadoAutoE}
                    />
                  </FormControl>
                </HStack>
                <Button
                  placeSelf="end"
                  rightIcon={<MdFileDownloadDone />}
                  bg="brand.naranja"
                  form="formAutoelevador"
                  color="white"
                  isLoading={loading}
                  size="md"
                  onClick={handleSubmit}
                >
                  Finalizar
                </Button>
              </Stack>
            </Form>
          )}
        </Formik>
      )}
    </Stack>
  );
};

export default FormCrearEditarAutoelevador;
