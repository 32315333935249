import React from "react";
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  Text,
  PopoverBody,
  Divider,
  Link,
  HStack,
  Stack,
  IconButton,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  MdNotificationImportant,
  MdKeyboardArrowDown,
  MdKeyboardArrowUp,
} from "react-icons/md";
import LogOut from "../../auth/Logout";
import { useState } from "react";

const Usuario = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(true);
  const { user } = useSelector((state) => state.user);

  return (
    <Popover placement="bottom-end">
      <PopoverTrigger>
        {open ? (
          <IconButton
            icon={<MdKeyboardArrowDown />}
            variant={"link"}
            onClick={() => setOpen(false)}
            name="configuraciones"
          />
        ) : (
          <IconButton
            icon={<MdKeyboardArrowUp />}
            variant={"link"}
            onClick={() => setOpen(true)}
            name="configuraciones"
          />
        )}
      </PopoverTrigger>

      <PopoverContent bg={"#818181"} borderRadius="10px" top={8}>
        <PopoverArrow bg="#818181" />
        <PopoverBody>
          <Stack w={"100%"} placeContent={"start"}>
            <Text
              color="brand.fondos_primarios"
              fontWeight="semibold"
              textTransform="uppercase"
              py={1}
            >
              {user.nombre}
            </Text>
            <Text color="brand.fondos_primarios">{user.email}</Text>
            {(user.idRol === "admin" || user.idRol === "coorOp") && (
              <>
                <Divider w={"100%"} />
                <HStack py={1} color="white">
                  <MdNotificationImportant />
                  <Link
                    onClick={() => navigate("/administrarNotificaciones")}
                    textColor="white"
                    fontSize="14px"
                  >
                    Administrador de notificaciones
                  </Link>
                </HStack>
              </>
            )}
            <Divider w={"100%"} />
            <LogOut />
          </Stack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default Usuario;
