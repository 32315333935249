import React, { useEffect, useState } from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Stack,
  HStack,
  IconButton,
  Heading,
  useToast,
  Input,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  Accordion,
  AccordionItem,
  Tooltip,
  CircularProgress,
} from "@chakra-ui/react";
import { getCamiones } from "../operaciones/helpers/api.helper";
import { MdFilterList, MdRefresh } from "react-icons/md";
import { currencyFormattingNumbers } from "../../utils/currencyFormattingNumbers";

const Camiones = () => {
  const [camiones, setCamiones] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filterBy, setFilterBy] = useState({
    nombreEmpresaT: "",
    idRSV: "",
    dominio: "",
  });
  const toast = useToast();

  const initData = async () => {
    setLoading(true);
    let res = await getCamiones();
    if (res.status === 200) {
      setCamiones(res.data);
    } else if (res.status === 404) {
      toast({
        title: "Exito",
        description: "No existen camiones para esta empresa",
        duration: 3000,
        isClosable: true,
        status: "info",
      });
    } else {
      toast({
        title: "Error",
        description: "Error al cargar los camiones",
        duration: 3000,
        isClosable: true,
        status: "error",
      });
    }
    setLoading(false);
  };

  const loadingtable = () => {
    return (
      <HStack w="100%" placeContent="center">
        <CircularProgress isIndeterminate color="brand.naranja" />
      </HStack>
    );
  };

  const showFiltrados = () => {
    if (Object.values(filterBy).every((value) => !value)) {
      return camiones;
    }
    return camiones.filter((camion) => {
      const { nombreEmpresaT, nombreCamion, dominio } = filterBy;

      return (
        (!nombreEmpresaT ||
          camion.empresaTransportista.nombreEmpresaT
            .toLowerCase()
            .includes(nombreEmpresaT.toLowerCase())) &&
        (!nombreCamion ||
          camion.nombreCamion
            ?.toLowerCase()
            .includes(nombreCamion.toLowerCase())) &&
        (!dominio ||
          camion.dominio.toLowerCase().includes(dominio.toLowerCase()))
      );
    });
  };
  const cleanFilter = () => {
    setFilterBy({
      nombreEmpresaT: "",
      idRSV: "",
      dominio: "",
    });
  };

  useEffect(() => {
    initData();
  }, []);

  return (
    <Stack w="100%" h="xl">
      <HStack w="100%" justifyContent="space-between" px={5}>
        <Heading
          fontWeight="medium"
          size="xl"
          fontFamily="fonts.title"
          color="brand.gris_primario"
          pl={4}
        >
          Camiones
        </Heading>
        <Popover isLazy>
          <PopoverTrigger>
            <IconButton
              icon={<MdFilterList fontSize="20px" />}
              bg={"brand.fondos_secundarios"}
              borderRadius="50%"
              color={"black"}
            />
          </PopoverTrigger>
          <PopoverContent>
            <PopoverArrow />
            <PopoverHeader>
              <Tooltip label="Limpiar filtro">
                <IconButton
                  icon={<MdRefresh />}
                  onClick={cleanFilter}
                  variant="link"
                  justifySelf={"end"}
                />
              </Tooltip>
            </PopoverHeader>
            <PopoverBody maxH={"sm"} overflowY={"auto"}>
              <Accordion allowToggle>
                <AccordionItem py={1}>
                  <Input
                    placeholder="Por empresa"
                    variant={"filled"}
                    size="sm"
                    w="2xs"
                    onChange={(e) => {
                      setFilterBy((prevstate) => ({
                        ...prevstate,
                        nombreEmpresaT: e.target.value,
                      }));
                      showFiltrados();
                    }}
                    value={filterBy.nombreCliente}
                  />
                </AccordionItem>
                <AccordionItem py={1}>
                  <Input
                    placeholder="Por dominio"
                    variant={"filled"}
                    size="sm"
                    w="2xs"
                    onChange={(e) => {
                      setFilterBy((prevstate) => ({
                        ...prevstate,
                        dominio: e.target.value,
                      }));
                      showFiltrados();
                    }}
                    value={filterBy.razonSocialCliente}
                  />
                </AccordionItem>
                <AccordionItem py={1}>
                  <Input
                    placeholder="Por interno"
                    variant={"filled"}
                    size="sm"
                    w="2xs"
                    onChange={(e) => {
                      setFilterBy((prevstate) => ({
                        ...prevstate,
                        nombreCamion: e.target.value,
                      }));
                      showFiltrados();
                    }}
                    value={filterBy.razonSocialCliente}
                  />
                </AccordionItem>
              </Accordion>
            </PopoverBody>
          </PopoverContent>
        </Popover>
      </HStack>
      {loading && loadingtable()}
      {!loading && camiones.length > 0 && (
        <TableContainer w="100%" maxH={"100%"} overflowY="auto">
          <Table size={"sm"}>
            <Thead>
              <Tr
                borderBottom="2px solid black"
                style={{
                  position: "sticky",
                  top: 0,
                  zIndex: 1,
                  backgroundColor: "#F3F6F9",
                  opacity: 1,
                }}
              >
                <Th>Empresa</Th>
                <Th>ID Camion</Th>
                <Th>ID Externo</Th>
                <Th>Dominio</Th>
                <Th>Tipo contrato</Th>
                <Th textAlign={"right"}>Costo completo</Th>
                <Th textAlign={"right"}>Costo Mensualizado</Th>
                <Th>Ubicacion</Th>
              </Tr>
            </Thead>
            <Tbody>
              {showFiltrados().map((camion) => (
                <Tr key={camion.idCamion}>
                  <Td>{camion.empresaTransportista.nombreEmpresaT}</Td>
                  <Td>{camion.nombreCamion}</Td>
                  <Td>{camion.idRSV}</Td>
                  <Td>{camion.dominio}</Td>
                  <Td>{camion.tipoContratoCamion}</Td>
                  <Td textAlign={"right"}>
                    {currencyFormattingNumbers(
                      camion.empresaTransportista.costoCompleto
                    )}
                  </Td>
                  <Td textAlign={"right"}>
                    {currencyFormattingNumbers(
                      camion.empresaTransportista.costoPenalizado
                    )}
                  </Td>
                  <Td>{camion.ubicacionCamion}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      )}
      {!loading && camiones.length < 1 && (
        <Heading textAlign={"center"} size={"md"}>
          No hay datos para mostrar
        </Heading>
      )}
    </Stack>
  );
};

export default Camiones;
