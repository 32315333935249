import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import {
  MdInsertInvitation,
  MdOutlineAirportShuttle,
  MdOutlineTimer,
} from "react-icons/md";
import {
  Heading,
  VStack,
  HStack,
  Card,
  CardHeader,
  CardBody,
  Text,
  Divider,
  Icon,
  Badge,
  Box,
  Progress,
} from "@chakra-ui/react";
import { ReactComponent as Sandcube } from "../../../../assets/iconos/Sand.svg";
import moment from "moment";
import { regionalFormattingNumbers } from "../../../../utils/regionalFormattingNumbers";

const OperacionEnCurso = (props) => {
  const { operacion, x, y, repo } = props;
  let llenosPozo = 0;
  let vaciosPozo = 0;
  let transitoPozo = 0;
  let today = moment();

  const fusionarEtapasConCantidad = () => {
    const fechasGeneradas = [];
    const cantidadesPorFecha = {};
    const fechaActual = moment();
    let fechaActualTemp = moment(operacion.fechaInicio);

    if (operacion.etapasSC) {
      operacion.etapasSC
        .filter((e) => e.eliminado === false)
        .forEach((etapa) => {
          const fechaFinalEtapaSC = moment(etapa.fechaFinalEtapaSC);

          if (fechaFinalEtapaSC.isSameOrAfter(fechaActualTemp, "day")) {
            const fechaFinalEtapaSCStr = fechaFinalEtapaSC.format("DD-MM");

            if (!cantidadesPorFecha[fechaFinalEtapaSCStr]) {
              cantidadesPorFecha[fechaFinalEtapaSCStr] = 0;
            }

            cantidadesPorFecha[fechaFinalEtapaSCStr] += 1;
          }
        });
    }

    while (fechaActualTemp.isSameOrBefore(fechaActual, "day")) {
      const diaMes = {
        dia: fechaActualTemp.date(),
        mes: fechaActualTemp.month() + 1,
        fecha: fechaActualTemp.toDate(),
      };

      const fecha = fechaActualTemp.format("DD-MM");
      const fechaII = fechaActualTemp.format("DD-MM");
      const cantidad = cantidadesPorFecha[fecha] || 0;

      fechasGeneradas.push({ ...diaMes, fechaII, cantidad });

      fechaActualTemp.add(1, "day");
    }

    let a = fechasGeneradas.filter((e) => e.cantidad !== 0);

    return a;
  };
  const handlePorcetajeCompleto = (completas, elemento) => {
    let porcentaje;
    if (completas) {
      let total = elemento.pozos.reduce((acumulador, elemento) => {
        return acumulador + parseInt(elemento.numeroEtapasPozo);
      }, 0);

      porcentaje =
        (completas[completas.length - 1].numTotalEtapaSC / total) * 100;

      const integerPercentage = Math.floor(porcentaje);
      return integerPercentage;
    } else {
      return 0;
    }
  };
  const handleCompletasDia = (element) => {
    let etapas = element?.etapasSC || [];
    let hoy = etapas.filter((e) => {
      return (
        moment(e.fechaFinalEtapaSC).format("YYYY-MM-DD") ===
        today.format("YYYY-MM-DD")
      );
    });

    return hoy.length;
  };
  const handleTnsOperada = (elemento) => {
    let etapas = elemento.etapasSC;
    let totalSC;
    if (elemento.etapasSC) {
      totalSC = etapas
        .filter((e) => e.sandCubes)
        .reduce(
          (acc, cuerrentValue) => acc + cuerrentValue.sandCubes?.length,
          0
        );
    } else {
      return 0;
    }
    return regionalFormattingNumbers({ number: totalSC * 13.5, digits: 2 });
  };
  const handleSandcubes = (event) => {
    if (operacion.scAsociados && event === "llenosPozo") {
      operacion.scAsociados.forEach((objeto) => {
        if (
          objeto.ubicacionSandCube === "EN POZO" &&
          objeto.statusSandCube === "Lleno"
        ) {
          llenosPozo++;
        }
      });
      return llenosPozo;
    } else if (operacion.scAsociados && event === "vaciosPozo") {
      operacion.scAsociados.forEach((objeto) => {
        if (
          objeto.ubicacionSandCube === "EN POZO" &&
          objeto.statusSandCube === "Vacio"
        ) {
          vaciosPozo++;
        }
      });
      return vaciosPozo;
    } else if (operacion.scAsociados && event === "transitoPozo") {
      repo.forEach((objeto) => {
        if (objeto.status2RV === "EN TRÁNSITO A POZO") {
          transitoPozo += 2;
        }
      });
      return transitoPozo;
    } else {
      return 0;
    }
  };

  const handlePromedio = (fecha) => {
    if (!fecha) return 0;
    let diasOperados = moment(today).diff(fecha[0].fechaFinalEtapaSC, "days");
    let promedio = fecha[fecha.length - 1].numTotalEtapaSC / (diasOperados + 1);

    return regionalFormattingNumbers({
      number: promedio.toFixed(2),
      digits: 2,
    });
  };

  return (
    <Card
      w={["md", "xl", "md", "lg", "2xl"]}
      h="100%"
      variant={"unstyled"}
      zIndex={10}
      bg={"white"}
    >
      <CardHeader>
        <Heading
          size={["sm", "md", "md", "md", "lg"]}
          fontWeight="semibold"
          color="brand.azul_text"
        >
          {operacion?.idPadlocacion}
        </Heading>
      </CardHeader>
      <Divider color="gray.300" size={"sm"} />
      <CardBody h="100%" w="100%">
        <Heading
          size={["sm", "md", "md", "md", "lg"]}
          placeSelf="start"
          fontWeight="bold"
          py={1}
        >
          Etapas
        </Heading>

        <HStack w="100%" justifyContent="space-around" pb={3}>
          <HStack
            bg={"brand.azul_text"}
            flexDir="row"
            display="flex"
            textAlign="left"
            borderRadius={5}
            p={3}
          >
            <Text
              placeSelf="start"
              fontWeight="bold"
              fontSize={["30px", "36px", "36px", "36px", "40px"]}
              textColor="white"
            >
              {operacion.etapasSC
                ? operacion.etapasSC[operacion.etapasSC.length - 1]
                    .numTotalEtapaSC
                : 0}
            </Text>

            <Text
              textColor="white"
              fontSize={["10px", "16px", "14px", "16px", "18px"]}
            >
              Completadas en total
            </Text>
          </HStack>
          <VStack w="100%">
          <Text
                            fontSize={["10px", "12px", "12px", "12px", "14px"]}
                          >
                            {operacion.etapasSC
                              ? `Completas hoy: ${handleCompletasDia(
                                  operacion.etapasSC
                                )}`
                              : "Completas hoy: 0"}
                          </Text>
                          <Text
                            fontSize={["10px", "12px", "12px", "12px", "14px"]}
                          >
                            {operacion.etapasSC
                              ? `Promedio: ${handlePromedio(
                                  operacion.etapasSC
                                )}`
                              : "Promedio: 0"}
                          </Text>
          </VStack>
          <VStack w="100%">
            <HStack>
              <Text fontSize="28px" fontWeight="bold">
                {handlePorcetajeCompleto(operacion.etapasSC, operacion)}%
              </Text>
              <Text fontSize="12px">
                pad <br />
                completo
              </Text>
            </HStack>
            <Box bg="white" w="80%" borderRadius={5}>
              <Progress
                borderRadius={5}
                colorScheme="orange"
                size="md"
                max={100}
                min={0}
                value={
                  operacion.etapasSC
                    ? handlePorcetajeCompleto(
                        operacion.etapasSC || 0,
                        operacion
                      )
                    : 0
                }
              />
            </Box>
          </VStack>
        </HStack>
        <Divider />
        <Heading
          size={["sm", "md", "md", "md", "lg"]}
          placeSelf="start"
          fontWeight="bold"
          py={1}
        >
          Sandcubes
        </Heading>
        <HStack w="100%" justifyContent="space-around">
          <HStack>
            <Text
              placeSelf="start"
              fontWeight="bold"
              fontSize={["18px", "30px", "24px", "30px", "36px"]}
            >
              {handleSandcubes("llenosPozo")}
            </Text>
            <Box w={8} h={8} alignSelf="start">
              <Sandcube stroke="#FF8200" />
            </Box>
            <Text fontSize="12px" alignSelf="start">
              llenos <br /> en pozo
            </Text>
          </HStack>
          <HStack>
            <Text
              placeSelf="start"
              fontWeight="bold"
              fontSize={["18px", "30px", "24px", "30px", "36px"]}
            >
              {handleSandcubes("vaciosPozo")}
            </Text>
            <Box w={8} h={8} alignSelf="start">
              <Sandcube stroke="#505E70" />
            </Box>
            <Text fontSize="12px" alignSelf="start">
              vacios <br /> en pozo
            </Text>
          </HStack>
          <HStack>
            <Text
              placeSelf="start"
              fontWeight="bold"
              fontSize={["18px", "30px", "24px", "30px", "36px"]}
            >
              {handleSandcubes("transitoPozo")}
            </Text>
            <Box w={8} h={8} alignSelf="start">
              <Sandcube stroke="#004B87" />
            </Box>
            <Text fontSize="12px" alignSelf="start">
              en transito <br /> a pozo
            </Text>
          </HStack>
        </HStack>
        <Divider />
        <Heading
          size={["sm", "md", "md", "md", "lg"]}
          placeSelf="start"
          fontWeight="bold"
        >
          Camiones
        </Heading>
        <HStack
          w="100%"
          justifyContent="space-around"
          alignItems={"flex-start"}
        >
          <VStack justifyContent={"flex-start"}>
            <HStack>
              <Text
                fontSize={["18px", "30px", "24px", "30px", "36px"]}
                fontWeight="bold"
                letterSpacing={1}
              >
                {y}
              </Text>
              <Icon
                as={MdOutlineAirportShuttle}
                fontSize={["18px", "18px", "20px", "24px", "30px"]}
              />
            </HStack>
            <Text
              fontSize={["10px", "12px", "12px", "12px", "14px"]}
              alignSelf={"start"}
              marginTop={"0 !important"}
            >
              Camiones activos
            </Text>
          </VStack>
          <Box>
            <HStack spacing={1}>
              <Text
                fontSize={["18px", "30px", "24px", "30px", "36px"]}
                fontWeight="bold"
                letterSpacing={1}
              >
                {x}
              </Text>
              <Icon
                as={MdOutlineTimer}
                fontSize={["18px", "18px", "20px", "24px", "30px"]}
              />
            </HStack>
            <HStack>
              <Text fontSize={["10px", "12px", "12px", "12px", "14px"]}>
                Standby en pozo <br /> del general
              </Text>
              <Text color="gray" fontSize="12px" fontStyle="italic">
                (mins)
              </Text>
            </HStack>
          </Box>

          <Divider orientation="vertical" />
          <VStack>
            <Text
              fontWeight="bold"
              fontSize={["18px", "30px", "24px", "30px", "36px"]}
              alignSelf="start"
            >
              {handleTnsOperada(operacion)}
            </Text>

            <Text
              fontSize={["10px", "12px", "12px", "12px", "14px"]}
              spacing={1}
              alignSelf={"start"}
              marginTop={"0 !important"}
            >
              toneladas operadas
            </Text>
          </VStack>
        </HStack>
        <HStack w="100%" justifyContent="space-between" py={1}>
          <Text fontSize={["10px", "12px", "12px", "14px", "14px"]}>
            Etapas completadas por dia
          </Text>
          <Badge textColor="brand.gris_primario" fontWeight="light">
            <HStack>
              <Icon as={MdInsertInvitation} color="gray" boxSize={5} />
              <Text>
                {" "}
                {operacion?.etapasSC
                  ? moment(operacion?.etapasSC[0].fechaInicioEtapaSC).format(
                      "DD/MM/YYYY"
                    )
                  : "--/--/----"}{" "}
                AL{" "}
                {operacion?.etapasSC
                  ? moment(
                      operacion?.etapasSC[operacion.etapasSC.length - 1]
                        .fechaInicioEtapaSC
                    ).format("DD/MM/YYYY")
                  : "--/--/----"}
              </Text>
            </HStack>
          </Badge>
        </HStack>
        <HStack w={"100%"} h="100px">
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              width={500}
              height={250}
              data={fusionarEtapasConCantidad()}
              margin={{
                left: -26,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey={"fechaII"} />
              <YAxis dataKey={"cantidad"} />
              <Tooltip />
              <Bar dataKey="cantidad" fill="#82ca9d" />
            </BarChart>
          </ResponsiveContainer>
        </HStack>
      </CardBody>
    </Card>
  );
};

export default OperacionEnCurso;
