import React from "react";
import { useToast, IconButton } from "@chakra-ui/react";
import { MdOutlineGetApp } from "react-icons/md";
import { descargarEtapas } from "../../../helpers/api.helper";

const DescargaReporteEtapas = (props) => {
  const { planificacion, etapas } = props;
  const toast = useToast();

  function formatearHorario(e) {
    const year = new Date(e).getFullYear();
    const month = String(new Date(e).getMonth() + 1).padStart(2, "0");
    const day = String(new Date(e).getDate()).padStart(2, "0");
    const hours = String(new Date(e).getHours()).slice(-2).padStart(2, "0");
    const minutes = String(new Date(e).getMinutes()).slice(-2).padStart(2, "0");

    return year + "-" + month + "-" + day + " " + hours + ":" + minutes;
  }
  const handleTnsNominales = (seleccionado) => {
    let sc = seleccionado.sandCubes ? seleccionado.sandCubes.length : 0;
    let cantidad = planificacion?.mallas[0].tnsArenaSc;

    let resultado = (sc * cantidad).toFixed(2);

    // resultado = resultado.replace(".", ",");

    return parseFloat(resultado);
  };

  const handleDownload = async () => {
    let data = etapas.map((etapa) => {
      return {
        padPozo: etapa.padLocacion,
        estado: planificacion?.estado.toUpperCase(),
        fecha: etapa.fechaFinalEtapaSC,
        nroEtapaGlobal: etapa.numTotalEtapaSC,
        etapaDelDia: "",
        padCompleto: "",
        pozo: etapa.pozo.nombrePozo,
        etapaPozo: etapa.numPozoEtapaSC,
        fechaHoraInicio: formatearHorario(etapa.fechaInicioEtapaSC),
        fechaHoraFinal: formatearHorario(etapa.fechaFinalEtapaSC),
        cantidadSC: etapa.sandCubes ? etapa.sandCubes.length : 0,
        tnsNominales: handleTnsNominales(etapa),
        operadora: planificacion?.operadora,
        companiaServicios: planificacion?.companiaServicio,
        setSandcube: planificacion?.idSET === "set1" ? "SET 1" : "SET 2",
        malla1: "",
        malla1SCs: "",
        malla1Tns: "",
        malla2: "",
        malla2SCs: "",
        malla2Tns: "",
        detalleSandcubes:
          etapa.sandCubes &&
          etapa.sandCubes.map((sc) => {
            return {
              idSandcube: sc.nombreSandCube,
              malla: sc.malla,
              planta: sc.nombrePlanta,
              wetsandSC: sc.wetsandSC,
            };
          }),
      };
    });

    console.log(data);

    let response = await descargarEtapas({
      detalleEtapas: [...data],
      id: planificacion?.idPlanificacion,
    });
    if (response.status === 200) {
      const url = window.URL.createObjectURL(
        new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
        })
      );
      const link = document.createElement("a");
      link.href = url;
      const nombreArchivo = `ReporteEtapas.xlsx`;
      link.setAttribute("download", nombreArchivo);
      document.body.appendChild(link);
      link.click();
      // link.setAttribute(
      //   "download",
      //   `Etapas-${planificacion.idPadlocacion}.xlsx`
      // );
      // document.body.appendChild(link);
      // link.click();
    } else if (response.status === 400) {
      return toast({
        title: "Error al descargar",
        description: "Hay soperpocicion entre fechas ",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } else if (response.status === 500) {
      return toast({
        title: "Error",
        description: "Error del servidor",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <>
      <IconButton
        size="sm"
        variant="outline"
        border="1px solid #FF8200"
        icon={<MdOutlineGetApp fontSize="20px" />}
        color="brand.naranja"
        _hover={{ bg: "brand.naranja", color: "white" }}
        onClick={() => handleDownload()}
      />
    </>
  );
};

export default DescargaReporteEtapas;
