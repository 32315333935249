import {
  Stack,
  HStack,
  FormControl,
  FormLabel,
  Input,
  Heading,
  IconButton,
  Button,
  useToast,
  Switch,
  InputGroup,
  InputLeftAddon,
  Select,
} from "@chakra-ui/react";
import { Formik, Form } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { MdArrowBack, MdFileDownloadDone } from "react-icons/md";
import {
  getSandcube,
  editarSandcube,
  crearSandcube,
} from "./../../helpers/api.helper";

const FormCrearEditarSandcubes = (props) => {
  const { type } = props;
  const [loading, setLoading] = useState(false);
  const [sandcube, setSandcube] = useState([]);
  const navigate = useNavigate();
  const id = useParams();
  const toast = useToast();

  const initData = async () => {
    setLoading(true);
    let res = await getSandcube(id);

    if (res.status === 200) {
      setSandcube({
        nombreSandCube: res.data[0].nombreSandCube.substring(3),
        condicion: res.data[0].condicion,
        malla: res.data[0].malla,
        toneladasSandCube: res.data[0].toneladasSandCube,
        idSandCube: res.data[0].idSandCube,
        statusSandCube: res.data[0].statusSandCube,
        tipoArena: res.data[0].tipoArena,
        wetsandSC: res.data[0].wetsandSC,
      });
    } else {
      return toast({
        status: "error",
        title: "Error",
        description: "Error al cargar los datos",
        isClosable: true,
        duration: 3000,
      });
    }

    setLoading(false);
  };

  let initialValues =
    type === "editar"
      ? {
          nombreSandCube: sandcube.nombreSandCube,
          condicion: sandcube.condicion === "activo" ? true : false,
          toneladasSandCube: sandcube.toneladasSandCube,
          malla: sandcube.malla,
          tipoArena: sandcube.tipoArena,
          statusSandCube: sandcube.statusSandCube,
          wetsandSC: sandcube.wetsandSC,
        }
      : {
          nombreSandCube: "",
          condicion: true,
          toneladasSandCube: "13.5",
          malla: "",
          tipoArena: "",
          statusSandCube: "",
          wetsandSC: false,
        };

  useEffect(() => {
    if (type === "editar") {
      initData();
    }
  }, []);

  return (
    <Stack bg="brand.fondo" w="100%" h="md" borderRadius={10} spacing={7}>
      <HStack w="100%" pl={5}>
        <IconButton
          icon={<MdArrowBack />}
          variant="link"
          onClick={() => navigate(-1)}
          fontSize="24px"
        />

        <Heading fontWeight="medium" py={3}>
          {type === "editar" ? "Editar" : "Nuevo"} Sandcube
        </Heading>
      </HStack>
      {!loading && (
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          onSubmit={async (values) => {
            setLoading(true);

            if (!values.nombreSandCube) {
              setLoading(false);
              return toast({
                title: "Error",
                description: "Codigo de sandcube requerido",
                status: "error",
                isClosable: true,
                duration: 3000,
              });
            } else if (!/^\d{4}$/.test(values.nombreSandCube)) {
              setLoading(false);
              return toast({
                title: "Error",
                description: "Ingrese solo números",
                status: "error",
                isClosable: true,
                duration: 3000,
              });
            }

            let res;

            if (type === "editar") {
              res = await editarSandcube({
                sandCubes: [
                  {
                    nombreSandCube: `SC ${values.nombreSandCube}`,
                    condicion:
                      values.condicion === true ? "activo" : "inactivo",
                    toneladasSandCube: values.toneladasSandCube,
                    malla: values.malla,
                    tipoArena: values.tipoArena,
                    statusSandCube: values.statusSandCube,
                    wetsandSC: values.wetsandSC,
                    idSandCube: sandcube.idSandCube,
                  },
                ],
              });
            }
            if (type === "crear") {
              res = await crearSandcube({
                nombreSandCube: `SC ${values.nombreSandCube}`,
                condicion: values.condicion === true ? "activo" : "inactivo",
                toneladasSandCube: values.toneladasSandCube,
                malla: values.malla,
                statusSandCube: values.statusSandCube,
                tipoArena: values.tipoArena,
                wetsandSC: values.wetsandSC,
              });
            }

            if (res.status === 200) {
              navigate(-1);
              toast({
                status: "success",
                isClosable: true,
                title: `Sandcube ${
                  type === "crear" ? "creado" : "editado"
                } correctamente`,
                duration: 3000,
              });
            } else if (res.status === 404) {
              setLoading(false);
              return toast({
                status: "error",
                isClosable: true,
                title: "Ya existe un Sandcube con ese nombre",
                duration: 3000,
              });
            } else {
              setLoading(false);
              return toast({
                status: "error",
                duration: 3000,
                isClosable: true,
                title: `Error al ${
                  type === "crear" ? "crear" : "editar"
                } sandcube`,
                description:
                  res.data.error.message || "Error al editar sandcube",
              });
            }

            setLoading(false);
          }}
        >
          {({ values, handleChange, handleBlur, handleSubmit }) => (
            <Form id="formSandvan">
              <Stack w="100%" spacing={10} px={7}>
                <HStack w="100%" spacing={10}>
                  <FormControl w={"xs"}>
                    <FormLabel fontWeight="bold">Nombre</FormLabel>
                    <InputGroup w={"xs"}>
                      <InputLeftAddon children="SC" />
                      <Input
                        name="nombreSandCube"
                        type="text"
                        maxLength={4}
                        placeholder="Codigo"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.nombreSandCube}
                        isRequired
                      />
                    </InputGroup>
                  </FormControl>
                  <FormControl w={"xs"}>
                    <HStack spacing={14}>
                      <Stack>
                        <FormLabel fontWeight="bold">Activo/Inactivo</FormLabel>
                        <Switch
                          defaultValue={true}
                          name="condicion"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isChecked={values.condicion}
                        />
                      </Stack>
                      <Stack>
                        <FormLabel fontWeight="bold">Wetsand</FormLabel>
                        <Switch
                          defaultValue={true}
                          name="wetsandSC"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isChecked={values.wetsandSC}
                        />
                      </Stack>
                    </HStack>
                  </FormControl>

                  <FormControl w={"xs"}>
                    <FormLabel fontWeight="bold">Estado</FormLabel>
                    <Select
                      placeholder="Estado"
                      name={"statusSandCube"}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.statusSandCube}
                    >
                      <option value={"Lleno"}>Lleno</option>
                      <option value={"Vacio"}>Vacio</option>
                    </Select>
                  </FormControl>
                </HStack>
                <HStack w="100%" spacing={10}>
                  <FormControl w={"xs"}>
                    <FormLabel fontWeight="bold">Malla</FormLabel>
                    <Select
                      placeholder="Tipo de malla"
                      name={"malla"}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.malla}
                    >
                      <option value={"100"}>100</option>
                      <option value={"30/70"}>30/70</option>
                      <option value={"70/140"}>70/140</option>
                      <option value={"50/70"}>50/70</option>
                      <option value={"40/80"}>40/80</option>
                      <option value={"40/70"}>40/70</option>
                      <option value={"30/50"}>30/50</option>
                      <option value={"20/40"}>20/40</option>
                      <option value={"Otros"}>Otros</option>
                    </Select>
                  </FormControl>
                  <FormControl w={"xs"}>
                    <FormLabel fontWeight="bold">Toneladas</FormLabel>
                    <Input
                      name="toneladasSandCube"
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.toneladasSandCube}
                      readOnly
                    />
                  </FormControl>
                  <FormControl w={"xs"}>
                    <FormLabel fontWeight="bold">Tipo de Arena</FormLabel>
                    <Select
                      placeholder="Tipo de arena"
                      name={"tipoArena"}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.tipoArena}
                    >
                      <option value={"seca"}>Seca</option>
                      <option value={"humeda"}>Humeda</option>
                    </Select>
                  </FormControl>
                </HStack>
                <Button
                  placeSelf="end"
                  rightIcon={<MdFileDownloadDone />}
                  bg="brand.naranja"
                  form="formSandvan"
                  color="white"
                  isLoading={loading}
                  size="md"
                  onClick={handleSubmit}
                >
                  Finalizar
                </Button>
              </Stack>
            </Form>
          )}
        </Formik>
      )}
    </Stack>
  );
};

export default FormCrearEditarSandcubes;
