import {
  Stack,
  HStack,
  FormControl,
  FormLabel,
  Input,
  Heading,
  Select,
  IconButton,
  Button,
  useToast,
} from "@chakra-ui/react";
import { Formik, Form } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { MdArrowBack, MdFileDownloadDone } from "react-icons/md";
import {
  getClientes,
  editarClientes,
  crearClientes,
} from "./../../helpers/api.helper";

const FormCrearEditarCliente = (props) => {
  const { type } = props;
  const [loading, setLoading] = useState(false);
  const [cliente, setCliente] = useState([]);
  const navigate = useNavigate();
  const id = useParams();
  const toast = useToast();

  const initData = async () => {
    setLoading(true);
    let res = await getClientes(id);

    if (res.status === 200) {
      setCliente(res.data[0]);
    } else {
      toast({
        title: "Error",
        description: "Error al cargar los datos",
        isClosable: true,
        duration: 3000,
      });
    }

    setLoading(false);
  };

  let initialValues =
    type === "editar"
      ? {
          ciudadCliente: cliente.ciudadCliente,
          cuitCuilCliente: cliente.cuitCuilCliente,
          direccionCliente: cliente.direccionCliente,
          emaillCliente: cliente.emaillCliente,
          nombreCliente: cliente.nombreCliente,
          nombreEmpresaCl: cliente.nombreEmpresaCl,
          provinciaCliente: cliente.provinciaCliente,
          razonSocialCliente: cliente.razonSocialCliente,
          telefonoCliente: cliente.telefonoCliente,
          tipoCliente: cliente.tipoCliente,
        }
      : {
          ciudadCliente: "",
          cuitCuilCliente: "",
          direccionCliente: "",
          emaillCliente: "",
          nombreCliente: "",
          nombreEmpresaCl: "",
          provinciaCliente: "",
          razonSocialCliente: "",
          telefonoCliente: "",
          tipoCliente: "",
        };

  useEffect(() => {
    initData();
  }, []);

  return (
    <Stack bg="brand.fondo" w="100%" h="md" borderRadius={10} spacing={7}>
      <HStack w="100%" pl={5}>
        <IconButton
          icon={<MdArrowBack />}
          variant="link"
          onClick={() => navigate(-1)}
          fontSize="24px"
        />

        <Heading fontWeight="medium" py={3}>
          {type === "editar" ? "Editar" : "Nuevo"} Cliente
        </Heading>
      </HStack>
      {!loading && (
        <Formik
          initialValues={initialValues}
          onSubmit={async (values) => {
            setLoading(true);
            if (!values.nombreCliente) {
              setLoading(false);
              return toast({
                title: "Error",
                description: "Nombre del cliente",
                status: "error",
                isClosable: true,
                duration: 3000,
              });
            }
            if (!values.cuitCuilCliente) {
              setLoading(false);
              return toast({
                title: "Error",
                description: "CUIT/CUIL requerido",
                status: "error",
                isClosable: true,
                duration: 3000,
              });
            }
            let res;

            if (type === "editar") {
              res = await editarClientes({
                ciudadCliente: values.ciudadCliente,
                cuitCuilCliente: values.cuitCuilCliente,
                direccionCliente: values.direccionCliente,
                emaillCliente: values.emaillCliente,
                nombreCliente: values.nombreCliente,
                nombreEmpresaCl: values.nombreEmpresaCl,
                provinciaCliente: values.provinciaCliente,
                razonSocialCliente: values.razonSocialCliente,
                telefonoCliente: values.telefonoCliente,
                tipoCliente: values.tipoCliente,
                id: cliente.idCliente,
              });
            }
            if (type === "crear") {
              res = await crearClientes({
                ciudadCliente: values.ciudadCliente,
                cuitCuilCliente: values.cuitCuilCliente,
                direccionCliente: values.direccionCliente,
                emaillCliente: values.emaillCliente,
                nombreCliente: values.nombreCliente,
                nombreEmpresaCl: values.nombreEmpresaCl,
                provinciaCliente: values.provinciaCliente,
                razonSocialCliente: values.razonSocialCliente,
                telefonoCliente: values.telefonoCliente,
                tipoCliente: values.tipoCliente,
              });
            }

            if (res.status === 200) {
              toast({
                status: "success",
                isClosable: true,
                title: `Cliente ${
                  type === "crear" ? "creado" : "editado"
                } correctamente`,
                duration: 3000,
              });
              navigate(-1);
            } else {
              return toast({
                status: "error",
                isClosable: true,
                title: `Error al ${
                  type === "crear" ? "crear" : "editar"
                } cliente`,
                description:
                  res.data.error.message || "Error al editar usuario",
              });
            }

            setLoading(false);
          }}
        >
          {({ values, handleChange, handleBlur, handleSubmit }) => (
            <Form id="formClientes">
              <Stack w="100%" spacing={10} px={7}>
                <HStack w="100%" spacing={10}>
                  <FormControl w={"xs"}>
                    <FormLabel fontWeight="bold">Nombre</FormLabel>
                    <Input
                      name="nombreCliente"
                      type="text"
                      placeholder="Nombre"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.nombreCliente}
                      isRequired
                    />
                  </FormControl>
                  <FormControl w={"xs"}>
                    <FormLabel fontWeight="bold">CUIT/CUIL</FormLabel>
                    <Input
                      name="cuitCuilCliente"
                      type="text"
                      placeholder="CUIT/CUIL"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.cuitCuilCliente}
                      isRequired
                    />
                  </FormControl>
                  <FormControl w={"xs"} alignSelf="start">
                    <FormLabel fontWeight="bold">Razon social</FormLabel>
                    <Input
                      name="razonSocialCliente"
                      type="email"
                      placeholder="Empresa"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.razonSocialCliente}
                    />
                  </FormControl>
                </HStack>
                <HStack spacing={10}>
                  <FormControl w={"xs"}>
                    <FormLabel fontWeight="bold">Telefono</FormLabel>
                    <Input
                      name="telefonoCliente"
                      type={"tel"}
                      placeholder="000-0000"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.telefonoCliente}
                    />
                  </FormControl>
                  <FormControl w={"xs"} alignSelf="center">
                    <FormLabel fontWeight="bold">Correo</FormLabel>
                    <Input
                      name="emaillCliente"
                      type="email"
                      placeholder="Email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.emaillCliente}
                    />
                  </FormControl>
                  <FormControl w={"xs"} alignSelf="center">
                    <FormLabel fontWeight="bold">Tipo cliente</FormLabel>
                    <Select
                      name="tipoCliente"
                      placeholder="Seleccione tipo"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.tipoCliente}
                    >
                      <option value={"companiaServicio"}>
                        Compania servicio
                      </option>
                      <option value={"operadora"}>Operadora</option>
                    </Select>
                  </FormControl>
                </HStack>
                <HStack spacing={10}>
                  <FormControl w={"xs"}>
                    <FormLabel fontWeight="bold">Calle</FormLabel>
                    <Input
                      name="direccionCliente"
                      type="text"
                      placeholder="Dirección"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.direccionCliente}
                    />
                  </FormControl>
                  <FormControl w={"xs"} alignSelf="center">
                    <FormLabel fontWeight="bold">Ciudad</FormLabel>
                    <Input
                      name="ciudadCliente"
                      type={"text"}
                      placeholder="Cuidad"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.ciudadCliente}
                    />
                  </FormControl>
                  <FormControl w={"xs"} alignSelf="center">
                    <FormLabel fontWeight="bold">Provincia</FormLabel>
                    <Input
                      name="provinciaCliente"
                      type={"text"}
                      placeholder="Email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.provinciaCliente}
                    />
                  </FormControl>
                </HStack>
                <Button
                  placeSelf="end"
                  rightIcon={<MdFileDownloadDone />}
                  bg="brand.naranja"
                  form="formClientes"
                  color="white"
                  isLoading={loading}
                  size="md"
                  onClick={handleSubmit}
                >
                  Finalizar
                </Button>
              </Stack>
            </Form>
          )}
        </Formik>
      )}
    </Stack>
  );
};

export default FormCrearEditarCliente;
