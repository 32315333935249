import React, { useEffect, useState } from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Stack,
  HStack,
  Heading,
  useToast,
  CircularProgress,
  Icon,
  IconButton,
  Tooltip,
  Button,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { MdAddCircleOutline, MdOutlineEdit } from "react-icons/md";
import { getUsuarios } from "./helpers/api.helper";
import EliminarUsuarios from "./components/EliminarUsuarios";
import { RiErrorWarningFill, RiCheckboxCircleFill } from "react-icons/ri";

const Usuarios = () => {
  const [usuarios, setUsuarios] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const toast = useToast();

  const handleEstado = (e) => {
    if (e === "activado" || e === "activo") {
      return (
        <Icon as={RiCheckboxCircleFill} color="brand.verde" fontSize="24px" />
      );
    } else {
      return (
        <Icon as={RiErrorWarningFill} color="brand.rojo" fontSize="24px" />
      );
    }
  };
  const handleRol = (e) => {
    switch (e) {
      case "admin":
        return "Admin";
      case "cliente":
        return "Cliente";
      case "usuarioBrent":
        return "Usuario BRENT";
      case "coorOp":
        return "Coordinador de Operaciones";
      case "userOperaciones":
        return "Operaciones";
      case "userMantenimiento":
        return "Mantenimiento";
      case "userFacturacion":
        return "Facturación";
      case "userHS":
        return "Higiene y Seguridad";
      case "gerencia":
        return "Gerencia";
      case "usuarioIT":
        return "Usuario IT";

      default:
        return "";
    }
  };

  const initUsuarios = async () => {
    setLoading(true);
    let res = await getUsuarios();

    if (res.status === 200) {
      setUsuarios(res.data);
    } else {
      toast({
        description: "Error al cargar usuarios",
        title: "Error",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
    setLoading(false);
  };

  const loadingtable = () => {
    return (
      <HStack w="100%" placeContent="center">
        <CircularProgress isIndeterminate color="brand.naranja" />
      </HStack>
    );
  };

  const handlePuesto = (e) => {
    const puestoMapping = {
      supervisor: "Supervisor",
      operador: "Operador",
      maquinista: "Maquinista",
    };

    return puestoMapping[e] || "--";
  };

  const showUsuarios = () => {
    return usuarios.map((usuario) => (
      <Tr key={usuario.idUsuario}>
        <Td>{usuario.nombre}</Td>
        <Td>{usuario.email}</Td>
        <Td>{handleRol(usuario.idRol)}</Td>
        <Td textAlign={"center"}>{handleEstado(usuario.estado)}</Td>
        <Td></Td>
        <Td>{handlePuesto(usuario.puesto)}</Td>
        <Td></Td>
        <Td>
          <>
            <Tooltip label="Editar usuario">
              <IconButton
                onClick={() =>
                  navigate(`/usuarios/editar/${usuario.idUsuario}`)
                }
                icon={<MdOutlineEdit />}
                fontSize="20px"
                variant="link"
              />
            </Tooltip>
            <EliminarUsuarios usuario={usuario} initUsuarios={initUsuarios} />
          </>
        </Td>
      </Tr>
    ));
  };

  useEffect(() => {
    initUsuarios();
  }, []);

  return (
    <Stack w="100%" h={"xl"}>
      <HStack w="100%" justifyContent={"space-between"} px={5}>
        <Heading
          fontWeight="medium"
          size="xl"
          fontFamily="fonts.title"
          color="brand.gris_primario"
        >
          Usuarios
        </Heading>
        <Button
          size="sm"
          variant="outline"
          border="1px solid #FF8200"
          leftIcon={<MdAddCircleOutline fontSize="20px" />}
          color="brand.naranja"
          onClick={() => {
            navigate("/usuarios/nuevo");
          }}
          _hover={{ bg: "brand.naranja", color: "white" }}
        >
          Nuevo usuario
        </Button>
      </HStack>
      {loading && loadingtable()}
      {!loading && usuarios.length > 0 && (
        <TableContainer w="100%" p={1} overflowY={"auto"}>
          <Table variant="simple" size="sm" bg="brand.fondo" borderRadius={10}>
            <Thead>
              <Tr
                style={{
                  position: "sticky",
                  top: -5,
                  zIndex: 1,
                  backgroundColor: "#F3F6F9",
                  opacity: 1,
                  marginTop: "-10px",
                }}
                textAlign="center"
                borderBottom="2px solid black"
              >
                <Th>Nombre</Th>
                <Th>Email</Th>
                <Th>Rol</Th>
                <Th textAlign={"center"}>Status</Th>
                <Th>Área</Th>
                <Th>Puesto</Th>
                <Th>Ubicación</Th>
                <Th>Acciones</Th>
              </Tr>
            </Thead>
            <Tbody>{showUsuarios()}</Tbody>
          </Table>
        </TableContainer>
      )}
      {!loading && usuarios.length < 1 && (
        <Heading textAlign={"center"} size={"md"}>
          No hay datos para mostrar
        </Heading>
      )}
    </Stack>
  );
};

export default Usuarios;
