import ajax from "../../../utils/axios";

export const getClientes = async (params) => {
  let response;
  try {
    response = await ajax.get("/endpoint/clientes", { params });
    return response;
  } catch (error) {
    console.error("Error en: api-helper.js - getClientes() - Error", {
      error,
    });
    return error.response;
  }
};

export const editarClientes = async (params) => {
  try {
    let res = await ajax.put("/endpoint/clientes", params);
    return res;
  } catch (error) {
    console.error("Error en: api.helper.js - editarClientes() - Error", error);
    return error.response.data;
  }
};

export const crearClientes = async (params) => {
  try {
    let res = await ajax.post("/endpoint/clientes", params);
    return res;
  } catch (error) {
    console.error("Error en: api.helper.js - crearClientes() - Error", error);
    return error.response.data;
  }
};

export const eliminarClientes = async (params) => {
  try {
    let res = await ajax.delete("/endpoint/clientes", { params });
    return res;
  } catch (error) {
    console.error(
      "Error en: api.helper.js - eliminarClientes() - Error",
      error
    );
    return error.response.data;
  }
};

export const getAutoelevadores = async (params) => {
  let response;
  try {
    response = await ajax.get("/endpoint/autoelevadores", { params });
    return response;
  } catch (error) {
    console.error("Error en: api-helper.js - getAutoelevadores() - Error", {
      error,
    });
    return error.response;
  }
};

export const editarAutoelevadores = async (params) => {
  try {
    let res = await ajax.put("/endpoint/autoelevadores", params);
    return res;
  } catch (error) {
    console.error(
      "Error en: api.helper.js - editarAutoelevadores() - Error",
      error
    );
    return error.response.data;
  }
};

export const crearAutoelevadores = async (params) => {
  try {
    let res = await ajax.post("/endpoint/autoelevadores", params);
    return res;
  } catch (error) {
    console.error(
      "Error en: api.helper.js - crearAutoelevadores() - Error",
      error
    );
    return error.response.data;
  }
};

export const eliminarAutoelevadores = async (params) => {
  try {
    let res = await ajax.delete("/endpoint/autoelevadores", { params });
    return res;
  } catch (error) {
    console.error(
      "Error en: api.helper.js - eliminarAutoelevadores() - Error",
      error
    );
    return error.response.data;
  }
};

export const getTransportista = async (params) => {
  let response;
  try {
    response = await ajax.get("/endpoint/empresastransportistas", { params });
    return response;
  } catch (error) {
    console.error("Error en: api-helper.js - getTransportista() - Error", {
      error,
    });
    return error.response;
  }
};

export const editarTransportista = async (params) => {
  try {
    let res = await ajax.put("/endpoint/empresastransportistas", params);
    return res;
  } catch (error) {
    console.error(
      "Error en: api.helper.js - editarTransportista() - Error",
      error
    );
    return error.response.data;
  }
};

export const crearTransportista = async (params) => {
  try {
    let res = await ajax.post("/endpoint/empresastransportistas", params);
    return res;
  } catch (error) {
    console.error(
      "Error en: api.helper.js - crearTransportista() - Error",
      error
    );
    return error.response.data;
  }
};

export const eliminarTransportista = async (params) => {
  try {
    let res = await ajax.delete("/endpoint/empresastransportistas", { params });
    return res;
  } catch (error) {
    console.error(
      "Error en: api.helper.js - eliminarTransportista() - Error",
      error
    );
    return error.response.data;
  }
};

export const editarCamiones = async (params) => {
  try {
    let res = await ajax.put("/endpoint/camiones", params);
    return res;
  } catch (error) {
    console.error("Error en: api.helper.js - editarCamiones() - Error", error);
    return error.response.data;
  }
};

export const crearCamiones = async (params) => {
  try {
    let res = await ajax.post("/endpoint/camiones", params);
    return res;
  } catch (error) {
    console.error("Error en: api.helper.js - crearCamiones() - Error", error);
    return error.response;
  }
};

export const eliminarCamiones = async (params) => {
  try {
    let res = await ajax.delete("/endpoint/camiones", { params });
    return res;
  } catch (error) {
    console.error(
      "Error en: api.helper.js - eliminarCamiones() - Error",
      error
    );
    return error.response.data;
  }
};

export const getPlantas = async (params) => {
  let response;
  try {
    response = await ajax.get("/endpoint/plantas", { params });
    return response;
  } catch (error) {
    console.error("Error en: api-helper.js - getPlantas() - Error", {
      error,
    });
    return error.response;
  }
};

export const editarPlantas = async (params) => {
  try {
    let res = await ajax.put("/endpoint/plantas", params);
    return res;
  } catch (error) {
    console.error("Error en: api.helper.js - editarPlantas() - Error", error);
    return error.response.data;
  }
};

export const crearPlantas = async (params) => {
  try {
    let res = await ajax.post("/endpoint/plantas", params);
    return res;
  } catch (error) {
    console.error("Error en: api.helper.js - crearPlantas() - Error", error);
    return error.response.data;
  }
};

export const eliminarPlantas = async (params) => {
  try {
    let res = await ajax.delete("/endpoint/plantas", { params });
    return res;
  } catch (error) {
    console.error("Error en: api.helper.js - eliminarPlantas() - Error", error);
    return error.response.data;
  }
};

export const getSandcube = async (params) => {
  let response;
  try {
    response = await ajax.get("/endpoint/sandcubes", { params });
    return response;
  } catch (error) {
    console.error("Error en: api-helper.js - getSandcube() - Error", {
      error,
    });
    return error.response;
  }
};

export const editarSandcube = async (params) => {
  try {
    let res = await ajax.put("/endpoint/sandcubes", params);
    return res;
  } catch (error) {
    console.error("Error en: api.helper.js - editarSandcube() - Error", error);
    return error.response.data;
  }
};

export const editarSandcube2 = async (params) => {
  try {
    let res = await ajax.put("/endpoint/editsandcubes", params);
    return res;
  } catch (error) {
    console.error("Error en: api.helper.js - editarSandcube2() - Error", error);
    return error.response.data;
  }
};

export const editarSandcube3 = async (params) => {
  try {
    let res = await ajax.put("/endpoint/estadosandcubes", params);
    return res;
  } catch (error) {
    console.error("Error en: api.helper.js - editarSandcube3() - Error", error);
    return error.response.data;
  }
};

export const crearSandcube = async (params) => {
  try {
    let res = await ajax.post("/endpoint/sandcubes", params);
    return res;
  } catch (error) {
    console.error("Error en: api.helper.js - crearSandcube() - Error", error);
    return error.response;
  }
};

export const eliminarSandcube = async (params) => {
  try {
    let res = await ajax.delete("/endpoint/sandcubes", { params });
    return res;
  } catch (error) {
    console.error(
      "Error en: api.helper.js - eliminarSandcube() - Error",
      error
    );
    return error.response.data;
  }
};

export const eliminarSandcubeII = async (params) => {
  try {
    let res = await ajax.put("/endpoint/delsandcubes", params);
    return res;
  } catch (error) {
    console.error(
      "Error en: api.helper.js - eliminarSandcubeII() - Error",
      error
    );
    return error.response.data;
  }
};

export const getSanders = async (params) => {
  let response;
  try {
    response = await ajax.get("/endpoint/sanders", { params });
    return response;
  } catch (error) {
    console.error("Error en: api-helper.js - getSanders() - Error", {
      error,
    });
    return error.response;
  }
};

export const editarSanders = async (params) => {
  try {
    let res = await ajax.put("/endpoint/sanders", params);
    return res;
  } catch (error) {
    console.error("Error en: api.helper.js - editarSanders() - Error", error);
    return error.response.data;
  }
};

export const crearSanders = async (params) => {
  try {
    let res = await ajax.post("/endpoint/sanders", params);
    return res;
  } catch (error) {
    console.error("Error en: api.helper.js - crearSanders() - Error", error);
    return error.response.data;
  }
};

export const eliminarSanders = async (params) => {
  try {
    let res = await ajax.delete("/endpoint/sanders", { params });
    return res;
  } catch (error) {
    console.error("Error en: api.helper.js - eliminarSanders() - Error", error);
    return error.response.data;
  }
};

export const getSandvans = async (params) => {
  let response;
  try {
    response = await ajax.get("/endpoint/sandvan", { params });
    return response;
  } catch (error) {
    console.error("Error en: api-helper.js - getSandvans() - Error", {
      error,
    });
    return error.response;
  }
};

export const editarSandvans = async (params) => {
  try {
    let res = await ajax.put("/endpoint/sandvan", params);
    return res;
  } catch (error) {
    console.error("Error en: api.helper.js - editarSandvans() - Error", error);
    return error.response.data;
  }
};

export const crearSandvans = async (params) => {
  try {
    let res = await ajax.post("/endpoint/sandvan", params);
    return res;
  } catch (error) {
    console.error("Error en: api.helper.js - crearSandvans() - Error", error);
    return error.response.data;
  }
};

export const eliminarSandvans = async (params) => {
  try {
    let res = await ajax.delete("/endpoint/sandvan", { params });
    return res;
  } catch (error) {
    console.error(
      "Error en: api.helper.js - eliminarSandvans() - Error",
      error
    );
    return error.response.data;
  }
};

export const getProveedores = async (params) => {
  let response;
  try {
    response = await ajax.get("/endpoint/proveedor", { params });
    return response;
  } catch (error) {
    console.error("Error en: api-helper.js - getProveedores() - Error", {
      error,
    });
    return error.response;
  }
};
