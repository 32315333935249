import React from "react";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Checkbox,
  Stack,
} from "@chakra-ui/react";

const FormModalUsuarios = ({
  isOpen,
  onClose,
  usuarios,
  usuariosHabilitados,
  handleCheckboxChange,
  setIsModalOpen,
  selectedItem,
  idTipoE,
}) => {
  const handleTitulo = (rol) => {
    const rolesTitulos = {
      admin: "Admin",
      cliente: "Cliente",
      usuarioBrent: "Usuario BRENT",
      adminOL: "Admin Operaciones y Logística",
      coorOp: "Coordinador de Operaciones",
      operaciones: "userOperaciones",
      userOperaciones: "Usuario de Operaciones",
      userFacturacion: "Usuario de Facturación",
      userHS: "Usuario de HyS",
      gerencia: "Gerencia",
      userMantenimiento: "Usuario de Mantenimiento",
    };

    return rolesTitulos[rol] || "Rol no reconocido";
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader> {handleTitulo(selectedItem.rolUsuario)}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing={3} px={5}>
            <TableContainer>
              <Table variant="simple">
                <Thead>
                  <Tr>
                    <Th></Th>
                    <Th>Nombre y Apellido</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {usuarios
                    ?.filter((u) => u.idRol === selectedItem.rolUsuario)
                    ?.map((usuario) => {
                      const isChecked = usuariosHabilitados
                        ?.filter(
                          (usuarioHabilitado) =>
                            usuarioHabilitado.idUsuario === usuario.idUsuario
                        )
                        ?.some(
                          (usuarioHabilitado) =>
                            usuarioHabilitado.filtroOff === true
                        );
                      return (
                        <Tr key={usuario.idUsuario}>
                          <Td>
                            <Checkbox
                              isChecked={isChecked}
                              onChange={() =>
                                handleCheckboxChange({
                                  idTipoE: idTipoE,
                                  idUsuario: usuario.idUsuario,
                                  filtroOff: isChecked ? false : true,
                                  userName: usuario.username,
                                  rolUser: usuario.idRol,
                                  email: usuario.email,
                                  idTelegram: usuario.idTelegram,
                                })
                              }
                            />
                          </Td>
                          <Td>{usuario.nombre}</Td>
                        </Tr>
                      );
                    })}
                </Tbody>
              </Table>
            </TableContainer>
          </Stack>
        </ModalBody>
        <ModalFooter>
          <Button variant="ghost" onClick={() => setIsModalOpen(false)}>
            Listo
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default FormModalUsuarios;
