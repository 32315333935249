import React, { useEffect, useState } from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Stack,
  HStack,
  Heading,
  useToast,
} from "@chakra-ui/react";
import { getPlanificaciones } from "../operaciones/helpers/api.helper";

const Locacion = () => {
  const [planificaciones, setPlanificaciones] = useState([]);
  const [loading, setLoading] = useState(false);
  const toast = useToast();

  const initData = async () => {
    setLoading(true);
    let res = await getPlanificaciones();
    if (res.status === 200) {
      setPlanificaciones(res.data);
    } else if (res.status === 404) {
      toast({
        status: "success",
        description: "Cargadas correctamente",
        isClosable: true,
        duration: 2000,
      });
    } else {
      toast({
        title: "Error",
        description: "Error al cargar las planificaciones",
        duration: 3000,
        isClosable: true,
        status: "error",
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    initData();
  }, []);

  return (
    <Stack w="100%" h="xl">
      <HStack w="100%" justifyContent="space-between" spacing={7}>
        <Heading
          fontWeight="medium"
          size="xl"
          fontFamily="fonts.title"
          color="brand.gris_primario"
          pl={5}
        >
          PAD/Locacion
        </Heading>
      </HStack>

      <TableContainer h={"lg"} variant="simple" overflowY="auto">
        <Table size="sm">
          <Thead>
            <Tr borderBottom="2px solid black">
              <Th>ID Pad / Locacion</Th>
              <Th>SET</Th>
            </Tr>
          </Thead>
          {!loading && planificaciones.length < 1 && (
            <Tbody>
              <Tr>
                <Td
                  colSpan={6}
                  textAlign="center"
                  fontWeight="bold"
                  color="brand.gris_primario"
                >
                  No hay planificaciónes creadas
                </Td>
              </Tr>
            </Tbody>
          )}
          {!loading && planificaciones.length > 1 && (
            <Tbody>
              {planificaciones.map((planificacion) => (
                <Tr key={planificacion.idPlanificacion}>
                  <Td>{planificacion.idPadlocacion}</Td>
                  <Td>{planificacion.idSET === "set1" ? "Set 1" : "Set 2"}</Td>
                </Tr>
              ))}
            </Tbody>
          )}
        </Table>
      </TableContainer>
    </Stack>
  );
};

export default Locacion;
