import React, { useState } from "react";
import {
  IconButton,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useDisclosure,
  useToast,
  Button,
  Tooltip,
} from "@chakra-ui/react";
import { MdOutlineDelete } from "react-icons/md";
import { eliminarAutoelevadores } from "../../helpers/api.helper";

const EliminarAutoelevador = (props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef();
  const [loading, setLoading] = useState(false);
  const { autoelevador, initData } = props;

  const toast = useToast();

  const handleEliminarRegistro = async () => {
    setLoading(true);
    let res = await eliminarAutoelevadores({
      id: autoelevador.idAutoE,
    });
    if (res.status === 200) {
      toast({
        status: "success",
        isClosable: true,
        title: "Autoelevador eliminado correctamente",
        duration: 3000,
      });
      initData();
      onClose();
    } else {
      return toast({
        title: "Error al eliminar autoelevador",
        description: res.data,
        status: "error",
        isClosable: true,
        duration: 3000,
      });
    }
    setLoading(false);
  };

  return (
    <>
      <Tooltip label="Eliminar Autoelevador">
        <IconButton
          variant="link"
          icon={<MdOutlineDelete fontSize="20px" />}
          onClick={onOpen}
          _hover={{ color: "brand.naranja" }}
        />
      </Tooltip>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        size="xl"
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader
              color="brand.gris_primario"
              fontSize="lg"
              fontWeight="bold"
            >
              Eliminar Autoelevador
            </AlertDialogHeader>

            <AlertDialogBody>
              ¿Estás seguro que desea eliminar el{" "}
              <strong>{autoelevador.nombreAutoE}</strong>?
              <br />
              Esta acción no se puede revertir.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} variant="link" onClick={onClose}>
                Cancelar
              </Button>
              <Button
                bg="brand.naranja"
                onClick={handleEliminarRegistro}
                ml={3}
                isDisabled={loading}
                color="white"
              >
                Eliminar
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default EliminarAutoelevador;
