import React, { useState, useRef } from "react";
import QrReader from "react-qr-scanner";
import { Box, Button, Center, Heading, Icon, VStack } from "@chakra-ui/react";
import { MdCheckCircle } from "react-icons/md";

const HandleQrReader = (props) => {
  const { scannedData, setScannedData, handlecodigo, type, setFieldValue } =
    props;
  const qrRef = useRef(null);

  const handleScan = (data) => {
    if (data) {
      setScannedData(data);
    }
  };

  const handleError = (err) => {
    console.error("Error al escanear el código QR:", err);
  };

  const handleCheck = () => {
    return (
      <VStack w="100%" h="300px" justifyContent="center" spacing={7}>
        <Icon as={MdCheckCircle} bg="white" color="green.500" boxSize="50%" />
        <Heading color="green.500">QR Leido</Heading>
        <Button
          bg="brand.naranja"
          color="white"
          w={"2xs"}
          onClick={() => handlecodigo(type, setFieldValue)}
        >
          Cargar
        </Button>
      </VStack>
    );
  };

  return (
    <Box>
      {!scannedData && (
        <Center h="300px">
          <QrReader
            ref={qrRef}
            delay={3000}
            onError={handleError}
            onScan={handleScan}
            style={{ width: "100%", height: "100%" }}
          />
        </Center>
      )}
      {scannedData && handleCheck()}
    </Box>
  );
};

export default HandleQrReader;
