import React, { useState, useEffect } from "react";
import {
  HStack,
  Input,
  FormControl,
  Stack,
  FormLabel,
  Button,
  Select,
  useToast,
  Heading,
  Text,
  Switch,
  Card,
  CardBody,
  CardFooter,
  Textarea,
  IconButton,
  Box,
} from "@chakra-ui/react";
import { Form, Formik } from "formik";
import { MdNavigateNext, MdArrowDropDown, MdArrowBack } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import { getUsuarios } from "../../../../usuarios/helpers/api.helper";
import {
  getPlanificaciones,
  crearRevisionSandvan,
  editarRevisionSandvan,
  getRevisionSandvan,
} from "../../../helpers/api.helper";

const RevisionSandVan = (props) => {
  const { tipo } = props;
  const [loading, setLoading] = useState(true);
  const [usuarios, setUsuarios] = useState([]);
  const [planificacion, setPlanificacion] = useState([]);
  const [responsable, setResponsable] = useState();
  const [editable, setEditable] = useState([]);
  const navigate = useNavigate();
  const toast = useToast();
  const padLocacion = useParams();
  const [observaciones, setObservaciones] = useState("");
  const charCount = observaciones.length;

  const initData = async () => {
    let res;

    res = await getPlanificaciones(padLocacion);

    if (res.status === 200) {
      setPlanificacion(res.data[0]);
    }

    res = await getUsuarios();

    if (res.status === 200) {
      setUsuarios(res.data);
    }

    res = await getRevisionSandvan();
    if (res.status === 200) {
      if (tipo === "crear") {
        setEditable(res.data);
      }
      /*    if (tipo === "editar") {
        setEditable(() => {
          let a = res.data.filter((e) => e.idRevisionSander === parseInt(id));
          return a[0];
        });
      } */

      setLoading(false);
    }
  };

  let initialValues =
    tipo === "editar"
      ? {
          responsable: editable.responsable,
          fechaRevision: editable.fechaRevision,
          acSV: editable.acSV,
          luminariasIntSV: editable.luminariasIntSV,
          tomasInternasSV: editable.tomasInternasSV,
          presurizadorSV: editable.presurizadorSV,
          matafuegoSV: editable.matafuegoSV,
          sillasSV: editable.sillasSV,
          monitoresSV: editable.monitoresSV,
          notebookSanderSV: editable.notebookSanderSV,
          notebookFracturaSV: editable.notebookFracturaSV,
          tableroSanderASV: editable.tableroSanderASV,
          tableroSanderBSV: editable.tableroSanderBSV,
          camaraSanderASV: editable.camaraSanderASV,
          camaraSanderBSV: editable.camaraSanderBSV,
          reflectoresExternosSV: editable.reflectoresExternosSV,
          techoRebatibleSV: editable.techoRebatibleSV,
          limpiezaFiltroSV: editable.limpiezaFiltroSV,
          mangaVientoSV: editable.mangaVientoSV,
          antenaCamaraSV: editable.antenaCamaraSV,
          activacionVibradoresP1SAWET: editable.activacionVibradoresP1SAWET,
          activacionVibradoresP1SBWET: editable.activacionVibradoresP1SBWET,
          activacionVibradoresP2SAWET: editable.activacionVibradoresP2SAWET,
          activacionVibradoresP2SBWET: editable.activacionVibradoresP2SBWET,
          activacionVibradoresP3SAWET: editable.activacionVibradoresP3SAWET,
          activacionVibradoresP3SBWET: editable.activacionVibradoresP3SBWET,
          activacionVibradoresP4SAWET: editable.activacionVibradoresP4SAWET,
          activacionVibradoresP4SBWET: editable.activacionVibradoresP4SBWET,
          sistemaDosificadoraWET: editable.sistemaDosificadoraWET,
          sistemaCamarasSanderAWET: editable.sistemaCamarasSanderAWET,
          sistemaCamarasSanderBWET: editable.sistemaCamarasSanderBWET,
          tableroComandoWET: editable.tableroComandoWET,
          observacionesSV: editable.observacionesSV,
        }
      : {
          acSV: false,
          luminariasIntSV: false,
          tomasInternasSV: false,
          presurizadorSV: false,
          matafuegoSV: false,
          sillasSV: false,
          monitoresSV: false,
          notebookSanderSV: false,
          notebookFracturaSV: false,
          tableroSanderASV: false,
          tableroSanderBSV: false,
          camaraSanderASV: false,
          camaraSanderBSV: false,
          reflectoresExternosSV: false,
          techoRebatibleSV: false,
          limpiezaFiltroSV: false,
          mangaVientoSV: false,
          antenaCamaraSV: false,
          activacionVibradoresP1SAWET: false,
          activacionVibradoresP1SBWET: false,
          activacionVibradoresP2SAWET: false,
          activacionVibradoresP2SBWET: false,
          activacionVibradoresP3SAWET: false,
          activacionVibradoresP3SBWET: false,
          activacionVibradoresP4SAWET: false,
          activacionVibradoresP4SBWET: false,
          sistemaDosificadoraWET: false,
          sistemaCamarasSanderAWET: false,
          sistemaCamarasSanderBWET: false,
          tableroComandoWET: false,
          observacionesSV: "",
          responsable: "",
          fechaRevision: "",
        };

  useEffect(() => {
    initData();
  }, []);

  return (
    <Stack w="100%">
      {!loading && (
        <Formik
          initialValues={initialValues}
          onSubmit={async (values) => {
            setLoading(true);
            let data =
              tipo === "editar"
                ? {
                    acSV: values.acSV,
                    luminariasIntSV: values.luminariasIntSV,
                    tomasInternasSV: values.tomasInternasSV,
                    presurizadorSV: values.presurizadorSV,
                    matafuegoSV: values.matafuegoSV,
                    sillasSV: values.sillasSV,
                    monitoresSV: values.monitoresSV,
                    notebookSanderSV: values.notebookSanderSV,
                    notebookFracturaSV: values.notebookFracturaSV,
                    tableroSanderASV: values.tableroSanderASV,
                    tableroSanderBSV: values.tableroSanderBSV,
                    camaraSanderASV: values.camaraSanderASV,
                    camaraSanderBSV: values.camaraSanderBSV,
                    reflectoresExternosSV: values.reflectoresExternosSV,
                    techoRebatibleSV: values.techoRebatibleSV,
                    limpiezaFiltroSV: values.limpiezaFiltroSV,
                    mangaVientoSV: values.mangaVientoSV,
                    antenaCamaraSV: values.antenaCamaraSV,
                    activacionVibradoresP1SAWET:
                      values.activacionVibradoresP1SAWET,
                    activacionVibradoresP1SBWET:
                      values.activacionVibradoresP1SBWET,
                    activacionVibradoresP2SAWET:
                      values.activacionVibradoresP2SAWET,
                    activacionVibradoresP2SBWET:
                      values.activacionVibradoresP2SBWET,
                    activacionVibradoresP3SAWET:
                      values.activacionVibradoresP3SAWET,
                    activacionVibradoresP3SBWET:
                      values.activacionVibradoresP3SBWET,
                    activacionVibradoresP4SAWET:
                      values.activacionVibradoresP4SAWET,
                    activacionVibradoresP4SBWET:
                      values.activacionVibradoresP4SBWET,
                    sistemaDosificadoraWET: values.sistemaDosificadoraWET,
                    sistemaCamarasSanderAWET: values.sistemaCamarasSanderAWET,
                    sistemaCamarasSanderBWET: values.sistemaCamarasSanderBWET,
                    tableroComandoWET: values.tableroComandoWET,
                    observacionesSV: observaciones,
                    id: editable.idSanvan,
                    responsable: responsable,
                    idSandvan: planificacion.sandvan.idSandvan,
                    equipoRevision: "SandVan",
                  }
                : {
                    acSV: values.acSV,
                    luminariasIntSV: values.luminariasIntSV,
                    tomasInternasSV: values.tomasInternasSV,
                    presurizadorSV: values.presurizadorSV,
                    matafuegoSV: values.matafuegoSV,
                    sillasSV: values.sillasSV,
                    monitoresSV: values.monitoresSV,
                    notebookSanderSV: values.notebookSanderSV,
                    notebookFracturaSV: values.notebookFracturaSV,
                    tableroSanderASV: values.tableroSanderASV,
                    tableroSanderBSV: values.tableroSanderBSV,
                    camaraSanderASV: values.camaraSanderASV,
                    camaraSanderBSV: values.camaraSanderBSV,
                    reflectoresExternosSV: values.reflectoresExternosSV,
                    techoRebatibleSV: values.techoRebatibleSV,
                    limpiezaFiltroSV: values.limpiezaFiltroSV,
                    mangaVientoSV: values.mangaVientoSV,
                    antenaCamaraSV: values.antenaCamaraSV,
                    activacionVibradoresP1SAWET:
                      values.activacionVibradoresP1SAWET,
                    activacionVibradoresP1SBWET:
                      values.activacionVibradoresP1SBWET,
                    activacionVibradoresP2SAWET:
                      values.activacionVibradoresP2SAWET,
                    activacionVibradoresP2SBWET:
                      values.activacionVibradoresP2SBWET,
                    activacionVibradoresP3SAWET:
                      values.activacionVibradoresP3SAWET,
                    activacionVibradoresP3SBWET:
                      values.activacionVibradoresP3SBWET,
                    activacionVibradoresP4SAWET:
                      values.activacionVibradoresP4SAWET,
                    activacionVibradoresP4SBWET:
                      values.activacionVibradoresP4SBWET,
                    sistemaDosificadoraWET: values.sistemaDosificadoraWET,
                    sistemaCamarasSanderAWET: values.sistemaCamarasSanderAWET,
                    sistemaCamarasSanderBWET: values.sistemaCamarasSanderBWET,
                    tableroComandoWET: values.tableroComandoWET,
                    observacionesSV: observaciones,
                    responsable: responsable,
                    idSandvan: planificacion.sandvan.idSandvan,
                    equipoRevision: "SandVan",
                    idPlanificacion: planificacion.idPlanificacion,
                  };

            let res;
            if (tipo === "editar") {
              res = await editarRevisionSandvan(data);
            } else {
              res = await crearRevisionSandvan(data);
            }

            if (res.status === 200) {
              toast({
                status: "success",
                isClosable: true,
                title: `Revision de SandVan ${
                  tipo === "editar" ? "editada" : "creada"
                } correctamente`,
                duration: 3000,
              });
            } else {
              setLoading(false);
              return toast({
                status: "error",
                isClosable: true,
                title: `Error al ${
                  tipo === "editar" ? "editar" : "crear"
                } revision de sandvan`,
                duration: 3000,
              });
            }

            navigate(-1);
            setLoading(false);
          }}
        >
          {({ values, handleSubmit, handleBlur, handleChange }) => (
            <Form id="miniPala">
              <HStack>
                <IconButton
                  icon={<MdArrowBack />}
                  variant="link"
                  onClick={() => navigate(-1)}
                  fontSize="24px"
                />
                <Heading size={"sm"} px={2}>
                  Revision SandVan
                </Heading>
              </HStack>
              <HStack px={7}>
                <FormControl isRequired>
                  <FormLabel>Responsable</FormLabel>
                  <Select
                    w="2xs"
                    size="sm"
                    placeholder="Seleccione responsable"
                    icon={<MdArrowDropDown />}
                    onChange={(e) => setResponsable(e.target.value)}
                  >
                    {usuarios
                      .sort((a, b) => {
                        // Ordenar alfabéticamente por nombre y apellido
                        const nameA = `${a.nombre}`.toUpperCase();
                        const nameB = `${b.nombre}`.toUpperCase();
                        if (nameA < nameB) return -1;
                        if (nameA > nameB) return 1;
                        return 0;
                      })
                      .map((usuario) => (
                        <option
                          key={usuario.idUsuario}
                          value={usuario.idUsuario}
                        >
                          {usuario.nombre}
                        </option>
                      ))}
                  </Select>
                </FormControl>
                <FormControl>
                  <FormLabel>SandVan</FormLabel>
                  <Input
                    w="2xs"
                    size="sm"
                    value={planificacion.sandvan.codigoSandVan}
                  />
                </FormControl>
              </HStack>
              <HStack w="100%" justifyContent={"space-around"} pt={2}>
                <Stack placeSelf="start">
                  <HStack>
                    <Switch
                      onChange={handleChange}
                      isChecked={values.acSV}
                      name="acSV"
                    />
                    <Text>Aire acondicionado</Text>
                  </HStack>
                  <HStack>
                    <Switch
                      onChange={handleChange}
                      isChecked={values.luminariasIntSV}
                      name="luminariasIntSV"
                    />
                    <Text>Luminarias internas</Text>
                  </HStack>
                  <HStack>
                    <Switch
                      onChange={handleChange}
                      isChecked={values.tomasInternasSV}
                      name="tomasInternasSV"
                    />
                    <Text>Tomas internas</Text>
                  </HStack>
                  <HStack>
                    <Switch
                      onChange={handleChange}
                      isChecked={values.presurizadorSV}
                      name="presurizadorSV"
                    />
                    <Text>Presurizador</Text>
                  </HStack>
                  <HStack>
                    <Switch
                      onChange={handleChange}
                      isChecked={values.matafuegoSV}
                      name="matafuegoSV"
                    />
                    <Text>Matafuego</Text>
                  </HStack>
                  <HStack>
                    <Switch
                      onChange={handleChange}
                      isChecked={values.sillasSV}
                      name="sillasSV"
                    />
                    <Text>Sillas</Text>
                  </HStack>
                  <HStack>
                    <Switch
                      onChange={handleChange}
                      isChecked={values.monitoresSV}
                      name="monitoresSV"
                    />
                    <Text>Monitores</Text>
                  </HStack>
                  <HStack>
                    <Switch
                      onChange={handleChange}
                      isChecked={values.notebookSanderSV}
                      name="notebookSanderSV"
                    />
                    <Text>Notebook de sander</Text>
                  </HStack>
                  <HStack>
                    <Switch
                      onChange={handleChange}
                      isChecked={values.notebookFracturaSV}
                      name="notebookFracturaSV"
                    />
                    <Text>Notebook de fractura</Text>
                  </HStack>
                </Stack>
                <Stack placeSelf="start">
                  <HStack>
                    <Switch
                      onChange={handleChange}
                      isChecked={values.tableroSanderASV}
                      name="tableroSanderASV"
                    />
                    <Text>Tablero de comando sander A</Text>
                  </HStack>
                  <HStack>
                    <Switch
                      onChange={handleChange}
                      isChecked={values.tableroSanderBSV}
                      name="tableroSanderBSV"
                    />
                    <Text>Tablero de comando sander B</Text>
                  </HStack>
                  <HStack>
                    <Switch
                      onChange={handleChange}
                      isChecked={values.camaraSanderASV}
                      name="camaraSanderASV"
                    />
                    <Text>Sistema de camaras Sander A</Text>
                  </HStack>
                  <HStack>
                    <Switch
                      onChange={handleChange}
                      isChecked={values.camaraSanderBSV}
                      name="camaraSanderBSV"
                    />
                    <Text>Sistema de camaras sander B</Text>
                  </HStack>
                  <HStack>
                    <Switch
                      onChange={handleChange}
                      isChecked={values.reflectoresExternosSV}
                      name="reflectoresExternosSV"
                    />
                    <Text>Reflectores externos</Text>
                  </HStack>
                  <HStack>
                    <Switch
                      onChange={handleChange}
                      isChecked={values.techoRebatibleSV}
                      name="techoRebatibleSV"
                    />
                    <Text>Techo rebatible</Text>
                  </HStack>
                  <HStack>
                    <Switch
                      onChange={handleChange}
                      isChecked={values.limpiezaFiltroSV}
                      name="limpiezaFiltroSV"
                    />
                    <Text>Limpieza de filtro pesurizador</Text>
                  </HStack>
                  <HStack>
                    <Switch
                      onChange={handleChange}
                      isChecked={values.mangaVientoSV}
                      name="mangaVientoSV"
                    />
                    <Text>Manga de viento</Text>
                  </HStack>
                  <HStack>
                    <Switch
                      onChange={handleChange}
                      isChecked={values.antenaCamaraSV}
                      name="antenaCamaraSV"
                    />
                    <Text>Antenas sistema de camara</Text>
                  </HStack>
                </Stack>
                <Stack placeSelf="start">
                  {planificacion.idDosificadora !== null && (
                    <>
                      {" "}
                      <HStack>
                        <Switch
                          onChange={handleChange}
                          isChecked={values.tableroComandoWET}
                          name="tableroComandoWET"
                        />
                        <Text>Tablero de comando WET</Text>
                      </HStack>
                      <HStack>
                        <Switch
                          onChange={handleChange}
                          isChecked={values.sistemaDosificadoraWET}
                          name="sistemaDosificadoraWET"
                        />
                        <Text>Sistema camaras Dosificadora</Text>
                      </HStack>
                      <HStack>
                        <Switch
                          onChange={handleChange}
                          isChecked={values.activacionVibradoresP1SAWET}
                          name="activacionVibradoresP1SAWET"
                        />
                        <Text>Activacion de vibradores P1 SA WET</Text>
                      </HStack>
                      <HStack>
                        <Switch
                          onChange={handleChange}
                          isChecked={values.activacionVibradoresP2SAWET}
                          name="activacionVibradoresP2SAWET"
                        />
                        <Text>Activacion de vibradores P2 SA WET</Text>
                      </HStack>
                      <HStack>
                        <Switch
                          onChange={handleChange}
                          isChecked={values.activacionVibradoresP3SAWET}
                          name="activacionVibradoresP3SAWET"
                        />
                        <Text>Activacion de vibradores P3 SA WET</Text>
                      </HStack>
                      <HStack>
                        <Switch
                          onChange={handleChange}
                          isChecked={values.activacionVibradoresP4SAWET}
                          name="activacionVibradoresP4SAWET"
                        />
                        <Text>Activacion de vibradores P4 SA WET</Text>
                      </HStack>
                      <HStack>
                        <Switch
                          onChange={handleChange}
                          isChecked={values.activacionVibradoresP1SBWET}
                          name="activacionVibradoresP1SBWET"
                        />
                        <Text>Activacion de vibradores P1 SB WET</Text>
                      </HStack>
                      <HStack>
                        <Switch
                          onChange={handleChange}
                          isChecked={values.activacionVibradoresP2SBWET}
                          name="activacionVibradoresP2SBWET"
                        />
                        <Text>Activacion de vibradores P2 SB WET</Text>
                      </HStack>
                      <HStack>
                        <Switch
                          onChange={handleChange}
                          isChecked={values.activacionVibradoresP3SBWET}
                          name="activacionVibradoresP3SBWET"
                        />
                        <Text>Activacion de vibradores P3 SB WET</Text>
                      </HStack>
                      <HStack>
                        <Switch
                          onChange={handleChange}
                          isChecked={values.activacionVibradoresP4SBWET}
                          name="activacionVibradoresP4SBWET"
                        />
                        <Text>Activacion de vibradores P4 SB WET</Text>
                      </HStack>
                    </>
                  )}
                </Stack>
              </HStack>
              <HStack w="100%" justifyContent="space-between" px={3}>
                <Box></Box>
                <FormControl w={"lg"}>
                  <FormLabel>Observaciones</FormLabel>
                  <Card w="lg" variant="unstyled" bg="brand.fondos_secundarios">
                    <CardBody>
                      <Textarea
                        w="lg"
                        placeholder="Descripcion"
                        name="observacionesSV"
                        onChange={(e) => setObservaciones(e.target.value)}
                        onBlur={handleBlur}
                        maxLength="1500"
                        resize="none"
                      />
                    </CardBody>
                    <CardFooter placeSelf="end" color="brand.gris_medio">
                      {charCount}/1500
                    </CardFooter>
                  </Card>
                </FormControl>
                <Button
                  placeSelf={"end"}
                  fontSize={12}
                  fontWeight="semibold"
                  bg="brand.naranja"
                  color="white"
                  rightIcon={<MdNavigateNext fontSize="20px" />}
                  variant="solid"
                  form="miniPala"
                  onClick={handleSubmit}
                  isDisabled={loading}
                  _hover={{ bg: "white", color: "brand.naranja" }}
                >
                  Finalizar
                </Button>
              </HStack>
            </Form>
          )}
        </Formik>
      )}
    </Stack>
  );
};

export default RevisionSandVan;
