import React, { useEffect, useState } from "react";
import {
  FormControl,
  HStack,
  Input,
  FormLabel,
  Button,
  Stack,
  Heading,
  IconButton,
  useToast,
  Checkbox,
  Select,
} from "@chakra-ui/react";
import { MdFileDownloadDone, MdArrowBack } from "react-icons/md";
import { Formik, Form } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import { getCamiones } from "../../../operaciones/helpers/api.helper";
import { editarCamiones, getTransportista } from "../../helpers/api.helper";

const FormEditarCamion = () => {
  const [loading, setLoading] = useState(false);
  const [camion, setCamion] = useState([]);
  const [transportista, setTransportista] = useState([]);
  const navigate = useNavigate();
  const { nombreTransportista, id } = useParams();
  const toast = useToast();

  const initData = async () => {
    setLoading(true);
    let res;
    res = await getCamiones({ id: id });

    if (res.status === 200) {
      setCamion(res.data[0]);
    } else {
      return toast({
        title: "Error",
        description: "Error al cargar los datos",
        isClosable: true,
        duration: 3000,
      });
    }

    res = await getTransportista({ nombre: nombreTransportista });
    if (res.status === 200) {
      setTransportista(res.data[0]);
    } else {
      return toast({
        title: "Error",
        description: "Error al cargar los datos",
        isClosable: true,
        duration: 3000,
      });
    }

    setLoading(false);
  };

  const handleSubmit = async (values) => {
    setLoading(true);
    if (!values.nombreCamion || !values.dominio) {
      setLoading(false);
      return toast({
        title: "Error",
        description: "Se requiere una identificacion para el camión",
        status: "error",
        isClosable: true,
        duration: 3000,
      });
    }
    if (!values.tipoContratoCamion) {
      setLoading(false);
      return toast({
        title: "Error",
        description: "Tipo de contrato requerido",
        status: "error",
        isClosable: true,
        duration: 3000,
      });
    }

    let tempProv = [];

    tempProv.push({
      idProveedor: 1,
      activada: values.rsv ? 1 : 0,
    });

    tempProv.push({
      idProveedor: 2,
      activada: values.microtrack ? 1 : 0,
    });

    tempProv.push({
      idProveedor: 3,
      activada: values.geotab ? 1 : 0,
    });

    let parserdCamiones = {
      nombreCamion: values.nombreCamion,
      dominio: values.dominio,
      tipoContratoCamion: values.tipoContratoCamion,
      idEmpresaT: camion.empresaTransportista.idEmpresaT,
      proveedores: tempProv,
      idCamion: camion.idCamion,
    };

    let res = await editarCamiones(parserdCamiones);
    if (res.status === 200) {
      toast({
        status: "success",
        isClosable: true,
        title: "Camion editado correctamente",
        duration: 3000,
      });
      navigate(-1);
    } else {
      return toast({
        status: "error",
        isClosable: true,
        title: "Error al editar el camion",
        description: res.data.error.message || "Error al crear camiones",
      });
    }

    setLoading(false);
  };
  useEffect(() => {
    initData();
  }, []);

  const initialValues = {
    nombreCamion: camion.nombreCamion,
    dominio: camion.dominio,
    idRSV: camion.idRSV,
    idMickroTrack: camion.idMickroTrack,
    tipoContratoCamion: camion.tipoContratoCamion,
    geotab:
      camion.proveedores && camion.proveedores.some((e) => e.idProveedor === 3)
        ? true
        : false,
    rsv:
      camion.proveedores && camion.proveedores.some((e) => e.idProveedor === 1)
        ? true
        : false,
    microtrack:
      camion.proveedores && camion.proveedores.some((e) => e.idProveedor === 2)
        ? true
        : false,
  };

  return (
    <Stack w={"100%"} p={3} spacing={5}>
      <HStack>
        <IconButton
          icon={<MdArrowBack />}
          variant="link"
          onClick={() => navigate(-1)}
          fontSize="24px"
        />
        <Heading
          fontWeight="medium"
          size="xl"
          fontFamily="fonts.title"
          color="brand.gris_primario"
        >
          Editar Camión
        </Heading>
      </HStack>
      {!loading && (
        <Formik initialValues={initialValues}>
          {({ values, handleBlur, handleChange }) => (
            <Form id="formAgregarCamiones">
              <Stack w="100%" spacing={5} px={5}>
                <HStack py={3}>
                  <FormControl>
                    <FormLabel>ID Interno</FormLabel>
                    <Stack>
                      <Input
                        w={"150px"}
                        placeholder="ID Interno"
                        name={"nombreCamion"}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.nombreCamion}
                      />
                    </Stack>
                  </FormControl>
                  <FormControl>
                    <FormLabel>Dominio</FormLabel>
                    <Stack>
                      <Input
                        w={"150px"}
                        placeholder="Dominio"
                        name={"dominio"}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.dominio}
                      />
                    </Stack>
                  </FormControl>
                  <FormControl>
                    <FormLabel>Tipo contrato</FormLabel>
                    <Stack>
                      <Select
                        w={"150px"}
                        placeholder="- Tipo contrato -"
                        name={"tipoContratoCamion"}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.tipoContratoCamion}
                      >
                        <option>{transportista.tipoContrato}</option>
                        {transportista.tipoContratoB && (
                          <option>{transportista.tipoContratoB}</option>
                        )}
                      </Select>
                    </Stack>
                  </FormControl>
                  <FormControl>
                    <FormLabel>Proveedores</FormLabel>
                    <Stack>
                      <HStack py={2}>
                        <Checkbox
                          onChange={handleChange}
                          isChecked={values.rsv}
                          name={"rsv"}
                        >
                          RSV
                        </Checkbox>
                        <Checkbox
                          onChange={handleChange}
                          isChecked={values.microtrack}
                          name={"microtrack"}
                        >
                          Microtrack
                        </Checkbox>
                        <Checkbox
                          onChange={handleChange}
                          isChecked={values.geotab}
                          name={"geotab"}
                        >
                          Geotab
                        </Checkbox>
                      </HStack>
                    </Stack>
                  </FormControl>
                </HStack>
                <Button
                  placeSelf="end"
                  rightIcon={<MdFileDownloadDone />}
                  bg="brand.naranja"
                  color="white"
                  isLoading={loading}
                  size="md"
                  onClick={() => handleSubmit(values)}
                >
                  Finalizar
                </Button>
              </Stack>
            </Form>
          )}
        </Formik>
      )}
    </Stack>
  );
};

export default FormEditarCamion;
