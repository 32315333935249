import ajax from "../../../utils/axios";

export const getGeocercas = async (params) => {
  let response;
  try {
    response = await ajax.get("/endpoint/geocercas", { params });
    return response;
  } catch (error) {
    console.error("Error en: api-helper.js - getGeocercas() - Error", {
      error,
    });
    return error.response;
  }
};

export const crearGeocercas = async (params) => {
  try {
    let res = await ajax.post("/endpoint/geocercas", params);
    return res;
  } catch (error) {
    console.error("Error en: api.helper.js - crearGeocercas() - Error", error);
    return error.response.data;
  }
};

export const editarGeocercas = async (params) => {
  try {
    let res = await ajax.put("/endpoint/geocercas", params);
    return res;
  } catch (error) {
    console.error("Error en: api.helper.js - editarGeocercas() - Error", error);
    return error.response;
  }
};

export const eliminarGeocercas = async (params) => {
  try {
    let res = await ajax.delete("/endpoint/geocercas", { params });
    return res;
  } catch (error) {
    console.error(
      "Error en: api.helper.js - eliminarGeocercas() - Error",
      error
    );
    return error.response.data;
  }
};

export const getGeocamiones = async (params) => {
  let response;
  try {
    response = await ajax.get("/endpoint/geocamiones", { params });
    return response;
  } catch (error) {
    console.error("Error en: api-helper.js - getGeocamiones() - Error", {
      error,
    });
    return error.response;
  }
};
