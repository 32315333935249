import React, { useState } from "react";
import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Stack,
  HStack,
  Heading,
  Input,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  IconButton,
  Button,
  useToast,
  CircularProgress,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  useDisclosure,
} from "@chakra-ui/react";
import { MdArrowRight, MdArrowLeft, MdFileDownloadDone } from "react-icons/md";
import { getCamiones, getSandcubes } from "../../helpers/api.helper";
import {
  crearSandcubesPlanificacion,
  editarPlanificacion4,
} from "../../helpers/api.helper";
import { useEffect } from "react";

const EditarCamionesSandcubes = (props) => {
  const { seleccionado, initPlan } = props;
  const [sandcubs, setSandcubs] = useState([]);
  const [sandcubesSeleccionados, setSandcubesSeleccionados] = useState([]);
  const [camionesSeleccionados, setCamionesSeleccionados] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState();
  const [camiones, setCamiones] = useState([]);
  const [camionesGuardados, setCamionesGuardados] = useState([]);
  const [sandcubesGuardados, setSandcubesGuardados] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [filterBy, setFilterBy] = useState({
    dominio: "",
    empresa: "",
  });
  const toast = useToast();

  const initData = async () => {
    let res;

    res = await getCamiones();

    if (res.status === 200) {
      let camionesFiltrados = res.data.filter(
        (e) => e.empresaTransportista.habilitado
      );
      setCamiones(camionesFiltrados);
    }

    res = await getSandcubes();

    if (res.status === 200) {
      setSandcubs(res.data);
    }

    setLoading(false);
  };

  const handleSeleccionados = (sandcube) => {
    setSandcubs(
      sandcubs.filter((item) => item.idSandCube !== sandcube.idSandCube)
    );
    setSandcubesSeleccionados([...sandcubesSeleccionados, sandcube]);
  };

  const handleLista = (e) => {
    setSandcubesSeleccionados(
      sandcubesSeleccionados.filter(
        (items) => items.idSandCube !== e.idSandCube
      )
    );
    setSandcubs([...sandcubs, e]);
  };

  const showSandcubes = () => {
    if (!filter) {
      if (sandcubesSeleccionados.length > 0) {
        return sandcubs.filter(
          (obj1) =>
            !sandcubesSeleccionados.some(
              (obj2) => obj2.idSandCube === obj1.idSandCube
            )
        );
      } else {
        return sandcubs;
      }
    } else {
      let item = [];

      let filtrado = filter.toUpperCase();

      item = sandcubs.filter((element) => {
        return element.nombreSandCube.toString().includes(filtrado);
      });

      return item.filter(
        (obj1) =>
          !sandcubesSeleccionados.some(
            (obj2) => obj2.idSandCube === obj1.idSandCube
          )
      );
    }
  };

  const filtrocamiones = () => {
    if (Object.values(filterBy).every((value) => !value)) {
      if (camionesSeleccionados.length > 0) {
        return camiones.filter(
          (obj1) =>
            !camionesSeleccionados.some(
              (obj2) => obj2.idCamion === obj1.idCamion
            )
        );
      } else {
        return camiones;
      }
    } else if (filterBy.dominio) {
      let item = [];

      let filtrado = filterBy.dominio.toUpperCase();

      item = camiones.filter((element) => {
        return element.dominio.toUpperCase().includes(filtrado);
      });

      return item.filter(
        (obj1) =>
          !camionesSeleccionados.some((obj2) => obj2.idCamion === obj1.idCamion)
      );
    } else {
      let item = [];

      let filtrado = filterBy.empresa.toUpperCase();

      item = camiones.filter((element) => {
        return element.empresaTransportista.nombreEmpresaT
          .toUpperCase()
          .includes(filtrado);
      });

      return item.filter(
        (obj1) =>
          !camionesSeleccionados.some((obj2) => obj2.idCamion === obj1.idCamion)
      );
    }
  };

  const handleSeleccionadosCamion = (camion) => {
    setCamiones(camiones.filter((item) => item.idCamion !== camion.idCamion));
    setCamionesSeleccionados([...camionesSeleccionados, camion]);
  };

  const handleListaCamion = (camion) => {
    setCamionesSeleccionados(
      camionesSeleccionados.filter(
        (items) => items.idCamion !== camion.idCamion
      )
    );
    setCamiones([...camiones, camion]);
  };

  const loadingtable = () => {
    return (
      <HStack w="100%" placeContent="center">
        <CircularProgress isIndeterminate color="brand.naranja" />
      </HStack>
    );
  };

  const handleCloseModal = () => {
    setCamionesGuardados([]);
    setSandcubesGuardados([]);
    setSandcubesSeleccionados([]);
    setCamionesSeleccionados([]);
    onClose();
  };

  const handleSubmitCamiones = async () => {
    setLoading(true);
    if (camionesGuardados.length > 0) {
      let idparsed = camionesGuardados.map((element) => {
        return { idCamion: element.idCamion };
      });

      let res = await editarPlanificacion4({
        editCamion: true,
        id: seleccionado.idPlanificacion,
        camion: idparsed,
      });

      if (res.status === 200) {
        toast({
          status: "success",
          isClosable: true,
          title: "Camiones editados correctamente",
          duration: 3000,
        });
      } else {
        setLoading(false);
        return toast({
          status: "error",
          isClosable: true,
          title: "Error al editar camiones",
          duration: 3000,
        });
      }

      initPlan();
      handleCloseModal();
    }
    setLoading(false);
  };

  const handleSubmitSandcubes = async () => {
    setLoading(true);

    if (sandcubesGuardados.length > 0) {
      let parsedSandcubes = sandcubesGuardados.map((e) => {
        return {
          idPlanificacion: seleccionado.idPlanificacion,
          idSandCube: e.idSandCube,
        };
      });

      let res = await crearSandcubesPlanificacion({
        idPlanificacion: seleccionado.idPlanificacion,
        sandCubes: parsedSandcubes,
      });

      if (res.status === 200) {
        toast({
          status: "success",
          isClosable: true,
          title: "Sandcubes editados correctamente",
          duration: 3000,
        });
      } else {
        setLoading(false);
        return toast({
          status: "error",
          isClosable: true,
          title: "Error al editar Sandcubes",
          duration: 3000,
        });
      }

      initPlan();
      handleCloseModal();
    }
    setLoading(false);
  };

  const handleOpen = () => {
    onOpen();
    if (seleccionado.scAsociados) {
      setSandcubesSeleccionados(seleccionado.scAsociados);
    } else {
      setSandcubesSeleccionados([]);
    }
    if (seleccionado.camiones) {
      setCamionesSeleccionados(seleccionado.camiones);
    } else {
      setCamionesSeleccionados([]);
    }
  };

  useEffect(() => {
    initData();
  }, []);

  return (
    <>
      <Button onClick={handleOpen} variant={"link"}>
        Editar Camiones/Sandcubes
      </Button>

      <Modal
        isOpen={isOpen}
        onClose={handleCloseModal}
        scrollBehavior={"inside"}
        size={"6xl"}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalBody>
            <Tabs isFitted variant="enclosed">
              <TabList>
                <Tab>Camiones</Tab>
                <Tab>Sandcubes</Tab>
              </TabList>

              <TabPanels>
                <TabPanel>
                  {loading && loadingtable()}
                  {!loading && camiones.length >= 1 && (
                    <Stack w="100%">
                      <Stack w={"100%"}>
                        <HStack>
                          <Stack w={"50%"}>
                            <HStack>
                              <Heading size="md">Lista de Camiones</Heading>
                              <Input
                                w="130px"
                                type="text"
                                value={filterBy.dominio}
                                placeholder={`Buscar por dominio`}
                                onChange={(e) => {
                                  setFilterBy((prevstate) => ({
                                    ...prevstate,
                                    dominio: e.target.value,
                                  }));
                                  filtrocamiones();
                                }}
                                variant="flushed"
                                size={"sm"}
                              />
                              <Input
                                w="130px"
                                type="text"
                                value={filterBy.empresa}
                                placeholder={`Buscar por empresa`}
                                onChange={(e) => {
                                  setFilterBy((prevstate) => ({
                                    ...prevstate,
                                    empresa: e.target.value,
                                  }));
                                  filtrocamiones();
                                }}
                                variant="flushed"
                                size={"sm"}
                              />
                            </HStack>
                            <TableContainer
                              w={"100%"}
                              h="xs"
                              overflowY="scroll"
                            >
                              <Table size="sm" variant="simple">
                                <Thead>
                                  <Tr>
                                    <Th>Dominio</Th>
                                    <Th>Interno</Th>
                                    <Th>Empresa</Th>
                                    <Th></Th>
                                  </Tr>
                                </Thead>
                                <Tbody>
                                  {filtrocamiones().map((camion) => (
                                    <Tr key={camion.idCamion}>
                                      <Td>{camion.dominio}</Td>
                                      <Td>{camion.nombreCamion}</Td>
                                      <Td>
                                        {
                                          camion.empresaTransportista
                                            .nombreEmpresaT
                                        }
                                      </Td>
                                      <Td>
                                        <IconButton
                                          variant="link"
                                          icon={<MdArrowRight />}
                                          onClick={() => {
                                            handleSeleccionadosCamion(camion);
                                          }}
                                          _hover={{ color: "brand.naranja" }}
                                        />
                                      </Td>
                                    </Tr>
                                  ))}
                                </Tbody>
                              </Table>
                            </TableContainer>
                          </Stack>
                          <Stack w={"50%"}>
                            <Heading size="md">Seleccionados</Heading>
                            <TableContainer
                              w={"100%"}
                              h="xs"
                              overflowY="scroll"
                            >
                              <Table size="sm" variant="simple">
                                <Thead>
                                  <Tr>
                                    <Th></Th>
                                    <Th>Dominio</Th>
                                    <Th>Interno</Th>
                                    <Th>Empresa</Th>
                                  </Tr>
                                </Thead>
                                <Tbody>
                                  {camionesSeleccionados.map((camion) => (
                                    <Tr key={camion.idCamion}>
                                      <Td>
                                        <IconButton
                                          variant="link"
                                          icon={<MdArrowLeft />}
                                          onClick={() => {
                                            handleListaCamion(camion);
                                          }}
                                          _hover={{ color: "brand.naranja" }}
                                        />
                                      </Td>
                                      <Td>{camion.dominio}</Td>
                                      <Td>{camion.nombreCamion}</Td>
                                      <Td>
                                        {
                                          camion.empresaTransportista
                                            .nombreEmpresaT
                                        }
                                      </Td>
                                    </Tr>
                                  ))}
                                </Tbody>
                              </Table>
                            </TableContainer>
                          </Stack>
                        </HStack>
                      </Stack>
                      <HStack w="100%" justifyContent="space-between">
                        <Button
                          bg={"red"}
                          color={"white"}
                          onClick={handleCloseModal}
                          isDisabled={loading}
                          _hover={{ opacity: 0.5 }}
                          placeSelf={"end"}
                        >
                          Cancelar
                        </Button>
                        <Stack>
                          <Button
                            bg={"brand.naranja"}
                            color={"white"}
                            variant="solid"
                            onClick={() => {
                              setCamionesGuardados(camionesSeleccionados);
                              toast({
                                status: "success",
                                isClosable: true,
                                title: "Camiones guardados correctamente",
                                duration: 1500,
                              });
                            }}
                            isDisabled={loading}
                            _hover={{ bg: "white", color: "brand.naranja" }}
                          >
                            Guardar
                          </Button>
                          <Button
                            color="brand.naranja"
                            fontSize={12}
                            letterSpacing={1}
                            border="1px solid orange"
                            variant="outline"
                            leftIcon={<MdFileDownloadDone fontSize="20px" />}
                            textColor="brand.naranja"
                            onClick={() => {
                              handleSubmitSandcubes();
                              handleSubmitCamiones();
                            }}
                            isDisabled={camionesGuardados.length < 1 || loading}
                            _hover={{ bg: "white", color: "brand.naranja" }}
                          >
                            Finalizar
                          </Button>
                        </Stack>
                      </HStack>
                    </Stack>
                  )}
                  {!loading && camiones.length < 1 && (
                    <Heading textAlign={"center"} size={"md"}>
                      No hay datos para mostrar
                    </Heading>
                  )}
                </TabPanel>
                <TabPanel>
                  {loading && loadingtable()}
                  {!loading && sandcubs.length >= 1 && (
                    <Stack w="100%">
                      <HStack w="100%">
                        <Stack w="50%">
                          <HStack>
                            <Heading size="md">Lista de Sandcubes</Heading>
                            <Input
                              w="150px"
                              type="text"
                              value={filter}
                              placeholder={`Buscar`}
                              onChange={(e) => {
                                setFilter(e.target.value);
                              }}
                              variant="flushed"
                              size={"sm"}
                            />
                          </HStack>
                          <TableContainer w={"100%"} h="xs" overflowY="scroll">
                            <Table size="sm" variant="simple">
                              <Thead>
                                <Tr>
                                  <Th>ID Sandcube</Th>
                                  <Th>Malla</Th>
                                  <Th></Th>
                                </Tr>
                              </Thead>
                              <Tbody>
                                {showSandcubes().map((sandcube) => (
                                  <Tr key={sandcube.idSandCube}>
                                    <Td>{sandcube.nombreSandCube}</Td>
                                    <Td>{sandcube.malla}</Td>
                                    <Td>
                                      <IconButton
                                        variant="link"
                                        icon={<MdArrowRight />}
                                        onClick={() => {
                                          handleSeleccionados(sandcube);
                                        }}
                                        _hover={{ color: "brand.naranja" }}
                                      />
                                    </Td>
                                  </Tr>
                                ))}
                              </Tbody>
                            </Table>
                          </TableContainer>
                        </Stack>
                        <Stack w="50%">
                          <Heading size="md">Seleccionados</Heading>
                          <TableContainer h="xs" overflowY="scroll">
                            <Table size="sm" variant="simple">
                              <Thead>
                                <Tr>
                                  <Th></Th>
                                  <Th>ID Sandcube</Th>
                                  <Th>Malla</Th>
                                </Tr>
                              </Thead>
                              <Tbody>
                                {sandcubesSeleccionados.map((sandcube) => (
                                  <Tr key={sandcube.idSandCube}>
                                    <Td>
                                      <IconButton
                                        variant="link"
                                        icon={<MdArrowLeft />}
                                        onClick={() => {
                                          handleLista(sandcube);
                                        }}
                                        _hover={{ color: "brand.naranja" }}
                                      />
                                    </Td>
                                    <Td>{sandcube.nombreSandCube}</Td>
                                    <Td>{sandcube.malla}</Td>
                                  </Tr>
                                ))}
                              </Tbody>
                            </Table>
                          </TableContainer>
                        </Stack>
                      </HStack>
                      <HStack w="100%" justifyContent={"space-between"}>
                        <Button
                          bg={"red"}
                          color={"white"}
                          onClick={onClose}
                          isDisabled={loading}
                          _hover={{ opacity: 0.5 }}
                          placeSelf={"end"}
                        >
                          Cancelar
                        </Button>
                        <Stack>
                          <Button
                            bg={"brand.naranja"}
                            color={"white"}
                            variant="solid"
                            onClick={() => {
                              setSandcubesGuardados(sandcubesSeleccionados);
                              toast({
                                status: "success",
                                isClosable: true,
                                title: "Sandcubes guardados correctamente",
                                duration: 1500,
                              });
                            }}
                            isDisabled={loading}
                            _hover={{ bg: "white", color: "brand.naranja" }}
                          >
                            Guardar
                          </Button>
                          <Button
                            color="brand.naranja"
                            fontSize={12}
                            letterSpacing={1}
                            border="1px solid orange"
                            variant="outline"
                            leftIcon={<MdFileDownloadDone fontSize="20px" />}
                            textColor="brand.naranja"
                            form="FormularioPlanificacion"
                            onClick={() => {
                              handleSubmitSandcubes();
                              handleSubmitCamiones();
                            }}
                            isDisabled={
                              sandcubesGuardados.length < 1 || loading
                            }
                            _hover={{ bg: "white", color: "brand.naranja" }}
                          >
                            Finalizar
                          </Button>
                        </Stack>
                      </HStack>
                    </Stack>
                  )}
                  {!loading && sandcubs.length < 1 && (
                    <Heading textAlign={"center"} size={"md"}>
                      No hay datos para mostrar
                    </Heading>
                  )}
                </TabPanel>
              </TabPanels>
            </Tabs>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default EditarCamionesSandcubes;
