import ajax from "../../../utils/axios";

export const getUsuarios = async (params) => {
  let response;

  try {
    if (params) {
      response = await ajax.get("/endpoint/usuarios", { params });
    } else {
      response = await ajax.get("/endpoint/usuarios");
    }
    return response;
  } catch (error) {
    console.error("Error en: api-helper.js - getUsuarios() - Error", { error });
    return error.response;
  }
};

export const deleteUsuarios = async (params) => {
  try {
    let res = await ajax.delete("/endpoint/usuarios", { params });
    return res;
  } catch (error) {
    console.error("Error en: api.helper.js - deleteUsuarios() - Error", error);
    return error.response.data;
  }
};

export const createUsuario = async (params) => {
  try {
    let res = await ajax.post("/endpoint/usuarios", params);
    return res;
  } catch (error) {
    console.error("Error en: api.helper.js - createUsuario() - Error", error);
    return error.response.data;
  }
};
export const putUsuarios = async (params) => {
  try {
    let res = await ajax.put("/endpoint/usuarios", params);
    return res;
  } catch (error) {
    console.error("Error en: api.helper.js - putUsuarios() - Error", error);
    return error.response.data;
  }
};
