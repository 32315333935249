import {
  Stack,
  HStack,
  FormControl,
  FormLabel,
  Input,
  Heading,
  Select,
  Radio,
  RadioGroup,
  IconButton,
  Button,
  useToast,
  useMediaQuery,
} from "@chakra-ui/react";
import { Formik, Form } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { MdNavigateNext, MdArrowBack } from "react-icons/md";
import { putUsuarios, createUsuario, getUsuarios } from "../helpers/api.helper";
import { getClientes } from "../../inventario/helpers/api.helper";
import { useSelector } from "react-redux";

const FormCrearEditarUsuarios = (props) => {
  const { type, initUsuarios, onClose } = props;
  const [loading, setLoading] = useState(false);
  const [usuario, setUsuario] = useState([]);
  const [clientes, setClientes] = useState([]);
  const navigate = useNavigate();
  const id = useParams();
  const toast = useToast();
  const [mobile] = useMediaQuery("(max-width: 425px)");
  let codigoUsuario = Math.floor(Math.random() * 101);
  const { user } = useSelector((state) => state.user);

  const initData = async () => {
    setLoading(true);
    let res;

    res = await getUsuarios(id);

    if (res.status === 200) {
      setUsuario(res.data[0]);
    } else {
      toast({
        title: "Error",
        description: "Error al cargar los datos",
        isClosable: true,
        duration: 3000,
      });
    }

    res = await getClientes();

    if (res.status === 200) {
      setClientes(res.data);
    } else {
      toast({
        title: "Error",
        description: "Error al cargar los datos",
        isClosable: true,
        duration: 3000,
      });
    }

    setLoading(false);
  };

  let initialValues =
    type === "editar"
      ? {
          nombre: usuario.nombre,
          apellido: usuario.apellido,
          email: usuario.email,
          idRol: usuario.idRol,
          estado: usuario.estado,
          codigoUsuario: usuario.codigoUsuario,
          idTelegram: usuario.idTelegram,
          puesto: usuario.puesto,
          idCliente: usuario.idCliente,
        }
      : {
          nombre: "",
          apellido: "",
          email: "",
          idRol: "",
          estado: "",
          idTelegram: "",
          puesto: "",
          idCliente: "",
        };

  useEffect(() => {
    initData();
  }, []);

  return (
    <Stack bg="brand.fondo" w="100%" h="md" borderRadius={10} spacing={7}>
      <HStack w="100%" pl={5}>
        <IconButton
          icon={<MdArrowBack />}
          variant="link"
          onClick={() => navigate(-1)}
          fontSize="24px"
        />
        {type === "editar" && (
          <Heading fontWeight="medium" py={3}>
            Editar Usuario
          </Heading>
        )}
        {type === "crear" && (
          <Heading fontWeight="medium" py={3}>
            Nuevo Usuario
          </Heading>
        )}
      </HStack>
      {!loading && (
        <Formik
          initialValues={initialValues}
          onSubmit={async (values) => {
            setLoading(true);
            if (!values.nombre) {
              setLoading(false);
              return toast({
                title: "Error",
                description: "Nombre de usuario requerido",
                status: "error",
                isClosable: true,
                duration: 3000,
              });
            }
            if (!values.email) {
              setLoading(false);
              return toast({
                title: "Error",
                description: "Email requerido",
                status: "error",
                isClosable: true,
                duration: 3000,
              });
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            ) {
              setLoading(false);
              return toast({
                title: "Error",
                description: "Email invalido",
                status: "error",
                isClosable: true,
                duration: 3000,
              });
            }
            if (!values.idRol) {
              setLoading(false);
              return toast({
                title: "Error",
                description: "Rol de usuario requerido",
                status: "error",
                isClosable: true,
                duration: 3000,
              });
            }
            if (!values.estado) {
              setLoading(false);
              return toast({
                title: "Error",
                description: "Estado de usuario requerido",
                status: "error",
                isClosable: true,
                duration: 3000,
              });
            }

            let data;

            if (
              values.idRol === "userOperaciones" ||
              values.idRol === "usuarioBrent"
            ) {
              data = {
                nombre: values.nombre,
                apellido: null,
                email: values.email,
                estado: values.estado,
                idRol: values.idRol,
                idTelegram: values.idTelegram || null,
                codigoUsuario: codigoUsuario,
                puesto: values.puesto,
              };
            } else if (values.idRol === "cliente") {
              data = {
                nombre: values.nombre,
                apellido: null,
                email: values.email,
                estado: values.estado,
                idRol: values.idRol,
                idTelegram: values.idTelegram || null,
                codigoUsuario: codigoUsuario,
                idCliente: values.idCliente,
              };
            } else {
              data = {
                nombre: values.nombre,
                apellido: null,
                email: values.email,
                estado: values.estado,
                idRol: values.idRol,
                idTelegram: values.idTelegram || null,
                codigoUsuario: codigoUsuario,
                puesto: values.puesto,
              };
            }

            let res;

            if (type === "editar") {
              res = await putUsuarios({
                ...data,
                idUsuario: usuario.idUsuario,
              });
            }
            if (type === "crear") {
              res = await createUsuario(data);
            }

            if (res.status === 200) {
              toast({
                status: "success",
                isClosable: true,
                title: `Usuario ${
                  type === "crear" ? "creado" : "editado"
                } correctamente`,
                duration: 3000,
              });
              navigate(-1);
              initUsuarios();
            } else {
              return toast({
                status: "error",
                isClosable: true,
                title: `Error al ${
                  type === "crear" ? "crear" : "editar"
                } usuario`,
                description:
                  res.data.error.message || "Error al editar usuario",
              });
            }

            setLoading(false);
          }}
        >
          {({ values, handleChange, handleBlur, handleSubmit }) => (
            <Form id="formUsuarios">
              <Stack w="100%" spacing={10} px={7}>
                <Stack
                  w="100%"
                  direction={mobile ? "column" : "row"}
                  spacing={10}
                >
                  <FormControl w={"xs"}>
                    <FormLabel fontWeight="bold">Nombre y Apellido</FormLabel>
                    <HStack>
                      <Input
                        name="nombre"
                        type="text"
                        placeholder="Nombre y Apellido"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.nombre}
                      />
                    </HStack>
                  </FormControl>
                  <FormControl w={"xs"} alignSelf="start">
                    <FormLabel fontWeight="bold">Email</FormLabel>
                    <Input
                      name="email"
                      type="email"
                      placeholder="Email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                    />
                  </FormControl>
                  <FormControl w={"xs"} alignSelf="start">
                    <FormLabel fontWeight="bold">ID Telegram</FormLabel>
                    <Input
                      name="idTelegram"
                      placeholder="ID telegram"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.idTelegram}
                    />
                  </FormControl>
                </Stack>
                <Stack direction={mobile ? "column" : "row"} spacing={10}>
                  <FormControl w={"xs"}>
                    <FormLabel fontWeight="bold">Rol</FormLabel>
                    <Select
                      name="idRol"
                      placeholder="Seleccione rol"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.idRol}
                    >
                      {user.idRol === "admin" && (
                        <option value="admin">Admin</option>
                      )}
                      <option value="cliente">Cliente</option>
                      <option value="usuarioBrent">Usuario BRENT</option>
                      <option value="coorOp">Coordinador de Operaciones</option>
                      <option value="userOperaciones">Operaciones</option>
                      <option value="userFacturacion">Facturación</option>
                      <option value="userHS">Higiene y Seguridad</option>
                      <option value="gerencia">Gerencia</option>
                      <option value="usuarioIT">Usuario IT</option>
                      <option value="userMantenimiento">Mantenimiento</option>
                    </Select>
                  </FormControl>
                  {(values.idRol === "userOperaciones" ||
                    values.idRol === "usuarioBrent") && (
                    <FormControl w={"xs"}>
                      <FormLabel fontWeight="bold">Puesto</FormLabel>
                      <Select
                        name="puesto"
                        placeholder="Seleccione puesto"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.puesto}
                      >
                        {values.idRol === "userOperaciones" && (
                          <>
                            <option value="supervisor">Supervisor</option>
                            <option value="operador">Operador</option>
                          </>
                        )}
                        {values.idRol === "usuarioBrent" && (
                          <>
                            <option value="maquinista">Maquinista</option>
                            <option value="brent">Usuario Brent</option>
                          </>
                        )}
                      </Select>
                    </FormControl>
                  )}
                  {values.idRol === "cliente" && (
                    <FormControl w={"xs"}>
                      <FormLabel fontWeight="bold">Cliente</FormLabel>
                      <Select
                        name="idCliente"
                        placeholder="Seleccione cliente"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.idCliente}
                      >
                        {clientes.map((e) => (
                          <option key={e.idCliente} value={e.idCliente}>
                            {e.nombreCliente}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                  <FormControl w={"xs"} alignSelf="center">
                    <RadioGroup
                      name="estado"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.estado}
                    >
                      <Stack direction="row">
                        <Radio
                          value="activado"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        >
                          Activar
                        </Radio>
                        <Radio
                          value="desactivado"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        >
                          Desactivar
                        </Radio>
                      </Stack>
                    </RadioGroup>
                  </FormControl>
                </Stack>

                <Button
                  placeSelf="end"
                  rightIcon={<MdNavigateNext size={16} />}
                  bg="brand.naranja"
                  form="formUsuarios"
                  fontSize={12}
                  color="white"
                  letterSpacing={2}
                  isLoading={loading}
                  size="md"
                  onClick={handleSubmit}
                >
                  Finalizar
                </Button>
              </Stack>
            </Form>
          )}
        </Formik>
      )}
    </Stack>
  );
};

export default FormCrearEditarUsuarios;
