import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  Input,
  useToast,
} from "@chakra-ui/react";
import { MdOutlineAdd } from "react-icons/md";
import { editarPlanificacion } from "../../helpers/api.helper";

const AgregarNTPS = (props) => {
  const { ejemplo, initData } = props;
  const [npts, setNpts] = useState();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  let res;
  const handleAgregar = async () => {
    res = await editarPlanificacion({
      id: ejemplo.idPlanificacion,
      npts: npts,
    });

    if (res.status === 200) {
      initData();
      onClose();
      toast({
        title: "Exito",
        description: "NPTs agregado",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } else {
      toast({
        title: "Error",
        description: "Error al cargar el NPTs",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };
  return (
    <>
      <Button
        variant="link"
        leftIcon={<MdOutlineAdd color="#FF8200" />}
        onClick={onOpen}
        size="sm"
      >
        Agregar
      </Button>
      <Modal size="sm" isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent borderRadius="20px">
          <ModalHeader placeSelf="center" fontWeight="bold">
            NPTs
          </ModalHeader>
          <ModalCloseButton size="sm" />
          <ModalBody>
            <Input
              placeholder="NPTs"
              borderRadius="20px"
              onChange={(e) => setNpts(e.target.value)}
              type="number"
            />
          </ModalBody>

          <ModalFooter placeContent="center">
            <Button
              bg="brand.naranja"
              fontSize="14px"
              color="white"
              onClick={() => handleAgregar()}
            >
              Cargar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
export default AgregarNTPS;
