import React, { useState, useEffect } from "react";
import {
  HStack,
  VStack,
  Heading,
  Text,
  Divider,
  Box,
  Badge,
  Icon,
  Stack,
  Progress,
  Table,
  Thead,
  Tbody,
  Stat,
  Tr,
  Th,
  Td,
  StatArrow,
  StatNumber,
  TableContainer,
  IconButton,
  Select,
  useToast,
} from "@chakra-ui/react";
import {
  CartesianGrid,
  BarChart,
  XAxis,
  ResponsiveContainer,
  YAxis,
  Tooltip,
  Bar,
} from "recharts";
import { useNavigate, useParams } from "react-router-dom";
import {
  MdArrowBack,
  MdInsertInvitation,
  MdOutlineAirportShuttle,
  MdOutlineTimer,
} from "react-icons/md";
import { RiErrorWarningFill, RiCheckboxCircleFill } from "react-icons/ri";
import { getPlanificaciones } from "../../operaciones/helpers/api.helper";
import { getGeocercas } from "../../geocercas/helpers/api.helper";
import { getReportesViajes } from "../../operaciones/helpers/api.helper";
import { ReactComponent as Sandcube } from "../../../assets/iconos/Sand.svg";
import { regionalFormattingNumbers } from "../../../utils/regionalFormattingNumbers";
import moment from "moment";
import "../../../styles/DetalleOperacion.css";

const DetalleOperacion = () => {
  const [operacion, setOperacion] = useState([]);
  const [operacionSeleccionada, setOperacionSeleccionada] = useState();
  const [geocercas, setGeocercas] = useState([]);
  const [reportes, setReportes] = useState([]);
  const [loading, setLoading] = useState(true);
  let llenosPozo = 0;
  let vaciosPozo = 0;
  let transitoPozo = 0;
  const navigate = useNavigate();
  const { padLocacion } = useParams();
  const toast = useToast();
  let today = moment().format("YYYY-MM-DDTHH:mm:ss");
  const todayII = moment().format("YYYY-MM-DD");

  const initData = async () => {
    let res;
    res = await getPlanificaciones({ padLocacion });

    if (res.status === 200) {
      setOperacion(res.data[0]);
    } else if (res.status === 400) {
      setLoading(false);
      return toast({
        title: "Error",
        description: " Error al cargar datos",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }

    res = await getGeocercas();
    if (res.status === 200) {
      setGeocercas(res.data);
    } else if (res.status === 400) {
      setLoading(false);
      return toast({
        title: "Error",
        description: " Error al cargar datos",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }

    res = await getReportesViajes({ padDestino: padLocacion });

    if (res.status === 200) {
      setReportes(res.data.reportes);
    } else if (res.status === 400) {
      setLoading(false);
      return toast({
        title: "Error",
        description: " Error al cargar datos",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }

    setLoading(false);
  };
  const handleDiasOperados = (fecha) => {
    let f = moment(fecha);

    let diasOperados = moment(today).diff(f, "days");

    return diasOperados + 1;
  };
  const handleDiasRestantes = (etapasTotales, fecha) => {
    let diasOperados = moment(today).diff(fecha[0].fechaInicioEtapaSC, "days");
    let promedio = fecha.length / (diasOperados + 1);
    let etapasRestantes =
      etapasTotales -
      operacion.etapasSC[operacion.etapasSC.length - 1].numTotalEtapaSC;

    let diasRestantes = etapasRestantes / promedio;

    return diasRestantes.toFixed(0);
  };
  const handlePromedio = (fecha) => {
    let diasOperados = moment(today).diff(fecha[0].fechaFinalEtapaSC, "days");
    let promedio = fecha[fecha.length - 1].numTotalEtapaSC / (diasOperados + 1);

    return regionalFormattingNumbers({
      number: promedio.toFixed(2),
      digits: 2,
    });
  };
  const fusionarEtapasConCantidad = () => {
    const fechasGeneradas = [];
    const cantidadesPorFecha = {};
    const fechaActual = moment();
    let fechaActualTemp = moment(operacion.fechaInicio);

    if (operacion.etapasSC) {
      let etapasTotal = operacion.etapasSC.filter((e) => e.eliminado === false);
      etapasTotal.forEach((etapa) => {
        const fechaFinalEtapaSC = moment(etapa.fechaFinalEtapaSC);

        if (fechaFinalEtapaSC.isSameOrAfter(fechaActualTemp)) {
          const fechaFinalEtapaSCStr = fechaFinalEtapaSC.format("DD-MM");

          if (!cantidadesPorFecha[fechaFinalEtapaSCStr]) {
            cantidadesPorFecha[fechaFinalEtapaSCStr] = 0;
          }

          cantidadesPorFecha[fechaFinalEtapaSCStr] += 1;
        }
      });
    }

    while (fechaActualTemp.isSameOrBefore(fechaActual)) {
      const diaMes = {
        dia: fechaActualTemp.date(),
        mes: fechaActualTemp.month() + 1,
        fecha: fechaActualTemp.toDate(),
      };

      const fecha = fechaActualTemp.format("DD-MM");
      const fechaII = fechaActualTemp.format("DD-MM");
      const cantidad = cantidadesPorFecha[fecha] || 0;

      fechasGeneradas.push({ ...diaMes, fechaII, cantidad });

      fechaActualTemp.add(1, "day");
    }

    let a = fechasGeneradas.filter((e) => e.cantidad !== 0);

    return a;
  };
  const handlePorcetajeCompleto = (completas, elemento) => {
    let porcentaje;
    if (completas) {
      let total = elemento.pozos.reduce((acumulador, elemento) => {
        return acumulador + parseInt(elemento.numeroEtapasPozo);
      }, 0);

      porcentaje =
        (completas[completas.length - 1].numTotalEtapaSC / total) * 100;

      const integerPercentage = Math.floor(porcentaje);
      return integerPercentage;
    } else {
      return 0;
    }
  };
  const handleEtapasHoy = () => {
    let b = moment(today).format("DD-MM-YYYY");

    if (operacion.etapasSC) {
      let etapasHoy = operacion.etapasSC.filter(
        (element) =>
          moment(element.fechaFinalEtapaSC).format("DD-MM-YYYY") === b
      );

      return etapasHoy.length;
    } else {
      return 0;
    }
  };
  const handleEtapasAyer = () => {
    let ayer = moment().subtract(1, "days");
    let b = moment(ayer).format("DD-MM-YYYY");

    if (operacion.etapasSC) {
      let etapasAyer = operacion.etapasSC.filter(
        (element) =>
          moment(element.fechaFinalEtapaSC).format("DD-MM-YYYY") === b
      );

      return etapasAyer.length;
    } else {
      return 0;
    }
  };
  const handleEtapasMax = () => {
    const fechaFinalCount = {};

    if (operacion.etapasSC) {
      operacion.etapasSC.forEach((etapa) => {
        const fechaFinal = moment(etapa.fechaFinalEtapaSC).format("DD-MM-YYYY");

        if (fechaFinalCount[fechaFinal]) {
          fechaFinalCount[fechaFinal]++;
        } else {
          fechaFinalCount[fechaFinal] = 1;
        }
      });
    }

    let cantidadMasComun = 0;

    for (const fechaFinal in fechaFinalCount) {
      if (fechaFinalCount[fechaFinal] > cantidadMasComun) {
        cantidadMasComun = fechaFinalCount[fechaFinal];
      }
    }

    return cantidadMasComun;
  };
  const handleTnsOperada = (elemento) => {
    let sumaDeLongitudes = 0;
    if (elemento.etapasSC) {
      for (let i = 0; i < elemento.etapasSC.length; i++) {
        if (
          elemento.etapasSC[i].sandCubes &&
          elemento.etapasSC[i].sandCubes.length > 0
        ) {
          sumaDeLongitudes += elemento.etapasSC[i].sandCubes.length;
        }
      }

      return mostrarResultado(sumaDeLongitudes * 13.5);
    } else {
      return 0;
    }
  };
  const handleSandcubes = (event) => {
    if (operacion.scAsociados && event === "llenosPozo") {
      operacion.scAsociados.forEach((objeto) => {
        if (
          objeto.ubicacionSandCube === "EN POZO" &&
          objeto.statusSandCube === "Lleno"
        ) {
          llenosPozo++;
        }
      });
      return llenosPozo;
    } else if (operacion.scAsociados && event === "vaciosPozo") {
      operacion.scAsociados.forEach((objeto) => {
        if (
          objeto.ubicacionSandCube === "EN POZO" &&
          objeto.statusSandCube === "Vacio"
        ) {
          vaciosPozo++;
        }
      });
      return vaciosPozo;
    } else if (operacion.scAsociados && event === "transitoPozo") {
      reportes.forEach((objeto) => {
        if (objeto.status2RV === "EN TRÁNSITO A POZO") {
          transitoPozo += 2;
        }
      });
      return transitoPozo;
    } else {
      return 0;
    }
  };
  const handleUltimoViajeCamion = () => {
    const camiones = operacion.camiones
      ? operacion.camiones.filter((e) => e.camionActivo === "true")
      : [];

    return camiones.length;
  };
  const mostrarResultado = (numero) => {
    if (numero.toFixed(2) % 1 === 0) {
      // Si el número es un entero (decimales .00), conviértelo a entero para eliminar los decimales
      return regionalFormattingNumbers({
        number: Math.floor(numero),
        digits: 0,
      });
    } else {
      // Si el número tiene decimales distintos de .00, muéstralo tal cual
      return regionalFormattingNumbers({
        number: numero.toFixed(2),
        digits: 2,
      });
    }
  };

  const contarViajesPorDia = (reportes) => {
    const viajesPorDia = {};

    reportes.forEach((reporte) => {
      const fecha = moment.utc(reporte.tsPozoIn).format("DD-MM-YYYY");
      if (viajesPorDia[fecha]) {
        viajesPorDia[fecha]++;
      } else {
        viajesPorDia[fecha] = 1;
      }
    });

    const diasDistintos = Object.keys(viajesPorDia).length;

    return { diasDistintos, viajesPorDia };
  };

  const handleDetallePlanta = () => {
    let reportesPlanta,
      standByPlantaHoy,
      ttPlantaPozoHoy,
      ttPozoPlantaHoy,
      vueltaCompletaHoy,
      VDCFijada,
      VDCHoy,
      reportesPlantaAvg,
      standByPlantaAvg,
      ttPlantaPozoAvg,
      ttPozoPlantaAvg,
      vueltaCompletaAvg,
      tonsTransportadas,
      tonsTransportadasAvg,
      reportesTemp,
      reportesPlantaII,
      VDCAvg;

    const seleccionada = operacion.detallePlantas.find(
      (e) => e.idPlanta === parseInt(operacionSeleccionada)
    );

    const handleActivoCamion = (value) => {
      const estadoMapping = {
        60: 1,
        120: 2,
        180: 3,
      };
      const estadoText = estadoMapping[value] || 0;
      return (24 - estadoText) * 60;
    };

    reportesTemp = reportes.filter((e) => e.statusRV === "Finalizado");

    let reportesTotales = reportesTemp.map((obj) => {
      obj.cargaTotal = 27;
      return obj;
    });

    if (seleccionada) {
      reportesPlantaII = reportesTotales.filter(
        (e) => e.IdPlanta === seleccionada.idPlanta
      );

      reportesPlantaAvg = reportesPlantaII;

      reportesPlanta = reportesPlantaII.filter(
        (e) => moment.utc(e.tsPozoIn).format("YYYY-MM-DD") === todayII
      );

      let reportesPorCamion = {};
      let reportesPorCamionAvg = {};
      let totalMinutosPorCamion = {};

      reportesPlanta.forEach(function (report) {
        let camion = report.camionRV;
        if (!reportesPorCamion[camion]) {
          reportesPorCamion[camion] = [];
        }
        reportesPorCamion[camion].push(report);
      });

      reportesPlantaAvg.forEach(function (report) {
        let camion = report.camionRV;
        if (!reportesPorCamionAvg[camion]) {
          reportesPorCamionAvg[camion] = [];
        }
        reportesPorCamionAvg[camion].push(report);
      });

      for (let camion in reportesPorCamion) {
        if (reportesPorCamion.hasOwnProperty(camion)) {
          totalMinutosPorCamion[camion] = 0;
          let reportesDelCamion = reportesPorCamion[camion];

          for (let i = 0; i < reportesDelCamion.length - 1; i++) {
            let tsPozoInActual = moment(reportesDelCamion[i].tsPozoOut);
            let tsPozoOutSiguiente = moment(reportesDelCamion[i + 1].tsPozoIn);
            let diferenciaMinutos = tsPozoOutSiguiente.diff(
              tsPozoInActual,
              "minutes"
            );
            totalMinutosPorCamion[camion] += diferenciaMinutos;
          }
        }
      }

      let standbyplanta = reportesPlanta.reduce((acumulador, elemento) => {
        return acumulador + elemento.standByPlanta;
      }, 0);

      let standbyplantaAvg = reportesPlantaAvg.reduce(
        (acumulador, elemento) => {
          return acumulador + elemento.standByPlanta;
        },
        0
      );

      let vueltaCompletaHoyTemp = reportesPlanta.reduce(
        (acumulador, elemento) => {
          return acumulador + elemento.tiempoVuelta;
        },
        0
      );

      let vueltaCompletaAvgTemp = reportesPlantaAvg.reduce(
        (acumulador, elemento) => {
          return acumulador + elemento.tiempoVuelta;
        },
        0
      );

      standByPlantaHoy = standbyplanta / reportesPlanta.length || 0;
      standByPlantaAvg = standbyplantaAvg / reportesPlantaAvg.length || 0;
      vueltaCompletaHoy = vueltaCompletaHoyTemp / reportesPlanta.length || 0;
      vueltaCompletaAvg = vueltaCompletaAvgTemp / reportesPlantaAvg.length || 0;

      //----------------------------------------------------------------------

      let ttPlantaPozoHoyII = reportesPlanta.reduce(
        (total, objeto) => total + objeto.tiempoSalidaPlantaPozo,
        0
      );

      ttPlantaPozoHoy = ttPlantaPozoHoyII / reportesPlanta.length || 0;
      //-----------------------------------------------------------------------
      let ttPozoPlantaHoyII = reportesPlanta.reduce(
        (total, objeto) => total + objeto.tiempoSalidaPozoPlanta,
        0
      );
      ttPozoPlantaHoy = ttPozoPlantaHoyII / reportesPlanta.length || 0;
      //-----------------------------------------------------------------------
      let ttPozoPlantaAvgII = reportesPlantaAvg.reduce(
        (total, objeto) => total + objeto.tiempoSalidaPozoPlanta,
        0
      );
      ttPozoPlantaAvg = ttPozoPlantaAvgII / reportesPlantaAvg.length || 0;
      //-----------------------------------------------------------------------
      let ttPlantaPozoAvgII = reportesPlantaAvg.reduce(
        (total, objeto) => total + objeto.tiempoSalidaPlantaPozo,
        0
      );

      ttPlantaPozoAvg = ttPlantaPozoAvgII / reportesPlantaAvg.length || 0;
      //-----------------------------------------------------------------------

      tonsTransportadas = reportesPlanta.reduce(
        (total, objeto) => total + parseFloat(objeto["cargaTotal"] || 0),
        0
      );

      let reportesAVG = contarViajesPorDia(reportesPlantaAvg);
      let y = [];

      for (let fecha in reportesAVG.viajesPorDia) {
        let x = (reportesAVG.viajesPorDia[fecha] *= 27);

        y.push(x);
      }

      let z = y.reduce((acc, count) => acc + count, 0);

      if (reportesAVG.diasDistintos !== 0) {
        tonsTransportadasAvg = z / reportesAVG.diasDistintos;
      } else {
        tonsTransportadasAvg = 0;
      }

      VDCFijada =
        (seleccionada.targetVueltaCompleta + seleccionada.targetStandBy) /
        handleActivoCamion(parseInt(seleccionada.standByCamion));

      //-----------------------------------------------------------------------
      let tempX = vueltaCompletaHoyTemp + parseInt(handleStandByPozo("hoy"));
      let tempW = tempX * reportesPlanta.length;

      if (tempW !== 0) {
        VDCHoy = tempX / tempW;
      } else {
        VDCHoy = 0;
      }

      //-----------------------------------------------------------------------
      let tempZ = vueltaCompletaAvgTemp + parseInt(handleStandByPozo("total"));
      let tempY = tempZ * reportesPlantaAvg.length;

      if (tempW !== 0) {
        VDCAvg = tempZ / tempY;
      } else {
        VDCAvg = 0;
      }
    }
    //-------------------------------------------------------------------------

    const plantaSeleccionada = geocercas.filter(
      (e) => e.idPlanta === parseInt(operacionSeleccionada)
    );
    function sumarKilometros(event) {
      if (event === "hoy") {
        let x = reportesPlanta.find((e) => e.kms !== null);
        if (x && !isNaN(x.kms / ((ttPozoPlantaHoy + ttPlantaPozoHoy) / 60))) {
          let z = x.kms / ((ttPozoPlantaHoy + ttPlantaPozoHoy) / 60);

          return mostrarResultado(z);
        } else {
          return 0;
        }
      } else if (event === "total") {
        let x = reportesPlantaAvg.find((e) => e.kms !== null);
        if (x && !isNaN(x.kms / ((ttPozoPlantaAvg + ttPlantaPozoAvg) / 60))) {
          let z = x.kms / ((ttPozoPlantaAvg + ttPlantaPozoAvg) / 60);

          return mostrarResultado(z);
        } else {
          return 0;
        }
      } else if (event === "target") {
        let x = reportesPlantaAvg.find((e) => e.kms !== null);
        if (
          x &&
          !isNaN(
            x.kms /
              ((seleccionada.targetIdaPozoPlanta +
                seleccionada.targetVueltaPozoPlanta) /
                60)
          )
        ) {
          let z =
            x.kms /
            ((seleccionada.targetIdaPozoPlanta +
              seleccionada.targetVueltaPozoPlanta) /
              60);

          return mostrarResultado(z);
        } else {
          return 0;
        }
      }
    }

    if (seleccionada) {
      return (
        <Stack w={"100%"}>
          <HStack px={5}>
            <Text fontSize="30px" fontWeight="bold">
              {handlePorcentajeArenaHoy(seleccionada) || 0}%
            </Text>
            <Text fontSize="14px" alignSelf="end" pb={2}>
              Hoy
            </Text>
          </HStack>
          <Box bg="white" borderRadius={5}>
            <Progress
              borderRadius={5}
              colorScheme="orange"
              size="md"
              max={100}
              min={0}
              value={
                operacion.etapasSC ? handlePorcentajeArenaHoy(seleccionada) : 0
              }
              border={"1px solid #FF8200"}
            />
          </Box>
          <HStack px={5} justifyContent="space-around">
            <HStack alignItems={"flex-start"}>
              <Text as="b" fontSize={30}>
                {regionalFormattingNumbers({
                  number: reportesPlantaAvg.reduce((total, objeto) => {
                    const valorPropiedad = objeto["cargaTotal"];
                    return valorPropiedad !== null
                      ? total + parseFloat(valorPropiedad)
                      : total;
                  }, 0),
                  digits: 0,
                })}
              </Text>
              <Text
                fontSize="14px"
                spacing={1}
                placeSelf={"end"}
                paddingBottom={1.5}
              >
                Tns. Totales
              </Text>
            </HStack>

            <HStack>
              <Text as="b" fontSize={30}>
                {handlePorcentajeArena()}%
              </Text>
              <Text
                fontSize="14px"
                spacing={1}
                placeSelf={"end"}
                paddingBottom={1.5}
              >
                Promedio
              </Text>
            </HStack>
          </HStack>

          <TableContainer>
            <Table variant="simple" size="sm">
              <Thead>
                <Tr>
                  <Th></Th>
                  <Th textAlign={"right"}>Target</Th>
                  <Th textAlign={"right"}>Hoy</Th>
                  <Th textAlign={"right"}>Avg.</Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Td>Tns. transportadas</Td>
                  <Td className="row_number">
                    {seleccionada.targetTnsDiaPlanta}
                  </Td>
                  <Td className="row_number">
                    <Stat>
                      <StatNumber fontSize={14}>
                        {mostrarResultado(tonsTransportadas)}
                        {tonsTransportadas > seleccionada.targetTnsDiaPlanta ? (
                          <StatArrow type={"increase"} color={"green.500"} />
                        ) : (
                          <StatArrow type="decrease" color={"red.500"} />
                        )}
                      </StatNumber>
                    </Stat>
                  </Td>
                  <Td className="row_number">
                    {mostrarResultado(tonsTransportadasAvg)}
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    Standby en Planta
                    <Text fontSize={8} color="gray">
                      (mins)
                    </Text>
                  </Td>
                  <Td className="row_number">{seleccionada.targetStandBy}</Td>
                  <Td className="row_number">
                    <Stat>
                      <StatNumber fontSize={14}>
                        {mostrarResultado(standByPlantaHoy)}
                        {standByPlantaHoy < seleccionada.targetStandBy ? (
                          <StatArrow type={"decrease"} color={"green.500"} />
                        ) : (
                          <StatArrow type="increase" color={"red.500"} />
                        )}
                      </StatNumber>
                    </Stat>
                  </Td>
                  <Td className="row_number">
                    {mostrarResultado(standByPlantaAvg)}
                  </Td>
                </Tr>
                <Tr>
                  <Td textAlign="left">
                    TT Pozo-Planta
                    <Text fontSize={8} color="gray">
                      (mins)
                    </Text>
                  </Td>
                  <Td className="row_number">
                    {seleccionada.targetIdaPozoPlanta}
                  </Td>
                  <Td className="row_number">
                    <Stat>
                      <StatNumber fontSize={14}>
                        {mostrarResultado(ttPozoPlantaHoy)}
                        {ttPozoPlantaHoy < seleccionada.targetIdaPozoPlanta ? (
                          <StatArrow type={"decrease"} color={"green.500"} />
                        ) : (
                          <StatArrow type="increase" color={"red.500"} />
                        )}
                      </StatNumber>
                    </Stat>
                  </Td>
                  <Td className="row_number">
                    {mostrarResultado(ttPozoPlantaAvg)}
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    TT Planta-Pozo
                    <Text fontSize={8} color="gray">
                      (mins)
                    </Text>
                  </Td>
                  <Td className="row_number">
                    {seleccionada.targetVueltaPozoPlanta}
                  </Td>
                  <Td className="row_number">
                    <Stat>
                      <StatNumber fontSize={14}>
                        {mostrarResultado(ttPlantaPozoHoy)}
                        {ttPlantaPozoHoy <
                        seleccionada.targetVueltaPozoPlanta ? (
                          <StatArrow type={"decrease"} color={"green.500"} />
                        ) : (
                          <StatArrow type="increase" color={"red.500"} />
                        )}
                      </StatNumber>
                    </Stat>
                  </Td>
                  <Td className="row_number">
                    {mostrarResultado(ttPlantaPozoAvg)}
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    Vuelta Completa
                    <Text fontSize={8} color="gray">
                      (mins)
                    </Text>
                  </Td>
                  <Td className="row_number">
                    {seleccionada.targetVueltaCompleta}
                  </Td>
                  <Td className="row_number">
                    <Stat>
                      <StatNumber fontSize={14}>
                        {mostrarResultado(vueltaCompletaHoy)}
                        {vueltaCompletaHoy <
                        seleccionada.targetVueltaCompleta ? (
                          <StatArrow type={"decrease"} color={"green.500"} />
                        ) : (
                          <StatArrow type="increase" color={"red.500"} />
                        )}
                      </StatNumber>
                    </Stat>
                  </Td>
                  <Td className="row_number">
                    {mostrarResultado(vueltaCompletaAvg)}
                  </Td>
                </Tr>
                <Tr>
                  <Td>VCD Total</Td>
                  <Td className="row_number">
                    {regionalFormattingNumbers({
                      number: VDCFijada.toFixed(2),
                      digits: 2,
                    })}
                  </Td>
                  <Td className="row_number">
                    <Stat>
                      <StatNumber fontSize={14}>
                        {mostrarResultado(VDCHoy)}
                        {VDCHoy < VDCFijada ? (
                          <StatArrow type={"decrease"} color={"green.500"} />
                        ) : (
                          <StatArrow type="increase" color={"red.500"} />
                        )}
                      </StatNumber>
                    </Stat>
                  </Td>
                  <Td className="row_number">{mostrarResultado(VDCAvg)}</Td>
                </Tr>
                <Tr>
                  <Td>Avg. Kms/h</Td>
                  <Td className="row_number">
                    {plantaSeleccionada[0] ? sumarKilometros("target") : 0}
                  </Td>
                  <Td className="row_number">
                    <Stat>
                      <StatNumber fontSize={14}>
                        {plantaSeleccionada[0] ? sumarKilometros("hoy") : 0}
                        {plantaSeleccionada[0] &&
                        sumarKilometros("hoy") < sumarKilometros("target") ? (
                          <StatArrow type={"decrease"} color={"green.500"} />
                        ) : (
                          <StatArrow type="increase" color={"red.500"} />
                        )}
                      </StatNumber>
                    </Stat>
                  </Td>
                  <Td className="row_number">
                    {plantaSeleccionada[0] ? sumarKilometros("total") : 0}
                  </Td>
                </Tr>
                <Tr>
                  <Td>Indice Logístico</Td>
                  <Td className="row_number">{1}</Td>
                  <Td className="row_number">
                    <Stat>
                      <StatNumber fontSize={14}>
                        {mostrarResultado(VDCHoy / VDCFijada)}
                        {VDCHoy / VDCFijada < 1 ? (
                          <StatArrow type={"decrease"} color={"green.500"} />
                        ) : (
                          <StatArrow type="increase" color={"red.500"} />
                        )}
                      </StatNumber>
                    </Stat>
                  </Td>
                  <Td className="row_number">
                    {mostrarResultado(VDCAvg / VDCFijada)}
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </TableContainer>
        </Stack>
      );
    }
  };
  const handlePorcentajeArena = () => {
    let seleccionada;

    let x = reportes.filter((e) => e.statusRV === "Finalizado");

    x = x.reduce(
      (total, objeto) => total + parseFloat(objeto["cargaTotal"] || 0),
      0
    );

    if (operacionSeleccionada) {
      seleccionada = operacion.detallePlantas.filter(
        (e) => e.idPlanta === parseInt(operacionSeleccionada)
      );
    }

    let y = reportes.filter((elem) => elem.IdPlanta === seleccionada.idPlanta);
    y = y.filter((e) => e.statusRV === "Finalizado");
    y = y.reduce(
      (total, objeto) => total + parseFloat(objeto["cargaTotal"] || 0),
      0
    );

    return (
      regionalFormattingNumbers({
        number: ((y * 100) / x).toFixed(2),
        digits: 2,
      }) || 0
    );
  };

  const handlePorcentajeArenaHoy = (seleccionada) => {
    let x = reportes.filter((e) => e.statusRV === "Finalizado");
    x = x.filter((e) => moment.utc(e.fechaRV).format("YYYY-MM-DD") === todayII);
    x = x.reduce(
      (total, objeto) => total + parseFloat(objeto["cargaTotal"] || 0),
      0
    );

    let y = reportes.filter((elem) => elem.IdPlanta === seleccionada.idPlanta);
    y = y.filter((e) => e.statusRV === "Finalizado");
    y = y.filter((e) => moment.utc(e.fechaRV).format("YYYY-MM-DD") === todayII);
    y = y.reduce(
      (total, objeto) => total + parseFloat(objeto["cargaTotal"] || 0),
      0
    );

    if (x !== 0) {
      return regionalFormattingNumbers({
        number: ((y * 100) / x).toFixed(2),
        digits: 2,
      });
    } else {
      return 0;
    }
  };
  const handleProximosArribos = () => {
    let reportesTP = reportes.filter(
      (e) => e.statusRV === "En curso" && e.status2RV === "EN TRÁNSITO A POZO"
    );

    return reportesTP.map((camion, index) => (
      <Tr
        key={camion.camionRV * 2 + index}
        textColor={
          moment.utc(camion.eta).format("YYYY-MM-DDTHH:mm:ss") < today
            ? "brand.naranja"
            : "brand.gris_primario"
        }
      >
        <Td>
          <Icon
            color={
              moment.utc(camion.eta).format("YYYY-MM-DDTHH:mm:ss") < today
                ? "brand.naranja"
                : "brand.verde"
            }
            as={
              moment.utc(camion.eta).format("YYYY-MM-DDTHH:mm:ss") < today
                ? RiErrorWarningFill
                : RiCheckboxCircleFill
            }
            fontSize="20px"
          />
        </Td>
        <Td>
          {camion.dominio && camion.nombreCamion
            ? `${camion.nombreCamion} -`
            : `${camion.nombreCamion}`}{" "}
          {camion.camionRV}
        </Td>
        <Td>
          {camion.eta ? moment.utc(camion.eta).format("DD-MM-YY HH:mm") : "--"}
        </Td>
      </Tr>
    ));
  };
  const handleStandByPozo = (e) => {
    if (e === "hoy") {
      let reportesTemp = reportes.filter((e) => e.statusRV === "Finalizado");

      let reportesHoy = reportesTemp.filter(
        (e) => moment.utc(e.tsPozoIn).format("YYYY-MM-DD") === todayII
      );

      const sumaStandByPozo = reportesHoy.reduce((acumulador, elemento) => {
        return acumulador + elemento.standByPozo;
      }, 0);

      if (!isNaN(sumaStandByPozo / reportesHoy.length)) {
        let x = sumaStandByPozo / reportesHoy.length;
        return mostrarResultado(x);
      } else {
        return 0;
      }
    }
    if (e === "total") {
      let reportesTotal = reportes.filter((e) => e.statusRV === "Finalizado");

      const sumaStandByPozo = reportesTotal.reduce((acumulador, elemento) => {
        return acumulador + elemento.standByPozo;
      }, 0);

      if (!isNaN(sumaStandByPozo / reportesTotal.length)) {
        return (sumaStandByPozo / reportesTotal.length).toFixed(1);
      } else {
        return 0;
      }
    }
  };

  useEffect(() => {
    initData();
  }, []);

  console.log(fusionarEtapasConCantidad());
  return (
    <Stack w="100%" bg="brand.fondos_secundarios" px={2}>
      {!loading && (
        <>
          <HStack w="100%">
            <Box>
              <IconButton
                icon={<MdArrowBack />}
                variant="link"
                onClick={() => navigate(-1)}
              />
            </Box>
            <Box>
              <Heading
                color="brand.azul_text"
                textAlign="left"
                fontWeight="semibold"
                size={["sm", "sm", "md", "md", "lg"]}
              >
                Detalle {operacion.idPadlocacion}
              </Heading>
              <Text fontSize="16px">
                Este es el resumen de las estadísticas de la operación
              </Text>
            </Box>
          </HStack>
          <VStack
            w="100%"
            bg="brand.fondos_primarios"
            borderRadius={10}
            overflowY="auto"
          >
            <HStack w="100%" px={5} justifyContent="space-around">
              <HStack>
                <Text>
                  Promedio etapas/día:{" "}
                  {operacion.etapasSC
                    ? handlePromedio(operacion.etapasSC)
                    : "--"}{" "}
                </Text>
                <Text textColor="gray" fontSize="12px">
                  (Ayer: {handleEtapasAyer()} Hoy: {handleEtapasHoy()} Max:{" "}
                  {handleEtapasMax()})
                </Text>
              </HStack>
              <HStack>
                <Text>
                  Días operados:{" "}
                  {operacion.etapasSC
                    ? handleDiasOperados(
                        operacion.etapasSC[0]?.fechaInicioEtapaSC
                      )
                    : "--"}
                </Text>
                <Text textColor="gray" fontSize="12px" textAlign={"end"}>
                  (
                  {operacion.etapasSC
                    ? handleDiasRestantes(
                        operacion.etapasTotalPad,
                        operacion.etapasSC
                      )
                    : "--"}{" "}
                  días restantes aprox)
                </Text>
              </HStack>
            </HStack>
            <Divider h="1px" bg="brand.gris_primario" />
            <HStack w="100%">
              <Stack w={"34%"} h={"100%"} px={2}>
                <Stack w="100%" justifyContent="space-around" py={3}>
                  <Heading
                    fontSize={24}
                    placeSelf="start"
                    fontWeight="bold"
                    letterSpacing={1}
                  >
                    Etapas
                  </Heading>
                  <HStack>
                    <HStack
                      bg="brand.azul_text"
                      textAlign="left"
                      borderRadius={5}
                      p={3}
                    >
                      <Text
                        placeSelf="start"
                        fontWeight="bold"
                        fontSize={28}
                        textColor="white"
                      >
                        {operacion.etapasSC
                          ? operacion.etapasSC[operacion.etapasSC.length - 1]
                              .numTotalEtapaSC
                          : 0}
                      </Text>
                      <Text textColor="white" fontSize="14px">
                        Completadas en total
                      </Text>
                    </HStack>
                    <HStack
                      bg="brand.azul_text"
                      textAlign="left"
                      borderRadius={5}
                      p={3}
                    >
                      <Text
                        placeSelf="start"
                        fontWeight="bold"
                        fontSize={28}
                        textColor="white"
                      >
                        {handleEtapasHoy()}
                      </Text>
                      <Text textColor="white" fontSize="14px">
                        Completadas hoy
                      </Text>
                    </HStack>
                  </HStack>
                  <HStack w="100%" px={5}>
                    <HStack>
                      <Text fontSize="28px" fontWeight="bold">
                        {operacion.etapasSC
                          ? handlePorcetajeCompleto(
                              operacion.etapasSC || 0,
                              operacion
                            )
                          : "0"}
                        {"%"}
                      </Text>
                      <Text fontSize="10px">
                        pad <br />
                        completo
                      </Text>
                    </HStack>
                    <Box bg="white" w="80%" borderRadius={5}>
                      <Progress
                        borderRadius={5}
                        colorScheme="orange"
                        size="md"
                        max={100}
                        min={0}
                        value={
                          operacion.etapasSC
                            ? handlePorcetajeCompleto(
                                operacion.etapasSC || 0,
                                operacion
                              )
                            : 0
                        }
                        border={"1px solid #FF8200"}
                      />
                    </Box>
                  </HStack>
                </Stack>
                <Divider h="1px" bg="brand.gris_primario" />
                <Stack w="100%" justify={"space-between"} px={2} py={3}>
                  <Heading
                    fontSize={24}
                    placeSelf="start"
                    fontWeight="bold"
                    letterSpacing={1}
                  >
                    Sandcubes
                  </Heading>
                  <HStack justify={"space-between"}>
                    <HStack>
                      <Text placeSelf="start" fontWeight="bold" fontSize="32px">
                        {handleSandcubes("llenosPozo")}
                      </Text>
                      <VStack>
                        <Box
                          w={8}
                          h={8}
                          style={{ "--icon-color": "#FF8200" }}
                          alignSelf="start"
                        >
                          <Sandcube />
                        </Box>

                        <Text fontSize="12px" alignSelf="start">
                          llenos <br /> en pozo
                        </Text>
                      </VStack>
                    </HStack>
                    <HStack>
                      <Text placeSelf="start" fontWeight="bold" fontSize="32px">
                        {handleSandcubes("vaciosPozo")}
                      </Text>
                      <VStack>
                        <Box
                          w={8}
                          h={8}
                          alignSelf="start"
                          style={{ "--icon-color": "#505E70" }}
                        >
                          <Sandcube />
                        </Box>
                        <Text fontSize="12px" alignSelf="start">
                          vacíos <br /> en pozo
                        </Text>
                      </VStack>
                    </HStack>
                    <HStack>
                      <Text placeSelf="start" fontWeight="bold" fontSize="32px">
                        {handleSandcubes("transitoPozo")}
                      </Text>
                      <VStack>
                        <Box
                          w={8}
                          h={8}
                          alignSelf="start"
                          style={{ "--icon-color": "#004B87" }}
                        >
                          <Sandcube />
                        </Box>
                        <Text fontSize="12px" alignSelf="start">
                          en tránsito <br /> a pozo
                        </Text>
                      </VStack>
                    </HStack>
                  </HStack>
                </Stack>
                <Divider h="1px" bg="brand.gris_primario" />
                <Stack w="100%" py={3}>
                  <Heading
                    fontSize={24}
                    placeSelf="start"
                    fontWeight="bold"
                    letterSpacing={1}
                  >
                    Camiones
                  </Heading>
                  <HStack spacing={1} justifyContent="space-between">
                    <Box>
                      <HStack>
                        <Text
                          fontSize="32px"
                          fontWeight="bold"
                          letterSpacing={1}
                        >
                          {handleUltimoViajeCamion()}
                        </Text>
                        <Icon as={MdOutlineAirportShuttle} fontSize="42px" />
                      </HStack>

                      <Text fontSize="12px">Camiones activos</Text>
                    </Box>
                    <Box>
                      <HStack spacing={1}>
                        <Text fontSize="1.5em" fontWeight="bold">
                          {handleStandByPozo("hoy")}
                        </Text>
                        <Icon as={MdOutlineTimer} fontSize="20px" />
                      </HStack>
                      <HStack fontSize="12px">
                        <Text>
                          Standby en <br />
                          pozo del día
                        </Text>
                        <Text color="gray" fontStyle="italic">
                          (mins)
                        </Text>
                      </HStack>
                    </Box>
                    <Box>
                      <HStack spacing={1}>
                        <Text fontSize="1.5em" fontWeight="bold">
                          {handleStandByPozo("total")}
                        </Text>
                        <Icon as={MdOutlineTimer} fontSize="20px" />
                      </HStack>
                      <HStack fontSize="12px">
                        <Text>
                          Standby en <br /> pozo general
                        </Text>
                        <Text color="gray" fontStyle="italic">
                          (mins)
                        </Text>
                      </HStack>
                    </Box>
                  </HStack>
                </Stack>
                <Divider w="1px" bg="brand.gris_primario" />
                <HStack px={1}>
                  <Text as="b" fontSize="32px">
                    {handleTnsOperada(operacion)}
                  </Text>

                  <Text fontSize="12px" spacing={1}>
                    toneladas operadas
                  </Text>
                </HStack>
              </Stack>
              <Divider
                orientation="vertical"
                h="100%"
                w="1px"
                bg="brand.gris_primario"
              />
              <VStack w={"35%"} h={"100%"}>
                <Stack w={"100%"}>
                  <Select
                    fontSize={24}
                    textAlign="center"
                    fontWeight="bold"
                    letterSpacing={1}
                    variant="unstyled"
                    placeholder="Seleccione planta"
                    onChange={(e) => setOperacionSeleccionada(e.target.value)}
                  >
                    {operacion.detallePlantas.map((planta, index) => (
                      <option
                        key={planta.idPlanta * 2 + index}
                        value={planta.idPlanta}
                      >
                        {planta.nombrePlanta}
                      </option>
                    ))}
                  </Select>
                </Stack>
                {handleDetallePlanta()}
              </VStack>
              <Divider
                orientation="vertical"
                h="100%"
                w="1px"
                bg="brand.gris_primario"
              />
              <VStack w={"31%"} pb={2}>
                <Heading
                  fontSize={24}
                  placeSelf="start"
                  fontWeight="bold"
                  letterSpacing={1}
                >
                  Próximos arribos
                </Heading>
                <Stack w={"100%"} h={"md"}>
                  <TableContainer overflowY="auto">
                    <Table size="sm">
                      <Thead>
                        <Tr>
                          <Th></Th>
                          <Th>ID Camión</Th>
                          <Th>ETA</Th>
                        </Tr>
                      </Thead>
                      <Tbody overflowY="auto">{handleProximosArribos()}</Tbody>
                    </Table>
                  </TableContainer>
                </Stack>
              </VStack>
            </HStack>
            <Divider h="1px" bg="brand.gris_primario" />
            <Stack w="100%" h={"200px"}>
              <HStack
                w="100%"
                fontSize="14px"
                justifyContent="space-between"
                px={3}
              >
                <Text>Etapas completadas por dia</Text>
                <Badge textColor="brand.gris_primario" fontWeight="light">
                  <HStack>
                    <Icon as={MdInsertInvitation} color="gray" boxSize={5} />
                    <Text>
                      {" "}
                      {operacion?.etapasSC
                        ? moment(
                            operacion?.etapasSC[0].fechaInicioEtapaSC
                          ).format("DD/MM/YYYY")
                        : "--/--/----"}{" "}
                      AL{" "}
                      {operacion?.etapasSC
                        ? moment(
                            operacion?.etapasSC[operacion.etapasSC.length - 1]
                              .fechaInicioEtapaSC
                          ).format("DD/MM/YYYY")
                        : "--/--/----"}
                    </Text>
                  </HStack>
                </Badge>
              </HStack>
              {fusionarEtapasConCantidad().length > 0 ? (
                <ResponsiveContainer width="100%" height="100%">
                  <BarChart
                    width={500}
                    height={300}
                    data={fusionarEtapasConCantidad()}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey={"fechaII"} />
                    <YAxis minTickGap={10} />
                    <Tooltip />
                    <Bar dataKey="cantidad" fill="#82ca9d" />
                  </BarChart>
                </ResponsiveContainer>
              ) : (
                <HStack w={"100%"}></HStack>
              )}
            </Stack>
          </VStack>
        </>
      )}
    </Stack>
  );
};

export default DetalleOperacion;
