import React, { useEffect, useState } from "react";
import {
  VStack,
  HStack,
  IconButton,
  Box,
  Heading,
  Text,
  Step,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepStatus,
  StepTitle,
  Stepper,
  useSteps,
  useToast,
  Divider,
  Stack,
} from "@chakra-ui/react";
import { Formik, Form } from "formik";
import { MdArrowBack } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import {
  crearRegistroEvento,
  editarRegistroEvento,
} from "../../operaciones/helpers/api.helper";
import { getUsuarios } from "../../usuarios/helpers/api.helper";
import PrimerPasoRegistroFijo from "./PrimerPasoRegistroFijo";
import SegundoPasoRegistroFijo from "./SegundoPasoRegistroFijo";
import moment from "moment";

const CrearEditarRegistroFijo = (props) => {
  const { type } = props;
  const [loading, setLoading] = useState([]);
  const [planificacion, setPlanificacion] = useState([]);
  const [textAccionesInmediatas, setTextAccionesInmediatas] = useState("");
  const [text, setText] = useState("");
  const [seleccionado, setSeleccionado] = useState([]);
  const [usuarios, setUsuarios] = useState([]);
  const [imgUpload, setImgUpload] = useState([]);
  const navigate = useNavigate();
  const toast = useToast();
  const steps = [{ title: "Detalles" }, { title: "Acciones inmediatas" }];
  const { activeStep, setActiveStep } = useSteps({
    index: 0,
    count: steps.length,
  });

  const initData = async () => {
    setLoading(true);
    let res;
    res = await getUsuarios();

    if (res.status === 200) {
      setUsuarios(res.data);
    } else {
      toast({
        title: "Error",
        description: "Error al cargar datos",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }

    setLoading(false);
  };

  const initialValues =
    type === "editar"
      ? {
          fechaRegistroEventos: moment(
            seleccionado.fechaRegistroEventos
          ).format("YYYY-MM-DDTHH:mm"),
          equipoRegistroEventos: seleccionado.equipoRegistroEventos,
          tipoRegistroEventos: seleccionado.tipoRegistroEventos,
          valoracionRegistroEventos: seleccionado.valoracionRegistroEventos,
          detectoRegistroEventos: seleccionado.detectoRegistroEventos,
          descripcionRegistroEventos: seleccionado.descripcionRegistroEventos,
          fotoRegistroEventos: seleccionado.fotoRegistroEventos,
          masFotosRegistroEventos: seleccionado.masFotosRegistroEventos,
          otroRegistroEventos: seleccionado.otroRegistroEventos,
          otroDondeRegistroEventos: seleccionado.otroDondeRegistroEventos,
          actoresRegistroEventos: seleccionado.actoresRegistroEventos,
          actoresQuienRegistroEventos: seleccionado.actoresQuienRegistroEventos,
          informoRegistroEventos: seleccionado.informoRegistroEventos,
          accionesRegistroEventos: seleccionado.accionesRegistroEventos,
          responsableAccionRegistroEventos:
            seleccionado.responsableAccionRegistroEventos,
          fechaAccionRegistroEventos: moment(
            seleccionado.fechaAccionRegistroEventos
          ).format("YYYY-MM-DDTHH:mm"),
          responsableRegistroEventos: seleccionado.responsableRegistroEventos,
          locacionRegistroEvento: seleccionado.locacionRegistroEvento,
          servicioReestRegistroEventos:
            seleccionado.servicioReestRegistroEventos,
          aQuienComunico: seleccionado.aQuienComunico,
          rtaObtenida: seleccionado.rtaObtenida,
          rtaObtenidaCl: seleccionado.rtaObtenidaCl,
        }
      : {
          fechaRegistroEventos: "",
          equipoRegistroEventos: "",
          tipoRegistroEventos: "",
          valoracionRegistroEventos: "",
          detectoRegistroEventos: "",
          descripcionRegistroEventos: "",
          fotoRegistroEventos: "",
          masFotosRegistroEventos: "",
          otroRegistroEventos: "",
          otroDondeRegistroEventos: "",
          actoresRegistroEventos: "",
          actoresQuienRegistroEventos: "",
          informoRegistroEventos: "",
          accionesRegistroEventos: "",
          responsableAccionRegistroEventos: "",
          fechaAccionRegistroEventos: "",
          responsableRegistroEventos: "",
          locacionRegistroEvento: "",
          servicioReestRegistroEventos: false,
          aQuienComunico: "",
          rtaObtenida: "",
          rtaObtenidaCl: "",
        };

  useEffect(() => {
    initData();
  }, []);

  return (
    <VStack w="100%" h="100%">
      <HStack pl={5} w="100%" bg="brand.fondos_secundario">
        <Box>
          <IconButton
            icon={<MdArrowBack />}
            variant="link"
            onClick={() => navigate(-1)}
            fontSize="24px"
          />
        </Box>
        <Heading
          fontWeight="medium"
          size="xl"
          fontFamily="fonts.title"
          color="brand.gris_primario"
        >
          {type === "crear" && "Nuevo evento"}
          {type === "editar" && "Edicion de registro"}
        </Heading>
      </HStack>
      <HStack w="100%" px={3} justifyContent="space-between">
        <Stepper index={activeStep} colorScheme="orange">
          {steps.map((step, index) => (
            <Step key={step}>
              <StepIndicator>
                <StepStatus
                  complete={<StepIcon />}
                  incomplete={<StepNumber />}
                  active={<StepNumber />}
                />
              </StepIndicator>

              <Box flexShrink="1">
                <StepTitle>{step.title}</StepTitle>
              </Box>
            </Step>
          ))}
        </Stepper>
        <Text color="brand.azul_text" fontSize={14}>
          {activeStep + 1} de {steps.length} partes
        </Text>
      </HStack>

      <Formik
        initialValues={initialValues}
        onSubmit={async (values) => {
          if (!values.fechaRegistroEventos) {
            return toast({
              title: "Error",
              description: "Se requiere fecha de registro",
              status: "error",
              duration: 3000,
              isClosable: true,
            });
          }
          if (!values.tipoRegistroEventos) {
            return toast({
              title: "Error",
              description: "Se requiere tipo de evento",
              status: "error",
              duration: 3000,
              isClosable: true,
            });
          }
          if (!values.valoracionRegistroEventos) {
            return toast({
              title: "Error",
              description: "Se requiere valoracion de evento",
              status: "error",
              duration: 3000,
              isClosable: true,
            });
          }
          if (!values.descripcionRegistroEventos) {
            return toast({
              title: "Error",
              description: "Se requiere descripcion del evento",
              status: "error",
              duration: 3000,
              isClosable: true,
            });
          }
          if (!values.accionesRegistroEventos) {
            return toast({
              title: "Error",
              description: "Se requiere detalle acciones inmediatas",
              status: "error",
              duration: 3000,
              isClosable: true,
            });
          }
          if (!values.responsableAccionRegistroEventos) {
            return toast({
              title: "Error",
              description: "Se requiere responsable",
              status: "error",
              duration: 3000,
              isClosable: true,
            });
          }
          if (!values.fechaAccionRegistroEventos) {
            return toast({
              title: "Error",
              description: "Se requiere hora del evento",
              status: "error",
              duration: 3000,
              isClosable: true,
            });
          }
          if (!values.detectoRegistroEventos) {
            return toast({
              title: "Error",
              description: "Se requiere quien detecto el evento",
              status: "error",
              duration: 3000,
              isClosable: true,
            });
          }
          if (!values.responsableRegistroEventos) {
            return toast({
              title: "Error",
              description: "Se requiere responsable",
              status: "error",
              duration: 3000,
              isClosable: true,
            });
          }

          const datos =
            type === "editar"
              ? {
                  id: seleccionado.idRegistroEventos,
                  idPlanificacion: seleccionado.idPlanificacion,
                  fechaRegistroEventos: values.fechaRegistroEventos,
                  equipoRegistroEventos: values.equipoRegistroEventos,
                  tipoRegistroEventos: values.tipoRegistroEventos,
                  valoracionRegistroEventos: values.valoracionRegistroEventos,
                  detectoRegistroEventos: values.detectoRegistroEventos,
                  descripcionRegistroEventos: values.descripcionRegistroEventos,
                  masFotosRegistroEventos: values.masFotosRegistroEventos,
                  otroRegistroEventos: values.otroRegistroEventos,
                  otroDondeRegistroEventos: values.otroDondeRegistroEventos,
                  actoresRegistroEventos: values.actoresRegistroEventos,
                  actoresQuienRegistroEventos:
                    values.actoresQuienRegistroEventos,
                  informoRegistroEventos: values.informoRegistroEventos,
                  accionesRegistroEventos: values.accionesRegistroEventos,
                  responsableAccionRegistroEventos:
                    values.responsableAccionRegistroEventos,
                  fechaAccionRegistroEventos: values.fechaAccionRegistroEventos,
                  responsableRegistroEventos: values.responsableRegistroEventos,
                  locacionRegistroEvento: values.locacionRegistroEvento,
                }
              : {
                  fechaRegistroEventos: values.fechaRegistroEventos,
                  idPlanificacion: planificacion.idPlanificacion,
                  equipoRegistroEventos: values.equipoRegistroEventos,
                  tipoRegistroEventos: values.tipoRegistroEventos,
                  valoracionRegistroEventos: values.valoracionRegistroEventos,
                  detectoRegistroEventos: values.detectoRegistroEventos,
                  descripcionRegistroEventos: values.descripcionRegistroEventos,
                  masFotosRegistroEventos: values.masFotosRegistroEventos,
                  otroRegistroEventos: values.otroRegistroEventos,
                  otroDondeRegistroEventos: values.otroDondeRegistroEventos,
                  actoresRegistroEventos: values.actoresRegistroEventos,
                  actoresQuienRegistroEventos:
                    values.actoresQuienRegistroEventos,
                  informoRegistroEventos: values.informoRegistroEventos,
                  accionesRegistroEventos: values.accionesRegistroEventos,
                  responsableAccionRegistroEventos:
                    values.responsableAccionRegistroEventos,
                  fechaAccionRegistroEventos: values.fechaAccionRegistroEventos,
                  responsableRegistroEventos: values.responsableRegistroEventos,
                  locacionRegistroEvento: values.locacionRegistroEvento,
                };
          let res;
          if (type === "editar") {
            res = await editarRegistroEvento({
              datos: datos,
              fotoRegistroEventos: imgUpload,
            });
          } else {
            res = await crearRegistroEvento({
              datos: datos,
              fotoRegistroEventos: imgUpload,
              type: "historial",
            });
          }

          if (res.status === 200) {
            toast({
              status: "success",
              isClosable: true,
              title: `Registro ${
                type === "editar" ? "editado" : "creado"
              } correctamente`,
              duration: 3000,
            });
          } else {
            return toast({
              status: "error",
              isClosable: true,
              title: `Error al ${
                type === "editar" ? "editar" : "crear"
              } registro`,
              duration: 3000,
            });
          }

          navigate(-1);
        }}
      >
        {({
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => (
          <Form id="FormularioRegistroEvento">
            <Stack Stack w="100%" pb={3}>
              <Divider w="100%" colorScheme="blackAlpha" />
              <br />
              {activeStep === 0 && (
                <PrimerPasoRegistroFijo
                  activeStep={activeStep}
                  setActiveStep={setActiveStep}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  handleSubmit={handleSubmit}
                  setFieldValue={setFieldValue}
                  values={values}
                  text={text}
                  setText={setText}
                  // initData={initData}
                  imgUpload={imgUpload}
                  setImgUpload={setImgUpload}
                  usuarios={usuarios}
                />
              )}
              {activeStep === 1 && (
                <SegundoPasoRegistroFijo
                  activeStep={activeStep}
                  setActiveStep={setActiveStep}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  handleSubmit={handleSubmit}
                  setFieldValue={setFieldValue}
                  values={values}
                  setTextAccionesInmediatas={setTextAccionesInmediatas}
                  textAccionesInmediatas={textAccionesInmediatas}
                  usuarios={usuarios}
                  // initData={initData}
                />
              )}
            </Stack>
          </Form>
        )}
      </Formik>
    </VStack>
  );
};

export default CrearEditarRegistroFijo;
