import ajax from "../../../utils/axios";

export const getClientes = async (params) => {
  let response;
  try {
    response = await ajax.get("/endpoint/clientes", { params });

    return response;
  } catch (error) {
    console.error("Error en: api-helper.js - getClientes() - Error", {
      error,
    });
    return error.response;
  }
};

export const getPlantas = async (params) => {
  let response;
  try {
    response = await ajax.get("/endpoint/plantas", { params });
    return response;
  } catch (error) {
    console.error("Error en: api-helper.js - getPlantas() - Error", {
      error,
    });
    return error.response;
  }
};

export const getCamiones = async (params) => {
  let response;
  try {
    response = await ajax.get("/endpoint/camiones", { params });
    return response;
  } catch (error) {
    console.error("Error en: api-helper.js - getCamiones() - Error", {
      error,
    });
    return error.response;
  }
};

export const getAutoelevadores = async (params) => {
  let response;
  try {
    response = await ajax.get("/endpoint/autoelevadores", { params });
    return response;
  } catch (error) {
    console.error("Error en: api-helper.js - getAutoelevadores() - Error", {
      error,
    });
    return error.response;
  }
};

export const getSander = async (params) => {
  let response;
  try {
    response = await ajax.get("/endpoint/sanders", { params });
    return response;
  } catch (error) {
    console.error("Error en: api-helper.js - getSander() - Error", {
      error,
    });
    return error.response;
  }
};

export const getSanvan = async (params) => {
  let response;
  try {
    response = await ajax.get("/endpoint/sandvan", { params });
    return response;
  } catch (error) {
    console.error("Error en: api-helper.js - getSanvan() - Error", {
      error,
    });
    return error.response;
  }
};

export const getDosificadora = async (params) => {
  let response;
  try {
    response = await ajax.get("/endpoint/dosificadora", { params });
    return response;
  } catch (error) {
    console.error("Error en: api-helper.js - getDosificadora() - Error", {
      error,
    });
    return error.response;
  }
};

export const getPlanificaciones1 = async (params) => {
  let response;
  try {
    response = await ajax.get("/endpoint/planificacion1", { params });
    return response;
  } catch (error) {
    console.error("Error en: api-helper.js - getPlanificaciones1() - Error", {
      error,
    });
    return error.response;
  }
};

export const getPlanificaciones = async (params) => {
  try {
    let response = await ajax.get("/endpoint/planificacion", { params });
    return response;
  } catch (error) {
    console.error("Error en: api-helper.js - getPlanificaciones() - Error", {
      error,
    });
    return error.response;
  }
};

export const getDetallePlantas = async (params) => {
  try {
    let response = await ajax.get("/endpoint/detalleplantas", { params });
    return response;
  } catch (error) {
    console.error("Error en: api-helper.js - getDetallePlantas() - Error", {
      error,
    });
    return error.response;
  }
};

export const crearPlanificacion = async (params) => {
  try {
    let res = await ajax.post("/endpoint/planificacion1", params);
    return res;
  } catch (error) {
    console.error(
      "Error en: api.helper.js - crearPlanificacion() - Error",
      error
    );
    return error.response.data;
  }
};

export const crearPlanificacion2 = async (params) => {
  try {
    let res = await ajax.post("/endpoint/planificacion2", params);
    return res;
  } catch (error) {
    console.error(
      "Error en: api.helper.js - crearPlanificacion2() - Error",
      error
    );
    return error.res.data;
  }
};

export const crearPlanificacion4 = async (params) => {
  try {
    let res = await ajax.post("/endpoint/planificacion4", params);
    return res;
  } catch (error) {
    console.error(
      "Error en: api.helper.js - crearPlanificacion4() - Error",
      error
    );
    return error.response.data;
  }
};

export const crearPlanificacion3 = async (params) => {
  try {
    let res = await ajax.post("/endpoint/detalleplantas", params);
    return res;
  } catch (error) {
    console.error(
      "Error en: api.helper.js - crearPlanificacion3() - Error",
      error
    );
    return error.response.data;
  }
};

export const editarPlanificacion = async (params) => {
  try {
    let res = await ajax.put("/endpoint/planificacion", params);
    return res;
  } catch (error) {
    console.error(
      "Error en: api.helper.js - editarPlanificacion() - Error",
      error
    );
    return error.response.data;
  }
};

export const editarPlanificacion1 = async (params) => {
  try {
    let res = await ajax.put("/endpoint/planificacion1", params);
    return res;
  } catch (error) {
    console.error(
      "Error en: api.helper.js - editarPlanificacion1() - Error",
      error
    );
    return error.response.data;
  }
};

export const editarPlanificacion2 = async (params) => {
  try {
    let res = await ajax.put("/endpoint/planificacion2", params);
    return res;
  } catch (error) {
    console.error(
      "Error en: api.helper.js - editarPlanificacion2() - Error",
      error
    );
    return error.response.data;
  }
};

export const editarPlanificacion3 = async (params) => {
  try {
    let res = await ajax.put("/endpoint/detalleplantas", params);
    return res;
  } catch (error) {
    console.error(
      "Error en: api.helper.js - editarPlanificacion3() - Error",
      error
    );
    return error.response.data;
  }
};

export const editarPlanificacion4 = async (params) => {
  try {
    let res = await ajax.put("/endpoint/planificacion4", params);
    return res;
  } catch (error) {
    console.error(
      "Error en: api.helper.js - editarPlanificacion4() - Error",
      error
    );
    return error.response.data;
  }
};

export const descargarPlanificacion = async (params) => {
  try {
    let res = await ajax.post("/endpoint/exportplanificacion", params, {
      responseType: "blob",
    });

    return res;
  } catch (error) {
    console.error(
      "Error en: api.helper.js - descargarPlanificacion() - Error",
      error
    );
    return error.response.data;
  }
};

export const descargarCronograma = async (params) => {
  try {
    let res = await ajax.post("/endpoint/exportcronograma", params, {
      responseType: "blob",
    });
    return res;
  } catch (error) {
    console.error(
      "Error en: api.helper.js - descargarCronograma() - Error",
      error
    );
    return error.response;
  }
};

export const eliminarPlanificacion = async (params) => {
  try {
    let res = await ajax.delete("/endpoint/planificacion", { params });
    return res;
  } catch (error) {
    console.error(
      "Error en: api.helper.js - eliminarPlanificacion() - Error",
      error
    );
    return error.response.data;
  }
};

export const getSandcubes = async () => {
  let response;
  try {
    response = await ajax.get("/endpoint/sandcubes");
    return response;
  } catch (error) {
    console.error("Error en: api-helper.js - getSandcubes() - Error", {
      error,
    });
    return error.response;
  }
};

export const editarSandcubes = async (params) => {
  try {
    let res = await ajax.put("/endpoint/sandcubes", params);
    return res;
  } catch (error) {
    console.error("Error en: api.helper.js - editarSandcubes() - Error", error);
    return error.response.data;
  }
};

export const getAsociacionCSC = async (params) => {
  let response;
  try {
    response = await ajax.get("/endpoint/asociacioncsc", { params });
    return response;
  } catch (error) {
    console.error("Error en: api-helper.js - getAsociacionCSC() - Error", {
      error,
    });
    return error.response;
  }
};

export const editarAsociacionCSC = async (params) => {
  try {
    let res = await ajax.put("/endpoint/asociacioncsc", params);
    return res;
  } catch (error) {
    console.error(
      "Error en: api.helper.js - editarAsociacionCSC() - Error",
      error
    );
    return error.response.data;
  }
};

export const crearAsociacionCSC = async (params) => {
  try {
    let res = await ajax.post("/endpoint/asociacioncsc", params);
    return res;
  } catch (error) {
    console.error(
      "Error en: api.helper.js - crearAsociacionCSC() - Error",
      error
    );
    return error.response.data;
  }
};

export const eliminarAsociacionCSC = async (params) => {
  try {
    let res = await ajax.delete("/endpoint/asociacioncsc", { params });
    return res;
  } catch (error) {
    console.error(
      "Error en: api.helper.js - eliminarAsociacionCSC() - Error",
      error
    );
    return error.response.data;
  }
};

export const editarEtapa = async (params) => {
  try {
    let res = await ajax.put("/endpoint/etapassc", params);
    return res;
  } catch (error) {
    console.error("Error en: api.helper.js - editarEtapa() - Error", error);
    return error.response;
  }
};

export const crearEtapa = async (params) => {
  try {
    let res = await ajax.post("/endpoint/etapassc", params);
    return res;
  } catch (error) {
    console.error("Error en: api.helper.js - crearEtapa() - Error", error);
    return error.response;
  }
};

export const eliminarEtapa = async (params) => {
  try {
    let res = await ajax.delete("/endpoint/etapassc", { params });
    return res;
  } catch (error) {
    console.error("Error en: api.helper.js - eliminarEtapa() - Error", error);
    return error.response.data;
  }
};

export const getEtapas = async (params) => {
  let response;
  try {
    response = await ajax.get("/endpoint/etapassc", {
      params,
    });
    return response;
  } catch (error) {
    console.error("Error en: api-helper.js - getEtapas() - Error", {
      error,
    });
    return error.response;
  }
};

export const descargarEtapas = async (params) => {
  try {
    let res = await ajax.post("/endpoint/exportetapassc", params, {
      responseType: "blob",
    });
    return res;
  } catch (error) {
    console.error("Error en: api.helper.js - descargarEtapas() - Error", error);
    return error.response;
  }
};

export const editarEstadoSandcubes = async (params) => {
  try {
    let res = await ajax.put("/endpoint/estadosandcubes", params);
    return res;
  } catch (error) {
    console.error(
      "Error en: api.helper.js - editarEstadoSandcubes() - Error",
      error
    );
    return error.response.data;
  }
};

export const getPlanificacionEtapas = async (params) => {
  let response;
  try {
    response = await ajax.get("/endpoint/planificacionetapas", { params });
    return response;
  } catch (error) {
    console.error(
      "Error en: api-helper.js - getPlanificacionEtapas() - Error",
      {
        error,
      }
    );
    return error.response;
  }
};

export const editarRegistroEvento = async (params) => {
  const headers = { "Contect-Type": "multipart/form-data" };
  let formData = new FormData();
  if (params.fotoRegistroEventos.name) {
    formData.append("fotoRegistroEventos", params.fotoRegistroEventos);
  }
  formData.append(
    "descripcionRegistroEventos",
    params.datos.descripcionRegistroEventos
  );
  formData.append(
    "masFotosRegistroEventos",
    params.datos.masFotosRegistroEventos
  );
  formData.append("otroRegistroEventos", params.datos.otroRegistroEventos);
  formData.append(
    "otroDondeRegistroEventos",
    params.datos.otroDondeRegistroEventos
  );
  formData.append(
    "actoresRegistroEventos",
    params.datos.actoresRegistroEventos
  );
  formData.append(
    "actoresQuienRegistroEventos",
    params.datos.actoresQuienRegistroEventos
  );
  formData.append(
    "informoRegistroEventos",
    params.datos.informoRegistroEventos
  );
  formData.append(
    "accionesRegistroEventos",
    params.datos.accionesRegistroEventos
  );
  formData.append(
    "responsableAccionRegistroEventos",
    params.datos.responsableAccionRegistroEventos
  );
  formData.append(
    "fechaAccionRegistroEventos",
    params.datos.fechaAccionRegistroEventos
  );
  formData.append(
    "responsableRegistroEventos",
    params.datos.responsableRegistroEventos
  );
  formData.append("idPlanificacion", params.datos.idPlanificacion);
  formData.append("fechaRegistroEventos", params.datos.fechaRegistroEventos);
  formData.append("equipoRegistroEventos", params.datos.equipoRegistroEventos);
  formData.append("tipoRegistroEventos", params.datos.tipoRegistroEventos);
  formData.append(
    "valoracionRegistroEventos",
    params.datos.valoracionRegistroEventos
  );
  formData.append(
    "detectoRegistroEventos",
    params.datos.detectoRegistroEventos
  );
  formData.append("id", params.datos.id);

  try {
    let res = await ajax.put("/endpoint/registroeventos", formData, headers);

    return res;
  } catch (error) {
    console.error(
      "Error en: api.helper.js - editarRegistroEvento() - Error",
      error
    );
    return error.response.data;
  }
};

export const crearRegistroEvento = async (params) => {
  const headers = { "Contect-Type": "multipart/form-data" };
  let formData = new FormData();
  formData.append("fotoRegistroEventos", params.fotoRegistroEventos);
  formData.append(
    "descripcionRegistroEventos",
    params.datos.descripcionRegistroEventos
  );
  formData.append(
    "masFotosRegistroEventos",
    params.datos.masFotosRegistroEventos
  );
  formData.append("otroRegistroEventos", params.datos.otroRegistroEventos);
  formData.append(
    "otroDondeRegistroEventos",
    params.datos.otroDondeRegistroEventos
  );
  formData.append(
    "actoresRegistroEventos",
    params.datos.actoresRegistroEventos
  );
  formData.append(
    "actoresQuienRegistroEventos",
    params.datos.actoresQuienRegistroEventos
  );
  formData.append(
    "informoRegistroEventos",
    params.datos.informoRegistroEventos
  );
  formData.append(
    "accionesRegistroEventos",
    params.datos.accionesRegistroEventos
  );
  formData.append(
    "responsableAccionRegistroEventos",
    params.datos.responsableAccionRegistroEventos
  );
  formData.append(
    "fechaAccionRegistroEventos",
    params.datos.fechaAccionRegistroEventos
  );
  formData.append(
    "responsableRegistroEventos",
    params.datos.responsableRegistroEventos
  );
  if (!params.type) {
    formData.append("idPlanificacion", params.datos.idPlanificacion);
  } else {
    formData.append(
      "locacionRegistroEvento",
      params.datos.locacionRegistroEvento
    );
  }
  formData.append("fechaRegistroEventos", params.datos.fechaRegistroEventos);
  formData.append("equipoRegistroEventos", params.datos.equipoRegistroEventos);
  formData.append("tipoRegistroEventos", params.datos.tipoRegistroEventos);
  formData.append(
    "valoracionRegistroEventos",
    params.datos.valoracionRegistroEventos
  );
  formData.append(
    "detectoRegistroEventos",
    params.datos.detectoRegistroEventos
  );

  try {
    let res = await ajax.post("/endpoint/registroeventos", formData, headers);

    return res;
  } catch (error) {
    console.error(
      "Error en: api.helper.js - crearRegistroEvento() - Error",
      error
    );
    return error.response.data;
  }
};

export const eliminarRegistroEvento = async (params) => {
  try {
    let res = await ajax.delete("/endpoint/registroeventos", { params });
    return res;
  } catch (error) {
    console.error(
      "Error en: api.helper.js - eliminarRegistroEvento() - Error",
      error
    );
    return error.response.data;
  }
};

export const getRegistroEvento = async (params) => {
  let response;
  try {
    response = await ajax.get("/endpoint/registroeventos", { params });
    return response;
  } catch (error) {
    console.error("Error en: api-helper.js - getRegistroEvento() - Error", {
      error,
    });
    return error.response;
  }
};

export const descargarEventos = async (params) => {
  try {
    let res = await ajax.post("/endpoint/exportregistros", params, {
      responseType: "blob",
    });
    return res;
  } catch (error) {
    console.error(
      "Error en: api.helper.js - descargarEventos() - Error",
      error
    );
    return error.response;
  }
};

export const descargarEventoPDF = async (params) => {
  try {
    let res = await ajax.post("/endpoint/exportregistrosPDF", params, {
      responseType: "blob",
    });
    return res;
  } catch (error) {
    console.error(
      "Error en: api.helper.js - descargarEventoPDF() - Error",
      error
    );
    return error.response;
  }
};

export const descargarRevisiones = async (params) => {
  try {
    let res = await ajax.post("/endpoint/exportrevisiones", params, {
      responseType: "blob",
    });
    return res;
  } catch (error) {
    console.error(
      "Error en: api.helper.js - descargarRevisiones() - Error",
      error
    );
    return error.response;
  }
};

export const getRevisionAutoelevadores = async (params) => {
  let response;
  try {
    response = await ajax.get("/endpoint/revisionautoelevador", { params });
    return response;
  } catch (error) {
    console.error(
      "Error en: api-helper.js - getRevisionAutoelevadores() - Error",
      {
        error,
      }
    );
    return error.response;
  }
};

export const editarRevisionAutoelevadores = async (params) => {
  try {
    let res = await ajax.put("/endpoint/revisionautoelevador", params);
    return res;
  } catch (error) {
    console.error(
      "Error en: api.helper.js - editarRevisionAutoelevadores() - Error",
      error
    );
    return error.response.data;
  }
};

export const crearRevisionAutoelevadores = async (params) => {
  try {
    let res = await ajax.post("/endpoint/revisionautoelevador", params);
    return res;
  } catch (error) {
    console.error(
      "Error en: api.helper.js - crearRevisionAutoelevadores() - Error",
      error
    );
    return error.response.data;
  }
};

export const eliminarRevisionAutoelevadores = async (params) => {
  try {
    let res = await ajax.delete("/endpoint/revisionautoelevador", { params });
    return res;
  } catch (error) {
    console.error(
      "Error en: api.helper.js - eliminarRevisionAutoelevadores() - Error",
      error
    );
    return error.response.data;
  }
};

export const getRevisionGeneradores = async (params) => {
  let response;
  try {
    response = await ajax.get("/endpoint/revisiongenerador", { params });
    return response;
  } catch (error) {
    console.error(
      "Error en: api-helper.js - getRevisionGeneradores() - Error",
      {
        error,
      }
    );
    return error.response;
  }
};

export const editarRevisionGeneradores = async (params) => {
  try {
    let res = await ajax.put("/endpoint/revisiongenerador", params);
    return res;
  } catch (error) {
    console.error(
      "Error en: api.helper.js - editarRevisionGeneradores() - Error",
      error
    );
    return error.response.data;
  }
};

export const crearRevisionGeneradores = async (params) => {
  try {
    let res = await ajax.post("/endpoint/revisiongenerador", params);
    return res;
  } catch (error) {
    console.error(
      "Error en: api.helper.js - crearRevisionGeneradores() - Error",
      error
    );
    return error.response.data;
  }
};

export const eliminarRevisionGeneradores = async (params) => {
  try {
    let res = await ajax.delete("/endpoint/revisiongenerador", { params });
    return res;
  } catch (error) {
    console.error(
      "Error en: api.helper.js - eliminarRevisionGeneradores() - Error",
      error
    );
    return error.response.data;
  }
};

export const getRevisionMiniPala = async (params) => {
  let response;
  try {
    response = await ajax.get("/endpoint/revisionminipala", { params });
    return response;
  } catch (error) {
    console.error("Error en: api-helper.js - getRevisionMiniPala() - Error", {
      error,
    });
    return error.response;
  }
};

export const editarRevisionMiniPala = async (params) => {
  try {
    let res = await ajax.put("/endpoint/revisionminipala", params);
    return res;
  } catch (error) {
    console.error(
      "Error en: api.helper.js - editarRevisionMiniPala() - Error",
      error
    );
    return error.response.data;
  }
};

export const crearRevisionMiniPala = async (params) => {
  try {
    let res = await ajax.post("/endpoint/revisionminipala", params);
    return res;
  } catch (error) {
    console.error(
      "Error en: api.helper.js - crearRevisionMiniPala() - Error",
      error
    );
    return error.response.data;
  }
};

export const eliminarRevisionMiniPala = async (params) => {
  try {
    let res = await ajax.delete("/endpoint/revisionminipala", { params });
    return res;
  } catch (error) {
    console.error(
      "Error en: api.helper.js - eliminarRevisionMiniPala() - Error",
      error
    );
    return error.response.data;
  }
};

export const getRevisionSanders = async (params) => {
  let response;
  try {
    response = await ajax.get("/endpoint/revisionsander", { params });
    return response;
  } catch (error) {
    console.error("Error en: api-helper.js - getRevisionSanders() - Error", {
      error,
    });
    return error.response;
  }
};

export const editarRevisionSanders = async (params) => {
  try {
    let res = await ajax.put("/endpoint/revisionsander", params);
    return res;
  } catch (error) {
    console.error(
      "Error en: api.helper.js - editarRevisionSanders() - Error",
      error
    );
    return error.response.data;
  }
};

export const crearRevisionSanders = async (params) => {
  try {
    let res = await ajax.post("/endpoint/revisionsander", params);
    return res;
  } catch (error) {
    console.error(
      "Error en: api.helper.js - crearRevisionSanders() - Error",
      error
    );
    return error.response.data;
  }
};

export const eliminarRevisionSanders = async (params) => {
  try {
    let res = await ajax.delete("/endpoint/revisionsander", { params });
    return res;
  } catch (error) {
    console.error(
      "Error en: api.helper.js - eliminarRevisionSanders() - Error",
      error
    );
    return error.response.data;
  }
};

export const getRevisionDosificadoras = async (params) => {
  let response;
  try {
    response = await ajax.get("/endpoint/revisiondosificadora", { params });
    return response;
  } catch (error) {
    console.error(
      "Error en: api-helper.js - getRevisionDosificadoras() - Error",
      {
        error,
      }
    );
    return error.response;
  }
};

export const editarRevisionDosificadoras = async (params) => {
  try {
    let res = await ajax.put("/endpoint/revisiondosificadora", params);
    return res;
  } catch (error) {
    console.error(
      "Error en: api.helper.js - editarRevisionDosificadoras() - Error",
      error
    );
    return error.response.data;
  }
};

export const crearRevisionDosificadoras = async (params) => {
  try {
    let res = await ajax.post("/endpoint/revisiondosificadora", params);
    return res;
  } catch (error) {
    console.error(
      "Error en: api.helper.js - crearRevisionDosificadoras() - Error",
      error
    );
    return error.response.data;
  }
};

export const eliminarRevisionDosificadoras = async (params) => {
  try {
    let res = await ajax.delete("/endpoint/revisiondosificadora", { params });
    return res;
  } catch (error) {
    console.error(
      "Error en: api.helper.js - eliminarRevisionEntreTurno() - Error",
      error
    );
    return error.response.data;
  }
};

export const descargarHistorialPlanificaciones = async (params) => {
  try {
    let res = await ajax.post("/endpoint/exporthistorial", params, {
      responseType: "blob",
    });
    return res;
  } catch (error) {
    console.error(
      "Error en: api.helper.js - descargarHistorialPlanificaciones() - Error",
      error
    );
    return error.response;
  }
};

export const getReportesViajes = async (params) => {
  let response;
  try {
    response = await ajax.get("/endpoint/reporteviaje", { params });
    return response;
  } catch (error) {
    console.error("Error en: api-helper.js - getReportesViajes() - Error", {
      error,
    });
    return error.response;
  }
};

export const editarReportesViajes = async (params) => {
  try {
    let res = await ajax.put("/endpoint/reporteviaje", params);
    return res;
  } catch (error) {
    console.error(
      "Error en: api.helper.js - editarReportesViajes() - Error",
      error
    );
    return error.response.data;
  }
};

export const PUTimgRemito = async (params) => {
  const headers = { "Contect-Type": "multipart/form-data" };
  let formData = new FormData();
  formData.append("imgRemitoRV", params.imgRemitoRV);
  formData.append("id", params.id);

  try {
    let res = await ajax.put("/endpoint/reporteviaje", formData, headers);
    return res;
  } catch (error) {
    console.error("Error en: api.helper.js - PUTimgRemito() - Error", error);
    return error.response.data;
  }
};

export const descargarReportes = async (params) => {
  try {
    let res = await ajax.post("/endpoint/exportreporteviaje", params, {
      responseType: "blob",
    });
    return res;
  } catch (error) {
    console.error(
      "Error en: api.helper.js - descargarReportes() - Error",
      error
    );
    return error.response;
  }
};

export const getRevisionLuminaria = async (params) => {
  let response;
  try {
    response = await ajax.get("/endpoint/revisionluminaria", { params });
    return response;
  } catch (error) {
    console.error("Error en: api-helper.js - getRevisionLuminaria() - Error", {
      error,
    });
    return error.response;
  }
};

export const editarRevisionLuminaria = async (params) => {
  try {
    let res = await ajax.put("/endpoint/revisionluminaria", params);
    return res;
  } catch (error) {
    console.error(
      "Error en: api.helper.js - editarRevisionLuminaria() - Error",
      error
    );
    return error.response.data;
  }
};

export const crearRevisionLuminaria = async (params) => {
  try {
    let res = await ajax.post("/endpoint/revisionluminaria", params);
    return res;
  } catch (error) {
    console.error(
      "Error en: api.helper.js - crearRevisionLuminaria() - Error",
      error
    );
    return error.response.data;
  }
};

export const eliminarRevisionLuminaria = async (params) => {
  try {
    let res = await ajax.delete("/endpoint/revisionluminaria", { params });
    return res;
  } catch (error) {
    console.error(
      "Error en: api.helper.js - eliminarRevisionLuminaria() - Error",
      error
    );
    return error.response.data;
  }
};

export const getRevisionBaseSV = async (params) => {
  let response;
  try {
    response = await ajax.get("/endpoint/revisionbasesandvan", { params });
    return response;
  } catch (error) {
    console.error("Error en: api-helper.js - getRevisionBaseSV() - Error", {
      error,
    });
    return error.response;
  }
};

export const editarRevisionBaseSV = async (params) => {
  try {
    let res = await ajax.put("/endpoint/revisionbasesandvan", params);
    return res;
  } catch (error) {
    console.error(
      "Error en: api.helper.js - editarRevisionBaseSV() - Error",
      error
    );
    return error.response.data;
  }
};

export const crearRevisionBaseSV = async (params) => {
  try {
    let res = await ajax.post("/endpoint/revisionbasesandvan", params);
    return res;
  } catch (error) {
    console.error(
      "Error en: api.helper.js - crearRevisionBaseSV() - Error",
      error
    );
    return error.response.data;
  }
};

export const eliminarRevisionBaseSV = async (params) => {
  try {
    let res = await ajax.delete("/endpoint/revisionbasesandvan", { params });
    return res;
  } catch (error) {
    console.error(
      "Error en: api.helper.js - eliminarRevisionBaseSV() - Error",
      error
    );
    return error.response.data;
  }
};

export const editarRevisionSandvan = async (params) => {
  try {
    let res = await ajax.put("/endpoint/revisionSandVan", params);
    return res;
  } catch (error) {
    console.error(
      "Error en: api.helper.js - editarRevisionSandvan() - Error",
      error
    );
    return error.response.data;
  }
};

export const crearRevisionSandvan = async (params) => {
  try {
    let res = await ajax.post("/endpoint/revisionSandVan", params);
    return res;
  } catch (error) {
    console.error(
      "Error en: api.helper.js - crearRevisionSandvan() - Error",
      error
    );
    return error.response.data;
  }
};

export const eliminarRevisionSandvan = async (params) => {
  try {
    let res = await ajax.delete("/endpoint/revisionSandVan", { params });
    return res;
  } catch (error) {
    console.error(
      "Error en: api.helper.js - eliminarRevisionSandvan() - Error",
      error
    );
    return error.response.data;
  }
};

export const getRevisionSandvan = async (params) => {
  let response;
  try {
    response = await ajax.get("/endpoint/revisionSandVan", { params });
    return response;
  } catch (error) {
    console.error("Error en: api-helper.js - getRevisionSandvan() - Error", {
      error,
    });
    return error.response;
  }
};

export const getUltimasRevisiones = async (params) => {
  let response;
  try {
    response = await ajax.get("/endpoint/revisiongral", { params });
    return response;
  } catch (error) {
    console.error("Error en: api-helper.js - getUltimasRevisiones() - Error", {
      error,
    });
    return error.response;
  }
};

export const editarSandcubesPlanificacion = async (params) => {
  try {
    let res = await ajax.put("/endpoint/planisandcube", params);
    return res;
  } catch (error) {
    console.error(
      "Error en: api.helper.js - editarSandcubesPlanificacion() - Error",
      error
    );
    return error.response.data;
  }
};

export const crearSandcubesPlanificacion = async (params) => {
  try {
    let res = await ajax.post("/endpoint/planisandcube", params);
    return res;
  } catch (error) {
    console.error(
      "Error en: api.helper.js - crearSandcubesPlanificacion() - Error",
      error
    );
    return error.response.data;
  }
};

export const getRevisionGenerales = async (params) => {
  let response;
  try {
    response = await ajax.get("/endpoint/revisiongral", { params });
    return response;
  } catch (error) {
    console.error("Error en: api-helper.js - getRevisionGenerales() - Error", {
      error,
    });
    return error.response;
  }
};
