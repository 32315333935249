import React, { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ColorModeScript } from "@chakra-ui/react";
import { Provider } from "react-redux";
import store from "./redux/store";
import { Global } from "@emotion/react";
import { LoadScript } from "@react-google-maps/api";
import "material-icons/iconfont/material-icons.css";
import { PublicClientApplication, EventType } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./pages/auth/helpers/api.helper";

const msalInstance = new PublicClientApplication(msalConfig);
const root = ReactDOM.createRoot(document.getElementById("root"));

const libs = ["visualization", "geometry", "drawing", "places"];

const CustomScrollbarStyles = () => (
  <Global
    styles={`
      ::-webkit-scrollbar {
        width: 8px;
        height: 5px;
      }

      ::-webkit-scrollbar-thumb {
        background-color: #888;
        border-radius: 10px;
      }

      ::-webkit-scrollbar-thumb:hover {
        background-color: #555;
      }

      ::-webkit-scrollbar-track {
        background-color: #f1f1f1;
      }
    `}
  />
);

await msalInstance.initialize();
await msalInstance
  .handleRedirectPromise()
  .then((response) => {
    if (response) {
      msalInstance.setActiveAccount(response.account);
    }
  })
  .catch((error) => {
    console.error("Error durante el handleRedirectPromise: ", error);
  });

msalInstance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS) {
    const account = event.payload.account;
    msalInstance.setActiveAccount(account);
  }
});

root.render(
  <StrictMode>
    <Provider store={store}>
      <LoadScript
        libraries={libs}
        googleMapsApiKey={process.env.REACT_APP_API_KEY}
      >
        <ColorModeScript />
        <CustomScrollbarStyles />
        <MsalProvider instance={msalInstance}>
          <App />
        </MsalProvider>
      </LoadScript>
    </Provider>
  </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
