import {
  VStack,
  Box,
  Heading,
  Text,
  Stack,
  HStack,
  Button,
  Divider,
  Icon,
  Progress,
  useToast,
  CircularProgress,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { MdOutlineAirportShuttle, MdFileDownloadDone } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Sandcube } from "../../assets/iconos/Sand.svg";
import { ReactComponent as Sandvan } from "../../assets/iconos/SandVan.svg";
import { ReactComponent as Sander } from "../../assets/iconos/Sander.svg";
import { useSelector } from "react-redux";
import { getPlanificaciones } from "../operaciones/helpers/api.helper";
import { getNotificacionesII } from "../config/helpers/api.helper";
import moment from "moment";
import { regionalFormattingNumbers } from "../../utils/regionalFormattingNumbers";

const Dashboard = (props) => {
  const { mobile, setNotificaciones } = props;
  const [operaciones, setOperaciones] = useState([]);
  const [loading, setLoading] = useState(true);
  const { user } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const toast = useToast();
  let today = moment().format("YYYY-MM-DD");

  const initData = async () => {
    let res;

    res = await getPlanificaciones({ estado: "activo" });

    if (res.status === 200) {
      setOperaciones(
        res.data.sort((a, b) => {
          const nameA = a.idSET.toUpperCase(); // ignore upper and lowercase
          const nameB = b.idSET.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }

          // names must be equal
          return 0;
        })
      );
    } else if (res.status === 204) {
      toast({
        status: "success",
        description: "Cargadas correctamente",
        isClosable: true,
        duration: 2000,
      });
    } else {
      toast({
        status: "error",
        description: "Error al cargar las planificaciones",
        title: "Error",
        isClosable: true,
        duration: 2000,
      });
    }

    res = await getNotificacionesII({ destinatarioNBS: user.username });

    if (res.status === 200) {
      setNotificaciones(res.data);
    }
    setLoading(false);
  };

  const handleCantTransportistas = (operacion) => {
    const valoresUnicos = {};
    const resultado = [];
    const camiones = operacion.camiones
      ? operacion.camiones.filter((e) => e.camionActivo === "true")
      : [];
    const conteoTransportista = [];
    let result;
    for (const objeto of camiones) {
      const valorPropiedad = objeto.idEmpresaT;

      if (!(valorPropiedad in valoresUnicos)) {
        valoresUnicos[valorPropiedad] = true;
        resultado.push(objeto);
      }
    }

    result = resultado;
    camiones.forEach((obj) => {
      const idEmpresa = obj.idEmpresaT;
      if (idEmpresa !== undefined && idEmpresa !== null) {
        if (!conteoTransportista[idEmpresa]) {
          conteoTransportista[idEmpresa] = 1;
        } else {
          conteoTransportista[idEmpresa]++;
        }
      }
    });
    result = result.map((objeto) => ({
      nombre: objeto.empresaTransportista.nombreEmpresaT,
      cantidad: conteoTransportista[objeto.idEmpresaT],
    }));

    if (result.length === 0) {
      return (
        <HStack w={"100%"} minHeight={"9.25%"}>
          <Text
            fontSize={["10px", "10px", "10px", "12px", "14px"]}
            textAlign="center"
          >
            No hay camiones activos
          </Text>
        </HStack>
      );
    }

    return (
      <HStack w={"100%"} minHeight={"9.25%"}>
        {result.map((element, index) => (
          <HStack key={index}>
            <Box>
              <HStack>
                <Icon
                  fontSize={["20px", "20px", "24px", "30px", "30px"]}
                  as={MdOutlineAirportShuttle}
                  color="gray.400"
                />
                <Text
                  fontSize={["8px", "10px", "10px", "10px", "12px"]}
                  textColor={"brand.naranja"}
                  textTransform={"uppercase"}
                >
                  {element.nombre}
                </Text>
              </HStack>
              <HStack>
                <Text
                  fontSize={["8px", "10px", "10px", "10px", "12px"]}
                  textAlign="center"
                >
                  {element.cantidad === 1
                    ? `${element.cantidad} camión`
                    : `${element.cantidad} camiones`}
                </Text>
              </HStack>
            </Box>
            <Divider orientation="vertical" />
          </HStack>
        ))}
      </HStack>
    );
  };

  const handleEstado = (e) => {
    switch (e) {
      case "activo":
        return "Activo";
      case "inactivo":
        return "Inactivo";
      default:
        break;
    }
  };

  const handleCompletasDia = (element) => {
    let hoy = element.filter((e) => {
      return moment(e.fechaFinalEtapaSC).format("YYYY-MM-DD") === today;
    });

    return hoy.length;
  };

  const handleTnsOperada = (elemento) => {
    let totalSC;
    if (elemento.etapasSC) {
      totalSC = elemento.etapasSC
        .filter((e) => e.sandCubes)
        .reduce(
          (acc, cuerrentValue) => acc + cuerrentValue.sandCubes?.length,
          0
        );
    } else {
      return 0;
    }
    return regionalFormattingNumbers({ number: totalSC * 13.5, digits: 0 });
  };

  const handlePorcetajeCompleto = (completas, elemento) => {
    let porcentaje;
    if (completas) {
      let total = elemento.pozos.reduce((acumulador, elemento) => {
        return acumulador + parseInt(elemento.numeroEtapasPozo);
      }, 0);

      porcentaje =
        (completas[completas.length - 1].numTotalEtapaSC / total) * 100;

      const integerPercentage = Math.floor(porcentaje);
      return integerPercentage;
    } else {
      return 0;
    }
  };

  const handlePromedio = (fecha) => {
    let diasOperados = moment(today).diff(fecha[0].fechaFinalEtapaSC, "days");
    let promedio = fecha[fecha.length - 1].numTotalEtapaSC / (diasOperados + 1);

    return regionalFormattingNumbers({
      number: promedio.toFixed(2),
      digits: 2,
    });
  };

  const loadingtable = () => {
    return (
      <HStack w="100%" placeContent="center">
        <CircularProgress isIndeterminate color="brand.naranja" />
      </HStack>
    );
  };
  const capitalizarNombre = (nombre) => {
    if (typeof nombre !== "string" || !nombre.trim()) {
      return "";
    }
    nombre = nombre.trim();
    const [primeraPalabra] = nombre.split(" "); // Obtiene la primera palabra antes de cualquier espacio
    let name =
      primeraPalabra.charAt(0).toUpperCase() +
      primeraPalabra.slice(1).toLowerCase();
    return name;
  };
  useEffect(() => {
    initData();
  }, []);

  return (
    <Stack w="100%" pl={7}>
      <Heading
        textColor="brand.azul_text"
        fontWeight="medium"
        textAlign="left"
        size={["sm", "md", "md", "md", "lg"]}
      >
        ¡Hola, {capitalizarNombre(user.nombre)}!
      </Heading>
      <Text fontSize="12px">
        Este es un detalle de las operaciones activas.
      </Text>

      {loading && loadingtable()}
      {!loading && operaciones.length > 0 && (
        <Stack
          w="100%"
          direction={mobile ? "row" : "column"}
          spacing={5}
          pr={3}
        >
          {operaciones.map((operacion, index) => {
            const text =
              operacion.estado === "activo"
                ? "brand.naranja"
                : "brans.gris_medio";
            return (
              <Stack
                w={"50%"}
                key={operacion.idPlanificacion * 2 + index}
                bg="brand.fondos_primarios"
                borderRadius={10}
                opacity={operacion.estado === "activo" ? 1 : 0.7}
                placeSelf={!mobile ? "center" : "auto"}
              >
                <Stack w="100%" h="100%" justifyContent="space-around" px={3}>
                  <Text
                    fontSize={["10px", "12px", "12px", "12px", "14px"]}
                    w="100%"
                    textAlign="left"
                    pt={2}
                  >
                    {operacion.idSET === "set1" ? "SET 1" : "SET 2"}
                  </Text>
                  <HStack w="100%" justifyContent="space-between">
                    <Text
                      fontSize={["14px", "18px", "18px", "18px", "20px"]}
                      color={"brand.azul_text"}
                      fontWeight={"bold"}
                    >
                      {operacion.idPadlocacion.toUpperCase()}
                    </Text>
                    <Text
                      fontSize={["10px", "16px", "14px", "16px", "18px"]}
                      color={"brand.verde"}
                      verticalAlign="baseline"
                    >
                      {handleEstado(operacion.estado)}
                    </Text>
                  </HStack>
                  <Divider h="1px" bg="brand.gris_primario" />
                  <HStack w="100%">
                    <Stack
                      w="70%"
                      h="100%"
                      spacing={-1}
                      justifyContent="space-between"
                    >
                      <Text
                        w="100%"
                        fontSize={["16px", "22px", "18px", "22px", "28px"]}
                        color="brand.gris_primario"
                        fontWeight="bold"
                      >
                        Etapas
                      </Text>
                      <HStack w="100%" justifyContent="space-around">
                        <Box
                          w={["8rem", "12rem", "10rem", "12rem", "12rem"]}
                          bg={"brand.azul_text"}
                          textAlign="left"
                          display="flex"
                          flexDir="row"
                          borderRadius={5}
                          p={3}
                        >
                          <Text
                            placeSelf="start"
                            fontWeight="bold"
                            fontSize={["30px", "36px", "36px", "36px", "40px"]}
                            textColor="white"
                            pr={3}
                          >
                            {operacion.etapasSC
                              ? operacion.etapasSC[
                                  operacion.etapasSC.length - 1
                                ].numTotalEtapaSC
                              : 0}
                          </Text>
                          <Text
                            textColor="white"
                            fontSize={["10px", "16px", "14px", "16px", "18px"]}
                          >
                            Completadas en total
                          </Text>
                        </Box>
                        <VStack w="100%">
                          <HStack>
                            <Text
                              fontSize={[
                                "16px",
                                "28px",
                                "22px",
                                "28px",
                                "34px",
                              ]}
                              fontWeight="bold"
                            >
                              {handlePorcetajeCompleto(
                                operacion.etapasSC,
                                operacion
                              )}
                              %
                            </Text>
                            <Text
                              fontSize={[
                                "10px",
                                "12px",
                                "12px",
                                "12px",
                                "14px",
                              ]}
                            >
                              pad <br />
                              completo
                            </Text>
                          </HStack>
                          <Box bg="white" w="70%" borderRadius={5}>
                            <Progress
                              aria-labelledby="labeldiv"
                              borderRadius={5}
                              colorScheme="orange"
                              size="md"
                              max={100}
                              min={0}
                              value={handlePorcetajeCompleto(
                                operacion.etapasSC || 0,
                                operacion
                              )}
                              border={"1px solid #FF8200"}
                            />
                          </Box>

                          <Text
                            fontSize={["10px", "12px", "12px", "12px", "14px"]}
                          >
                            {operacion.etapasSC
                              ? `Completas hoy: ${handleCompletasDia(
                                  operacion.etapasSC
                                )}`
                              : "Completas hoy: 0"}
                          </Text>
                          <Text
                            fontSize={["10px", "12px", "12px", "12px", "14px"]}
                          >
                            {operacion.etapasSC
                              ? `Promedio: ${handlePromedio(
                                  operacion.etapasSC
                                )}`
                              : "Promedio: 0"}
                          </Text>
                        </VStack>
                      </HStack>
                    </Stack>
                    <Divider
                      h="100%"
                      w="1px"
                      bg="brand.gris_primario"
                      orientation="vertical"
                    />
                    <Stack pl={4} w="30%" textAlign="left">
                      <Text
                        w="100%"
                        fontSize={["16px", "22px", "18px", "22px", "28px"]}
                        color="brand.gris_primario"
                        fontWeight="bold"
                      >
                        Arena
                      </Text>
                      <VStack w="100%">
                        <Text
                          w="100%"
                          fontWeight="bold"
                          color={"brand.azul_text"}
                          fontSize={["18px", "30px", "24px", "30px", "36px"]}
                        >
                          {handleTnsOperada(operacion)}
                        </Text>
                        <Stack
                          w="100%"
                          fontSize={["10px", "12px", "12px", "12px", "14px"]}
                          spacing={1}
                        >
                          <Text>toneladas operadas</Text>
                        </Stack>
                      </VStack>
                    </Stack>
                  </HStack>
                  <Divider h="1px" bg="brand.gris_primario" />
                  <Text
                    fontSize={["18px", "18px", "20px", "24px", "30px"]}
                    fontWeight="bold"
                    placeSelf="start"
                    color="brand.gris_primario"
                  >
                    Equipamiento
                  </Text>
                  <HStack w="100%" justifyContent="space-around">
                    <HStack>
                      <Box w={[10, 10, 12, 14, 16]} h={[10, 10, 12, 14, 16]}>
                        <Sandvan />
                      </Box>
                      <VStack textAlign="left" spacing={0.5}>
                        <Text
                          fontSize={["10px", "12px", "12px", "12px", "14px"]}
                          textColor={"brand.naranja"}
                        >
                          ID SandVan
                        </Text>
                        <Text
                          fontSize={["10px", "12px", "12px", "12px", "14px"]}
                        >
                          {operacion.sandvan
                            ? operacion.sandvan.codigoSandVan
                            : "--"}
                        </Text>
                      </VStack>
                    </HStack>
                    <HStack>
                      <Box w={[10, 10, 12, 14, 16]} h={[10, 10, 12, 14, 16]}>
                        <Sander />
                      </Box>
                      <VStack textAlign="left" spacing={0.5}>
                        <Text
                          fontSize={["10px", "12px", "12px", "12px", "14px"]}
                          textColor={text}
                        >
                          ID Sander
                        </Text>
                        <Text
                          fontSize={["10px", "12px", "12px", "12px", "14px"]}
                        >
                          {operacion.sanderPrincipal
                            ? operacion.sanderPrincipal.codigoSander
                            : "--"}
                          <br />
                          {operacion.sanderBackup
                            ? operacion.sanderBackup.codigoSander
                            : "--"}
                        </Text>
                      </VStack>
                    </HStack>
                    <HStack>
                      <Box w={[10, 10, 12, 14, 16]} h={[10, 10, 12, 14, 16]}>
                        <Sandcube />
                      </Box>
                      <VStack textAlign="left" spacing={0.5}>
                        <Text
                          fontSize={["10px", "12px", "12px", "12px", "14px"]}
                          textColor={text}
                        >
                          Cantidad total
                        </Text>
                        <Text
                          fontSize={["10px", "12px", "12px", "12px", "14px"]}
                        >
                          {`${operacion.SandCubes}  Sandcubes`}
                        </Text>
                      </VStack>
                    </HStack>
                  </HStack>
                  <Divider h="1px" bg="brand.gris_primario" />
                  <Text
                    fontSize={["16px", "22px", "18px", "22px", "28px"]}
                    color="brand.gris_primario"
                    fontWeight="bold"
                    placeSelf="start"
                  >
                    Empresas de Logística
                  </Text>
                  {handleCantTransportistas(operacion)}
                </Stack>
                <Divider
                  h="1px"
                  bg="brand.gris_primario"
                  w="96%"
                  marginInlineStart={"10px !important"}
                />
                <HStack w="100%" justifyContent="end" px={5} pb={2}>
                  <Button
                    color="white"
                    letterSpacing="1px"
                    fontSize={["10px", "12px", "12px", "12px", "14px"]}
                    bg={"brand.naranja"}
                    leftIcon={<MdFileDownloadDone size={18} />}
                    _hover={{
                      bg: "brand.fondos_primarios",
                      color: "brand.naranja",
                    }}
                    onClick={() => {
                      navigate(`/detalleOperacion/${operacion.idPadlocacion}`);
                    }}
                  >
                    Ver detalle
                  </Button>
                </HStack>
              </Stack>
            );
          })}
        </Stack>
      )}
      {!loading && operaciones.length <= 0 && (
        <Box py={5}>
          <Heading textAlign={"center"} size="xl">
            No hay operaciones activas
          </Heading>
        </Box>
      )}
    </Stack>
  );
};

export default Dashboard;
